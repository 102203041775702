import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DateFilter } from '../../../common/DateFilter'
import CustomPagination from '../../../common/Pagination/customPagination'
import { fetchBookings } from '../../../../features/userProfile/bookingsSlice'

import React from 'react'
import updateMeta from '../../../../helpers/seo-meta'


const initialStates = {
    search_key: '',
}

const MyBookings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { bookings, isLoading, metaData } = useSelector((state) => state.booking)
    const [params, setParams] = useState(initialStates)
    const [value, setValue] = useState('ALL')
    const [currentPage, setCurrentPage] = useState(1)

    const [date, setDate] = useState({
        start_date: '',
        end_date: '',
    })

    useEffect(() => {
        dispatch(fetchBookings(params.search_key, date.start_date, date.end_date,currentPage))
    }, [])

    const handleSearch = (event) => {
        setParams({ ...params, [event.target.name]: event.target.value })
    }
    const clear = () => {
        if(params.search_key || date.end_date && date.start_date){
            setDate({
                start_date: '',
             end_date: '', 
            })
            setParams(initialStates) 
            setValue('ALL')
            setCurrentPage(1)
            dispatch(fetchBookings('', '', '',1))
        }
       
    }


    const onDateSelect = (dates) => {
        setDate(dates)
        dispatch(fetchBookings(params.search_key, dates.start_date, dates.end_date,currentPage))
    }

    const handleSubmit = () => {
        dispatch(fetchBookings(params.search_key, date.start_date, date.end_date,1))
        setCurrentPage(1)
    }
    useEffect(() => {
        dispatch(fetchBookings(params.search_key, date.start_date, date.end_date,currentPage))
    }, [currentPage])

    useEffect(() => {
        document.title = 'Zain care | High-Quality, Affordable Healthcare at your Home';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);

    return (
        <>
            <div
                className={`${
                    bookings?.length > 0 ? "md:h-auto" : "md:h-fit"
                } bg-white h-screen w-full md:bg-with-shadow md:w-[50%] lg:w-[70%] flex flex-col rounded-lg gap-2 md:gap-4 md:pt-2 md:px-5 lg:px-8 md:pb-5 pb-6`}
            >
                <div className="md:hidden  flex justify-start items-center gap-4 border-b border-Harp px-6 py-3">
                    <img
                        className=" cursor-pointer"
                        onClick={() => navigate("/user-profile")}
                        src='/images/icons/leftArrow.svg'
                        alt="Left Arrow"
                    />
                    <p className="text-DarkGrey text-base font-satoshiBold">
                        My Bookings
                    </p>
                </div>

                <div className="md:mt-6 mb-1 flex flex-col lg:flex-row items-center justify-between md:gap-2 gap-6 px-5 md:px-0">
                    <div className="w-full relative flex md:mb-3 lg:mb-0">
                        <input
                            type="search"
                            className="w-full placeholder:text-xs md:placeholder:text-sm placeholder:font-medium bg-grey p-4 rounded-lg outline-none"
                            placeholder="Search your orders"
                            onChange={handleSearch}
                            name="search_key"
                            value={params.search_key}
                        />

                        <div
                            onClick={handleSubmit}
                            className="cursor-pointer rounded-lg absolute right-0 flex justify-center p-4 bg-Verdigris w-16"
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                                    fill="white"
                                />
                                <path
                                    d="M21.9999 22.7514C21.8099 22.7514 21.6199 22.6814 21.4699 22.5314L19.4699 20.5314C19.1799 20.2414 19.1799 19.7614 19.4699 19.4714C19.7599 19.1814 20.2399 19.1814 20.5299 19.4714L22.5299 21.4714C22.8199 21.7614 22.8199 22.2414 22.5299 22.5314C22.3799 22.6814 22.1899 22.7514 21.9999 22.7514Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <DateFilter
                        onDateRangeSelect={onDateSelect}
                        value={value}
                        setValue={setValue}
                    />
                </div>

                <div className={`relative -top-3`}>
                    <span
                        onClick={clear}
                        className="underline text-BlueJay text-right absolute right-0"
                    >
                        Reset
                    </span>
                </div>

                {
                    <p className="md:block hidden md:mb-2 lg:text-2xl md:text-xl text-BlueJay font-satoshiMedium">
                        My Bookings
                    </p>
                }
                {isLoading ? (
                    <div className="flex flex-row justify-center items-center w-full h-full">
                        <p className="text-2xl font-satoshiBold text-BlueJay">
                            Loading...
                        </p>
                    </div>
                ) : (
                    <></>
                )}
                {bookings?.length && !isLoading ? (
                    <>
                        <div
                            className={`mt-2 md:mt-0 h-screen md:h-auto flex flex-col gap-4 overflow-auto max-h-[600px] md:max-h-[540px] ${
                                bookings?.length < 3
                                    ? "md:pr-0 lg:pr-0"
                                    : "md:pr-4 pr-0 mr-4 md:mr-0"
                            }`}
                        >
                            {bookings?.map((item) => {
                                return (
                                    <div className="bg-WhiteLilac lg:p-4 p-2 rounded-lg mx-5 md:mx-0">
                                        <div className="bg-white rounded-lg p-4">
                                            <div className="flex justify-start items-center mb-3 gap-1">
                                                <p className="text-secondaryLight text-lg font-satoshiMedium">
                                                    Booking ID :{" "}
                                                </p>
                                                <p className="font-satoshiBold text-BlueJay text-lg">
                                                    {item?.id}
                                                </p>
                                            </div>
                                            <div className="flex justify-start items-center mb-2 gap-1">
                                                <p className="text-secondaryLight text-sm font-satoshiMedium">
                                                    Order Date :{" "}
                                                </p>
                                                <p className="font-satoshiBold text-BlueJay text-sm">
                                                    {moment(
                                                        item?.created_at
                                                    ).format("Do MMMM, YYYY")}
                                                </p>
                                            </div>
                                            <div className="flex justify-start items-center mb-2 gap-1">
                                                <p className="text-secondaryLight text-sm font-satoshiMedium">
                                                    Order Status :{" "}
                                                </p>
                                                {item?.status === "PENDING" ? (
                                                    <p className="font-satoshiBold text-[#EF4949] text-sm">
                                                        {item?.status}
                                                    </p>
                                                ) : (
                                                    <p className="font-satoshiBold text-[#4f972d] text-sm">
                                                        {item?.status}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="flex flex-col lg:flex-row justify-start lg:justify-between ">
                                                <div className="flex justify-start items-center gap-1 mb-0 md:mb-2 lg:mb-0">
                                                    <p className="text-secondaryLight text-sm font-satoshiMedium">
                                                        Sample Collection Date :{" "}
                                                    </p>
                                                    <p className="font-satoshiBold text-BlueJay text-sm">
                                                        {moment(
                                                            item?.samp_col_date_time
                                                        ).format(
                                                            "Do MMMM, YYYY"
                                                        )}
                                                    </p>
                                                </div>
                                                <p
                                                    className="hidden md:block w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiBold"
                                                    onClick={() =>
                                                        navigate(
                                                            `/user-profile/bookings/booking-details/${item?.id}`
                                                        )
                                                    }
                                                >
                                                    View Details
                                                </p>
                                                <p
                                                    className="block md:hidden w-fit mt-2 cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiBold"
                                                    onClick={() =>
                                                        navigate(
                                                            `/user-profile/bookings/booking-details/${item?.id}`
                                                        )
                                                    }
                                                >
                                                    View Details
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <CustomPagination
                                currentPage={currentPage}
                                totalCount={metaData.total}
                                pageSize={10}
                                currentItemsCount={bookings.length}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                    </>
                ) : (
                    <div className="mx-4 md:mx-0 lg:pb-2 lg:mt-2">
                        <div
                            className={` w-full h-screen md:h-min bg-WhiteLilac px-4 md:py-36 rounded-lg text-center flex flex-col justify-center items-center`}
                        >
                            <p className="md:mb-4 font-satoshiRegular text-[#3C567E50]">
                                No Bookings Found
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default MyBookings