import React from "react";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";

const StyledFormControlLabel = styled((props) => (
    <FormControlLabel {...props} />
))(({ checked }) => ({
    ".MuiFormControlLabel-label": checked
        ? { color: "#33B0AA" }
        : { color: "#323232" },
        
}));

const MyFormControlLabel = (props) => {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }

    return <StyledFormControlLabel checked={checked} {...props} />;
};

const RadioButton= ({
    items,
    name,
    onChange,
    checked,
    defaultValue,
    row,
}) => (
    <div className=" w-full">
        {" "}
        <RadioGroup name={name} defaultValue={defaultValue}>
            <div className={`${row ? "flex" : ""}`}>
                {" "}
                {React.Children.toArray(
                    items.map((e) => (
                        <MyFormControlLabel
                            sx={{fontSize:'14px'}}
                            value={e?.value}
                            label={e?.label}
                            control={
                                <Radio
                                    checked={checked}
                                    onChange={onChange}
                                    sx={{
                                        // color: "#33B0AA",
                                        "&.Mui-checked": {
                                            color: "#33B0AA",
                                        },
                                    }}
                                />
                            }
                        />
                    ))
                )}
            </div>
        </RadioGroup>
    </div>
);

export default RadioButton;
