import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../helpers/axios";
import moment from "moment";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { makeStyles } from "@mui/styles";
import updateMeta from "../../../../helpers/seo-meta";


const initalStates = {
    search_key: "",
};
const useStyles = makeStyles(() => ({
    root: {
        // backgroundColor: '#F1F4F8 !important',
        paddingY: "30px",
        "&:before": {
            backgroundColor: "transparent",
        },
    },
    details: {
        // margin: ' 24px',
        backgroundColor: "#F1F4F8",
        borderRadius: "0.5rem !important",
    },

    summary: {
        // pointerEvents: 'none',
        backgroundColor: "#F1F4F8 !important",
        borderRadius: "8px !important",
        margin: "0px",

        padding: "0px",
        // maxHeight: '25px',
    },
    icon: {
        pointerEvents: "auto",
    },
}));
const MyReports = () => {
    const navigate = useNavigate();
    const [reportsList, setReportsList] = useState([] );
    const [isLoading, setIsLoading] = useState(false);
    const [params, setParams] = useState(initalStates);
    const [date, setDate] = useState({
        start_date: "",
        end_date: "",
    });
    const classes = useStyles();

    useEffect(() => {
        reportsListing(params.search_key, date.start_date, date.end_date);
    }, []);

    const reportsListing = async (a, b, c) => {
        setIsLoading(true);
        axiosInstance
            .get(
                `/customer-reports?search_key=${a}&start_date=${b}&end_date=${c}`
            )
            .then((res) => {
                setReportsList(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleSearch = (event) => {
        setParams({ ...params, [event.target.name]: event.target.value });
    };

    const onDateSelect = (dates) => {
        setDate(dates);
        reportsListing(params.search_key, date.start_date, date.end_date);
    };

    const handleSubmit = () => {
        reportsListing(params.search_key, date.start_date, date.end_date);
    };

    const handleDowload = (item) => {
        window.open(item.report_url, "_blank");
    };
    const openLink = (e, link) => {
        window.open(link, "_blank");
    };

    useEffect(() => {
        document.title = 'My Reports';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);

    return (
        <>
            <div
                className={`${
                    reportsList?.length > 0 ? "md:h-auto" : "md:h-fit"
                } bg-white h-screen w-full md:bg-with-shadow md:w-[50%] lg:w-[70%] flex flex-col rounded-lg gap-2 md:gap-4 md:pt-2 md:px-5 lg:px-8 md:pb-5 pb-6`}
            >
                <div className="md:hidden  flex justify-start items-center gap-4 border-b border-Harp px-6 py-3">
                    <img
                        className=" cursor-pointer"
                        onClick={() => navigate("/user-profile")}
                        src='/images/icons/leftArrow.svg'
                        alt="Left Arrow"
                    />
                    <p className="text-DarkGrey text-base font-satoshiBold">
                        My Reports
                    </p>
                </div>
                {reportsList?.length < 1 || isLoading ? (
                    <div></div>
                ) : (
                    <div className="mt-4 md:mt-6 mb-1 flex flex-col lg:flex-row items-center justify-between md:gap-2 gap-6 px-5 md:px-0">
                        <div className="w-full relative flex md:mb-3 lg:mb-0">
                            <input
                                type="text"
                                className="w-full placeholder:text-xs md:placeholder:text-sm placeholder:font-medium bg-grey p-4 rounded-lg outline-none"
                                placeholder="Search your orders"
                                onChange={handleSearch}
                                name="search_key"
                                value={params.search_key}
                            />

                            <div
                                onClick={handleSubmit}
                                className="cursor-pointer rounded-lg absolute right-0 flex justify-center p-4 bg-Verdigris w-16"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M21.9999 22.7514C21.8099 22.7514 21.6199 22.6814 21.4699 22.5314L19.4699 20.5314C19.1799 20.2414 19.1799 19.7614 19.4699 19.4714C19.7599 19.1814 20.2399 19.1814 20.5299 19.4714L22.5299 21.4714C22.8199 21.7614 22.8199 22.2414 22.5299 22.5314C22.3799 22.6814 22.1899 22.7514 21.9999 22.7514Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                )}

                {isLoading ? (
                    <div className="hidden"></div>
                ) : (
                    <p className="md:block hidden md:mb-2 lg:text-2xl md:text-xl text-BlueJay font-satoshiMedium">
                        My Reports
                    </p>
                )}
                {isLoading ? (
                    <div className="flex flex-row justify-center items-center w-full h-full">
                        <p className="text-2xl font-satoshiBold text-BlueJay">
                            Loading...
                        </p>
                    </div>
                ) : reportsList?.length > 0 ? (
                    <div
                        className={`mt-2 md:mt-0 h-screen md:h-auto flex flex-col gap-4 overflow-auto max-h-[600px] md:max-h-[540px] ${
                            reportsList?.length < 3
                                ? "md:pr-0 lg:pr-0"
                                : "md:pr-4 pr-0 mr-4 md:mr-0"
                        }`}
                    >
                        {reportsList?.map((item) => {
                            return (
                                <div className="bg-WhiteLilac lg:p-4 p-2 rounded-lg mx-5 md:mx-0">
                                    <div className="bg-white rounded-lg p-4">
                                        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center gap-2 lg:gap-0">
                                            <div className="flex justify-start items-center gap-1">
                                                <p className="text-secondaryLight text-lg font-satoshiMedium">
                                                    Booking ID :
                                                </p>
                                                <p className="font-satoshiBold text-BlueJay text-lg">
                                                    {item?.id}
                                                </p>
                                            </div>
                                            <div className="flex justify-start items-center gap-1 ">
                                                <p className="text-secondaryLight text-sm font-satoshiMedium">
                                                    Order Status :
                                                </p>
                                                {item?.status === "PENDING" ? (
                                                    <p className="font-satoshiBold text-[#EF4949] text-sm">
                                                        {item?.status}
                                                    </p>
                                                ) : (
                                                    <p className="font-satoshiBold text-[#4f972d] text-sm">
                                                        {item?.status}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-start items-center gap-1 mt-2">
                                            <p className="text-secondaryLight text-sm font-satoshiMedium">
                                                Order Date :
                                            </p>
                                            <p className="font-satoshiBold text-BlueJay text-sm">
                                                {moment(
                                                    item?.created_at
                                                ).format("Do MMMM, YYYY")}
                                            </p>
                                        </div>
                                        {item?.selected_tests?.map(
                                            (ele) => {
                                                return (
                                                    <div className="mt-4 lg:mt-6 bg-white py-5 px-3 rounded-lg ">
                                                        <div className="flex flex-col lg:grid lg:grid-flow-col gap-6 grid-cols-3 ">
                                                            <div className="flex  flex-row justify-between lg:flex lg:flex-col">
                                                                <p className="text-Kimberly text-xs sm:w-full w-44">
                                                                    Test/
                                                                    Package Name
                                                                </p>
                                                                <p className="text-SpaceCadet text-sm capitalize">
                                                                    {
                                                                        ele?.package_name
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="flex  flex-row justify-between lg:flex lg:flex-col">
                                                                <p className="text-Kimberly text-xs">
                                                                    Patient Name{" "}
                                                                </p>
                                                                <p className="text-SpaceCadet text-sm">
                                                                    {
                                                                        ele?.patient_name
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="flex  flex-row justify-between lg:flex lg:flex-col">
                                                                <p className="text-Kimberly text-xs">
                                                                    Report Link
                                                                </p>
                                                                <p className="text-SpaceCadet text-sm">
                                                                    {ele.smart_report_url ? (
                                                                        <p
                                                                            className="cursor-pointer"
                                                                            onClick={(
                                                                                e
                                                                            ) =>
                                                                                openLink(
                                                                                    e,
                                                                                    ele.smart_report_url
                                                                                )
                                                                            }
                                                                        >
                                                                            Click
                                                                            Here
                                                                        </p>
                                                                    ) : (
                                                                        <>--</>
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="flex  flex-row justify-between lg:flex lg:flex-col">
                                                                <p className="text-Kimberly text-xs">
                                                                    Full Report
                                                                </p>
                                                                {ele.report_url ? (
                                                                    <svg
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            openLink(
                                                                                e,
                                                                                ele.report_url
                                                                            )
                                                                        }
                                                                        width="21"
                                                                        height="20"
                                                                        viewBox="0 0 21 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            d="M10.1211 13.4394L10.1211 1.39844"
                                                                            stroke="#38AE5E"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M13.0371 10.5156L10.1211 13.4436L7.20511 10.5156"
                                                                            stroke="#38AE5E"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M14.7541 6.13281H15.6871C17.7221 6.13281 19.3711 7.78181 19.3711 9.81781V14.7018C19.3711 16.7318 17.7261 18.3768 15.6961 18.3768L4.55609 18.3768C2.52109 18.3768 0.871094 16.7268 0.871094 14.6918V9.80681C0.871094 7.77781 2.51709 6.13281 4.54609 6.13281H5.48809"
                                                                            stroke="#38AE5E"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                ) : (
                                                                    <>--</>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className=" grid grid-cols-3 justify-between my-4">
                                                            <p className="text-xs text-center text-Kimberly">
                                                                Sl No
                                                            </p>
                                                            <p className="text-xs text-center text-Kimberly">
                                                                Lab Name
                                                            </p>
                                                            <p className="text-xs text-center text-Kimberly">
                                                                Report
                                                            </p>
                                                        </div>
                                                        {ele.labs?.map(
                                                            (
                                                                y,
                                                                index
                                                            ) => (
                                                                <>
                                                                    <Accordion
                                                                        elevation={
                                                                            0
                                                                        }
                                                                        className={
                                                                            classes.root
                                                                        }
                                                                        sx={{
                                                                            "&:before":
                                                                                {
                                                                                    display:
                                                                                        "none",
                                                                                },
                                                                            border: "none",
                                                                            borderRadius:
                                                                                "8px",
                                                                        }}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={
                                                                                <svg
                                                                                    width="24"
                                                                                    height="25"
                                                                                    viewBox="0 0 24 25"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        fill-rule="evenodd"
                                                                                        clip-rule="evenodd"
                                                                                        d="M2.75 12.274C2.75 17.3833 6.891 21.5264 12 21.5264C17.108 21.5264 21.25 17.3833 21.25 12.274C21.25 7.1646 17.108 3.02148 12 3.02148C6.891 3.02148 2.75 7.1646 2.75 12.274Z"
                                                                                        stroke="#0C8EC7"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                    <path
                                                                                        d="M8.52881 10.832L11.9998 14.319L15.4708 10.832"
                                                                                        stroke="#0C8EC7"
                                                                                        strokeWidth="1.5"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"
                                                                                    />
                                                                                </svg>
                                                                            }
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            className={
                                                                                classes.summary
                                                                            }
                                                                        >
                                                                            <div className="items-center grid grid-cols-3 justify-between   w-full ">
                                                                                <p className="justify-self-center">
                                                                                    {index +
                                                                                        1}
                                                                                </p>
                                                                                <p className=" w-full text-center ">
                                                                                    {
                                                                                        y?.name
                                                                                    }
                                                                                </p>
                                                                                <div className="justify-center flex  gap-2">
                                                                                    {y.report_url ? (
                                                                                        <svg
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                openLink(
                                                                                                    e,
                                                                                                    y.report_url
                                                                                                )
                                                                                            }
                                                                                            width="21"
                                                                                            height="20"
                                                                                            viewBox="0 0 21 20"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M10.1211 13.4394L10.1211 1.39844"
                                                                                                stroke="#38AE5E"
                                                                                                strokeWidth="1.5"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                            />
                                                                                            <path
                                                                                                d="M13.0371 10.5156L10.1211 13.4436L7.20511 10.5156"
                                                                                                stroke="#38AE5E"
                                                                                                strokeWidth="1.5"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                            />
                                                                                            <path
                                                                                                d="M14.7541 6.13281H15.6871C17.7221 6.13281 19.3711 7.78181 19.3711 9.81781V14.7018C19.3711 16.7318 17.7261 18.3768 15.6961 18.3768L4.55609 18.3768C2.52109 18.3768 0.871094 16.7268 0.871094 14.6918V9.80681C0.871094 7.77781 2.51709 6.13281 4.54609 6.13281H5.48809"
                                                                                                stroke="#38AE5E"
                                                                                                strokeWidth="1.5"
                                                                                                strokeLinecap="round"
                                                                                                strokeLinejoin="round"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        "--"
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </AccordionSummary>
                                                                        <br />

                                                                        <AccordionDetails
                                                                            className={
                                                                                classes.details
                                                                            }
                                                                        >
                                                                            <div className="px-4">
                                                                                <div className="rounded-t-lg border-b border-DreamyCloud flex justify-between px-4 py-3 bg-CalmWaters">
                                                                                    <p className="text-xs text-center text-Kimberly">
                                                                                        Test
                                                                                        Name
                                                                                    </p>
                                                                                    <p className="text-xs text-center text-Kimberly">
                                                                                        Parameter
                                                                                        Count
                                                                                    </p>
                                                                                </div>
                                                                                {y.sub_tests.map(
                                                                                    (
                                                                                        z
                                                                                    ) => (
                                                                                        <div className=" border-t border-DreamyCloud  bg-CalmWaters flex justify-between p-4">
                                                                                            <div className="flex">
                                                                                                {
                                                                                                    z?.name
                                                                                                }
                                                                                            </div>
                                                                                            <div className="text-sm flex">
                                                                                                {
                                                                                                    z?.param_cnt
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </div>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                    <br />
                                                                </>
                                                            )
                                                        )}
                                                        {!ele.labs.length ? <p className='text-center'>Sample Not Submitted to Labs</p> : <> </>}

                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="mx-4 md:mx-0 lg:pb-2 lg:mt-2">
                        <div
                            className={` w-full h-screen md:h-min bg-WhiteLilac px-4 md:py-36 rounded-lg text-center flex flex-col justify-center`}
                        >
                            <p className="md:mb-4 font-satoshiRegular text-[#3C567E50]">
                                No Reports Found
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default MyReports;
