import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from '../../helpers/helpers'

const initialState = {
  familyFriends: [],
  isLoading: false,
  isCreatePending: false,
  isCreateSuccess: false,
  apiSuccess: false,
  profileData: {},
  updateApiSuccess: false,
  isdeleteSuccess: false,
  isdeletePending: false,
  isFailed: false,
};

export const family = createSlice({
  name: 'family',
  initialState,
  reducers: {
    formReset: () => { },
    resetLoading: (state) => {
      state.isLoading = true;
    },
    CreatePending: (state) => {
      state.isLoading = true;
      state.isCreatePending = true
  },
    creationSuccessful: (state, action) => {
      state.isLoading = false;
      state.isCreatePending = false
      state.isCreateSuccess = true;
      showToastMessage(action.payload.message, 'success')
    },

    resetApiSuccess: (state) => {
      state.isCreateSuccess = false
    },
    
    creationFailure: (state, action) => {
      state.isLoading = false;
      state.apiSuccess = false;
      state.isCreatePending = false
      showToastMessage(action.payload, 'error');
    },

    SuccessfulList: (state, action) => {
      state.familyFriends = action?.payload;
      state.isLoading = false;
    },

    UserListPending: (state) => {
      state.isLoading = true;
    },

  },
});

export const {
  creationSuccessful,
  creationFailure,
  formReset,
  resetLoading,
  SuccessfulList,
  UserListPending,
  CreatePending,
  resetApiSuccess,
} = family.actions;
export default family.reducer;

export const fetchMembers = () =>
  apiCallBegan({
    url: `/customer-family`,
    method: 'GET',
    onStart: UserListPending.type,
    onSuccess: SuccessfulList.type,
    onError: creationFailure.type,
  });

export const editMemberDetails = (id, data) =>
    apiCallBegan({
        url: `/customer-family/${id}`,
        method: 'PUT',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })

export const addMembers = (data) =>
    apiCallBegan({
        url: `/customer-family`,
        method: 'POST',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })
