import React from "react";

const TextArea = ({
    placeholder,
    id,
    name,
    rows,
    className,
    value,
    error,
    handleChange,
    helperText,
    readOnly,
    width,
    label
}) => (
    <div className={`relative ${width}`}>
         {
            label ?  <p className="text-left text-[#6A6A78] px-2 pb-2">{label}</p> : <></>
         }
        
        <textarea
            readOnly={readOnly}
            className={`w-full border border-border  ${
                className ? className : "bg-white"
            } rounded-lg  text-[#10A39B] placeholder:text-[#6A6A78] border-2 hover:border-2 hover:border-[#6A6A78] p-4 placeholder-placeholder focus:outline-2 focus:outline-[#10A39B] placeholder:text-Comet focus:border-WaterBlue ${className}  ${
                error && "border border-red-500 placeholder:text-text-red-500"
            }`}
            name={name}
            id={id}
            rows={rows}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
        />
        {helperText && (
            <p className="absolute -bottom-3 ml-4 text-red-500 text-xs">
                {helperText}
            </p>
        )}
    </div>
);

export default TextArea;

TextArea.defaultProps = {
    placeholder: "",
    id: "",
    name: "",
    className: "",
    value: "",
    error: false,
    handleChange: function test() {},
    helperText: "",
};
