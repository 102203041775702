import React from 'react'
import { motion } from 'framer-motion'


const HeroAnimation = ({ type }) => {

    // for desktop
    const variants = {
        guyAnimation: {
            x: [0, 520, 520, 0],
            y: [0, 260, -320, 0],
            scale: [1, 0.7, 0.7, 1]
        },
        doctor: {
            x: [0, 0, -520, 0],
            y: [0, -600, -260, 0],
            scale: [0.7, 0.7, 1, 0.7]
        },
        lab: {
            x: [0, -520, 0, 0],
            y: [0, 320, 600, 0],
            scale: [0.7, 1, 0.7, 0.7]
        }
    }

    const shapes = {
        cyanHex1: {
            x: [0, 285, 0, 0],
            y: [0, 185, 370, 0],
        },
        cyanHex2: {
            x: [0, 0, 285, 0],
            y: [0, -370, -185, 0],
        },
        blueHex: {
            x: [0, -285, -285, 0],
            y: [0, 185, -185, 0],
        }
    }

    // for tab
    const variantsTab = {
        guyAnimation: {
            x: [0, 460, 460, 0],
            y: [0, 240, -240, 0],
            scale: [1.3, 1, 1, 1.3]
        },
        lab: {
            x: [0, -460, 0, 0],
            y: [0, 280, 520, 0],
            scale: [1, 1.3, 1, 1]
        },
        doctor: {
            x: [0, 0, -460, 0],
            y: [0, -520, -230, 0],
            scale: [1, 1, 1.3, 1]
        }
    }

    const shapesTab = {
        cyanHex1: {
            x: [0, 210, 0, 0],
            y: [0, 180, 370, 0]
        },
        cyanHex2: {
            x: [0, 0, 210, 0],
            y: [0, -370, -180, 0]
        },
        blueHex: {
            x: [0, -210, -210, 0],
            y: [0, 180, -180, 0]
        }

    }

    // for mobile
    const variantsPhone = {
        guyAnimation: {
            x: [0, 200, 200, 0],
            y: [0, 110, -140, 0],
            scale: [1.1, 0.9, 0.9, 1.1]
        },
        lab: {
            x: [0, -200, 0, 0],
            y: [0, 140, 260, 0],
            scale: [0.9, 1.1, 0.9, 0.9]
        },
        doctor: {
            x: [0, 0, -200, 0],
            y: [0, -250, -120, 0],
            scale: [0.9, 0.9, 1.1, 0.9]
        }
    }

    const shapesPhone = {
        cyanHex1: {
            x: [0, 130, 0, 0],
            y: [0, 150, 300, 0]
        },
        cyanHex2: {
            x: [0, 0, 130, 0],
            y: [0, -300, -150, 0]
        },
        blueHex: {
            x: [0, -130, -130, 0],
            y: [0, 150, -150, 0]
        }
    }

    return (

        <React.Fragment>
            {
                type === 'desktop' && (
                    <div className='flex flex-col justify-center items-center'>

                        <div className='flex items-center gap-20 scale-[0.7] relative z-30'>
                            <motion.div
                                variants={variants}
                                animate="guyAnimation"
                                transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
                            >
                                <img src='/images/hero/guy.webp' alt="Hero Sec1" />
                            </motion.div>
                            <div className='flex flex-col gap-y-20'>
                                <motion.div
                                    variants={variants}
                                    animate="lab"
                                    // transition={{ duration: 2 }}
                                    transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}

                                >
                                    <img src='/images/hero/lab_tech.webp' alt="Lab Tech" />
                                </motion.div>

                                <motion.div
                                    variants={variants}
                                    animate="doctor"
                                    transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
                                // transition={{ duration: 2 }}
                                >
                                    <img src='/images/hero/doctor.webp' alt="Doctor" />
                                </motion.div>
                            </div>
                        </div>

                        <div className='flex items-center gap-60 top-[-750px] left-[120px] relative z-20'>
                            <div className='flex flex-col gap-80'>
                                <motion.div
                                    variants={shapes}
                                    animate="cyanHex1"
                                    transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
                                // transition={{ duration: 2 }}

                                >
                                    <img src='/images/hero/cyanHexagon.svg' alt="Hero Section2" />
                                </motion.div>

                                <motion.div
                                    variants={shapes}
                                    animate="cyanHex2"
                                    transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
                                // transition={{ duration: 2 }}
                                >
                                    <img src='/images/hero/cyanHexagon.svg' alt="Hero Section" />
                                </motion.div>
                            </div>
                            <motion.div
                                variants={shapes}
                                animate="blueHex"
                                // transition={{ duration: 2 }}
                                transition={{ duration: 6, repeat: Infinity, ease: 'easeInOut' }}
                            >
                                <img src='/images/hero/blueHexagon.svg' alt="Hero Section2" />
                            </motion.div>
                        </div>
                    </div>
                )
            }

            {
                type === 'tab' && (
                    <div className='flex flex-col justify-center items-center'>
                        <div className='flex items-center gap-40 scale-[0.9] relative z-30'>
                            <motion.div
                                variants={variantsTab}
                                animate="guyAnimation"
                                // transition={{ duration: 2 }}
                                transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                            >
                                <img src='/images/hero/guy.webp' alt="Hero Section 2" />
                            </motion.div>
                            <div className='flex flex-col gap-y-40'>
                                <motion.div
                                    variants={variantsTab}
                                    animate="lab"
                                    // transition={{ duration: 2 }}
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                                >
                                    <img src='/images/hero/lab_tech.webp' alt="Lab tech" />
                                </motion.div>

                                <motion.div
                                    variants={variantsTab}
                                    animate="doctor"
                                    // transition={{ duration: 2 }}
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                                >
                                    <img src='/images/hero/doctor.webp' alt="Doctor" />
                                </motion.div>
                            </div>

                        </div>

                        <div className='flex items-center gap-40 relative top-[-620px] left-[100px] z-20'>
                            <div className='flex flex-col gap-y-80'>
                                <motion.div
                                    variants={shapesTab}
                                    animate="cyanHex1"
                                    // transition={{ duration: 2 }}
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                                >
                                    <img src='/images/hero/cyanHexagon.svg' alt="Hero Section2" />
                                </motion.div>

                                <motion.div
                                    variants={shapesTab}
                                    animate="cyanHex2"
                                    // transition={{ duration: 2 }}
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                                >
                                    <img src='/images/hero/cyanHexagon.svg' alt="Hero Section 2" />
                                </motion.div>
                            </div>
                            <motion.div
                                variants={shapesTab}
                                animate="blueHex"
                                // transition={{ duration: 2 }}
                                transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                            >
                                <img src='/images/hero/blueHexagon.svg' alt="Hero section2" />
                            </motion.div>
                        </div>
                    </div>
                )
            }

            {
                type === 'phone' && (
                    <div className='flex flex-col justify-center items-center'>
                        {/* Hero */}
                        <div className='flex items-center gap-5 relative z-30'>
                            <motion.div
                                variants={variantsPhone}
                                animate="guyAnimation"
                                transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}

                            // transition={{ duration: 2 }}
                            >
                                <img src='/images/hero/guy.webp' alt="Hero Section" />
                            </motion.div>
                            <div className='flex flex-col gap-10'>
                                <motion.div
                                    variants={variantsPhone}
                                    animate="lab"
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}

                                // transition={{ duration: 2 }}
                                >
                                    <img src='/images/hero/lab_tech.webp' alt="Lab test" />
                                </motion.div>

                                <motion.div
                                    variants={variantsPhone}
                                    animate="doctor"
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}

                                // transition={{ duration: 2 }}
                                >
                                    <img src='/images/hero/doctor.webp' alt="Doctor" />
                                </motion.div>
                            </div>
                        </div>
                        {/* Shapes */}
                        <div className='flex items-center gap-20 scale-[0.7] relative top-[-350px] mb-[-350px] left-[20px] z-20'>
                            <div className='flex flex-col gap-y-60'>

                                <motion.div
                                    variants={shapesPhone}
                                    animate="cyanHex1"
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                                // transition={{ duration: 2 }}
                                >
                                    <img src='/images/hero/cyanHexagon.svg' alt="Hero Sec 2" />
                                </motion.div>


                                <motion.div
                                    variants={shapesPhone}
                                    animate="cyanHex2"
                                    transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                                // transition={{ duration: 2 }}
                                >
                                    <img src='/images/hero/cyanHexagon.svg' alt="Hero sec 2" />
                                </motion.div>

                            </div>
                            <motion.div
                                variants={shapesPhone}
                                animate="blueHex"
                                transition={{ duration: 7, repeat: Infinity, ease: 'easeInOut' }}
                            // transition={{ duration: 2 }}
                            >
                                <img src='/images/hero/blueHexagon.svg' alt="Hero section2" />
                            </motion.div>
                        </div>

                    </div>
                )
            }


        </React.Fragment>
    )
}

export default HeroAnimation