import { Link } from "react-router-dom";
import { uuid } from "../helpers/helpers";
import { Skeleton } from "@mui/material";
import axiosInstance from "../helpers/axios";
import React, { useState, useEffect } from "react";
import { CustomSelectInput } from "./common/CustomSelect";
import { CustomInput } from "./common/CustomInput";


function SearchActiveModal({
    search,
    active,
    setActive,
    handleSearchClose,
    packagesList,
    testsList,
    totalTests,
    totalPackages,
    isLoading,
    handleFilterChange,
    resetFilter,
    params,
    searchActiveRef,
    isPlaying
}) {
    const [genderDropdown, setGenderDropdown] = useState([]);

    // get dropdown values from api
    const getAllDropdown = () => {
        axiosInstance
            .get("/tests/prerequisite")
            .then((response) => {
                let thisData = response.data.data;
                setGenderDropdown(thisData.GENDER);
            })
            .catch((error) => {
            });
    };

    useEffect(() => {
        // dropdown data
        getAllDropdown();
    }, []);

    return (
        <div
            ref={searchActiveRef}
            className={`bg-white ${search} lg:w-[1005px] md:w-[634px] w-screen shadow-[0px_22px_70px_4px_rgba(0,0,0,0.2)] relative mr-auto ml-auto ${isPlaying ? 'lg:bottom-[530px] md:bottom-[530px] bottom-[220px] lg:mb-[-530px] md:mb-[-530px] mb-[-220px] ' : 'lg:bottom-[360px] md:bottom-[290px] md:mb-[-290px] bottom-[300px] lg:mb-[-360px] mb-[-300px]'} rounded-lg py-[18px]`}
        >
            <div className="flex justify-end mr-5">
                <img
                    onClick={() => {
                        resetFilter();
                        handleSearchClose();
                    }}
                    className="cursor-pointer"
                    src='/images/icons/closeIcon.svg'
                    alt='Close Icon'
                />
            </div>

            {/* filters */}
            <div className="grid grid-flow-col lg:overflow-x-hidden md:overflow-x-hidden overflow-x-scroll px-[18px] justify-center items-center lg:gap-[18px] gap-2 py-2 border-b">

                <div className="relative bottom-[1.2px]">
                <CustomInput
                    rows={1}
                    width='lg:w-[100px] md:w-[88px] w-[88px]'
                    disabled={false}
                    readOnly={false}
                    handleChange={handleFilterChange}
                    value={params?.age}
                    label='Age'
                    name='age'
                />
                </div>

                <CustomSelectInput
                    width="lg:w-[200px] w-[107px]"
                    handleChange={handleFilterChange}
                    options={genderDropdown}
                    label="Gender"
                    name="gender"
                    value={params?.gender}
                />
            </div>

            {/* Heading & Listing Desktop*/}
            <div className="lg:grid hidden grid-flow-col justify-center items-start py-4 ">
                <div className="flex flex-col">
                    <div className="flex flex-row gap-[10px] items-center lg:px-[130px] py-5">
                        <p className="text-[#363636] font-satoshiBold text-[18px]">
                            Tests{" "}
                        </p>
                        <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                            {totalTests}
                        </p>
                    </div>
                    {/* List of tests */}

                    <div
                        className={`px-5 ${testsList.length >= 5 ? "h-[380px]" : "h-max-fit"
                            } grid gap-[12px] grid-flow-row ${testsList.length >= 5 ? "overflow-y-scroll" : ""
                            }`}
                    >
                        {isLoading ? (
                            <div className="flex flex-col gap-2 justify-center items-center">
                                {[1, 1, 1, 1, 1].map((item) => (
                                    <Skeleton
                                        key={uuid()}
                                        variant="rectangular"
                                        width={310}
                                        height={60}
                                        sx={{ borderRadius: "14px" }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <>
                                {testsList.length == 0 ? (
                                    <p className="font-satoshiBold text-base flex justify-center items-center">
                                        No Tests Found!
                                    </p>
                                ) : (
                                    <>
                                        {testsList.map((item) => (
                                            <Link key={uuid()} to={`/tests/${item.slug}`}>
                                                <div className="px-[29px] py-[24px] cursor-pointer border border-[#E9EEF5] rounded-xl hover:bg-[#EBEEF2]">
                                                    <p className="text-xs font-satoshiMedium text-[#363636]">
                                                        {item?.name}
                                                    </p>
                                                </div>
                                            </Link>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="flex flex-row gap-[10px] items-center lg:px-[130px] py-5">
                        <p className="text-[#363636] font-satoshiBold text-[18px]">
                            Packages{" "}
                        </p>
                        <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                            {totalPackages}
                        </p>
                    </div>
                    {/* List of Packages */}

                    <div
                        className={` px-5 ${packagesList.length >= 5 ? "h-[380px]" : "h-max-fit"
                            } lg:grid hidden  gap-[12px] grid-flow-row overflow-y-auto `}
                    >
                        {isLoading ? (
                            <div className="flex flex-col justify-center gap-2 items-center">
                                {[1, 1, 1, 1, 1].map((item) => (
                                    <Skeleton
                                        key={uuid()}
                                        variant="rectangular"
                                        width={310}
                                        height={60}
                                        sx={{ borderRadius: "14px" }}
                                    />
                                ))}
                            </div>
                        ) : (
                            <>
                                {packagesList.length == 0 ? (
                                    <p className="font-satoshiBold text-base flex justify-center items-center">
                                        No Packages Found!
                                    </p>
                                ) : (
                                    <>
                                        {packagesList.map((item) => (
                                            <Link
                                                key={uuid()}
                                                to={`/packages/${item.slug}`}
                                            >
                                                <div className="px-[29px] py-[24px] border cursor-pointer border-[#E9EEF5] rounded-xl hover:bg-[#EBEEF2]">
                                                    <p className="text-xs font-satoshiMedium text-[#363636]">
                                                        {item?.name}
                                                    </p>
                                                </div>
                                            </Link>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* Heading & Listing for tab & phone */}
            <div className="md:mt-[18px] mt-[26px] lg:hidden block">
                {active === "tests" && (
                    <>
                        <div className="grid grid-cols-2 items-center">
                            <button className="bg-[#F4F7FA]">
                                <div className="flex flex-row gap-[10px] justify-center items-center py-3">
                                    <p className="text-[#363636] font-satoshiBold text-[18px]">
                                        Tests{" "}
                                    </p>
                                    <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                                        {/* 134 */}
                                        {totalTests}
                                    </p>
                                </div>
                            </button>

                            <button
                                onClick={() => {
                                    setActive("packages");
                                }}
                            >
                                <div className="flex flex-row gap-[10px] justify-center items-center py-3 opacity-60">
                                    <p className="text-[#363636] font-satoshiBold text-[18px]">
                                        Packages{" "}
                                    </p>
                                    <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                                        {/* 56 */}
                                        {totalPackages}
                                    </p>
                                </div>
                            </button>
                        </div>
                        <br />

                        <div
                            className={`px-5 ${testsList.length >= 5
                                ? "h-[380px]"
                                : "h-max-fit"
                                } grid  gap-[12px] grid-flow-row ${testsList.length > 5 ? "overflow-y-scroll" : ""
                                }`}
                        >
                            {isLoading ? (
                                <div className="flex flex-col gap-2 justify-center items-center">
                                    {[1, 1, 1, 1, 1].map((item) => (
                                        <Skeleton
                                            key={uuid()}
                                            variant="rectangular"
                                            width={310}
                                            height={60}
                                            sx={{ borderRadius: "14px" }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <>
                                    {testsList.length == 0 ? (
                                        <p className="font-satoshiBold text-base flex justify-center items-center">
                                            No Tests Found!
                                        </p>
                                    ) : (
                                        <>
                                            {testsList.map((item) => (
                                                <Link
                                                    key={uuid()}
                                                    to={`/tests/${item.slug}`}
                                                >
                                                    <div className="px-[29px] py-[24px] border cursor-pointer border-[#E9EEF5] rounded-xl hover:bg-[#EBEEF2]">
                                                        <p className="text-xs font-satoshiMedium text-[#363636]">
                                                            {item?.name}
                                                        </p>
                                                    </div>
                                                </Link>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}

                {active === "packages" && (
                    <>
                        <div className="grid grid-cols-2 items-center">
                            <button
                                onClick={() => {
                                    setActive("tests");
                                }}
                            >
                                <div className="flex flex-row gap-[10px] justify-center items-center py-3 opacity-60">
                                    <p className="text-[#363636] font-satoshiBold text-[18px]">
                                        Tests{" "}
                                    </p>
                                    <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                                        {/* 134 */}
                                        {totalTests}
                                    </p>
                                </div>
                            </button>

                            <button className="bg-[#F4F7FA]">
                                <div className="flex flex-row gap-[10px] justify-center items-center py-3">
                                    <p className="text-[#363636] font-satoshiBold text-[18px]">
                                        Packages{" "}
                                    </p>
                                    <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                                        {/* 56 */}
                                        {totalPackages}
                                    </p>
                                </div>
                            </button>
                        </div>
                        <br />
                        <div
                            className={`px-5 ${packagesList.length >= 5
                                ? "h-[380px]"
                                : "h-max-fit"
                                } grid gap-[12px] grid-flow-row ${packagesList.length > 5
                                    ? "overflow-y-scroll"
                                    : ""
                                }`}
                        >
                            {isLoading ? (
                                <div className="flex flex-col gap-2 justify-center items-center">
                                    {[1, 1, 1, 1, 1].map((item) => (
                                        <Skeleton
                                            key={uuid()}
                                            variant="rectangular"
                                            width={310}
                                            height={60}
                                            sx={{ borderRadius: "14px" }}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <>
                                    {packagesList.length == 0 ? (
                                        <p className="font-satoshiBold text-base flex justify-center items-center">
                                            No Packages Found!
                                        </p>
                                    ) : (
                                        <>
                                            {packagesList.map((item) => (
                                                <Link
                                                    key={uuid()}
                                                    to={`/packages/${item.slug}`}
                                                >
                                                    <div className="px-[29px] py-[24px] cursor-pointer border border-[#E9EEF5] rounded-xl hover:bg-[#EBEEF2]">
                                                        <p className="text-xs font-satoshiMedium text-[#363636]">
                                                            {item?.name}
                                                        </p>
                                                    </div>
                                                </Link>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>

            <Link to="/packages">
                <p className="text-blueShadow text-center text-base font-satoshiBold mt-[40px] underline cursor-pointer">
                    Show all
                </p>
            </Link>
        </div>
    );
}

export default SearchActiveModal;
