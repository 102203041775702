import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from '../../helpers/helpers'

const initialState = {
  departments: {},
  isLoading: false,
  isCreatePending: false,
  isCreateSuccess: false,
  apiSuccess: false,
  profileData: {},
  metadata: {
    totalUsers: 0,
    totalPages: 0,
  },
  updateApiSuccess: false,
  isdeleteSuccess: false,
  isdeletePending: false,
  isFailed: false,
};

export const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    formReset: () => { },
    resetLoading: (state) => {
      state.isLoading = true;
    },
    CreatePending: (state) => {
      state.isLoading = true;
      state.isCreatePending = true
  },
    creationSuccessful: (state, action) => {
      state.isLoading = false;
      state.isCreatePending = false
      state.isCreateSuccess = true;
      showToastMessage(action.payload.message, 'success')
    },

    resetApiSuccess: (state) => {
      state.isCreateSuccess = false
    },

    creationFailure: (state, action) => {
      state.isLoading = false;
      state.apiSuccess = false;
      state.isCreatePending = false
      showToastMessage(action.payload, 'error');
    },

    SuccessfulList: (state, action) => {
      state.profileData = action?.payload;
      state.isLoading = false;
    },

    UserListPending: (state) => {
      state.isLoading = true;
    },

  },
});

export const {
  creationSuccessful,
  creationFailure,
  formReset,
  resetLoading,
  SuccessfulList,
  UserListPending,
  CreatePending,
  resetApiSuccess

} = profile.actions;
export default profile.reducer;

export const fetchProfile = () =>
  apiCallBegan({
    url: `/customer`,
    method: 'GET',
    onStart: UserListPending.type,
    onSuccess: SuccessfulList.type,
    onError: creationFailure.type,
  });

export const editProfile = (data) =>
    apiCallBegan({
        url: '/customer',
        method: 'PUT',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })