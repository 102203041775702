import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Validator from "validatorjs";
import { updateCart, updatePaymentMode } from "../../../../features/cart/cartSlice";
import axiosInstance from "../../../../helpers/axios";
import { showToastMessage } from "../../../../helpers/helpers";
import CustomButton from "../../../common/Button";
import { Input } from "../../../common/Input";
import RadioButton from "../../../common/RadioButton";
import InfoLabel from "../components/InfoLabel";
import Previous from "../components/Previous";
import Title from "../components/Title";
import Login from "../../LoginModal/Login";
import { login, verifyOtp } from "../../../../features/auth/authSlice";

const initialValue = {
    code: ""
};
function Confirmation({
    bookPackageRequest,
    setActiveStep,
    isBookingLoading,
}) {
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const [params, setParams] = useState(initialValue);
    const [formErrors, setFormErrors] = useState(initialValue );
    const [isLoading, setIsLoading] = useState(false);

    const applyCoupon = (e) => {
        setFormErrors(initialValue)
        e.preventDefault();
        let payload = {
            code: params.code,
        };
        const rules = {
            code: "required|string|max:50",
        };

        const re = /^[a-zA-Z0-9]*$/;
        if ((params.code && !re.test(params.code))) {
            setFormErrors({
                code: 'Invalid coupon code'
            });

            return;
        }


        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            const err = Object.keys(fieldErrors);
            if (err.length) {
                const input = document.querySelector(
                    `input[name=${err[0]}]`
                );
                if (input) {
                    input.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "start",
                    });
                }
            }
            setFormErrors(fieldErrors);
            setReadOnly(false)
            return false;
        }
        else {
            setIsLoading(true);
            axiosInstance
                .post("/coupons/apply", payload)
                .then((response) => {
                    dispatch(updateCart(response.data));
                    setIsLoading(false);
                    setReadOnly(true);
                })
                .catch((err) => {
                    setIsLoading(false);
                    const errors =
                        err.response?.data.data || err.response?.data.errors;
                    if (errors.message) {
                        showToastMessage(errors.message, "error");
                        setFormErrors({ code: errors.message });
                    } else {
                        for (let key in errors) {
                            showToastMessage(errors[key][0], "error");
                        }
                    }
                });
        }
    };

    const removeCoupon = (e) => {
        setFormErrors(initialValue)
        setReadOnly(false)
        e.preventDefault();
        setIsLoading(true);
        axiosInstance
            .post("/coupons/remove", {})
            .then((response) => {
                dispatch(updateCart(response.data));
                setIsLoading(false);
                showToastMessage("Coupon Removed Successfully!", "success");
                setParams({ code: "" });
            })
            .catch((err) => {
                setIsLoading(false);
                const errors =
                    err.response?.data.data || err.response?.data.errors;
                if (errors.message) {
                    showToastMessage(errors.message, "error");
                    setFormErrors({ code: errors.message });
                } else {
                    for (let key in errors) {
                        showToastMessage(errors[key][0], "error");
                    }
                }
            });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setParams({ ...params, [name]: String(value).toUpperCase() });
        setFormErrors({});
    };

    const handleRadioChange = (e) => {
        const { value } = e.target;
        dispatch(updatePaymentMode(value));
    };



    useEffect(() => {
        if (cart.coupon && cart.coupon.is_applied) {
            setParams({ code: cart.coupon.code.toUpperCase() });
        }
    }, []);



    const radioItems = [
        {
            label: "Cash On Delivery",
            value: "2",
        },
        {
            label: "Pay Online",
            value: "1",
        },
    ];


    const [readOnly, setReadOnly] = useState(false)
    const [otp, setOTP] = useState("");

    const [loginParams, setLoginParams] = useState({
        phone: "",
        code: "+971",
        otp: otp,
    }) 

    const [loginErrors, setLoginErrors] = useState({
        phone: "",
        code: "+971",
        otp: otp,
    }) 


    const [loginOpen, setLoginOpen] = useState(false);

    const [loginflag, setloginflag] = useState(false);

    const handleLoginClose = () => setLoginOpen(false);
    const handleOtp = (otp) => {
        setOTP(otp);
    };


    const onChangeHandler = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            if (name === "phone") {
                const re = /^[0-9\b]+$/;
                if (value && !re.test(value)) {
                    return;
                } else {
                    setLoginParams({ ...loginParams, phone: value });
                }
            } else {
                setLoginParams({ ...loginParams, [name]: value });
            }
        }
        setLoginErrors({});
    };

    const sendOtp = () => {

        let payload = {
            phone: `971${loginParams.phone}`,
        };
        const rules = {
            phone: "required|max:15",
        };

        let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[01256])[0-9]{7}$/;
        if (!regex.test(`+971${loginParams.phone}`)) {
            setLoginErrors(({ ...loginErrors, phone: 'Invalid Phone Number' }));
            return
        }

        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setLoginErrors(fieldErrors);
            return false;
        }
        if (!regex.test(`+971${loginErrors.phone}`)) {
            setLoginErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
            return
        }


        dispatch(login(payload));
        setLoginErrors({});
        return true;
    };

    const navigate = useNavigate()

    const handleSubmit = () => {

        let payload = {
            phone: `971${loginParams.phone}`,
            otp: otp,
        };
        const rules = {
            otp: "required|numeric|digits:5",
        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setLoginErrors(fieldErrors);
            return false;
        }

        dispatch(verifyOtp(payload));
        setLoginErrors({});
        return true;
    };

    const [counter, setCounter] = useState(60);

    const reEnterNumber = () => {
        setloginflag(false);
        setCounter(60);
        setOTP("");
    };
    const toPrivacyPolicy = () => {
        handleLoginClose();
        navigate("/privacy-policy");
    };


    return (
        <div>
            <p className="text-xs font-satoshiBold text-Verdigris relative md:left-[620px] left-[280px] bottom-[20px] lg:hidden md:block block">
                Confirmation
            </p>
            <Title title="Confirm your Booking" />
            <div className="border-t-0 border border-[#F4F7FA] lg:w-[568px] md:w-[704px] w-[344px] flex flex-col justify-center items-center rounded-b-lg">
                <br />
                <InfoLabel title="Enter the Coupon Code to get Exciting Discount" />

                <div className="mt-[31px]">
                    <Input
                        rows={1}
                        width=" lg:w-[344px] md:w-[324px] w-[311px]"
                        disabled={false}
                        readOnly={readOnly && cart?.coupon?.is_applied ? true : false}
                        handleChange={handleChange}
                        value={params?.code}
                        error={formErrors?.code}
                        helperText={formErrors?.code}
                        label="Enter Coupon Code"
                        name="code"
                    />
                </div>

                {parseInt(cart.total.promotion || 0) !== 0 && (
                    <div className="flex justify-between items-center text-secondaryLight text-[16px] font-satoshiMedium mt-2">
                        <p>
                            Congrats! You got Discount code of &nbsp;
                            <span className="bg-Verdigris p-1 rounded text-white font-satoshiMedium">
                                {cart.coupon.type.value}
                                {cart.coupon.type.type.toLowerCase() ===
                                    "percentage"
                                    ? " % "
                                    : " "}
                                OFF
                            </span>{" "}
                        </p>
                    </div>
                )}
                {cart?.coupon?.is_applied && !isLoading && (
                    <p
                        onClick={removeCoupon}
                        className="text-Verdigris font-satoshiBold text-[16px] cursor-pointer mt-[19px] border-b-2 w-max-fit border-Verdigris"
                    >
                        Remove Coupon
                    </p>
                )}

                {!cart?.coupon?.is_applied && !isLoading && (
                    <p
                        onClick={applyCoupon}
                        className="text-Verdigris font-satoshiBold text-[16px] cursor-pointer mt-[19px] border-b-2 w-max-fit border-Verdigris"
                    >
                        Apply Coupon
                    </p>
                )}

                {isLoading && (
                    <p
                        onClick={applyCoupon}
                        className="text-Verdigris font-satoshiBold text-[16px] cursor-pointer mt-[19px] border-b-2 w-max-fit border-Verdigris"
                    >
                        Loading...
                    </p>
                )}

                <br />
                <div className="bg-[#F4F7FA] p-5 rounded-lg m-2 mb-5">
                    <p className="text-blueShadow font-satoshiMedium lg:text-[20px] text-[16px] m-2 text-center">
                        Total Payable Amount :{" "}
                        <span className="text-Verdigris font-bold lg:text-[20px] text-[16px]">
                            {" "}
                            AED {cart?.total?.total}
                        </span>
                    </p>

                    <div className="relative left-[25px] my-5">
                        <RadioButton
                            name="type"
                            onChange={handleRadioChange}
                            items={radioItems}
                            defaultValue={cart.mode_of_payment}
                            row={true}
                        />
                    </div>

                    <div className="paymentBtnShadow">
                        {!isBookingLoading && (
                            <CustomButton
                                borderRadius="0.5rem"
                                variant="secondary-contained"
                                width="w-[335px]"
                                onClick={bookPackageRequest}
                            >
                                {
                                    cart.mode_of_payment === '1' ?
                                        <>
                                            {"Pay"} {" "}
                                            {"AED"} {cart?.total?.total}
                                        </> :
                                        <>{'Proceed'}</>
                                }

                            </CustomButton>
                        )}

                        {isBookingLoading && (
                            <CustomButton
                                borderRadius="0.5rem"
                                variant="secondary-contained"
                                width="w-[335px]"
                            >
                                Loading...
                            </CustomButton>
                        )}
                    </div>
                </div>
            </div>
            <div className="my-1">
                <Previous
                    onClick={() => {
                        setActiveStep(4);
                    }}
                />
            </div>

            <Login
                loginOpen={loginOpen}
                handleLoginClose={handleLoginClose}
                handleOtp={handleOtp}
                otp={otp}
                handleChange={onChangeHandler}
                sendOtp={sendOtp}
                errors={loginErrors}
                loginflag={loginflag}
                setloginflag={setloginflag}
                params={params}
                handleSubmit={handleSubmit}
                counter={counter}
                reEnterNumber={reEnterNumber}
                toPrivacyPolicy={toPrivacyPolicy}
            />

        </div>
    );
}

export default Confirmation;
