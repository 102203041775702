import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../middleware/api-creators";

const initialState = {
    feedbackList: [],
};

export const feedbackSlice = createSlice({
    name: "feedback",
    initialState,
    reducers: {
        FeedbackListSuccess: (state, action) => {
            state.feedbackList = action?.payload.data;
        },
    },
});

export const { FeedbackListSuccess } =
    feedbackSlice.actions;

export default feedbackSlice.reducer;

export const fetchFeedbackList = (page='') =>
    apiCallBegan({
        url: `/feedback?page=${page}`,
        method: "GET",
        onSuccess: FeedbackListSuccess.type,
    });
