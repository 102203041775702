import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Input } from '../../../common/Input'
import TextArea from '../../../common/TextArea'
import CustomButton from '../../../common/Button'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}


const ShareFeedback = ({
    open,
    handleClose,
    params,
    formErrors,
    handleChange,
    handleSubmit,
}) => {



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                className='md:w-[450px] w-[320px] p-4 md:px-6 md:py-0'
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className=' px-1 py-0 md:py-5'>
                    <div className='flex justify-end'>
                        <span
                            className='cursor-pointer'
                            onClick={handleClose}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.17004 14.8299L14.83 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.83 14.8299L9.17004 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <p className='text-lg font-satoshiBold text-BlueJay text-center'>Share your ideas & feedback</p>
                    <div className='mt-6 flex flex-col gap-3'>
                        <Input
                            rows={1}
                            width='w-full'
                            disabled={false}
                            readOnly={false}
                            handleChange={handleChange}
                            value={params?.name}
                            error={formErrors?.name}
                            helperText={formErrors?.name}
                            label='Your Name'
                            name='name'
                        />
                        <div className='flex flex-row gap-3'>
                            <Input
                                rows={1}
                                width='md:w-[90px] w-[120px]'
                                disabled={false}
                                readOnly={true}
                                handleChange={handleChange}
                                value={params?.code}
                                label='code'
                                name='code'
                            />
                            <Input
                                rows={1}
                                width='w-full'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.phone}
                                error={formErrors?.phone}
                                helperText={formErrors?.phone}
                                label='Phone Number'
                                name='phone'
                            />
                        </div>
                        <Input
                            rows={1}
                            width='w-full'
                            disabled={false}
                            readOnly={false}
                            handleChange={handleChange}
                            value={params?.email}
                            error={formErrors?.email}
                            helperText={formErrors?.email}
                            label='Email ID'
                            name='email'
                        />
                        <TextArea
                            className='border-[1px] placeholder:text-[#909EB4] w-full'
                            placeholder='Tell us about your feedback '
                            name='message'
                            rows={4}
                            error={formErrors?.message}
                            value={params.message}
                            handleChange={handleChange}
                            helperText={formErrors?.message}
                        />
                    </div>
                    <div className='mt-6 flex flex-row-reverse justify-start lg:flex-col lg:justify-center items-center gap-10 md:gap-20 lg:gap-4 font-satoshiMedium'>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            size='large'
                            width='w-fit lg:w-[80%]'
                            onClick={handleSubmit}
                        >
                            Submit your feedback
                        </CustomButton>
                        <p
                            className='w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiBold'
                            onClick={handleClose}
                        >
                            Cancle
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default ShareFeedback