const fields = {
    name: '',
    phone: '',
    email: '',
    message: '',
}

const rules = {
    name: 'required|max:100',
    phone: 'required',
    email: 'required|email',
    message: 'required|max:500',
}

module.exports = { fields, rules }
