import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Input } from '../../../common/Input'
import CustomButton from '../../../common/Button'
import { SelectInput } from '../../../common/Select'
import { PhoneInput } from '../../../common/PhoneInput'
import CommonDatepicker from '../../../common/DatePicker'
import SelectNationality from '../../../common/SelectNationality'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}

const dummyOptions = [
    {
        id: 'Male',
        value: 'Male',
        name: 'Male',
    },
    {
        id: 'Female',
        value: 'Female',
        name: 'Female',
    },
]

const AddMember = ({
    open,
    handleClose,
    params,
    formErrors,
    handleChange,
    handlePhone,
    handleSubmit,
    onDateChange,
    captureNationality
}) => {




    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                className='md:w-[450px] w-[350px] md:p-6 p-4'
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className='w-full flex flex-col gap-2'>
                    <div className='flex justify-end '>
                        <span
                            className='cursor-pointer'
                            onClick={handleClose}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9.17004 14.8299L14.83 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.83 14.8299L9.17004 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    </div>
                    <p className='font-satoshiBold text-BlueJay text-2xl mb-4'>{params.name !== '' ? 'Edit Member' : 'Add Member'}</p>
                    <Input
                        rows={1}
                        width='w-full'
                        disabled={false}
                        readOnly={false}
                        handleChange={handleChange}
                        value={params?.name}
                        error={formErrors?.name}
                        helperText={formErrors?.name}
                        label='Name'
                        name='name'
                    />
                    {/* <Input
                        rows={1}
                        width='w-full'
                        disabled={false}
                        readOnly={false}
                        handleChange={handleChange}
                        value={params?.phone}
                        error={formErrors?.phone}
                        helperText={formErrors?.phone}
                        label='Phone Number'
                        name='phone'
                    /> */}
                    <PhoneInput rows={1}
                        width="w-full"
                        disabled={false}
                        readOnly={false}
                        handleChange={handlePhone}
                        value={params?.phone}
                        error={formErrors?.phone}
                        helperText={formErrors?.phone}
                        label="Contact No*"
                        name="phone" />
                    <Input
                        rows={1}
                        width='w-full'
                        disabled={false}
                        readOnly={false}
                        handleChange={handleChange}
                        value={params?.email}
                        error={formErrors?.email}
                        helperText={formErrors?.email}
                        label='Email'
                        name='email'
                    />
                    <div className='flex flex-row items-center justify-center gap-2'>
                        <SelectInput
                            readonly={false}
                            width='w-full text-left mt-1'
                            handleChange={handleChange}
                            options={dummyOptions}
                            label='Gender'
                            name='gender'
                            value={params?.gender}
                            error={formErrors?.gender}
                            helperText={formErrors?.gender}
                        />
                        <div className='relative w-full'>
                            <CommonDatepicker
                                label='Date of Birth'
                                value={params?.dob}
                                onChange={onDateChange}
                                error={formErrors?.dob}
                                helperText={formErrors?.dob}
                                isSample={false}
                            />
                            <svg
                                className={`absolute top-4 right-3 ${params?.dob === '' ? 'block lg:hidden' : 'hidden'}`}
                                width='17'
                                height='18'
                                viewBox='0 0 17 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                            >
                                <path
                                    d='M1.07422 6.92317H15.3334'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M11.7536 10.0482H11.761'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M8.20279 10.0482H8.2102'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M4.6481 10.0482H4.65552'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M11.7536 13.1566H11.761'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M8.20279 13.1566H8.2102'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M4.6481 13.1566H4.65552'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M11.4331 1V3.63262'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    d='M4.97212 1V3.63262'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                                <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M11.5906 2.26367H4.81677C2.46742 2.26367 1 3.57242 1 5.97809V13.2178C1 15.6613 2.46742 17.0003 4.81677 17.0003H11.5832C13.94 17.0003 15.4 15.684 15.4 13.2783V5.97809C15.4074 3.57242 13.9474 2.26367 11.5906 2.26367Z'
                                    stroke='#141C4C'
                                    strokeWidth='1.5'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                />
                            </svg>
                        </div>
                    </div>
                    <Input
                        rows={1}
                        width='w-full'
                        disabled={false}
                        readOnly={false}
                        handleChange={handleChange}
                        value={params?.relation}
                        error={formErrors?.relation}
                        helperText={formErrors?.relation}
                        label='Relation'
                        name='relation'
                    />

                    <div className='my-1'>
                        <SelectNationality
                            error={formErrors?.nationality}
                            helperText={formErrors?.nationality}
                            value={params?.nationality}
                            captureNationality={captureNationality} />
                    </div>

                    <div className='mt-2 flex flex-row-reverse justify-start lg:flex-col lg:justify-center items-center gap-24 lg:gap-4'>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            size='large'
                            width='md:w-[180px] lg:w-[200px] w-[150px]'
                            onClick={handleSubmit}
                        >
                            {params.name !== '' ? 'Update' : 'Submit'}
                        </CustomButton>
                        <p
                            className='w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiBold'
                            onClick={handleClose}
                        >
                            Cancel
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default AddMember