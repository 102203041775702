import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CustomButton from '../../common/Button'
import React from 'react'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}


const LogoutPopup = ({
    logOutPopup,
    handleLogoutClose,
    signOut,
}) => {



    return (
        <Modal
            open={logOutPopup}
            onClose={handleLogoutClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                className='md:w-[450px] w-[320px] p-4 md:px-6 md:py-0'
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className='flex flex-col justify-center items-center gap-5 p-2 sm:p-5 my-2'>
                    <p className='text-lg font-satoshiBold text-BlueJay'>
                        Are you sure you want to sign out ?
                    </p>
                    <div className='flex gap-6'>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-outlined'
                            size='large'
                            width='md:w-[100px] w-[80px] h-[40px]'
                            onClick={handleLogoutClose}
                        >
                            No
                        </CustomButton> 
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            size='large'
                            width='md:w-[100px] w-[80px]'
                            onClick={signOut}
                        >
                            Yes
                        </CustomButton>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default LogoutPopup