import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../common/BreadCrumb";
import ManageAddress from "./ManageAddress/ManageAddress";
import MyBookings from "./MyBookings/MyBookings";
import MyFamily from "./MyFamily/MyFamily";
import MyReports from "./MyReports/MyReports";
import Profile from "./Profile/Profile";
import { isUserLoggedIn, logout } from "../../../helpers/auth";
import LogoutPopup from "../LoginModal/LogoutPopup";
import { getUserName, showToastMessage } from "../../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../../features/userProfile/profileSlice";
import updateMeta from "../../../helpers/seo-meta";

const MyProfile = () => {
    const [options, setOptions] = useState(1);

    const navigate = useNavigate();
    const isLoggedIn = isUserLoggedIn();
    const [logOutPopup, setLogOutPopup] = useState(false);
    const step = localStorage.getItem("step");
    const { profileData } = useSelector((state) => state.profile)
    const dispatch = useDispatch()

    useEffect(() => {
        if (step) {
            setOptions(+step);
        } else {
            setOptions(1);
        }
    }, [step]);

    const navigateOptions = (e, option) => {
        setOptions(option);
        localStorage.setItem("step", option);
    };

    const loggingOut = () => {
        logout();
        localStorage.removeItem("step");
        navigate("/");
        handleLogoutClose();
        showToastMessage("Successfully signed out", "success");
    };

    const navigateOptionsMob = (e, option) => {
        e.preventDefault();
        option === 1
            ? navigate("/user-profile/my-profile")
            : option === 2
            ? navigate("/user-profile/my-family-friends")
            : option === 3
            ? navigate("/user-profile/manage-address")
            : option === 4
            ? navigate("/user-profile/bookings")
            : option === 5
            ? navigate("/user-profile/reports")
            : "";
    };


    const handleLogoutClose = () => setLogOutPopup(false);

    const handleLogoutOpen = () => setLogOutPopup(true);

    useEffect(() => {
        dispatch(fetchProfile())
    }, [])

    useEffect(() => {
        document.title = 'Zain care | High-Quality, Affordable Healthcare at your Home';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
      }, []);

    return (
        <>
            {/* for medium and large screens */}
            <div className="hidden md:block pt-[150px] lg:w-[1440px] m-auto">
                {/* BreadCrumb */}
                <div className="px-10">
                    <BreadCrumb
                        links={[
                            { path: "Home", url: "/" },
                            {
                                path: "My Account",
                                url: "#",
                            },
                        ]}
                    />
                </div>
                <div className="flex flex-row justify-center lg:gap-4 md:gap-5 lg:px-16 md:px-8 lg:py-10 lg:mb-20 md:mb-56">
                    <div className="bg-with-shadow lg:w-[30%] md:w-[50%] h-fit p-4">
                        <p className="text-Verdigris font-jostSemibold text-2xl ml-3 mt-2">
                            Hi, {getUserName(profileData)} 
                        </p>
                        <ul className="bg-WhiteLilac p-4 flex flex-col gap-4 rounded-lg md:mt-5 lg:mt-8 font-satoshiMedium text-BlueJay">
                            <li
                                onClick={(e) => navigateOptions(e, 1)}
                                className={`${
                                    options === 1
                                        ? "option-active"
                                        : "option-inactive"
                                }`}
                            >
                                My Profile
                            </li>
                            <li
                                onClick={(e) => navigateOptions(e, 2)}
                                className={`${
                                    options === 2
                                        ? "option-active"
                                        : "option-inactive"
                                }`}
                            >
                                My Family & Friends
                            </li>
                            <li
                                onClick={(e) => navigateOptions(e, 3)}
                                className={`${
                                    options === 3
                                        ? "option-active"
                                        : "option-inactive"
                                }`}
                            >
                                Manage Address
                            </li>
                            <li
                                onClick={(e) => navigateOptions(e, 4)}
                                className={`${
                                    options === 4
                                        ? "option-active"
                                        : "option-inactive"
                                }`}
                            >
                                My Bookings
                            </li>
                            <li
                                onClick={(e) => navigateOptions(e, 5)}
                                className={`${
                                    options === 5
                                        ? "option-active"
                                        : "option-inactive"
                                }`}
                            >
                                My Reports
                            </li>
                            <li
                                onClick={handleLogoutOpen}
                                className={`${
                                    options === 6
                                        ? "option-active"
                                        : "option-inactive"
                                }`}
                            >
                                Logout
                            </li>
                        </ul>
                    </div>
                    {options === 1 ? (
                        <Profile />
                    ) : options === 2 ? (
                        <MyFamily />
                    ) : options === 3 ? (
                        <ManageAddress />
                    ) : options === 4 ? (
                        <MyBookings />
                    ) : options === 5 ? (
                        <MyReports />
                    ) : (
                        ""
                    )}
                </div>
            </div>

            {/* for mobile screens */}

            <div className="relative top-[150px] mb-[150px] bg-with-shadow p-3 mx-6 md:hidden block">
                <p className="text-Verdigris font-jostSemibold text-xl ml-2 mt-2">
                    Hi, {profileData.phone}
                </p>
                <ul className="flex flex-col gap-2 mt-5 font-satoshiMedium text-BlueJay">
                    <li
                        onClick={(e) => navigateOptionsMob(e, 1)}
                        className="py-4 px-5 nav-shadow flex justify-between cursor-pointer"
                    >
                        <span>My Profile</span>
                        <img src='/images/icons/rightArrow.svg' alt="navigation" />
                    </li>
                    <li
                        onClick={(e) => navigateOptionsMob(e, 2)}
                        className="py-4 px-5 nav-shadow flex justify-between cursor-pointer"
                    >
                        <span>My Family & Friends</span>
                        <img src='/images/icons/rightArrow.svg' alt="Navigation" />
                    </li>
                    <li
                        onClick={(e) => navigateOptionsMob(e, 3)}
                        className="py-4 px-5 nav-shadow flex justify-between cursor-pointer"
                    >
                        <span>Manage Address</span>
                        <img src='/images/icons/rightArrow.svg' alt="Navigation" />
                    </li>
                    <li
                        onClick={(e) => navigateOptionsMob(e, 4)}
                        className="py-4 px-5 nav-shadow flex justify-between cursor-pointer"
                    >
                        <span>My Bookings</span>
                        <img src='/images/icons/rightArrow.svg' alt="navigation" />
                    </li>
                    <li
                        onClick={(e) => navigateOptionsMob(e, 5)}
                        className="py-4 px-5 nav-shadow flex justify-between cursor-pointer"
                    >
                        <span>My Reports</span>
                        <img src='/images/icons/rightArrow.svg' alt="My Reports" />
                    </li>
                    <li
                        onClick={handleLogoutOpen}
                        className="py-4 px-5 nav-shadow flex justify-between cursor-pointer"
                    >
                        <span>Logout</span>
                        <img src='/images/icons/rightArrow.svg' alt="Logout" />
                    </li>
                </ul>
            </div>

            {logOutPopup && (
                <LogoutPopup
                    logOutPopup={logOutPopup}
                    handleLogoutClose={handleLogoutClose}
                    signOut={loggingOut}
                />
            )}
        </>
    );
};
export default MyProfile;
