import Radio from "@mui/material/Radio";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

const styles = makeStyles({
    radio: {
        "&$checked": {
            color: "#33B0AA",
        },
    },
    checked: {},
});

function AddressRadioSelect({ data, onSelect }) {
    const classes = styles();
    return (
        <div
            className={` cursor-pointer ${
                !data?.checked
                    ? "bg-[#F4F7FA]"
                    : "bg-white border border-[#33B0AA] memberSelect"
            } rounded-lg lg:w-[444px] md:w-[491px] w-[311px] p-5 `}
        >
            <div className="flex flex-row justify-between items-center ">
                <div>
                    <div className="text-blueShadow">
                        <p className="font-bold text-[14px]">
                            {data?.location_type}
                        </p>
                    </div>
                    <p className="text-secondaryLight text-[13px] font-medium">
                        {data?.house_no}, {data?.building_name},{" "}
                        {data?.locality}, {data?.city}.
                    </p>
                    <p className="text-secondaryLight text-[13px] font-medium lg:w-[350px] md:w-[350px] w-[250px] break-words">
                        Address Instructions :{" "}
                        {data?.address_instructions ?? "NA"}
                    </p>
                </div>

                <div>
                    <Radio
                        classes={{
                            root: classes.radio,
                            checked: classes.checked,
                        }}
                        checked={data.checked}
                        onClick={(e) => onSelect(e, data)}
                        name="address"
                        inputProps={{ "aria-label": "A" }}
                        sx={{
                            color: "#33B0AA",
                            "&.Mui-checked": {
                                color: "#33B0AA",
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default AddressRadioSelect;
