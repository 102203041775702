const TOKEN_KEY = 'user-token'
const AUTH_USER = 'auth_user';

export const login = (value) => {
  if (value.length) return typeof window !== 'undefined' ? localStorage?.setItem(TOKEN_KEY, value) : ''
}

export const token = () => typeof window !== 'undefined' ? localStorage?.getItem(TOKEN_KEY) || '' : ''

export const logout = () => {
  typeof window !== 'undefined' ? localStorage?.removeItem(AUTH_USER) : '';
  typeof window !== 'undefined' ? localStorage?.removeItem(TOKEN_KEY) : '';
};

export const isUserLoggedIn = () => {
  if (typeof window !== 'undefined') {
    if (localStorage?.getItem(TOKEN_KEY) && localStorage?.getItem(AUTH_USER)) {
      return true;
    }
    return false;
  }

};

export const getLoggedInUser = () => {
  if (typeof window !== 'undefined') {
    if (localStorage?.getItem(AUTH_USER)) {
      return JSON.parse(typeof window !== 'undefined' ? localStorage?.getItem(AUTH_USER) || '' : '')
    }
    return false
  }

}