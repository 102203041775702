import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    currency: 'AED',
    items: [],
    total: {},
    authenticated: false,
    step_index: 1,
    is_guest_user: false,
    currency_symbol: 'د.إ',
    count: 0,
    patient_list: [],
    sample_collection_date_time: '',
    samp_col_time_slot: '',
    is_basic_done:true,
    address: {},
    user:{},
    coupon: {
        is_applied: false,
    },
    mode_of_payment:'1'
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        updateCart: (state, action) => {
            state.items = action.payload.data.items
            state.total = action.payload.data.total
            state.currency = action.payload.data.currency
            state.authenticated = action.payload.data.authenticated
            state.step_index = action.payload.data.step_index
            state.is_guest_user = action.payload.data.is_guest_user
            state.currency_symbol = action.payload.data.currency_symbol
            let count =0;
            if (action.payload.data.patient_list
                .length) {
                count = action.payload.data.patient_list.map((x) => x.items.length).reduce((a, b) => a + b, 0)
            }else {
                count =  action.payload.data.items.length
            }
            state.count = count
            state.patient_list = action.payload.data.patient_list || []
            state.sample_collection_date_time = action.payload.data.sample_collection_date_time
            state.samp_col_time_slot = action.payload.data.samp_col_time_slot
            state.user = action.payload.data.user
            state.address = action.payload.data.address
            state.is_basic_done = action.payload.data.is_basic_done
            state.coupon = action.payload.data.coupon
        },
        updatePaymentMode: (state, action) => {
            state.mode_of_payment = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateCart, updatePaymentMode } = cartSlice.actions

export default cartSlice.reducer
