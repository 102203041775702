import React from 'react'
import CustomButton from '../common/Button'

const OpeningBanner = ({handleExplore}) => {
  const width = typeof window !== 'undefined' ? window.innerWidth : 1000
  const buttonHeight = width <= 768 ? '47px' : '56px'
  

  return (
    <div
      className='max-w-[1440px] m-auto  px-6 pb-8 sm:p-8 lg:px-16 
       '
    >
      <div className='flex relative  '>
        <div className=' lg:pl-0 md:pl-8  pl-0 flex flex-col gap-y-8 lg:gap-y-10  z-30 '>
          <p className=' leading-tight  lg:text-[4rem] md:text-[2.25rem] text-[30px] text-blueShadow font-jostSemibold  '>
            We focus on <br /> Simplifying
            <span className='text-Verdigris'> healthcare</span> <br /> & Impacting lives!
          </p>


          <div className='my-40 sm:hidden block'></div>

          <p className='font-satoshiRegular  lg:w-[487px] md:w-[296px] w-[98%] lg:text-[20px] md:text-[16px] text-[14px] text-secondaryLight lg:leading-[26px] md:leading-[18px] leading-[20px] '>
          Zaincare strives to redefine healthcare and to promote overall wellness by optimisation of your health by achieving limitless potential of human body and its vibrant disease-free longevity and vitality.
          </p>
          <CustomButton
            width='w-full sm:w-44 '
            borderRadius='0.5rem'
            variant='secondary-contained'
            size='large'
            height={buttonHeight}
            onClick={handleExplore}
          >
            Explore
          </CustomButton>
        </div>

        <img
          className='z-20 lg:w-[60%] md:w-[60%]   absolute lg:-right-16 lg:top-0 md:top-0 top-0 bottom-0 m-auto -right-2  '
          src='/images/images/about_us_banner.png'
          alt=''
        />
      </div>
    </div>
  )
}

export default OpeningBanner
