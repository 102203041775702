import { Checkbox } from "@mui/material";
import React from 'react'

function MemberSelect({ data, onSelect }) {
    return (
        <div
            onClick={(e) => onSelect(!data.checked, data)}
            className={` cursor-pointer ${
                !data?.checked
                    ? "bg-[#F4F7FA]"
                    : "bg-white border border-[#33B0AA] memberSelect"
            } rounded-lg lg:w-[444px] md:w-[491px] w-[311px] p-5 `}
        >
            <div className="flex flex-row justify-between items-center ">
                <div>
                    <div className="flex flex-row gap-[8px] items-center text-blueShadow">
                        <p className="font-satoshiBold lg:text-[18px] text-[14px]">
                            {data?.name}
                        </p>{" "}
                    </div>
                    <p className="text-secondaryLight font-satoshiMedium lg:text-[16px] text-[10px]">
                        {data.phone}, {data?.gender},{" "}
                        {data.age ? data.age + "years" : ""}
                    </p>
                    <p className="text-secondaryLight font-satoshiMedium lg:text-[16px] text-[10px]">
                        {data.email}
                    </p>
                    <p className="text-secondaryLight font-satoshiMedium lg:text-[16px] text-[10px]">
                        Relation : {data?.relation}
                    </p>
                </div>
                <div>
                    <Checkbox
                        icon={<img src='/images/checkboxIcons/unChecked.svg' alt={"Checked"} />}
                        checkedIcon={<img src='/images/checkboxIcons/checked.svg' alt={"Unchecked"} />}
                        id="select"
                        checked={data.checked}
                        onChange={(e) => onSelect(e.target.checked, data)}
                    />
                </div>
            </div>
        </div>
    );
}

export default MemberSelect;
