import React from 'react'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'


const FileUpload = ({
    id,
    label,
    acceptMimeTypes = ['application/pdf'],
    setImage,
    title = 'Drag and Drop PDF here',
    maxSize = 5, // in MB,
    imageUrl = '',
    styleType = 'lg',
    filename,
    previewData = '',
    setPreviewMeta,
    removeImage,
    error,
    flag
}) => {
    const [isUploaded, setUpload] = useState(false)
    const [active, setActive] = useState(false)
    const [imageMeta, setImagePreview] = useState({ name: '', url: '', type: '' } )

    useEffect(() => {
        flag ? handleCancel() : () => { }
    }, [flag])

    useEffect(() => {
        // show preview image
        if (imageUrl && imageUrl.startsWith('https')) {
            setUpload(true)
            const name = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
            const lastDot = name.lastIndexOf('.')
            const ext = name.substring(lastDot + 1)
            setImagePreview({
                name,
                url: imageUrl,
                type: ext === 'pdf' ? 'application/pdf' : 'application/pdf',
            })
        }

        if (previewData) {
            setImagePreview(previewData)
            setUpload(true)
        }
    }, [previewData, imageUrl])

    const validation = (file) => {
        const maxSizeInBytes = Number(maxSize) * 1024 ** 2
        if (file.size > maxSizeInBytes) {
            // show toast message;
            toast.error(`File Size Exceeds the ${maxSize}`, {
                position: toast.POSITION.TOP_RIGHT,
            })
            return false
        }

        if (!acceptMimeTypes.includes(file.type)) {
            toast.error('Unsupported file selected!', {
                position: toast.POSITION.TOP_RIGHT,
            })
            return false
        }

        return true
    }

    const convertBase64 = (file) => {
        const isValid = validation(file) // validation for file type and size
        if (isValid) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                const meta = { name: file.name, url: reader.result, type: file.type }
                setImagePreview(meta)
                // setPreviewMeta && setPreviewMeta(meta);
                if (setPreviewMeta) {
                    setPreviewMeta(meta)
                }
                setUpload(true)
                setActive(true)
                setImage({
                    url: reader.result || '',
                    name: filename,
                    file,
                    preview: meta,
                })
            }
        }
    }

    const chooseFile = (e) => {
        e.preventDefault()
        const ele = document.getElementById(id)
        if (ele) ele.click()
    }
    const onChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        convertBase64(file)
    }

    const handleCancel = () => {
        setUpload(false)
        setActive(false)
        if (removeImage) {
            removeImage('')
        }
    }

    // drag and drop functionalities
    const onDrag = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.type === 'dragover') {
            setActive(true)
        }

        if (event.type === 'dragleave') {
            setActive(false)
        }

        if (event.type === 'drop') {
            const file = event.dataTransfer.files[0]
            convertBase64(file)
        }
    }
    return (
        <div
            className={`relative file-upload-box w-full h-fit ${error && 'file-upload-error'} ${active ? 'file-upload-active' : ''
                }`}
        >
            {!isUploaded ? (
                <>
                    <label
                        htmlFor={id}
                        className={`cursor-pointer ${styleType === 'md' ? 'hidden' : 'block'}`}
                        onDragOver={(e) => onDrag(e)}
                        onDragLeave={(e) => {
                            onDrag(e)
                        }}
                        onDrop={(e) => {
                            onDrag(e)
                        }}
                    >
                        <p className='px-[50px] py-3 border-2 cursor-pointer hover:shadow-md border-[#909EB4] rounded-lg text-[#3C567E] font-satoshiBold text-base'>
                            Add File
                        </p>
                    </label>
                    <div
                        className={`flex justify-start items-center gap-5  ${styleType === 'md' ? 'pt-0' : 'pt-6'
                            }`}
                    >
                        <div>
                            <input
                                type='file'
                                id={id}
                                onChange={onChange}
                                accept={acceptMimeTypes.toString()}
                                className='hidden'
                            />
                            <div
                                className='upload-btn   flex cursor-pointer justify-center items-center rounded-lg'
                                id={id}
                                role='presentation'
                                onClick={(e) => chooseFile(e)}
                            >
                                <p className='lg:px-[50px] lg:py-3 md:px-[50px] md:py-3 py-4 px-5 border-2 cursor-pointer hover:shadow-md border-[#909EB4] rounded-lg text-[#3C567E] font-satoshiBold text-base'>
                                    Add File
                                </p>
                            </div>
                        </div>
                        <div className='flex justify-start items-center  space-x-0 lg:space-x-6'>
                            <div className='text-Kimberly flex flex-col text-sm label-sec'>
                                <p>We accept PDF only</p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={`flex justify-center flex-col  items-center ${styleType === 'md' ? 'hidden' : 'block'
                            }`}
                    >
                        <p className='text-SpaceCadet text-center pt-4 font-bold'>Uploaded!</p>
                    </div>

                    <div
                        className={`flex justify-between items-center  ${styleType === 'md' ? 'pt-0' : 'pt-6'
                            }`}
                    >
                        <div className='flex justify-center items-center space-x-6'>
                            <p className='text-SpaceCadet text-sm'>
                                {imageMeta.name.length > 10 ? (
                                    <>
                                        {imageMeta.name.substring(0, 10)}
                                        ...
                                    </>
                                ) : (
                                    <>{imageMeta.name}</>
                                )}
                            </p>
                        </div>
                        <div>
                            <button
                                onClick={handleCancel}
                                type='button'
                                className=' cancel-btn flex cursor-pointer justify-center items-center rounded-lg'
                            >
                                Remove
                            </button>
                        </div>
                    </div>
                </>
            )}

            {error && <p className='absolute -bottom-6 ml-3 text-[#EF4949] text-xs'>{error}</p>}
        </div>
    )
}

export default FileUpload
