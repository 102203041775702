import React, { useState } from 'react'
import { Badge, ClickAwayListener } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FloatingCart = () => {
    const [open, setOpen] = useState(false)

    const cart = useSelector((state) => state.cart);


    const navigate = useNavigate()


    const shadow = "0px -2px 80px rgba(35, 134, 167, 0.06), 0px -0.835552px 33.4221px rgba(35, 134, 167, 0.0431313), 0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664), 0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03), 0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336), 0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687)"

    return (
        <React.Fragment>
            {
                cart.items.length > 0 ?
                    <ClickAwayListener onClickAway={() => setOpen(false)}>
                        <div 
                        className='fixed bottom-[143px] right-[20px] min-w-[64px] z-[98] flex flex-col justify-end items-end rounded-xl'>
                            {open && (
                                <div style={{ boxShadow: shadow }} className='bg-[#f2f2f2]  w-[267px] relative rounded-xl bottom-[10px]'>
                                    <div className='w-full p-3 bg-blueShadow flex items-center justify-center rounded-tl-xl rounded-tr-xl'>
                                        <p className='text-white font-jostMedium text-base'>Cart</p>
                                    </div>
                                    <div className=' h-[100px] overflow-y-scroll mx-5 mt-2 mb-3'>
                                        {cart.items.length > 0 ? <>
                                            {cart.items.map((item, index) => (
                                                <p className='first:mt-0 mt-[10px] text-sm text-blueShadow font-satoshiMedium'><span className='text-[#363636] text-sm font-satoshiBold'>{index + 1}.{" "}</span>{item?.name}</p>
                                            ))}
                                        </> : <p className='text-center text-[#323232] font-satoshiMedium text-sm'>Cart is empty</p>}


                                    </div>
                                    <div className='flex justify-center items-center'>
                                        <p onClick={() => {
                                            navigate('/orders/checkout')
                                            setOpen(!open)
                                        }
                                        } className='bg-[#33B0AA] mb-3 p-2 hover:shadow-lg text-white font-jostMedium text-sm rounded-lg text-center cursor-pointer'>Proceed to checkout</p>

                                    </div>
                                </div>
                            )}
                            <div
                                onMouseLeave={() => !open && setOpen(false)}
                                onClick={() => setOpen(!open)}
                                className='bg-[#3C567E] p-4 rounded-full w-16 h-16 cursor-pointer flex flex-row justify-center items-center'
                            >
                                {!open && (
                                    <div 
                                    className='relative right-[2px]'>
                                        <Badge
                                            badgeContent={cart?.count}
                                            sx={{
                                                "& .MuiBadge-badge": {
                                                    color: "#33B0AA",
                                                    backgroundColor: "white",
                                                },
                                            }}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                            }}
                                        ><img
                                                src='/images/icons/cart.svg'
                                                alt='cart icon'
                                                className='w-8'
                                            />
                                        </Badge>
                                    </div>

                                )}
                                {open && (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        className='h-7 w-7 inline-block text-white'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                        stroke='currentColor'
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                            d='M6 18L18 6M6 6l12 12'
                                        />
                                    </svg>
                                )}
                            </div>
                        </div>
                    </ClickAwayListener > : <></>
            }
        </React.Fragment >
    )
}

export default FloatingCart
