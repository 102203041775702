import React from 'react'
import Title from './Title'
import { useState } from 'react'
import InfoCard from './InfoCard'
import { motion } from 'framer-motion'
import { uuid } from '../../helpers/helpers'

// react-multi-carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const AnimatedGroup = ({ item }) => {

    let condition = true

    return (
        <React.Fragment>
            {/* Desktop */}
            <div className={`lg:flex md:hidden hidden flex-col justify-center items-center relative scale-[0.8] overflow-hidden`}>

                <div className={`flex flex-row mt-[100px] relative ${item.number === '/images/numbers/one.svg' ? 'right-[30px]' : item.number === '/images/numbers/two.svg' ? 'right-[80px]' : 'right-[80px]'}`}>
                    <motion.div
                        initial={{ x: 180 }}
                        whileInView={{ x: condition ? 280 : '' }}
                        transition={{ type: "spring", stiffness: 55, duration: 5000 }}
                        exit={{ opacity: 0 }}
                        className='relative z-20 lg:bottom-[50px] lg:mb-[-50px] md:bottom-[0px] bottom-[30px]'>
                        <img src={item.number} alt={item.number} />
                    </motion.div>
                    {/* hexagon one left bottom */}
                    <motion.div
                        initial={{ x: -100 }}
                        // whileInView={{ x: 130 }}
                        whileInView={{ x: condition ? 70 : '' }}
                        transition={{ type: "spring", stiffness: 55, duration: 5000 }}
                        exit={{ opacity: 0 }}
                    >
                        <img src='/images/shapes/hexagonLight.svg' alt='test' className='relative z-10 lg:top-[100px] md:top-[70px] top-[1px] lg:left-[70px] md:left-[70px] left-[1px]' />
                    </motion.div>
                    {/* lady in the middle */}
                    <motion.div
                        className='relative z-40'
                        initial={{ y: 70 }}
                        whileInView={{ y: item.img === '/images/people/lady_2_v1.webp' ? -50 : -30 }}
                        transition={{ type: "spring", stiffness: 55, duration: 5000 }}
                        exit={{ opacity: 0 }}
                    >
                        <img src={item.img} alt={item.img} className='scale-[1.1]' />
                    </motion.div>

                    {/* hexagon two right top*/}
                    <motion.div
                        initial={{ x: 100 }}
                        // whileInView={{ x: -130 }}
                        whileInView={{ x: condition ? -70 : '' }}
                        transition={{ type: "spring", stiffness: 55, duration: 5000 }}
                        exit={{ opacity: 0 }}
                    ><img src='/images/shapes/hexagonLight.svg' alt='test' className='relative z-10 lg:bottom-[100px] md:bottom-[70px] bottom-[50px] lg:right-[70px] md:right-[70px] right-[1px]' /></motion.div>
                </div>

                <InfoCard title={item?.title} info={item?.info} />
            </div>

            {/* Tab */}
            <div className='lg:hidden md:flex hidden flex-col justify-center items-center relative lg:scale-[1] md:scale-[0.8] lg:px-5 overflow-hidden'>
                <div className={`flex flex-row mt-[100px] relative ${item.number === '/images/numbers/one.svg' ? 'right-[30px]' : item.number === '/images/numbers/two.svg' ? 'right-[30px]' : 'right-[30px]'}`}>
                    <motion.div
                        initial={{ x: 180 }}
                        whileInView={{ x: 265 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                        className='relative z-20 bottom-[40px]'>
                        <img src={item.number} alt={item.number} />
                    </motion.div>
                    {/* hexagon one left bottom */}
                    <motion.div
                        initial={{ x: -100 }}
                        whileInView={{ x: 50 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                    >
                        <img src='/images/shapes/hexagonLight.svg' alt='test' className='relative z-10 lg:top-[100px] md:top-[70px] top-[1px] lg:left-[70px] md:left-[70px] left-[1px]' />
                    </motion.div>
                    {/* lady in the middle */}
                    <motion.div
                        className='relative z-40'
                        initial={{ y: 70 }}
                        whileInView={{ y: item.img === '/images/people/lady_2_v1.webp' ? -50 : -30 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                    >
                        <img src={item.img} alt={item.img} className='scale-[1.1]' />
                    </motion.div>

                    {/* hexagon two right top*/}
                    <motion.div
                        initial={{ x: 100 }}
                        whileInView={{ x: -50 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                    ><img src='/images/shapes/hexagonLight.svg' alt='test' className='relative z-10 lg:bottom-[100px] md:bottom-[70px] bottom-[50px] lg:right-[70px] md:right-[70px] right-[1px]' /></motion.div>
                </div>

                <InfoCard title={item?.title} info={item?.info} />
            </div>

            {/* Phone */}
            <div className='lg:hidden md:hidden flex flex-col justify-center items-center relative lg:px-5 overflow-hidden'>
                <div className="flex flex-row mt-[100px] relative ">
                    <motion.div
                        initial={{ x: 180 }}
                        whileInView={{ x: 127 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                        className={`relative z-20 ${item.number === '/images/numbers/one.svg' ? 'bottom-[40px]' : item.number === '/images/numbers/two.svg' ? 'bottom-[50px]' : 'bottom-[40px]'}`}>
                        <img src={item.number} alt={item.number} />
                    </motion.div>
                    {/* hexagon one left bottom */}
                    <motion.div
                        initial={{ x: -100 }}
                        whileInView={{ x: 50 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                    >
                        <img src='/images/shapes/hexagonLight.svg' alt='test' className='relative z-10 lg:top-[100px] md:top-[70px] top-[1px] lg:left-[70px] md:left-[70px] left-[1px]' />
                    </motion.div>
                    {/* lady in the middle */}
                    <motion.div
                        className='relative z-40'
                        initial={{ y: 70 }}
                        whileInView={{ y: item.img === '/images/people/lady_2_v1.webp' ? -50 : -30 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                    >
                        <img src={item.img} alt={item.img} className='scale-[1.1]' />
                    </motion.div>

                    {/* hexagon two right top*/}
                    <motion.div
                        initial={{ x: 100 }}
                        whileInView={{ x: -50 }}
                        transition={{ type: "spring", stiffness: 55 }}
                        exit={{ opacity: 0 }}
                    ><img src='/images/shapes/hexagonLight.svg' alt='test' className='relative z-10 lg:bottom-[100px] md:bottom-[70px] bottom-[50px] lg:right-[70px] md:right-[70px] right-[1px]' /></motion.div>
                </div>

                <InfoCard title={item?.title} info={item?.info} />
            </div>
        </React.Fragment>
    )
}

const content = [
    {
        id: uuid(),
        title: "Book your tests",
        info: " Book your test easily with our online system or via call/WhatsApp. Provide your details, choose a suitable date/time, and our friendly staff will assist you for a stress-free booking",
        img: '/images/people/lady_1_v1.webp',
        number: '/images/numbers/one.svg',

    },
    {
        id: uuid(),
        title: "Home Lab test Collection",
        info: " Our technician with a home visit kit will come to you, verify the OTP, follow our checklist, and collect the sample. It takes only 5-10 minutes",
        img: '/images/people/lady_2_v1.webp',
        number: '/images/numbers/two.svg',

    },
    {
        id: uuid(),
        title: "Lab Reports delivery",
        info: "  We handle samples with precision, transport them to affiliated labs, and process with strict protocols to ensure reliability. Smart reports are delivered within the specified time via email and online portal.",
        img: '/images/people/lady_3_v1.webp',
        number: '/images/numbers/three.svg',


    }
]


function LifeSavingSteps({ setActiveIndex }) {
    const [aIndex, setAIndex] = useState(0)


    const CustomDot = ({ onMove, index, onClick, active }) => {
        // onMove means if dragging or swiping in progress.
        // active is provided by this lib for checking if the item is active or not.
        return (
            <li
                className={active ? "mx-2" : "opacity-60 mx-2"}
                onClick={() => onClick()}
            >
                <div className={`h-[10px] w-[70px] rounded ${active?'bg-blueShadow':'bg-[#D5E4E9]'} cursor-pointer`}>
                </div>
            </li>
        );
    };

    return (
        <div id="thisCarousel" className="lg:mt-[30px] ">
            <div className='relative lg:top-[50px] md:top-[80px] top-[30px]'>
                <Title />
            </div>
            <br />
            <br />


            <div className="lg:block md:hidden hidden">
                <Carousel
                    customDot={<CustomDot />}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay={true}
                    autoPlaySpeed={2200}
                    centerMode={true}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable={false}
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl={false}
                    minimumTouchDrag={80}
                    pauseOnHover={false}
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 1,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay={false}
                    showDots={true}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable={false}
                >
                    {
                        content.map((item, index) => {
                            return (
                                <div className='lg:w-[800px] m-auto'>
                                    <AnimatedGroup item={item} index={index} activeIndex={aIndex} />
                                </div>
                            )
                        })}
                </Carousel>
            </div>

            <div className="lg:hidden md:block block">
                <Carousel
                    customDot={<CustomDot />}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay={true}
                    autoPlaySpeed={2200}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable={false}
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl={false}
                    minimumTouchDrag={80}
                    pauseOnHover={false}
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 1,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay={false}
                    showDots={true}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable={false}
                >
                    {
                        content.map((item, index) => {
                            return (
                                <div className='lg:scale-[0.99] scale-1'>
                                    <AnimatedGroup item={item} index={index} activeIndex={aIndex} />
                                </div>
                            )
                        })}
                </Carousel>
            </div>

        </div>
    )
}

export default LifeSavingSteps