import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Input } from '../../../common/Input'
import TextArea from '../../../common/TextArea'
import CustomButton from '../../../common/Button'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}

const SupportPopup = ({
    open,
    handleClose,
    params,
    formErrors,
    handleChange,
    handleSubmit,
}) => {



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                className='md:w-[450px] w-[350px] px-4 py-2 md:px-6 md:py-0'
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className='flex justify-end mt-4 mb-1'>
                    <span
                        className='cursor-pointer'
                        onClick={handleClose}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.17004 14.8299L14.83 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.83 14.8299L9.17004 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
                <div className='w-full flex flex-col gap-3 max-h-[700px] overflow-auto mb-6'>
                    <p className='font-satoshiRegular text-BlueJay text-lg mb-4 sm:w-[85%] text-left leading-5'>Send us your message and let us get back to you !  </p>
                    <Input
                        rows={1}
                        width='w-full'
                        disabled={false}
                        readOnly={false}
                        handleChange={handleChange}
                        value={params?.name}
                        error={formErrors?.name}
                        helperText={formErrors?.name}
                        label='Name'
                        name='name'
                    />
                    <Input
                        rows={1}
                        width='w-full'
                        disabled={false}
                        readOnly={false}
                        handleChange={handleChange}
                        value={params?.phone}
                        error={formErrors?.phone}
                        helperText={formErrors?.phone}
                        label='Contact No'
                        name='phone'
                    />
                    <Input
                        rows={1}
                        width='w-full'
                        disabled={false}
                        readOnly={false}
                        handleChange={handleChange}
                        value={params?.email}
                        error={formErrors?.email}
                        helperText={formErrors?.email}
                        label='Email ID'
                        name='email'
                    />
                    <TextArea
                        className='border-[1px] placeholder:text-[#909EB4] w-full'
                        placeholder='Special request if any in your inquiry'
                        name='message'
                        rows={4}
                        error={formErrors?.message}
                        value={params.message}
                        handleChange={handleChange}
                        helperText={formErrors?.message}
                    />

                    <div className='mt-2 flex flex-row-reverse justify-start lg:flex-col lg:justify-center items-center gap-20 lg:gap-4'>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            size='large'
                            width='md:w-[180px] lg:w-full w-[150px]'
                            onClick={handleSubmit}
                        >
                            Submit
                        </CustomButton>
                        <p
                            className='w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiMedium'
                            onClick={handleClose}
                        >
                            Cancel
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default SupportPopup