import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import profileReducer from "./userProfile/profileSlice";
import familyReducer from "./userProfile/familySlice";
import bookingReducer from "./userProfile/bookingsSlice";
import addressReducer from "./userProfile/addressSlice";
import cartReducer from "./cart/cartSlice";
import feedbackReducer from "./feedback/feedbackSlice";

export default combineReducers({
    auth: authReducer,
    profile: profileReducer,
    family: familyReducer,
    booking: bookingReducer,
    address: addressReducer,
    cart: cartReducer,
    feedback: feedbackReducer,
});
