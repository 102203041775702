import * as React from 'react'
import { Link } from 'react-router-dom'
import { uuid } from '../../helpers/helpers'

const BreadCrumb = ({ links }) => (
    <div role='presentation' className='hidden lg:block md:hidden'>
        <p>
            {links.map((item, i, arr) =>
                i !== arr.length - 1 ? (
                    <React.Fragment key={uuid()}>
                        <Link
                            className='text-[#909EB4] font-[500] text-base cursor-pointer'
                            to={item?.url}
                        >
                            {item?.path}
                        </Link>
                        <span className='px-1 text-[#909EB4] font-[500]  text-base '>{'/'}</span>
                    </React.Fragment>
                ) : (
                    <a className='  text-[#3C567E] font-[500] text-base '>{item?.path}</a>
                ),
            )}
        </p>
    </div>
)

export default BreadCrumb
