import React, { useEffect, useState } from 'react'
import { Input } from '../../../common/Input'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { editProfile, fetchProfile, resetApiSuccess } from '../../../../features/userProfile/profileSlice'
import moment from 'moment'
import CommonDatepicker from '../../../common/DatePicker'
import Validator from 'validatorjs'
import { calculateAge } from '../../../../helpers/helpers'
import { CommonSelectInput } from '../../../common/CommonSelect'
import updateMeta from '../../../../helpers/seo-meta'

const initialValue = {
    name: '',
    email: '',
    dob: '',
    gender: '',
    img_file: '',
    phone: '',
    image: ''
}

const Profile = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { profileData, isLoading, isCreateSuccess } = useSelector((state) => state.profile)
    const [showForm, setShowForm] = useState(false)
    const [params, setParams] = useState(initialValue)
    const [formErrors, setFormErrors] = useState(initialValue)
    let age = calculateAge(profileData?.dob)

    useEffect(() => {
        setParams({
            ...params,
            name: profileData?.name,
            email: profileData?.email,
            phone: profileData?.phone,
            gender: profileData?.gender,
            // dob: moment(profileData?.dob).format('YYYY-MM-DD'),
            dob: Date.parse(profileData?.dob),
            img_file: profileData?.image
        })
    }, [])

    useEffect(() => {
        if (Object.keys(profileData).length) {
            let user = localStorage.getItem('auth_user')
            if (user) {
                user = JSON.parse(user)
                user['name'] = profileData.name
                user['phone'] = profileData.phone
                user['email'] = profileData.email
            }
            localStorage.setItem('auth_user', JSON.stringify(user))
        }
    }, [profileData])

    const handleEditProfile = () => {
        setShowForm(!showForm)
        setParams({
            ...params, 
            name: profileData?.name, 
            email: profileData?.email, 
            phone: profileData?.phone,
            gender: profileData?.gender, 
            // dob: moment(profileData?.dob).format('YYYY-MM-DD'),
            dob: Date.parse(profileData?.dob)
        })
    }

    useEffect(() => {
        dispatch(fetchProfile())
    }, [])

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target
            if (name === "phone" || name === "age") {
                const re = /^[0-9\b]+$/;
                if (value && !re.test(value)) {
                    return;
                }
            }
            setParams({ ...params, [name]: value })
        }
        setFormErrors({})
    }

    const onDateChange = (newValue) => {
        let date2 = moment(new Date(newValue)).format('YYYY-MM-DD')
        setParams({ ...params, dob: date2 })
    }

    const clearForm = () => {
        setShowForm(false)
        setParams({ ...params, img_file: 'file', image: '' });
    }

    // const [formData, setFormData] = useState({} )

    const handleUpdate = (e) => {
        e.preventDefault();
        let payload = {
            name: params.name,
            email: params.email,
            dob: params.dob,
            gender: params.gender,
            img_file: params.img_file,
        }
        const rules = {
            name: 'required|string|max:50',
            email: 'required|email|max:50',
            dob: 'required',
            gender: 'required',
        }
        const validation = new Validator(payload, rules)
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            const err = Object.keys(fieldErrors)
            if (err.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            setFormErrors(fieldErrors)
            return false
        }

        const formData = new FormData()

        Object.entries(payload).forEach(([key, value]) => {
            if (!['img_file'].includes(key)) {
                formData.append(`${key}`, `${value}`)
            }
        })

        if (params.img_file) {
            formData.append('img_file', params.img_file)
        }
        // setFormData(formData)
        dispatch(editProfile(formData))
        setFormErrors({})
        setShowForm(false)
        return true
    }

    // useEffect(() => {
    //     dispatch(editProfile(formData))
    // }, [params.img_file])


    const handleImage = (e) => {
        const reader = new FileReader()
        if (!e || !e.target?.files) {
            return
        }
        const file = e.target?.files[0]
        if (file) {
            reader.readAsDataURL(file)
            reader.onload = () => {
                setParams({ ...params, img_file: file, image: reader.result });

            };
        }

    }

    useEffect(() => {
        if (isCreateSuccess == true) {
            dispatch(resetApiSuccess())
            dispatch(fetchProfile())
        }
    }, [isCreateSuccess])

    useEffect(() => {
        document.title = 'Zain care | High-Quality, Affordable Healthcare at your Home';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);

    return (
        <div className='h-screen md:h-fit bg-white w-full md:bg-with-shadow md:w-[50%] lg:w-[70%] flex flex-col lg:flex-row gap-4 lg:p-6 md:p-5 pb-6 rounded-lg'>
            <div className='md:hidden  flex justify-start items-center gap-4 border-b border-Harp px-6 py-3'>
                <img className=' cursor-pointer' onClick={() => navigate('/user-profile')} src='/images/icons/leftArrow.svg' alt=" Left Arrow" />
                <p className='text-DarkGrey text-base font-satoshiBold'>My Profile</p>
            </div>
            {
                isLoading ?
                    <div className='flex flex-row justify-center items-center w-full h-full'>
                        <p className='text-2xl font-satoshiBold text-BlueJay'>Loading...</p>
                    </div> :
                    <>
                        <div className='lg:mt-0 md:h-[270px] lg:h-[445px] bg-WhiteLilac mx-6 md:mx-0 p-4 lg:w-[40%] md:w-full rounded-lg text-center flex flex-col justify-center items-center lg:gap-10 md:gap-4'>
                            <p className='md:mb-2 lg:mb-0 md:block hidden md:text-xl lg:text-2xl text-base text-BlueJay font-satoshiMedium'>My Profile</p>
                            <div className='lg:mb-4'>
                                <div className='relative'>
                                    <img
                                        className='mb-2 lg:mb-4 mx-auto w-[110px] h-[110px] lg:w-[170px] lg:h-[170px] rounded-full border-[5px] border-Verdigris'
                                        src={showForm && params.image ? params.image : profileData?.image ? profileData?.image : '/images/avatar/DefaultProfilePic.png'}
                                        alt=""
                                    />
                                    {
                                        showForm &&
                                        <label htmlFor="file">
                                            <img
                                                className='cursor-pointer absolute bottom-4 right-4 lg:bottom-3 lg:right-3 border-[2px] border-Verdigris p-0 md:p-1 rounded-full bg-white'
                                                src='/images/icons/camera.svg'
                                                alt="Capture Camera"
                                                onClick={handleImage}
                                            />
                                            <input onChange={handleImage} type="file" id="file" style={{ display: 'none' }} name="image" accept="image/gif,image/jpeg,image/jpg,image/png" data-original-title="upload photos" />
                                        </label>
                                    }

                                </div>
                                <p className='font-satoshiBold text-sm text-BlueJay'> {profileData?.name || profileData?.phone || '--'}</p>
                                <p className='text-BlueJay font-satoshiMedium'>{profileData?.email || '--'}</p>
                            </div>
                        </div>
                        {
                            showForm ?
                                <div className='h-screen md:h-auto md:mt-4 lg:w-[60%] md:px-0 lg:px-4 px-6 flex flex-col justify-between gap-4 mt-4 lg:mt-2 w-full'>
                                    <div className='flex flex-col gap-4'>
                                        <Input
                                            rows={1}
                                            width='w-full'
                                            disabled={false}
                                            readOnly={false}
                                            handleChange={handleChange}
                                            value={params?.name}
                                            error={formErrors?.name}
                                            helperText={formErrors?.name}
                                            label='Your Name'
                                            name='name'
                                        />
                                        <Input
                                            rows={1}
                                            width='w-full'
                                            disabled={false}
                                            readOnly={false}
                                            handleChange={handleChange}
                                            value={params?.email}
                                            error={formErrors?.email}
                                            helperText={formErrors?.email}
                                            label='Email ID'
                                            name='email'
                                        />
                                        <CommonSelectInput
                                            readonly={false}
                                            width='w-full'
                                            handleChange={handleChange}
                                            options={["Male", "Female"]}
                                            label='Gender'
                                            name='gender'
                                            value={params?.gender}
                                            error={formErrors?.gender}
                                            helperText={formErrors?.gender}
                                        />
                                        <CommonDatepicker
                                            label='Date of Birth'
                                            value={params?.dob}
                                            onChange={onDateChange}
                                        />
                                    </div>
                                    <div className='mt-5 flex flex-row justify-end items-center gap-24 mb-2 md:mb-0'>
                                        <p
                                            className='cursor-pointer text-[#10A39B] border-b-[2px] border-[#10A39B] font-satoshiBold '
                                            onClick={clearForm}
                                        >
                                            Cancel
                                        </p>
                                        <button
                                            className='w-[150px] h-[47px] lg:w-[156px] lg:h-[56px] bg-[#10A39B] rounded-lg font-satoshiRegular text-white text-[14px] font-bold'
                                            onClick={handleUpdate}
                                            disabled={isLoading}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>

                                :

                                <div className='lg:mt-0 h-[445px] bg-WhiteLilac mx-6 md:mx-0 px-8 py-4 lg:w-[60%] md:w-full rounded-lg'>
                                    <div className='flex justify-end '>
                                        <span
                                            className='cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiBold'
                                            onClick={handleEditProfile}
                                        >
                                            Edit Profile
                                        </span>
                                    </div>
                                    <div className='flex flex-col gap-4'>
                                        <div>
                                            <p className='text-BlueJay font-satoshiBold'>Name:</p>
                                            <p className='text-BlueJay font-satoshiMedium'>{profileData?.name || '--'}</p>
                                        </div>
                                        <div>
                                            <p className='text-BlueJay font-satoshiBold'>Email ID:</p>
                                            <p className='text-BlueJay  font-satoshiMedium'>{profileData?.email || '--'}</p>
                                        </div>
                                        <div>
                                            <p className='text-BlueJay font-satoshiBold'>Phone Number:</p>
                                            <p className='text-BlueJay  font-satoshiMedium'>{profileData?.phone || '--'}</p>
                                        </div>
                                        <div>
                                            <p className='text-BlueJay font-satoshiBold'>Gender:</p>
                                            <p className='text-BlueJay  font-satoshiMedium'>{profileData?.gender || '--'}</p>
                                        </div>
                                        <div>
                                            <p className='text-BlueJay font-satoshiBold'>Date of Birth:</p>
                                            <p className='text-BlueJay  font-satoshiMedium'>
                                                {moment((profileData?.dob)).format('YYYY-MM-DD') !== 'Invalid date' && moment((profileData?.dob)).format('YYYY-MM-DD') || '--'}
                                            </p>
                                        </div>
                                        <div>
                                            <p className='text-BlueJay font-satoshiBold'>Age:</p>
                                            <p className='text-BlueJay font-satoshiMedium'>{`${age ? age + ' Years' : '--'}`}</p>
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
            }
        </div>
    )
}
export default Profile