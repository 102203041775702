import React from 'react'
import { useEffect, useState } from 'react'
import BreadCrumb from './common/BreadCrumb'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { makeStyles } from '@mui/styles'
import axiosInstance from '../helpers/axios'
import Pagination from './common/Pagination/Pagination'
import updateMeta from '../helpers/seo-meta'

const useStyles = makeStyles(() => ({
    root: {
        fontWeight: "700",
        backgroundColor: "#fcfcfc !important",
        padding: "10px",
        borderRadius: "12px !important",
        "&:before": {
            backgroundColor: "transparent",
        },
    },
    details: {
        backgroundColor: "#fcfcfc",
        borderRadius: "12px !important",
    },

    summary: {
        color: "#3C567E",
        backgroundColor: "#fcfcfc !important",
        borderRadius: "12px !important",
    },
    icon: {
        pointerEvents: "auto",
    },
}));

const Faq = () => {

    const classes = useStyles()
    const [faqList, setFaqList] = useState([] )
    const [metaData, setMetaData] = useState({} )
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        faqListing(currentPage)
    }, [currentPage])

    const faqListing = async (page) => {
        setIsLoading(true)
        axiosInstance
            .get(`/faq?page=${page}`)
            .then((res) => {
                setFaqList(res.data.data.data)
                setMetaData(res.data.data.meta)
              setIsLoading(false)
            })
            .catch((err) => {
              setIsLoading(false)
            })
    }

    const [expanded, setExpanded] = React.useState(false)

    const handleAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    useEffect(() => {
        document.title = 'Frequently Asked Questions | Zain Care - Delivering High Quality Healthcare';
        updateMeta('description', `Find answers to your queries about our smart, affordable healthcare solutions. Dive into our comprehensive guide and make informed decisions for a healthier future.`);
    }, []);

    return (
        <div className="">
            <div className="terms_and_cookie_bg">
                <div className="pt-[160px]">
                    <div className="w-[1440px] m-auto px-10">
                        <BreadCrumb
                            links={[
                                { path: "Home", url: "/" },
                                { path: "FAQ's", url: "/" },
                            ]}
                        />
                    </div>
                    <p className=" text-center text-blueShadow font-jostRegular font-[600] lg:text-[36px] md:text-[26px] text-[24px]">
                        Frequently Asked Questions
                    </p>
                </div>
            </div>

            {/* terms & conditions */}
            <div className={`${faqList.length < 1 ? 'h-[0px]' : 'h-fit'} relative lg:bottom-[200px] md:bottom-[120px] bottom-[120px] left-0 right-0  ml-auto mr-auto lg:w-[1247px] md:w-[704px] w-[334px] leading-2`}>
                <br />

                {faqList.length > 0 && (
                    <p className="text-[#3C567E] font-bold lg:text-[20px] md:text-base text-[14px] py-2 mb-4">
                        Need answers? Find them here..
                    </p>
                )}
                {
                    faqList.length > 0 ?
                        faqList.map((item, index) => (
                            <Accordion
                                className={classes.root}
                                sx={{
                                    "&:before": {
                                        display: "none",
                                    },
                                    border: "none",
                                    marginBottom: "18px",
                                }}
                                expanded={expanded === `panel${index + 1}`}
                                onChange={handleAccordion(`panel${index + 1}`)}
                                elevation={4}
                            >
                                <AccordionSummary
                                    expandIcon={<img src='/images/icons/accArrow.svg' alt='Accordion Down' />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className={classes.summary}
                                >
                                    <p className='text-[#3C567E] font-satoshiMedium lg:text-[20px] text-base'>
                                        {item?.query}
                                    </p>
                                </AccordionSummary>
                                <AccordionDetails className={classes.details}>
                                    <p className='ml-4 text-blueShadow font-satoshiLight lg:text-[20px] md:text-[18px] text-[14px] leading-relaxed'>
                                        {item?.solution}
                                    </p>
                                </AccordionDetails>
                            </Accordion>
                        )) :
                        <div className='flex justify-center items-center flex-col gap-4 mt-6'>
                            <p className='text-[#3C567E] text-[20px] font-satoshiBold'>
                                {isLoading ? 'Loading...' : 'No FAQs right now !!'}
                               </p>
                        </div>
                }

                {
                    faqList.length > 0 ?
                        <div className='w-full p-4 flex justify-center gap-10'>
                            <Pagination
                                className='pagination-bar'
                                currentPage={currentPage}
                                totalCount={metaData.total}
                                pageSize={10}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div> : ''
                }
            </div>
        </div>
    );
};

export default Faq;
