import React from "react";
import { useState } from 'react'
import { isMobile } from 'react-device-detect'

const Whatsapp = () => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("Hey I'm interested in Booking a Package")

    function handleChange(e) {
        const { value } = e.target
        setMessage(value)
    }

    function redirectURL() {
        let w = window
        w.location = `https://${
            isMobile ? 'api' : 'web'
        }.whatsapp.com/send/?phone=+971528866165&text=${message}`
    }

    return (
        <>
            <div className='fixed bottom-[63px] right-[20px] min-w-[64px] z-[99] flex flex-col justify-end items-end rounded-xl'>
                {open && (
                    <div className='shadow-2xl bg-white min-w-[300px] rounded-xl relative bottom-[10px]'>
                        <div className='bg-[#3fba38] py-2 text-center rounded-tl-xl rounded-tr-xl text-white'>
                            Whatsapp Chat
                        </div>
                        <textarea
                            name='message'
                            value={message}
                            onChange={(e) => handleChange(e)}
                            style={{ minHeight: '125px' }}
                            className='w-full outline-none p-2'
                        />
                        <div
                            onClick={(e) => redirectURL()}
                            className='bg-[#3fba38] py-2 text-center rounded-bl-xl cursor-pointer rounded-br-xl text-white'
                        >
                            Send via Whatsapp
                        </div>
                    </div>
                )}
                <div
                    onClick={() => setOpen(!open)}
                    className='bg-[#3fba38] p-4 rounded-full w-16 h-16 cursor-pointer flex flex-row justify-center items-center'
                >
                    {!open && (
                        <img
                            src='/images/images/whatsapp.svg'
                            alt='WhatsApp Logo'
                            className='w-8 invert'
                        />
                    )}
                    {open && (
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-7 w-7 inline-block text-white'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M6 18L18 6M6 6l12 12'
                            />
                        </svg>
                    )}
                </div>
            </div>
        </>
    )
}

export default Whatsapp
