import React from 'react'

function Title({ title }) {
    return (
        <p className='font-satoshiBold text-blueShadow p-4 bg-[#EBEEF2] lg:w-[568px] md:w-[704px] w-[344px] lg:text-[18px] text-[16px] text-center rounded-t-lg m-auto'>
            {title}
        </p>
    )
}

export default Title
