import React, { useState } from 'react'
import { PhoneInput } from '../../common/PhoneInput'
import Validator from 'validatorjs'
import { validateNumber } from '../../../helpers/helpers'
import axiosInstance from '../../../helpers/axios'
import useGtag from '../../../helpers/gtag';

const GetACall = ({ title }) => {
    const gtag = useGtag();
    const fields = {
        phone: ''
    }
    const rules = {
        phone: 'required'
    }

    const [params, setParams] = useState(fields) 
    const [errors, setErrors] = useState(fields) 

    const handleChange = (e) => {
        setisDisable(false)

        if (e.target) {
            let { name, value } = e.target
            updateParams([{ name, value }])
        }
        if (e.url) {
            updateParams([{ name: e?.name, value: e?.url }])
        }

        setErrors({})
    }

    const updateParams = (records) => {
        const newParams = JSON.parse(JSON.stringify(params))
        Object.keys(records).forEach((key) => (newParams[records[key].name] = records[key].value))
        setParams(newParams)
    }

    const validate = (parameters, rule) => {
        const validator = new Validator(parameters, rule)

        if (validator.fails()) {
            const fieldErrors = {}

            /* eslint-disable */
            for (const key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0]
            }
            /* eslint-enable */

            setErrors(fieldErrors)
            return false
        }
        setErrors({})
        return true
    }

    const [isDisable, setisDisable] = useState(false)

    const handleSubmit = () => {
        setisDisable(true)

        if (params.phone && !validateNumber(params.phone)) {
            setErrors({ ...errors, phone: 'Invalid Phone Number' })
            return
        }
        if (!validate(params, rules)) {
            return
        }

        if (!validate(params, rules)) {
            const err = Object.keys(errors)
            if (err?.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }

            return
        }
        getACall()
    }

    const [ack, setAck] = useState('')

    const getACall = () => {
        axiosInstance
            .post("/call_request", { phone: params.phone })
            .then((response) => {
                gtag('event', 'conversion', {
                    send_to: 'AW-11437476569/IhseCPL9h4gZENmV6M0q',
                });
                setAck('Request Submitted Successfully. Our Executive will be calling you shortly.')
                setTimeout(() => {
                    setAck('')
                    setParams({ phone: ' ' })
                }, 3000)
                setisDisable(false)

            })
            .catch((error) => {
            });
    };



    return (
        <div className='flex flex-col justify-center items-center w-full mt-5'>

            <p className="lg:w-full md:w-[603px] w-[326px] m-auto lg:mt-[90px] md:mt-[50px] mt-[81px] text-center text-blueShadow font-satoshiBold lg:text-[24px] text-[16px] px-5">
                {title ?? 'Talk to us to find the Most suitable Package'}{" "}
            </p>
            <br />
            <div className='flex items-start'>
                <PhoneInput rows={1}
                    width="lg:w-[573px] md:w-[473px] w-[226px]"
                    disabled={false}
                    readOnly={false}
                    handleChange={handleChange}
                    value={params?.phone}
                    error={errors?.phone}
                    helperText={errors?.phone}
                    label="Enter your mobile number"
                    bgcolor={"transparent"}
                    name="phone" />

                <button
                    disabled={isDisable}
                    onClick={handleSubmit}
                    className={`lg:w-[156px] md:w-[141px] w-[115px] relative right-[10px] h-[56px]  ${isDisable ? 'bg-[#dfdfdf]' : 'bg-[#10A39B]'} rounded-lg font-satoshiRegular text-white text-[14px] font-bold`}
                >
                    Get a Call
                </button>
            </div>

            <p className='text-center text-base text-blueShadow font-satoshiMedium mt-[16px]'>{ack}</p>
        </div>
    )
}

export default GetACall