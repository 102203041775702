import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useSelector } from 'react-redux'
import CustomButton from '../../../common/Button'
import React, { useEffect, useState } from 'react'
import AddAddress from '../ManageAddress/AddAddress'
import AddressRadioSelect from './AddressRadioSelect'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}

const ChangeAddress = ({
    open,
    handleClose,
    addressList,
    handleRadio,
    handleChangeAddress,
    handleNewAddress,
    params,
    handleChange,
    formErrors2,
}) => {
    const { isLoading ,isCreateSuccess} = useSelector((state) => state.address)

    const [newAddressOpen, setNewAddressOpen] = useState(false)

    const handleNewAddressOpen = () => setNewAddressOpen(true)

    const handleNewAddressClose = () => setNewAddressOpen(false)

    useEffect(()=>{
     if(isCreateSuccess){
        setNewAddressOpen(false)
     }
    
    },[isCreateSuccess])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className='flex justify-end mt-4 mr-4'>
                    <span
                        className='cursor-pointer'
                        onClick={handleClose}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.17004 14.8299L14.83 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.83 14.8299L9.17004 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
                <div className='flex flex-col justify-center items-center rounded-lg'>
                    <p className='font-satoshiBold text-BlueJay text-2xl mb-2'>Change Address</p>
                    <div className='max-h-[300px] overflow-auto m-5'>
                        <div className='max-w-[365px] flex flex-col gap-3 justify-center items-center w-[280px] md:w-[460px] md:mx-[20px] mx-[10px]'>
                            {addressList.map((item, index) => (
                                <AddressRadioSelect
                                    handleRadio={() => handleRadio(item)}
                                    item={item}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-row justify-center items-center gap-[8px] border-t border-b w-full py-4'>
                        <p className='text-blueShadow text-[14px]'>Not in the list ?</p>

                        <p
                            onClick={handleNewAddressOpen}
                            className='text-Verdigris font-bold text-[16px] underline cursor-pointer'
                        >
                            +Add New Address
                        </p>

                        {
                            newAddressOpen &&
                            <AddAddress
                                open={newAddressOpen}
                                handleClose={handleNewAddressClose}
                                handleSubmit={handleNewAddress}
                                handleChange={handleChange}
                                formErrors={formErrors2}
                                params={params}
                            />
                        }
                    </div>
                    <div className='py-5 flex flex-row-reverse justify-end ml-auto lg:ml-0 mr-5 md:mr-10 lg:mr-0 items-center lg:flex-col lg:justify-center gap-10 md:gap-20 lg:gap-4'>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            size='large'
                            width='lg:w-[200px] md:w-[180px] w-[150px]'
                            onClick={handleChangeAddress}
                            disabled={isLoading}
                        >
                            Submit
                        </CustomButton>
                        <p
                            className='w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiMedium'
                            onClick={handleClose}
                        >
                            Cancel
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default ChangeAddress