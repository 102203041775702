/* eslint-disable react/require-default-props */
import React from 'react'
import Button from '@mui/material/Button'

const styles = {
  border: '1px solid #10A39B',
  color: '#fff',
  '&:hover': {
    border: '1px solid #10A39B',
    color: '#fff',
  },
}

const CustomButton= ({
  disabled,
  children,
  onClick,
  variant = 'outlined',
  width,
  size,
  icon,
  borderRadius,
  type = 'button',
  isdeletebtn,
  height,
}) => {
  const styles1 = {
    border: '1px solid #10A39B',
    backgroundColor: '#10A39B',
    color: '#fff',
    '&:hover': {
      border: '1px solid #10A39B',
      backgroundColor: '#10A39B',
      color: '#fff',
    },
  }

  const disabledStyles = {
    '&:disabled': {
      fontWeight: '700',
      border: 'none ',
      color: '#797979',
      backgroundColor: '#D9D9D9',
    },
  }

  const secondaryOutlined = {
    border: '1px solid #3C567E',
    backgroundColor: 'transparent',
    color: '#3C567E',
    '&:hover': {
      border: '1px solid #3C567E',
      backgroundColor: 'transparent',
      color: '#3C567E',
    },
  }

  const secondaryContained = {
    border: '1px solid #3C567E',
    backgroundColor: '#3C567E',
    color: '#fff',

    '&:hover': {
      border: '1px solid #3C567E',
      backgroundColor: '#3C567E',
      color: '#fff',
    },
  }

  return (
    <div className={width}>
      <Button
        style={{
          height: height,
          borderRadius,
          textTransform: 'none',
          paddingTop: '0.8rem',
          paddingBottom: '0.8rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
        fullWidth
        size={size}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        startIcon={icon}
        className='font-satoshiRegular'
        type={type}
        sx={
          variant === 'secondary-outlined' && !disabled
            ? secondaryOutlined
            : variant === 'outlined' && !disabled
            ? styles
            : variant === 'contained' && !disabled
            ? styles1
            : variant === 'secondary-contained' && !disabled
            ? secondaryContained
            : disabled && variant === 'contained'
            ? {
                '&:disabled': {
                  fontWeight: '700',
                  border: 'none',
                  color: '#6A6A78 !important',
                },
              }
            : disabled
            ? disabledStyles
            : ''
        }
      >
        <p className='w-full font-bold font-satoshiRegular text-sm'>{children}</p>
      </Button>
    </div>
  )
}

CustomButton.defaultProps = {
  disabled: false,
  children: null,
  variant: '',
  width: '',
  size: '',
  icon: '',
  borderRadius: '',
  onClick: function test() {},
}
export default CustomButton
