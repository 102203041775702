import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import { uuid } from "../../helpers/helpers";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SelectInputCustom= ({
    handleChange,
    value,
    label,
    error,
    helperText,
    options,
    width,
    name,
    required,
    readonly,
    disabled,
    isSample
}) => {

    const useStyles = makeStyles({
        error: {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "red",
                borderRadius: "8px",
            },
        },
        select: {
            "& ul": {
                backgroundColor: "#fcfcfc",
            },
            "& li": {
                backgroundColor: "#fcfcfc",
            },
        },
        icon: {
            fill: "white",
        },
        root: {
            "& .MuiOutlinedInput-input": {
                color: "#3C567E",
            },
            "& .MuiInputLabel-root": {
                color: isSample ? " #3C567E" : ""
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: isSample ? "#3C567E" : "#E7E8ED",
                borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-input": {
                color: "#3C567E",
            },
            "&:hover .MuiInputLabel-root": {
                color: "#3C567E",
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3C567E",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#33B0AA",
            },
            "& .MuiInputLabel-root.Mui-focused": {
                color: "#33B0AA",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
                borderColor: "#33B0AA",
            },
        },
    });

    const classes = useStyles();
    return (
        <div className={`${width} py-3`}>
            <FormControl
                disabled={disabled}
                className={!error ? classes.root : classes.error}
                fullWidth
                error={error}
            >
                <div className="relative ">
                    <InputLabel id="select-input-label">{label}</InputLabel>
                </div>
                <Select
                    defaultValue=""
                    labelId="select-input-label"
                    style={{
                        width,
                        // height: "47px",
                        backgroundColor: "white",
                        borderRadius: "8px",
                    }}
                    size={'medium'}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                        // disableScrollLock: true,
                        sx: {
                            "&& .MuiMenuItem-root": {
                                backgroundColor: "#fcfcfc",
                                border: "1px solid #E7E8ED !important",
                                color: "#3C567E",
                                "&:hover": {
                                    backgroundColor: "#fcfcfc !important",
                                },
                            },
                            "&& .MuiMenu-list": {
                                padding: "0",
                            },

                            "&& .Mui-selected": {
                                color: "#909EB4 !important",
                                backgroundColor: "#fcfcfc",
                            },
                        },
                    }}
                    sx={{
                        color: "#3C567E",
                        ".MuiSvgIcon-root ": {
                            fill: "#3C567E !important",
                        },
                    }}
                    required={required}
                    value={value}
                    onChange={handleChange}
                    label={label}
                    name={name}
                    error={error}
                    fullWidth
                    readOnly={readonly}
                >
                    {options.length > 0 ? (
                        options?.map((item) => (
                            <MenuItem key={uuid()} value={item}>
                                {item}
                            </MenuItem>
                        ))
                    ) : (
                        <p className="text-white p-4 text-xl">Not found !</p>
                    )}
                </Select>

                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        </div>
    );
};
