import moment from 'moment'
import Validator from 'validatorjs'
import Reschedule from './Reschedule'
import CommonPopup from '../CommonPopup'
import { useParams } from 'react-router'
import SupportPopup from './SupportPopup'
import ChangeAddress from './ChangeAddress'
import ShareFeedback from './ShareFeedback'
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../common/BreadCrumb'
import updateMeta from '../../../../helpers/seo-meta'
import axiosInstance from '../../../../helpers/axios'
import { useDispatch, useSelector } from 'react-redux'
import { validateNumber } from '../../../../helpers/helpers'
import { addAddress } from '../../../../features/userProfile/addressSlice'
import { getSupport, rescheduleTiming, updateAddress } from '../../../../features/userProfile/bookingsSlice'

const initialValue = {
    location_type: '',
    building_name: '',
    house_no: '',
    locality: '',
    city: '',
    name: '',
    email: '',
    phone: '',
    code: '+971',
    message: ''
}

const BookingDetails = () => {

    const [open, setOpen] = useState(false)
    const { id } = useParams()
    const dispatch = useDispatch()
    const { isCreateSuccess } = useSelector((state) => state.address)
    const [params, setParams] = useState(initialValue)
    const [openReschedule, setOpenReschedule] = useState(false)
    const [resSuccessOpen, setResSuccessOpen] = useState(false)
    const [updateAddSuccess, setUpdateAddSuccess] = useState(false)
    const [dateTime, setDateTime] = useState('');
    const [cancelReq, setcancelReq] = useState(false)
    const [cancelReqSuccess, setcancelReqSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [allDetails, setAllDetails] = useState({} )
    const [payloadData, setPayloadData] = useState({} )
    const [addressList, setAddressList] = useState([] )
    const [formErrors, setFormErrors] = useState({ dateTime }) 
    const [formErrors2, setFormErrors2] = useState(initialValue) 
    const [openFeedback, setOpenFeedback] = useState(false)
    const [openSupport, setOpenSupport] = useState(false)
    const [disableChanges, setDisableChanges] = useState(false);

    useEffect(() => {
        bookingDetails()
        allAddress()
    }, [])

    useEffect(() =>{
        if(isCreateSuccess){
            allAddress()
        }
    },[isCreateSuccess])

    const bookingDetails = async () => {
        setIsLoading(true)
        axiosInstance
            .get(`/customer-bookings/${id}`)
            .then((res) => {
                setAllDetails(res.data.data)
                // setCustomDate(res.data.data.samp_col_date_time)
                setIsLoading(false)

                const currentDate = moment();
                const sampleDate = moment(res.data.data.samp_col_date_time);
                if(sampleDate.diff(currentDate, 'days') <=1 || res.data.data.is_cancelled || res.data.data.samp_col_start_time) {
                    setDisableChanges(true)
                } 
            })
            .catch((err) => {
                setIsLoading(false)
            })
    }

    const allAddress = async () => {
        axiosInstance
            .get(`/customer-address`)
            .then((res) => {
                let data = res.data.data
                data.map((i) => {
                    if (i['checked']) {
                        let index = i.findIndex((item) => {
                            return item === i
                        })
                        setPayloadData(i[index])
                    }
                    else {
                        i['checked'] = false
                        setPayloadData({})
                    }
                })
                setAddressList(data)
            })
            .catch((err) => {
            })
    }

    const handleRadio = (data) => {
        let x = [...addressList]
        let index = x.findIndex((item) => {
            return item === data
        })
        if (index != -1) {
            x.map((item, indx) => {
                if (index === indx) {
                    item['checked'] = true
                }
                else {
                    item['checked'] = false
                }
            })
        }
        setAddressList(x)
        setPayloadData(addressList[index])
    }

    const handleChangeAddress = () => {
        const validation = new Validator(payloadData, {})
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            setFormErrors(fieldErrors)
            return false
        }

        dispatch(updateAddress(id, payloadData))
        setFormErrors({})
        handleClose()
        changeAddSuccessOpen()
        return true
    }

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target
            setParams({ ...params, [name]: value })
        }
        setFormErrors({})
        setFormErrors2({})
    }

    const handleNewAddress = () => {
        let payload = {
            location_type: params.location_type,
            building_name: params.building_name,
            house_no: params.house_no,
            locality: params.locality,
            city: params.city,
        }
        const rules = {
            location_type: 'required|string|max:50',
            building_name: 'required|string|max:50',
            house_no: 'required|string|max:50',
            locality: 'required|string|max:50',
            city: 'required|string|max:50',
        }

        
        const validation = new Validator(payload, rules)
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            setFormErrors2(fieldErrors)
            return false
        }

        dispatch(addAddress(payload))
        setFormErrors2({})
        return true
    }

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleResheduleOpen = () => setOpenReschedule(true)
    const handleResheduleClose = () => setOpenReschedule(false)

    const resheduleSuccessOpen = () => setResSuccessOpen(true)
    const resheduleSuccessClose = () => {
        setResSuccessOpen(false)
        bookingDetails()
    }

    const handleSupportOpen = () => setOpenSupport(true)
    const handleSupportClose = () => {
        setOpenSupport(false)
    }

    const changeAddSuccessOpen = () => setUpdateAddSuccess(true)
    const changeAddSuccessClose = () => {
        setUpdateAddSuccess(false)
        bookingDetails()
    }

    const handleCancelReqOpen = () => setcancelReq(true)
    const handleCancelReqClose = () => setcancelReq(false)
    

    const openRescheduleModel = () => {
        rescheduleDateTime(allDetails.samp_col_date_time)
        handleResheduleOpen()
    }
    const reqForCancellation = async () => {
        axiosInstance
            .get(`/customer-bookings/cancel/${id}`)
            .then((res) => {
                setcancelReqSuccess(true)
                handleCancelReqClose()
            })
            .catch((err) => {
                handleCancelReqClose()
            })
    }

    const rescheduleDateTime = (newValue) => {
        let x = moment(new Date(newValue)).format('YYYY-MM-DD HH:mm:ss')
        setDateTime(x)
    }


    const validate = (parameters, rule) => {
        const validator = new Validator(parameters, rule)

        if (validator.fails()) {
            const fieldErrors = {}

            /* eslint-disable */
            for (const key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0]
            }
            /* eslint-enable */

            setFormErrors2(fieldErrors)

            return false
        }
        setFormErrors2({})
        return true
    }

    const submitReschedule = () => {
        let payload = {
            date_time: `${dateTime}`
        }
        const rules = {
            // dateTime: 'required',
        }
        const validation = new Validator(payload, rules)
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            setFormErrors(fieldErrors)
            return false
        }
        dispatch(rescheduleTiming(id, payload))
        setFormErrors({})
        handleResheduleClose()
        resheduleSuccessOpen()
        return true
    }

    const submitSupport = () => {
        let payload = {
            name: params.name,
            email: params.email,
            phone: params.phone,
            message: params.message,
        }
        const rules = {
            name: 'required|string|max:30',
            email: 'required|email|max:50',
            phone: "required",
            message: 'required',
        }
        const validation = new Validator(payload, rules)

        if (params.phone && !validateNumber(params.phone)) {
            setFormErrors2({ ...formErrors2, phone: 'Invalid Phone Number' })
            return
          }
          if (!validate(params, rules)) {
            return
          }
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            setFormErrors2(fieldErrors)
            return false
        }
        dispatch(getSupport(payload))
        setFormErrors2({})
        handleSupportClose()
        setParams(initialValue)
        return true
    }

    const submitFeedBack = () => {
        let payload = {
            name: params.name,
            email: params.email,
            phone: params.phone,
            message: params.message,
        }
        const rules = {
            name: 'required|string|max:30',
            email: 'required|email|max:50',
            phone: "required|numeric|digits:10",
            message: 'required',
        }
        const validation = new Validator(payload, rules)
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            setFormErrors2(fieldErrors)
            return false
        }
        // dispatch(getSupport(payload))
        setFormErrors2({})
        handleSupportClose()
        return true
    }

    useEffect(() => {
        document.title = 'Zain care | High-Quality, Affordable Healthcare at your Home';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);

    return isLoading ? (
        <div className="flex flex-row justify-center items-center w-full h-screen">
            <p className="text-2xl font-satoshiBold text-BlueJay">Loading...</p>
        </div>
    ) : (
        <>
            <div className="lg:px-16 md:px-8 md:pt-36 px-4 pb-6 flex flex-col justify-center items-center gap-4">
                <div className="px-10 lg:block hidden mr-auto">
                    <BreadCrumb
                        links={[
                            { path: "Home", url: "/" },
                            {
                                path: "My Account",
                                url: "/user-profile",
                            },
                            {
                                path: "Booking Details",
                                url: "#",
                            },
                        ]}
                    />
                </div>
                <div className="flex justify-center mb-2">
                    <p className="font-jostSemibold text-BlueJay md:text-3xl text-xl">
                        Booking Details
                    </p>
                </div>

                {/* Booking Summary */}

                <div className="bg-with-shadow lg:w-[70%] w-full">
                    <p className="p-2 text-Verdigris font-satoshiBold text-center border-b-[2px] border-[#3c567e33]">
                        Booking Summary
                    </p>
                    <div className="m-6 ">
                        <div className="p-4 bg-WhiteLilac rounded-lg flex flex-row justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <p className="text-secondaryLight text-xs font-satoshiMedium">
                                    Booking Id
                                </p>
                                <p className="text-sm font-satoshiBold text-BlueJay">
                                    {allDetails?.id}
                                </p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-secondaryLight text-xs font-satoshiMedium">
                                    Total Amount
                                </p>
                                <p className="text-sm font-satoshiBold text-BlueJay">
                                    {allDetails?.total}
                                </p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-secondaryLight text-xs font-satoshiMedium">
                                    Payment Status
                                </p>
                                <p className="text-sm font-satoshiBold text-BlueJay">
                                    {allDetails?.payment_status}
                                </p>
                            </div>
                            <div className="flex flex-col gap-1">
                                <p className="text-secondaryLight text-xs font-satoshiMedium">
                                    Booking Status
                                </p>
                                <p className="text-sm font-satoshiBold text-Verdigris">
                                    {allDetails?.status}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Booking Information */}

                <div className="bg-with-shadow lg:w-[70%] w-full">
                    <p className="p-2 text-Verdigris font-satoshiBold text-center border-b-[2px] border-[#3c567e33]">
                        Booking Information
                    </p>
                    <div className="m-4">
                        <div className="p-4 bg-WhiteLilac rounded-lg flex flex-col items-center justify-center gap-4">
                            {allDetails?.tests?.map((item, index) => {
                                return (
                                    <div className="bg-white p-4 rounded-lg w-full">
                                        <div className="flex gap-2 items-center">
                                            <p className="text-lg font-satoshiBold text-BlueJay">
                                                {item?.name}
                                            </p>
                                            <div className="bg-BlueJay h-[24px] w-[1px]"></div>
                                            <span className="text-sm text-BlueJay">
                                                {item?.relation}
                                            </span>
                                        </div>

                                        <p className="text-secondaryLight text-xs font-satoshiMedium mt-1">
                                            {item?.gender}, {item?.age} years
                                        </p>

                                        <div className="border-b-[2px] border-[#3c567e33] mt-4"></div>

                                        <div className="p-4 bg-WhiteLilac rounded-lg mt-4">
                                            <p className="font-satoshiMedium text-BlueJay text-sm">
                                                {index + 1}.{" "}
                                                {item?.selected_test?.name}
                                            </p>
                                           
                                            <span className="text-Verdigris text-sm font-satoshiBold">
                                                AED {item?.price}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Sample Collection Information */}

                <div className="bg-with-shadow lg:w-[70%] w-full">
                    <p className="p-2 text-Verdigris font-satoshiBold text-center border-b-[2px] border-[#3c567e33]">
                        Sample Collection Information
                    </p>
                    <div className="m-4">
                        <div className="p-4 bg-WhiteLilac rounded-lg">
                            <div className="flex flex-row justify-between border-b-[2px] border-[#3c567e33] pb-4">
                                <div className="flex items-center gap-2">
                                    <svg
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.00001 9.45297C9.14877 9.45297 10.08 8.52172 10.08 7.37297C10.08 6.22422 9.14877 5.29297 8.00001 5.29297C6.85126 5.29297 5.92001 6.22422 5.92001 7.37297C5.92001 8.52172 6.85126 9.45297 8.00001 9.45297Z"
                                            stroke="#33B0AA"
                                            strokeWidth="1.5"
                                        />
                                        <path
                                            d="M2.41333 6.1587C3.72667 0.385366 12.28 0.392033 13.5867 6.16537C14.3533 9.55203 12.2467 12.4187 10.4 14.192C9.06 15.4854 6.94 15.4854 5.59333 14.192C3.75333 12.4187 1.64667 9.54537 2.41333 6.1587Z"
                                            stroke="#33B0AA"
                                            strokeWidth="1.5"
                                        />
                                    </svg>
                                    <p className="text-sm font-satoshiBold text-BlueJay">
                                        Location
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    {!disableChanges ? (
                                        <p
                                            className="cursor-pointer text-BlueJay text-sm border-b-[2px] border-BlueJay font-satoshiMedium"
                                            onClick={handleOpen}
                                        >
                                            Change Address
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {open && (
                                        <ChangeAddress
                                            open={open}
                                            handleClose={handleClose}
                                            params={params}
                                            addressList={addressList}
                                            handleRadio={handleRadio}
                                            handleChangeAddress={
                                                handleChangeAddress
                                            }
                                            handleNewAddress={handleNewAddress}
                                            handleChange={handleChange}
                                            formErrors={formErrors}
                                            formErrors2={formErrors2}
                                            isCreateSuccess={isCreateSuccess}
                                        />
                                    )}
                                    {updateAddSuccess && (
                                        <CommonPopup
                                            open={updateAddSuccess}
                                            handleClose={changeAddSuccessClose}
                                            title={"Booking Address is "}
                                            title2={"Updated!"}
                                            btnText={"Okay"}
                                            submit={changeAddSuccessClose}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="mt-4">
                                <p className="text-lg font-satoshiBold text-BlueJay mb-1">
                                    {allDetails?.location_type || "--"}
                                </p>
                                <p className="text-sm text-secondaryLight font-satoshiMedium">
                                    {allDetails?.house_no},{" "}
                                    {allDetails?.building_name},{" "}
                                    {allDetails?.locality}, {allDetails?.city}
                                </p>
                            </div>
                        </div>

                        <div className="p-4 bg-WhiteLilac rounded-lg mt-4">
                            <div className="flex flex-row justify-between border-b-[2px] border-[#3c567e33] pb-4">
                                <div className="flex items-center gap-2">
                                    <img src='/images/icons/calendarGreen.svg' alt="" />
                                    <p className="text-sm font-satoshiBold text-BlueJay">
                                        Sample Collection time
                                    </p>
                                </div>
                                <div>
                                    {!disableChanges ? (
                                        <p
                                            className="cursor-pointer text-BlueJay text-sm border-b-[2px] border-BlueJay font-satoshiMedium"
                                            onClick={openRescheduleModel}
                                        >
                                            Reshedule
                                        </p>    
                                    ) : (
                                        <></>
                                    )}

                                    {openReschedule && (
                                        <Reschedule
                                            open={openReschedule}
                                            handleClose={handleResheduleClose}
                                            dateTime={dateTime}
                                            rescheduleDateTime={
                                                rescheduleDateTime
                                            }
                                            submit={submitReschedule}
                                            formErrors={formErrors}
                                        />
                                    )}
                                    {resSuccessOpen && (
                                        <CommonPopup
                                            open={resSuccessOpen}
                                            handleClose={resheduleSuccessClose}
                                            title={
                                                "Sample collection Date & Time is "
                                            }
                                            title2={"Rescheduled!"}
                                            btnText={"Okay"}
                                            submit={resheduleSuccessClose}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="mt-4 flex items-center justify-start gap-2">
                                <p className="text-lg font-satoshiBold text-BlueJay">
                                    {moment(
                                        allDetails?.samp_col_date_time
                                    ).format("YYYY-MM-DD")}{" "}
                                </p>
                                <p className="w-[1px] h-[24px] bg-BlueJay"></p>
                                <p className="text-sm text-secondaryLight">
                                    {moment(
                                        allDetails?.samp_col_date_time
                                    ).format("LT")}
                                </p>
                            </div>
                        </div>

                        <div className="mt-4 w-full text-center">
                            {!allDetails.is_cancelled && !disableChanges ? (
                                <button
                                    className="px-6 h-[40px] bg-[#EBEEF2] rounded-lg font-satoshiRegular text-[#EF4949] text-[14px] font-bold"
                                    onClick={handleCancelReqOpen}
                                >
                                    Request for Cancellation
                                </button>
                            ) : (
                                <></>
                            )}

                            {cancelReq && (
                                <CommonPopup
                                    open={cancelReq}
                                    handleClose={handleCancelReqClose}
                                    title={
                                        "Are you sure want to Request for Cancellation?"
                                    }
                                    btnText={"Yes, Continue"}
                                    underlineText={"No"}
                                    submit={reqForCancellation}
                                />
                            )}
                            {cancelReqSuccess && (
                                <CommonPopup
                                    open={cancelReqSuccess}
                                    handleClose={() =>
                                        setcancelReqSuccess(false)
                                    }
                                    title={
                                        "Your Request for Cancellation is Received."
                                    }
                                    title2={"Our Executive will call you soon."}
                                    btnText={"Okay"}
                                    submit={() => setcancelReqSuccess(false)}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {/* Billing Information */}

                <div className="bg-with-shadow lg:w-[70%] w-full">
                    <p className="p-2 text-Verdigris font-satoshiBold text-center border-b-[2px] border-[#3c567e33]">
                        Billing Information
                    </p>
                    <div className="m-4">
                        <div className="p-4 bg-WhiteLilac rounded-lg flex flex-col gap-2">
                            <div className="flex flex-row items-center justify-between">
                                <p className="text-sm text-secondaryLight">
                                    Price
                                </p>
                                <p className="text-sm font-satoshiMedium text-secondaryLight">
                                    AED {allDetails?.subtotal || "--"}
                                </p>
                            </div>
                            <div className="flex flex-row items-center justify-between">
                                <p className="text-sm text-secondaryLight">
                                Sample Collection Charges
                                </p>
                                <p
                                    className={`text-sm font-satoshiMedium text-secondaryLight`}
                                >
                                    {allDetails?.sample_coll_charge ? 'AED ' +allDetails?.sample_coll_charge : "FREE"}
                                </p>
                            </div>

                            <div className="flex flex-row items-center justify-between">
                                <p className="text-sm text-secondaryLight">
                                    Discount Applied
                                </p>
                                <p
                                    className={`text-sm font-satoshiMedium text-secondaryLight`}
                                >
                                    {allDetails?.promotion ? '- AED ' +allDetails?.promotion : "--"}
                                </p>
                            </div>
                            <div className="border-b-[2px] border-[#3c567e33] mt-2"></div>
                            <div className="flex flex-row items-center justify-between">
                                <p className="text-sm text-BlueJay font-satoshiMedium">
                                    Total Price
                                </p>
                                <p className="text-base text-BlueJay font-satoshiBold">
                                    AED {allDetails?.total || "--"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-10 mb-8">
                    <p
                        className="cursor-pointer text-2xl  text-BlueJay border-b-[2px] border-BlueJay font-satoshiBold"
                        onClick={() => setOpenFeedback(true)}
                    >
                        Share Your Feedback
                    </p>
                    {openFeedback && (
                        <ShareFeedback
                            open={openFeedback}
                            handleClose={() => setOpenFeedback(false)}
                            handleChange={handleChange}
                            handleSubmit={submitFeedBack}
                            formErrors={formErrors2}
                            params={params}
                        />
                    )}
                </div>

                <div className="lg:w-[70%] w-full border-[2px] border-[#33B0AA] rounded-lg p-6 mt-4 mb-20">
                    <p className="text-sm text-BlueJay font-satoshiBold mb-2">
                        Need Help
                    </p>
                    <p className="text-sm text-secondaryLight">
                        <span className="font-satoshiMedium">
                            Lorem ipsum dolor sit amet consectetur. Ut sed
                            turpis enim dictumst placerat consectetur quisque
                        </span>
                        <span
                            className="cursor-pointer text-Verdigris text-sm border-b-[2px] border-Verdigris font-satoshiBold ml-1"
                            onClick={handleSupportOpen}
                        >
                            Get Support.
                        </span>
                    </p>
                </div>
            </div>
            {openSupport && (
                <SupportPopup
                    open={openSupport}
                    handleClose={handleSupportClose}
                    handleChange={handleChange}
                    handleSubmit={submitSupport}
                    formErrors={formErrors2}
                    params={params}
                />
            )}
        </>
    );
}
export default BookingDetails