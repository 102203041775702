import Packages from "./Packages";
import Backdrop from "@mui/material/Backdrop";
import { Box, Modal } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Validator from "validatorjs";
import axiosInstance from "../helpers/axios";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "./common/Button";
import { Input } from "./common/Input";
import SearchActiveModal from "./SearchActiveModal";
const { fields, rules } = require("./fields");
import FileUpload from "./common/upload/FileUpload";
import TextArea from "./common/TextArea";
import ServiceModal from "./ServiceModal";
import { PhoneInput } from "./common/PhoneInput";
import LifeSavingSteps from "./LifeSavingSteps/LifeSavingSteps";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../features/userProfile/profileSlice";
import { isUserLoggedIn } from "../helpers/auth";
import HeroAnimation from "./Pages/HeroAnimation";
import ServicesCarousel from "./Popular_Tests/ServicesCarousel";
import updateMeta from "../helpers/seo-meta";
import useGtag from "../helpers/gtag";
import LifeSavingsCarousel from "../LifeSavingsCarousel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fcfcfc",
};

const addToCartStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 656,
  bgcolor: "background.paper",
  p: 4,
};

const Home = () => {
  const gtag = useGtag();
  // filter states & handle functions
  let width = typeof window !== "undefined" ? window.innderWidth : "";

  const { profileData } = useSelector((state) => state.profile);

  const navigate = useNavigate();

  const [contactForm, setContactForm] = useState(false);

  const handleContactFormOpen = () => {
    setContactForm(true);
  };
  const handleContactFormClose = () => {
    setContactForm(false);
  };

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleVideo = () => {
    if (isPlaying) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
      setIsPlaying(false);
    } else setIsPlaying(true);
  };

  const filterInitialStates = {
    search_key: "",
    age: "",
    gender: "",
    symptoms: [],
  };

  const [applyFilter, setApplyFilter] = useState(false);

  const [filterParams, setFilterParams] = useState(filterInitialStates);

  const resetFilter = () => {
    setApplyFilter(false);
    setFilterParams({
      ...filterInitialStates,
    });
  };

  const handleFilterApply = () => {
    setApplyFilter((prevState) => !prevState);
  };

  const updateFilterParams = (records) => {
    const newParams = JSON.parse(JSON.stringify(filterParams));

    Object.keys(records).forEach(
      (key) => (newParams[records[key].name] = records[key].value)
    );

    setFilterParams(newParams);
  };

  const handleFilterChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      if (name === "price" || name === "markup_price") {
        const re = /^\d*\.?\d*$/;
        if ((value && !re.test(value)) || value === "0") {
          return;
        }
      }

      if (e.target.name === "name") {
        let res = /^[a-zA-Z0-9 ]*$/g;

        if (!res.test(e.target.value)) {
          return;
        }
      }

      if (name === "param_cnt") {
        const re = /^[0-9\b]+$/;
        if ((value && !re.test(value)) || value === "0") {
          return;
        }
      }

      if (name?.includes("is")) {
        updateFilterParams([{ name: e.target.name, value: e.target.checked }]);
      } else {
        updateFilterParams([{ name: name, value: value }]);
      }
    }

    // setErrors({})
  };

  const [searchPackageList, setSearchPackagesList] = useState([]);
  const [testsList, setTestsList] = useState([]);

  const [totalPackages, setTotalPackages] = useState(0);
  const [totalTests, setTotalTests] = useState(0);

  // SearchActiveModal tests & packages
  const searchTestsPackages = () => {
    axiosInstance
      .get(
        `/search-packages?search_key=${filterParams.search_key.trim()}&age=${
          filterParams.age
        }&gender=${filterParams.gender}&symptoms=${filterParams.symptoms}`
      )
      .then((response) => {
        setSearchPackagesList(response?.data?.packages);
        setTestsList(response?.data?.tests);
        setTotalTests(response?.data?.total_tests);
        setTotalPackages(response?.data?.total_packages);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    searchTestsPackages();
  }, [applyFilter, filterParams]);

  useEffect(() => {
    searchTestsPackages();
  }, []);

  useEffect(() => {
    document.title =
      "Zain Care | Delivering High Quality Healthcare at your doorsteps in UAE";
    updateMeta(
      "description",
      `Experience smart, affordable home healthcare solutions that are compassionate and accessible with ZainCare.`
    );
    updateMeta(
      "keywords",
      `blood test at home dubai, blood test at home, blood test home collection, blood sample collection from home, lab test home collection`
    );
  }, []);

  const buttonHeight = width <= 768 ? "47px" : "56px";
  const tests = [
    {
      name: "home_lab_test",
      slug: "liver_function",
      description: "Home Lab Test",
      link: "#",
      url: "/images/cards/lab_test.svg",
    },
    {
      name: "heart",
      slug: "heart_function",
      description: "Home Nursing Care",
      link: "#",
      url: "/images/cards/heart.svg",
    },
    {
      name: "liver",
      slug: "lungs_function",
      description: "I V Drip Therapy",
      link: "#",
      url: "/images/cards/ivdrip.svg",
    },
    {
      name: "kidney",
      slug: "kidney_function",
      description: "Physiotherapy",
      link: "#",
      url: "/images/cards/physio.svg",
    },
    {
      name: "lungs",
      slug: "heart_function",
      description: "Rehabilitation",
      link: "#",
      url: "/images/cards/doctor.svg",
    },
  ];
  function redirectURL() {
    let w = typeof window !== "undefined" ? window : {};
    w.location = `https://${
      isMobile ? "api" : "web"
    }.whatsapp.com/send/?phone=+971528866165&text=Hey I'm interested in Booking a Package`;
  }

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setParams({
      name: "",
      email: "",
      phone: " ",
      file: "",
      message: "",
    });
    setErrors({});
  };

  const [pFlag, setFlag] = useState("init");

  const [search, setSearchActive] = useState("hidden");

  const handleSearchClose = () => {
    setSearchActive("hidden");
  };

  const [packagesList, setPackagesList] = useState([]);

  const getPopularPackages = () => {
    axiosInstance
      .get(`/popular-packages`)
      .then((response) => {
        setPackagesList(response.data.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getPopularPackages();
  }, []);

  // for search active modal toggle between tests or packages
  const [active, setActive] = useState("tests");

  const [highLight, setHighlight] = useState("healthcare");

  const handleHighlight = (str, duration) => {
    setTimeout(() => {
      setHighlight(str);
    }, duration);
  };

  const highLightText = (init = "") => {
    // on first page load
    if (init) {
      handleHighlight("healthcare", 2200);
      handleHighlight("high_quality", 4400);
      handleHighlight("accuracy", 6600);
    }
    // every six seconds
    else {
      handleHighlight("healthcare", 2200);
      handleHighlight("high_quality", 4400);
      handleHighlight("accuracy", 6600);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      highLightText();
    }, 6600);

    // on mount or first page load
    highLightText(true);

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  // add to cart modal states
  const [addToCart, setAddtoCart] = useState(false);
  const handleAddToCartOpen = () => setAddtoCart(true);
  const handleAddToCartClose = () => setAddtoCart(false);

  // handling input & validations

  const [params, setParams] = useState(fields);
  const [errors, setErrors] = useState(fields);

  const [files, setFiles] = useState({});

  const [file, setUploadFile] = useState({});

  const handlePhone = (e) => {
    if (e.target) {
      let { name, value } = e.target;
      updateParams([{ name, value }]);
    }
    if (e.url) {
      updateParams([{ name: e?.name, value: e?.url }]);
    }

    setErrors({});
  };

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      if (
        name === "pincode" ||
        name === "phone" ||
        name === "alternate_phone"
      ) {
        const re = /^[0-9\b]+$/;
        if (value && !re.test(value)) {
          return;
        }
      }
      updateParams([{ name, value }]);
    } else {
      updateParams([{ name: e?.name, value: e?.url }]);
    }

    if (e?.file) {
      setUploadFile(e.file);
      setFiles({ ...files, [e.name]: e.file });
    }

    setErrors({});
  };

  const updateParams = (records) => {
    const newParams = JSON.parse(JSON.stringify(params));

    Object.keys(records).forEach(
      (key) => (newParams[records[key].name] = records[key].value)
    );

    setParams(newParams);
  };

  const validate = (parameters, rule) => {
    const validator = new Validator(parameters, rule);

    if (validator.fails()) {
      const fieldErrors = {};

      /* eslint-disable */
      for (const key in validator.errors.errors) {
        fieldErrors[key] = validator.errors.errors[key][0];
      }
      /* eslint-enable */

      setErrors(fieldErrors);

      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = () => {
    let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;

    if (!validate(params, rules)) {
      const err = Object.keys(errors);
      if (err?.length) {
        const input = document.querySelector(`input[name=${err[0]}]`);
        if (input) {
          input.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "start",
          });
        }
      }
      if (!regex.test(`${params.phone}`)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Invalid Phone Number",
        }));
        return;
      }
      return;
    }
    setFlag("success");
    uploadPrescription();
  };

  const removeImage = (name) => {
    setFiles({ ...files, [name]: "" });
    updateParams([{ name: name, value: "" }]);
  };

  const uploadPrescription = () => {
    const formData = new FormData();

    for (let key in params) {
      formData.append(key, params[key]);
    }
    formData.append("image", file);

    axiosInstance
      .post(`/prescription-upload-request`, formData)
      .then((response) => {
        gtag("event", "conversion", {
          send_to: "AW-11437476569/IhseCPL9h4gZENmV6M0q",
        });
        setParams({
          name: "",
          email: "",
          phone: "",
          file: "",
          message: "",
        });
        setFlag("success");
      })
      .catch((error) => {});
  };

  const playVideo = () => {
    setStart(0);
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoEnd = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      setIsPlaying(!isPlaying);
    }
  };

  const searchActiveRef = useRef();

  // scroll into view
  const scrollModalIntoView = () => {
    searchActiveRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  // card service type set value onClick
  const [serviceType, setServiceType] = useState("");

  // thumbnail position
  const [start, setStart] = useState(31);

  // initial thumbnail position
  useEffect(() => {
    let vid = document?.getElementById("howtoOperateVideo");
    vid.currentTime = start;
    if (vid.currentTime === 40) {
      setStart(31);
    }
  }, [start]);
  const [activeIndex, setActiveIndex] = useState(0);

  const dispatch = useDispatch();
  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    if (isLoggedIn) dispatch(fetchProfile());
  }, [isLoggedIn]);

  useEffect(() => {
    setParams({
      ...params,
      name: profileData?.name,
      phone: profileData?.phone,
      email: profileData?.email,
    });
  }, [profileData]);

  return (
    <main>
      {/* 1.Opening Banner */}
      <div className="relative top-[100px] m-auto mb-[100px] flex flex-col  justify-between p-6 sm:items-center sm:p-10  md:w-[768px] md:flex-row lg:top-[200px] lg:mb-[200px] lg:w-[1440px]">
        <div className="absolute top-[-250px] z-10 hidden opacity-30 md:block lg:block">
          <img className="" src="/images/shapes/hexBackground.svg" alt="" />
        </div>
        <div className="flex flex-col gap-y-8 md:w-full  lg:w-full lg:gap-y-10 lg:pl-8 ">
          <p className="font-jostSemibold  text-[30px] leading-tight text-blueShadow md:text-[36px] lg:text-[65px]">
            Delivering{" "}
            <span
              className={`${
                highLight === "healthcare" ? "text-Verdigris" : ""
              }`}
            >
              high quality
            </span>{" "}
            <br />
            <span
              className={`${
                highLight === "high_quality" ? "text-Verdigris" : ""
              }`}
            >
              healthcare
            </span>{" "}
            at your
            <br />
            <span
              className={`${highLight === "accuracy" ? "text-Verdigris" : ""}`}
            >
              doorsteps
            </span>
          </p>

          {/* Phone */}
          <div className=" relative top-[-30px] block sm:hidden">
            <div className="block md:hidden lg:hidden">
              <HeroAnimation type={"phone"} />
            </div>
          </div>

          <div className="flex items-center">
            <p className="w-[98%]  font-satoshiRegular text-[14px] leading-[20px] text-secondaryLight md:w-[296px] md:text-[16px] md:leading-[18px] lg:w-[500px] lg:text-[20px] lg:leading-[26px]">
              Revolutionize your healthcare experience with{" "}
              <span className="font-satoshiBold">‘ZainCare’</span>. Experience
              smart, affordable home healthcare solutions that are compassionate
              and accessible with ZainCare.
            </p>
          </div>

          <div className="flex items-center justify-around gap-4 md:justify-start md:gap-x-4 lg:justify-start lg:gap-x-4">
            <button className="h-[46px] w-[159px] rounded-lg border border-blueShadow font-satoshiMedium text-base text-blueShadow md:w-44 lg:h-[56px] lg:w-[200px] ">
              <a href="tel:+971 528866165">
                <p className="flex flex-row items-center justify-center gap-2">
                  <img
                    style={{
                      width: "18.63px",
                      height: "20px",
                    }}
                    src="/images/icons/call.svg"
                    alt="Call Us"
                  />
                  Call Us
                </p>
              </a>
            </button>

            <button
              onClick={(e) => redirectURL()}
              className="h-[46px] w-[159px] rounded-lg border-blueShadow bg-blueShadow font-satoshiMedium text-base text-white md:w-44 lg:h-[56px] lg:w-[200px]"
            >
              <p className="flex flex-row items-center justify-center gap-2">
                <img
                  style={{ width: "18.63px", height: "20px" }}
                  src="/images/icons/whatsapp_icon.svg"
                  alt="Whatsapp"
                />
                Whatsapp
              </p>
            </button>
          </div>
        </div>

        {/* Desktop */}
        <div className="absolute right-10 top-6  hidden  w-[400px] lg:-top-20 lg:right-0  lg:block  lg:w-[750px] ">
          <div className="relative top-[-60px] hidden scale-[0.9] md:hidden lg:block">
            <HeroAnimation type={"tab"} />
          </div>

          <div className="absolute right-0 top-[50%] z-10">
            <img src="/images/shapes/matrix.svg" alt="Zaincare" />
          </div>
        </div>

        {/* Tab */}
        <div className="absolute right-10 top-6 hidden  w-[311px]  md:block lg:-top-20 lg:right-20  lg:hidden  lg:w-[750px]">
          <div className="hidden md:block lg:hidden">
            <HeroAnimation type={"phone"} />
          </div>
          <div className="absolute right-[-80px] top-[40%] z-10 h-[91px] w-[167px] opacity-80">
            <img src="/images/shapes/matrix.svg" alt="Vector Image" />
          </div>
        </div>
      </div>

      {/* 2.Test Slider */}
      <section className="mt-10 py-8  lg:mt-[120px]">
        <div className="flex flex-col items-center justify-center">
          <p className="font-satoshiMedium text-[16px] text-secondaryLight lg:text-[22px]">
            Our Services
          </p>
          <div className=" mx-2 md:mx-6">
            <h2 className="text-center font-jostSemibold text-[24px] text-[#3C567E] lg:text-[36px]">
              Only Solution for all your problems
            </h2>
          </div>
        </div>

        <ServicesCarousel
          content={tests}
          handleContactFormOpen={handleContactFormOpen}
          setServiceType={setServiceType}
        />
      </section>

      <div className="relative z-40 flex flex-col items-center justify-center">
        <p className="p-6 text-center text-sm text-secondaryLight sm:p-0 md:w-[690px] lg:mt-[-20px] lg:w-[805px] lg:text-lg">
          ‘Zain Care’ is committed to delivering exceptional healthcare services
          that help you maintain a healthy lifestyle. Choose us as your partner
          in your health journey, and let&#39;s work together to keep you in the
          best of health.
        </p>
        <br className="hidden sm:block" />
      </div>

      {/* How it works */}
      {/* <LifeSavingSteps setActiveIndex={setActiveIndex} /> */}

      <LifeSavingsCarousel />

      {/* 3.Video */}
      <div
        className={`relative mb-52 mt-[100px] ${isPlaying ? "" : "bg-black "}`}
      >
        <div
          onClick={playVideo}
          className={` h-[446px] md:h-[526px] lg:h-[667px]`}
        >
          <video
            onEnded={handleVideoEnd}
            onClick={handleVideo}
            id="howtoOperateVideo"
            ref={videoRef}
            // poster={thisThumbnail}
            controls={isPlaying ? true : false}
            className={` h-full object-fill ${
              isPlaying ? "" : "opacity-20"
            }    `}
            width="100%"
          >
            <source
              src="https://zaincare.s3.me-central-1.amazonaws.com/how_works.mp4"
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>

          {!isPlaying ? (
            <div className="  absolute bottom-0 left-0 right-0 top-32 m-auto leading-relaxed sm:top-44">
              <p className="text-center text-[1rem]   font-medium text-white lg:text-[22px]">
                Click Here to Know
              </p>
              <p className=" text-center font-jostBold  text-2xl font-semibold text-Verdigris lg:text-4xl">
                How We Operate
              </p>

              <br />
              <img
                onClick={isPlaying ? handleVideo : playVideo}
                className="m-auto h-[72px] w-[72px] cursor-pointer  lg:h-[135px] lg:w-[135px]"
                src="/images/icons/howtoOperate.svg"
                alt="howtoOperate"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* Search for hundreds of tests and packages */}
        <div
          style={{
            boxShadow:
              "0px -2px 80px rgba(35, 134, 167, 0.06), 0px -0.835552px 33.4221px rgba(35, 134, 167, 0.0431313), 0px -0.446726px 17.869px rgba(35, 134, 167, 0.0357664), 0px -0.250431px 10.0172px rgba(35, 134, 167, 0.03), 0px -0.133002px 5.32008px rgba(35, 134, 167, 0.0242336), 0px -0.0553451px 2.21381px rgba(35, 134, 167, 0.0168687)",
          }}
          className={`${
            isPlaying
              ? "relative left-0 right-0 top-[20px]"
              : " absolute -bottom-64 drop-shadow-sm  md:-bottom-64 lg:-bottom-48"
          } left-0 right-0 m-auto max-w-[343px]  rounded-3xl border bg-white px-4 py-6 sm:p-9 md:max-w-[704px]
                        lg:max-w-[1080px]`}
        >
          <p className="w-full  text-center  font-satoshiBold text-sm font-bold text-blueShadow md:text-base lg:text-2xl ">
            Search for hundreds of Tests and Packages
          </p>
          <br />
          {/* input search bar */}
          <div className=" flex ">
            <input
              onClick={scrollModalIntoView}
              onChange={handleFilterChange}
              onFocus={() => {
                if (width <= 640) navigate("/search_filter");
                setSearchActive("block");
              }}
              name="search_key"
              value={filterParams.search_key}
              type="text"
              className="relative w-full  rounded-lg bg-grey p-4  outline-none   placeholder:text-xs placeholder:font-medium md:placeholder:text-sm lg:placeholder:text-lg"
              placeholder="Search for All Tests and Packages"
            />

            {/* Search Button */}
            <div
              onClick={handleFilterApply}
              className="absolute right-4 flex w-16 cursor-pointer justify-center rounded-lg bg-Verdigris p-4 sm:right-9"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                  fill="white"
                />
                <path
                  d="M21.9999 22.7514C21.8099 22.7514 21.6199 22.6814 21.4699 22.5314L19.4699 20.5314C19.1799 20.2414 19.1799 19.7614 19.4699 19.4714C19.7599 19.1814 20.2399 19.1814 20.5299 19.4714L22.5299 21.4714C22.8199 21.7614 22.8199 22.2414 22.5299 22.5314C22.3799 22.6814 22.1899 22.7514 21.9999 22.7514Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>

          <br />
          {/* hr line with or */}
          <div className="br-red-400 relative flex w-full gap-8">
            <div className="hr-line w-full"></div>
            <p className="absolute -top-4 left-[49.2%] text-lg font-bold text-DarkGrey">
              or
            </p>
            <div className="hr-line w-full"></div>
          </div>
          <br />
          <div className="flex flex-col items-center justify-center">
            <p className="text-center text-sm font-bold  text-CadetGrey sm:font-normal lg:text-xl">
              Upload your Prescription, We are here to help you!
            </p>
            <br />
            <CustomButton
              width="w-56"
              borderRadius="0.5rem"
              variant="secondary-contained"
              size="large"
              onClick={handleOpen}
            >
              Upload Prescription
            </CustomButton>
          </div>
        </div>
      </div>

      {/* Search Active Modal */}

      <SearchActiveModal
        isPlaying={isPlaying}
        search={search}
        active={active}
        setActive={setActive}
        packagesList={searchPackageList}
        testsList={testsList}
        totalTests={totalTests}
        totalPackages={totalPackages}
        resetFilter={resetFilter}
        handleFilterChange={handleFilterChange}
        params={filterParams}
        handleSearchClose={handleSearchClose}
        searchActiveRef={searchActiveRef}
      />

      <br />

      {/* 4.Packages */}

      <div className="mt-20">
        <Packages
          packagesList={packagesList}
          handleAddToCartOpen={handleAddToCartOpen}
        />
      </div>

      {/* Upload prescription modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={style}
          style={{
            textAlign: "center",
            borderRadius: "24px",
            outline: "none",
          }}
        >
          <div className="flex w-[350px] flex-col items-center py-5 md:w-[437px] md:py-[25px] lg:w-full lg:py-[45px]">
            {pFlag === "init" && (
              <>
                <p className="text-center font-satoshiBold text-base text-blueShadow md:text-2xl lg:text-2xl">
                  Upload Prescription
                </p>
                {/* Inputs */}
                <div className="mt-[20px] flex flex-col items-center justify-center gap-4 lg:flex-row lg:gap-[21px]">
                  <div>
                    <FileUpload
                      imageUrl={params.file}
                      removeImage={() => {
                        removeImage("file");
                        setUploadFile("");
                      }}
                      styleType="md"
                      setImage={handleChange}
                      acceptMimeTypes={["application/pdf", "image/jpeg"]}
                      title="Upload or Drag and Drop image"
                      label="File Format: .pdf/ .docx"
                      id="pdf"
                      maxSize={3}
                      filename="file"
                      error={errors?.file}
                    />
                  </div>

                  {/* input fields */}
                  <div>
                    <Input
                      rows={1}
                      width="lg:w-[417px] md:w-[382px] w-[308px] "
                      disabled={false}
                      readOnly={false}
                      handleChange={handleChange}
                      value={params?.name}
                      error={errors?.name}
                      helperText={errors?.name}
                      label="Name*"
                      name="name"
                    />
                    <Input
                      rows={1}
                      width="lg:w-[417px] md:w-[382px] w-[308px] mt-[16px]"
                      disabled={false}
                      readOnly={false}
                      handleChange={handleChange}
                      value={params?.email}
                      error={errors?.email}
                      helperText={errors?.email}
                      label="Email Address*"
                      name="email"
                    />

                    <div className="mt-[16px]">
                      <PhoneInput
                        rows={1}
                        width="w-full"
                        disabled={false}
                        readOnly={false}
                        handleChange={handlePhone}
                        value={params?.phone}
                        error={errors?.phone}
                        helperText={errors?.phone}
                        label="Contact No*"
                        name="phone"
                      />
                    </div>

                    <div className="mt-[16px]">
                      <TextArea
                        rows={3}
                        handleChange={handleChange}
                        value={params?.message}
                        helperText={errors?.message}
                        placeholder="Write a message"
                        name="message"
                      />
                    </div>
                  </div>
                </div>

                {/* CTA */}
                <div className="right-[50px] mt-[16px]  flex w-[949px] flex-row items-center justify-center gap-[17px] lg:relative lg:justify-end ">
                  <div className="flex w-[140px] items-center justify-center md:w-[175px] lg:w-[200px]">
                    <p
                      onClick={handleClose}
                      className="w-max-fit cursor-pointer border-b-2 border-blueShadow text-blueShadow"
                    >
                      Cancel
                    </p>
                  </div>
                  <CustomButton
                    onClick={handleSubmit}
                    width="lg:w-[200px] md:w-[175px] w-[140px] "
                    borderRadius="0.5rem"
                    variant="secondary-contained"
                    size="large"
                    height={buttonHeight}
                  >
                    Submit
                  </CustomButton>
                </div>
              </>
            )}

            {pFlag === "success" && (
              <div className=" flex flex-col items-center justify-center font-satoshiBold text-sm md:text-base lg:w-[543px] lg:text-base ">
                <p className="text-[#33B0AA]">
                  Your Prescription has been Submitted!
                </p>
                <p className="text-blueShadow">
                  Our experts will call you back within 15 minutes.
                </p>
                <div className="mt-[16px] md:mt-[19px] lg:mt-[26px]">
                  <CustomButton
                    onClick={() => {
                      handleClose();
                      setFlag("init");
                    }}
                    width="lg:w-[250px] md:w-[180px] w-[128px]"
                    borderRadius="0.5rem"
                    variant="secondary-contained"
                    size="large"
                    height={buttonHeight}
                  >
                    Okay
                  </CustomButton>
                </div>
              </div>
            )}
          </div>
        </Box>
      </Modal>

      {/* Add to cart or Proceed to payment modal */}
      <Modal
        open={addToCart}
        onClose={handleAddToCartClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={addToCartStyles}
          style={{
            textAlign: "center",
            borderRadius: "16px",
            outline: "none",
          }}
        >
          <div className="flex justify-end ">
            <img
              onClick={handleAddToCartClose}
              className=" cursor-pointer"
              src="/images/icons/closeIcon.svg"
              alt="Close Icon"
            />
          </div>

          <p className="w-[294px] font-jostRegular text-[20px] font-bold text-[#3C567E] lg:w-full lg:text-[26px]">
            {" "}
            Want to add More test/ Package to your{" "}
            <span className="lg:block">shopping cart?</span>
          </p>
          <br />

          <div className="flex flex-col items-center justify-center gap-5 lg:flex-row">
            <CustomButton
              borderRadius="0.5rem"
              variant="secondary-outlined"
              width="lg:w-[252px] w-[276px]"
              onClick={handleAddToCartClose}
            >
              Add More Test/ Package
            </CustomButton>
            <Link to="/orders/checkout">
              <CustomButton
                borderRadius="0.5rem"
                variant="secondary-contained"
                width="lg:w-[252px] w-[276px]  "
              >
                Proceed to Checkout
              </CustomButton>
            </Link>
          </div>
        </Box>
      </Modal>

      <ServiceModal
        open={contactForm}
        handleOpen={handleContactFormOpen}
        handleClose={handleContactFormClose}
        service_type={serviceType}
      />
    </main>
  );
};

export default Home;
