import React, { useEffect, useState } from "react";
import AddMember from "./AddMember";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    addMembers,
    editMemberDetails,
    fetchMembers,
    resetApiSuccess,
} from "../../../../features/userProfile/familySlice";
import axiosInstance from "../../../../helpers/axios";
import Validator from "validatorjs";
import moment from "moment";
import { calculateAge, showToastMessage } from "../../../../helpers/helpers";
import updateMeta from "../../../../helpers/seo-meta";

const initialValue = {
    name: "",
    dob: null,
    phone: "",
    gender: "",
    age: "",
    relation: "",
    email: "",
    nationality: ""
};

const MyFamily = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { familyFriends, isLoading, isCreateSuccess } = useSelector(
        (state) => state.family
    );
    const [params, setParams] = useState(initialValue);
    const [formErrors, setFormErrors] = useState(initialValue);
    const [memberId, setMemberId] = useState("");
    const [date, setDate] = useState("");
    let calc_age = calculateAge(date);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setParams({
            ...params,
            ...initialValue,
        });
        setMemberId("");
        setOpen(false);
    };

    useEffect(() => {
        dispatch(fetchMembers());
    }, []);

    const handleEditProfile = async (id) => {
        setMemberId(id);
        axiosInstance
            .get(`/customer-family/${id}`)
            .then((response) => {
                let details = response.data.data;

                setParams({
                    ...params,
                    name: details?.name,
                    phone: details?.phone,
                    gender: details?.gender,
                    email: details?.email,
                    dob: Date.parse(details?.dob),
                    age: details?.age,
                    relation: details?.relation,
                    nationality: details?.nationality
                });
                handleOpen();
            })
            .catch((err) => {
            });
    };

    const onDateChange = (date) => {
        let date2 = moment(date).format("YYYY-MM-DD HH:mm:ss");
        if (date2 !== "Invalid date") {
            setDate(date2);
            setParams({ ...params, dob: date });
        }
    };

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            if (name === "phone" || name === "age") {
                const re = /^[0-9\b]+$/;
                if (value && !re.test(value)) {
                    return;
                }
            }
            setParams({ ...params, [name]: value });
        }
        setFormErrors({});
    };

    const handlePhone = (e) => {
        if (e.target) {
            let { name, value } = e.target
            updateParams([{ name, value }])
        }
        if (e.url) {
            updateParams([{ name: e?.name, value: e?.url }])
        }

        setFormErrors({})
    }

    const updateParams = (records) => {
        const newParams = JSON.parse(JSON.stringify(params))
        Object.keys(records).forEach((key) => (newParams[records[key].name] = records[key].value))
        setParams(newParams)
    }

    const handleSubmit = () => {
        let payload = {
            name: params.name,
            dob: moment(params.dob).format('YYYY-MM-DD'),
            gender: params.gender,
            phone: params.phone,
            age: calc_age,
            relation: params.relation,
            email: params.email,
            nationality: params.nationality
        };
        const rules = {
            name: "required|string|max:50",
            dob: "required",
            phone: "required|max:15",
            gender: "required",
            email: "required|max:225|email",
            relation: "required|string|max:30",
            nationality: "required",

        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            const err = Object.keys(fieldErrors);
            if (err.length) {
                const input = document.querySelector(
                    `input[name=${err[0]}]`
                );
                if (input) {
                    input.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "start",
                    });
                }
            }
            setFormErrors(fieldErrors);
            return false;
        }

        let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[01256])[0-9]{7}$/;
        if (!regex.test(`${payload.phone}`)) {
            setFormErrors(({ ...formErrors, phone: 'Invalid Phone Number' }));
            return
        }

        memberId !== ""
            ? dispatch(editMemberDetails(memberId, payload))
            : dispatch(addMembers(payload));
        setFormErrors({});
        handleClose();
        return true;
    };

    const handleDeleteProfile = async (id) => {
        axiosInstance
            .delete(`/customer-family/${id}`)
            .then((response) => {
                showToastMessage(response.data.data.message, "success");
                dispatch(fetchMembers());
            })
            .catch((err) => {
                showToastMessage(err.message, "error");
            });
    };

    useEffect(() => {
        if (isCreateSuccess == true) {
            dispatch(resetApiSuccess());
            dispatch(fetchMembers());
        }
    }, [isCreateSuccess]);

    const captureNationality = (event, newValue) => {
        setParams({ ...params, nationality: newValue });
        setFormErrors({})
    }

    useEffect(() => {
        document.title = 'Zain care | High-Quality, Affordable Healthcare at your Home';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);


    return (
        <>
            <div
                className={`${isLoading ? "md:h-auto" : "md:h-fit"
                    } bg-white h-screen w-full md:bg-with-shadow md:w-[50%] lg:w-[70%] flex flex-col rounded-lg md:gap-4 md:px-5 md:pb-5 lg:px-8 lg:pb-8 md:pt-6`}
            >
                <div className="md:hidden  flex justify-start items-center gap-4 border-b border-Harp px-6 py-3">
                    <img
                        className=" cursor-pointer"
                        onClick={() => navigate("/user-profile")}
                        src='/images/icons/leftArrow.svg'
                        alt="User Profile"
                    />
                    <p className="text-DarkGrey text-base font-satoshiMedium">
                        My Family & Friends
                    </p>
                </div>
                {isLoading ? (
                    <div className="hidden"></div>
                ) : (
                    <p className="hidden md:flex md:mb-2 lg:mb-4 lg:text-2xl md:text-xl text-BlueJay font-satoshiMedium">
                        My Family & Friends
                    </p>
                )}
                {isLoading ? (
                    <div className="flex flex-row justify-center items-center w-full h-full">
                        <p className="text-2xl font-satoshiBold text-BlueJay">
                            Loading...
                        </p>
                    </div>
                ) : familyFriends?.length > 0 ? (
                    <div className="h-screen md:h-auto flex flex-col justify-between pr-4 mt-2 md:pr-0 md:mt-0">
                        <div className="overflow-auto max-h-[700px] md:max-h-[370px] lg:max-h-[310px]">
                            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 gap-4 ml-6 mr-4 md:ml-0 md:mr-2 lg:mx-4 mt-4 md:mt-0">
                                {familyFriends?.map((item) => {
                                    return (
                                        <div className=" bg-WhiteLilac lg:p-4 p-2 rounded-lg">
                                            <div className="bg-white rounded-lg p-3 lg:py-4 lg:px-6">
                                                <div className="flex flex-row justify-between mb-2">
                                                    <p className="font-satoshiBold text-base text-BlueJay">
                                                        {item?.name || "--"}
                                                    </p>
                                                    <div className="flex flex-row justify-center items-center gap-4">
                                                        <img
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                handleEditProfile(
                                                                    item?.id
                                                                )
                                                            }
                                                            src='/images/icons/Edit.svg'
                                                            alt="Edit Profile"
                                                        />
                                                        <img
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                handleDeleteProfile(
                                                                    item?.id
                                                                )
                                                            }
                                                            src='/images/icons/Delete.svg'
                                                            alt="Delete Profile"
                                                        />
                                                    </div>
                                                </div>
                                                <p className="text-secondaryLight text-base font-satoshiMedium">
                                                    {item?.gender || "--"},{" "}
                                                    {item?.age ? item.age + ' years ,' : ''}
                                                </p>
                                                <p className="text-secondaryLight text-base font-satoshiMedium">
                                                    {item?.phone || "--"}
                                                </p>
                                                <p className="text-secondaryLight text-base font-satoshiMedium">
                                                    {item?.email}
                                                </p>
                                                <p className="text-secondaryLight text-base font-satoshiMedium">
                                                    Relation : {item?.relation}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mb-6 md:mb-0 flex flex-row justify-center items-center lg:justify-center lg:items-center md:justify-end md:items-end">
                            <button
                                className="mt-4 w-[150px] h-[47px] lg:w-[190px] lg:h-[56px] bg-[#10A39B] rounded-lg font-satoshiRegular text-white text-[14px] font-bold"
                                onClick={handleOpen}
                            >
                                Add Member
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="mx-6 md:mx-0 my-4 md:my-0">
                        <div className="w-full md:h-min h-screen bg-WhiteLilac px-4 py-4 md:py-28 rounded-lg text-center flex flex-col justify-center">
                            <p className="mb-4 font-satoshiRegular text-[#3C567E50]">
                                No Family & Friends
                            </p>
                            <div className="mb-4">
                                <button
                                    className="w-[150px] h-[47px] lg:w-[190px] lg:h-[56px] bg-[#10A39B] rounded-lg font-satoshiRegular text-white text-[14px] font-bold"
                                    onClick={handleOpen}
                                >
                                    Add Member
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {open && (
                <AddMember
                    open={open}
                    handleClose={handleClose}
                    params={params}
                    formErrors={formErrors}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    onDateChange={onDateChange}
                    handlePhone={handlePhone}
                    captureNationality={captureNationality}
                />
            )}
        </>
    );
};
export default MyFamily;
