import { useEffect } from "react";
import BreadCrumb from "./common/BreadCrumb";
import React from "react";
import updateMeta from "../helpers/seo-meta";

const CookiePolicy = () => {

    useEffect(() => {
        document.title = "Cookie Policy | Zain Care - Delivering High Quality Healthcare";
    }, []);

    return (
        <div className="">
            <div className="terms_and_cookie_bg">
                <div className="pt-[160px] lg:w-[1440px] m-auto">
                    <div className="px-10">
                        <BreadCrumb
                            links={[
                                { path: "Home", url: "/" },
                                { path: "Cookie Policy", url: "/" },
                            ]}
                        />
                    </div>
                    <p className=" text-center text-blueShadow font-jostRegular font-[600] lg:text-[36px] md:text-[26px] text-[24px]">
                        Cookie Policy
                    </p>
                </div>
            </div>

            {/* terms & conditions */}
            <div className="">
                <div className="relative text-[#3C567E] lg:bottom-[220px] md:bottom-[120px] bottom-[100px] left-0 right-0 bg-white shadow-xl rounded-lg ml-auto mr-auto lg:w-[1127px] md:w-[704px] w-[334px]  p-10 leading-2">
                    
                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base">
                        Welcome to ZAINCARE!
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] my-[10px]">
                        These terms and conditions outline the rules and
                        regulations for the use of Zain Alsifa Home Health
                        Care`s Website, located at https://zaincare.com.
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px]">
                        By accessing this website we assume you accept these
                        terms and conditions. Do not continue to use ZainCare if
                        you do not agree to take all of the terms and conditions
                        stated on this page. The following terminology applies
                        to these Terms and Conditions, Privacy Statement and
                        Disclaimer Notice and all Agreements: "Client", "You"
                        and "Your" refers to you, the person log on this website
                        and compliant to the Company’s terms and conditions.
                        "The Company", "Ourselves", "We", "Our" and "Us", refers
                        to our Company. "Party", "Parties", or "Us", refers to
                        both the Client and ourselves. All terms refer to the
                        offer, acceptance and consideration of payment necessary
                        to undertake the process of our assistance to the Client
                        in the most appropriate manner for the express purpose
                        of meeting the Client’s needs in respect of the
                        provision of the Company’s stated services, in
                        accordance with and subject to, prevailing law of
                        Netherlands. Any use of the above terminology or other
                        words in the singular, plural, capitalization and/or
                        he/she or they, are taken as interchangeable and
                        therefore as referring to same.
                    </p>

                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base mt-[28px]">
                        Cookies
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] my-[10px]">
                        We employ the use of cookies. By accessing ZainCare, you
                        agreed to use cookies in agreement with Zain Alsifa Home
                        Health Care Privacy Policy.
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] ">
                        Most interactive websites use cookies to let us retrieve
                        the user’s details for each visit. Cookies are used by
                        our website to enable the functionality of certain areas
                        to make it easier for people visiting our website. Some
                        of our affiliate/advertising partners may also use
                        cookies.
                    </p>

                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base my-[28px]">
                        License
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] ">
                        Unless otherwise stated, Zain Alsifa Home Health Care
                        and/or its licensors own the intellectual property
                        rights for all material on ZainCare. All intellectual
                        property rights are reserved. You may access this from
                        ZainCare for your own personal use subjected to
                        restrictions set in these terms and conditions. You must
                        not:
                    </p>

                    <ul className="list-disc p-5 marker:text-blueShadow font-satoshiRegular text-blueShadow lg:text-[20px] md:text-[18px] text-[14px] leading-2">
                        <li>Republish material from ZainCare</li>
                        <li>
                            Sell, rent or sub-license material from ZainCare
                        </li>
                        <li>
                            Reproduce, duplicate or copy material from ZainCare
                        </li>
                        <li>Redistribute content from ZainCare</li>
                    </ul>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] ">
                        Parts of this website offer an opportunity for users to
                        post and exchange opinions and information in certain
                        areas of the website. Zain Alsifa Home Health Care does
                        not filter, edit, publish or review Comments prior to
                        their presence on the website. Comments do not reflect
                        the views and opinions of Zain Alsifa Home Health Care,
                        its agents and/or affiliates. Comments reflect the views
                        and opinions of the person who post their views and
                        opinions. To the extent permitted by applicable laws,
                        Zain Alsifa Home Health Care shall not be liable for the
                        Comments or for any liability, damages or expenses
                        caused and/or suffered as a result of any use of and/or
                        posting of and/or appearance of the Comments on this
                        website.
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[10px]">
                        Zain Alsifa Home Health Care reserves the right to
                        monitor all Comments and to remove any Comments which
                        can be considered inappropriate, offensive or causes
                        breach of these Terms and Conditions.
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[28px]">
                        You warrant and represent that:
                    </p>
                    <ul className="list-disc p-5 marker:text-blueShadow font-satoshiRegular text-blueShadow lg:text-[20px] md:text-[18px] text-[14px] leading-2">
                        <li>
                            You are entitled to post the Comments on our website
                            and have all necessary licenses and consents to do
                            so;
                        </li>
                        <li>
                            The Comments do not invade any intellectual property
                            right, including without limitation copyright,
                            patent or trademark of any third party;
                        </li>
                        <li>
                            The Comments do not contain any defamatory,
                            libelous, offensive, indecent or otherwise unlawful
                            material which is an invasion of privacy
                        </li>
                        <li>
                            The Comments will not be used to solicit or promote
                            business or custom or present commercial activities
                            or unlawful activity.
                        </li>
                        <li>
                            You hereby grant Zain Alsifa Home Health Care a
                            non-exclusive license to use, reproduce, edit and
                            authorize others to use, reproduce and edit any of
                            your Comments in any and all forms, formats or
                            media.
                        </li>
                    </ul>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[10px]">
                        These organizations may link to our home page, to
                        publications or to other Website information so long as
                        the link: (a) is not in any way deceptive; (b) does not
                        falsely imply sponsorship, endorsement or approval of
                        the linking party and its products and/or services; and
                        (c) fits within the context of the linking party’s site.
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[28px]">
                        We may consider and approve other link requests from the
                        following types of organizations: commonly-known
                        consumer and/or business information sources;
                    </p>

                    <ul className="list-disc p-5 marker:text-blueShadow font-satoshiRegular text-blueShadow lg:text-[20px] md:text-[18px] text-[14px] leading-2">
                        <li>dot.com community sites;</li>
                        <li>
                            associations or other groups representing charities;
                        </li>
                        <li>online directory distributors;</li>
                        <li>
                            internet portals; accounting, law and consulting
                            firms; and
                        </li>
                        <li>
                            educational institutions and trade associations.
                        </li>
                    </ul>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[10px]">
                        We will approve link requests from these organizations
                        if we decide that: (a) the link would not make us look
                        unfavorably to ourselves or to our accredited
                        businesses; (b) the organization does not have any
                        negative records with us; (c) the benefit to us from the
                        visibility of the hyperlink compensates the absence of
                        Zain Alsifa Home Health Care, and (d) the link is in the
                        context of general resource information.
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[10px]">
                        These organizations may link to our home page so long as
                        the link: (a) is not in any way deceptive; (b) does not
                        falsely imply sponsorship, endorsement or approval of
                        the linking party and its products or services; and (c)
                        fits within the context of the linking party’s site.
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[10px]">
                        If you are one of the organizations listed in paragraph
                        2 above and are interested in linking to our website,
                        you must inform us by sending an e-mail to Zain Alsifa
                        Home Health Care. Please include your name, your
                        organization name, contact information as well as the
                        URL of your site, a list of any URLs from which you
                        intend to link to our Website, and a list of the URLs on
                        our site to which you would like to link. Wait 2-3 weeks
                        for a response.
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] mt-[28px]">
                        Approved organizations may hyperlink to our Website as
                        follows:
                    </p>
                    <ul className="list-disc p-5 marker:text-blueShadow font-satoshiRegular text-blueShadow lg:text-[20px] md:text-[18px] text-[14px] leading-2">
                        <li>By use of our corporate name; or</li>
                        <li>
                            By use of the uniform resource locator being linked
                            to; or
                        </li>
                        <li>
                            By use of any other description of our Website being
                            linked to that makes sense within the context and
                            format of content on the linking party’s site.
                        </li>
                        <li>
                            No use of Zain Alsifa Home Health Care's logo or
                            other artwork will be allowed for linking absent a
                            trademark license agreement.
                        </li>
                    </ul>

                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base my-[28px]">
                        Content Liability
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px]">
                        We shall not be held responsible for any content that
                        appears on your Website. You agree to protect and defend
                        us against all claims that are rising on your Website.
                        No link(s) should appear on any Website that may be
                        interpreted as libelous, obscene or criminal, or which
                        infringes, otherwise violates, or advocates the
                        infringement or other violation of, any third party
                        rights.
                    </p>

                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base my-[28px]">
                        Your Privacy
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px]">
                        Please read Privacy Policy
                    </p>

                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base my-[28px]">
                        Reservation of Rights
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px]">
                        We reserve the right to request that you remove all
                        links or any particular link to our Website. You approve
                        to immediately remove all links to our Website upon
                        request. We also reserve the right to amend these terms
                        and conditions and its linking policy at any time. By
                        continuously linking to our Website, you agree to be
                        bound to and follow these linking terms and conditions.
                    </p>

                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base my-[28px]">
                        Removal of links from our website
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px]">
                        If you find any link on our Website that is offensive
                        for any reason, you are free to contact and inform us at
                        any moment. We will consider requests to remove links
                        but we are not obligated to or so or to respond to you
                        directly.
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px]">
                        We do not ensure that the information on this website is
                        correct, we do not warrant its completeness or accuracy;
                        nor do we promise to ensure that the website remains
                        available or that the material on the website is kept up
                        to date.
                    </p>

                    <p className=" font-bold lg:text-[24px] md:text-[22px] text-base mt-[28px] mb-[10px]">
                        Disclaimer
                    </p>
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px]">
                        To the maximum extent permitted by applicable law, we
                        exclude all representations, warranties and conditions
                        relating to our website and the use of this website.
                        Nothing in this disclaimer will:
                    </p>

                    <ul className="list-disc p-5 marker:text-blueShadow font-satoshiRegular text-blueShadow lg:text-[20px] md:text-[18px] text-[14px] leading-2">
                        <li>
                            limit or exclude our or your liability for death or
                            personal injury;
                        </li>
                        <li>
                            limit or exclude our or your liability for fraud or
                            fraudulent misrepresentation;
                        </li>
                        <li>
                            limit any of our or your liabilities in any way that
                            is not permitted under applicable law; or exclude
                            any of our or your liabilities that may not be
                            excluded under applicable law.
                        </li>
                        <li>
                            The limitations and prohibitions of liability set in
                            this Section and elsewhere in this disclaimer: (a)
                            are subject to the preceding paragraph; and (b)
                            govern all liabilities arising under the disclaimer,
                            including liabilities arising in contract, in tort
                            and for breach of statutory duty.
                        </li>
                        <li>
                            As long as the website and the information and
                            services on the website are provided free of charge,
                            we will not be liable for any loss or damage of any
                            nature.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CookiePolicy;
