import { useEffect, useState } from "react";
import AddAddress from "./AddAddress";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    addAddress,
    editAddress,
    fetchAllAddress,
    resetApiSuccess,
} from "../../../../features/userProfile/addressSlice";
import Validator from "validatorjs";
import axiosInstance from "../../../../helpers/axios";
import { showToastMessage } from "../../../../helpers/helpers";

import React from 'react'
import updateMeta from "../../../../helpers/seo-meta";

const initialValue = {
    location_type: "",
    building_name: "",
    house_no: "",
    locality: "",
    city: "Dubai",
    address_instructions: "",
    postal_code: "",
};

const ManageAddress = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addressList, isLoading, isCreateSuccess } = useSelector(
        (state) => state.address
    );
    const [params, setParams] = useState(initialValue);
    const [formErrors, setFormErrors] = useState(initialValue);
    const [addressId, setAddressId] = useState("");

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setParams(initialValue);
        setAddressId("");
        setOpen(false);
        setFormErrors(initialValue)
    };

    useEffect(() => {
        if (isCreateSuccess) {
            handleClose();
        }
    }, [isCreateSuccess]);

    useEffect(() => {
        dispatch(fetchAllAddress());
    }, []);

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            setParams({ ...params, [name]: value });
        }
        setFormErrors({});
    };

    const handleEditAddress = async (id) => {
        setAddressId(id);
        axiosInstance
            .get(`/customer-address/${id}`)
            .then((response) => {
                let details = response.data.data;
                setParams({
                    ...params,
                    location_type: details.location_type,
                    building_name: details.building_name,
                    house_no: details.house_no,
                    locality: details.locality,
                    city: details.city,
                    address_instructions: details.address_instructions
                });
                handleOpen();
            })
            .catch((err) => {
            });
    };

    const handleSubmit = () => {
        let payload = {
            location_type: params.location_type,
            building_name: params.building_name,
            house_no: params.house_no,
            locality: params.locality,
            city: params.city,
            address_instructions: params.address_instructions,
        };
        const rules = {
            location_type: "required",
            building_name: "required|string|max:50",
            house_no: "required|string|max:50",
            locality: "required|string|max:50",
            address_instructions: "max:500",
        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setFormErrors(fieldErrors);
            return false;
        }

        addressId !== ""
            ? dispatch(editAddress(addressId, payload))
            : dispatch(addAddress(payload));
        setFormErrors({});
        return true;
    };

    const handleDeleteAddress = async (id) => {
        axiosInstance
            .delete(`/customer-address/${id}`)
            .then((response) => {
                showToastMessage(response.data.data.message, "success");
                dispatch(fetchAllAddress());
            })
            .catch((err) => {
                showToastMessage(err.message, "error");
            });
    };

    useEffect(() => {
        if (isCreateSuccess == true) {
            dispatch(resetApiSuccess());
            dispatch(fetchAllAddress());
        }
    }, [isCreateSuccess]);

    useEffect(() => {
        document.title = 'Manage Address';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);

    return (
        <>
            <div
                className={`${isLoading ? "md:h-auto" : "md:h-fit"
                    } bg-white h-screen w-full md:bg-with-shadow md:w-[50%] lg:w-[70%] flex flex-col rounded-lg md:gap-4 md:px-5 md:pb-5 lg:px-8 lg:pb-8 md:pt-6`}
            >
                <div className="md:hidden flex justify-start items-center gap-4 border-b border-Harp px-6 py-3">
                    <img
                        className=" cursor-pointer"
                        onClick={() => navigate("/user-profile")}
                        src='/images/icons/leftArrow.svg'
                        alt=" User Profile"
                    />
                    <p className="text-DarkGrey text-base font-satoshiBold">
                        Manage Address
                    </p>
                </div>
                {isLoading ? (
                    <div className="hidden"></div>
                ) : (
                    <p className="md:block hidden md:mb-2 lg:mb-4 lg:text-2xl text-xl text-BlueJay font-satoshiMedium">
                        Manage Address
                    </p>
                )}
                {isLoading ? (
                    <div className="flex flex-row justify-center items-center w-full h-full">
                        <p className="text-2xl font-satoshiBold text-BlueJay">
                            Loading...
                        </p>
                    </div>
                ) : addressList?.length > 0 ? (
                    <div className="h-screen md:h-auto flex flex-col justify-between pr-4 mt-2 md:pr-0 md:mt-0">
                        <div className="overflow-auto max-h-[700px] md:max-h-[370px] lg:max-h-[310px]">
                            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-6 gap-4 ml-6 mr-4 md:ml-0 md:mr-2 lg:mx-4 mt-4 md:mt-0">
                                {addressList?.map((item) => {
                                    return (
                                        <div className=" bg-WhiteLilac lg:p-4 p-2 rounded-lg">
                                            <div className="bg-white rounded-lg p-3 lg:py-4 lg:px-6">
                                                <div className="flex flex-row justify-between mb-2">
                                                    <p className="font-satoshiBold text-base text-BlueJay">
                                                        {item?.location_type}
                                                    </p>
                                                    <div className="flex flex-row justify-center items-center gap-4">
                                                        <img
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                handleEditAddress(
                                                                    item?.id
                                                                )
                                                            }
                                                            src='/images/icons/Edit.svg'
                                                            alt="Edit Address"
                                                        />
                                                        <img
                                                            className="cursor-pointer"
                                                            onClick={() =>
                                                                handleDeleteAddress(
                                                                    item?.id
                                                                )
                                                            }
                                                            src='/images/icons/Delete.svg'
                                                            alt="Delete Address"
                                                        />
                                                    </div>
                                                </div>
                                                <p className="text-secondaryLight text-base mt-2 font-satoshiMedium w-[70%]">
                                                    {item?.house_no || "--"}{" "}
                                                    {", "} {item?.building_name}{" "}
                                                    {", "}{" "}
                                                    {item?.locality || "--"}
                                                    {", "} {item?.city || "--"}
                                                </p>
                                                <p className="text-secondaryLight text-base mt-2 font-satoshiMedium w-full break-words">
                                                    Address Instructions :{" "}
                                                    {item?.address_instructions ||
                                                        "NA"}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="mb-6 md:mb-0 flex flex-row justify-center items-center lg:justify-center lg:items-center md:justify-end md:items-end">
                            <button
                                className="md:mt-4 w-[150px] h-[47px] lg:w-[190px] lg:h-[56px] bg-[#10A39B] rounded-lg font-satoshiRegular text-white text-[14px] font-bold"
                                onClick={handleOpen}
                            >
                                Add Address
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="mt-2 md:mt-0 mx-4 md:mx-0">
                        <div className="w-full md:h-min h-screen bg-WhiteLilac px-4 md:py-28 rounded-lg text-center flex flex-col justify-center">
                            <p className="mb-4 font-satoshiRegular text-[#3C567E50]">
                                No Address Found
                            </p>
                            <div className="mb-4">
                                <button
                                    className="w-[150px] h-[47px] lg:w-[190px] lg:h-[56px] bg-[#10A39B] rounded-lg font-satoshiRegular text-white text-[14px] font-bold"
                                    onClick={handleOpen}
                                >
                                    Add Address
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {open && (
                <AddAddress
                    open={open}
                    handleClose={handleClose}
                    params={params}
                    formErrors={formErrors}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                />
            )}
        </>
    );
};
export default ManageAddress;
