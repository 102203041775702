import React from "react";
import Validator from "validatorjs";
import { useEffect } from 'react';
import { Input } from "./common/Input";
import TextArea from "./common/TextArea";
import { Box, Modal } from "@mui/material";
import CustomButton from "./common/Button";
import axiosInstance from "../helpers/axios";
import Backdrop from "@mui/material/Backdrop";
import { useState } from "react";
import { showToastMessage } from "../helpers/helpers";
import { PhoneInput } from "./common/PhoneInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../features/userProfile/profileSlice";
import { isUserLoggedIn } from "../helpers/auth";
import useGtag from '../helpers/gtag';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fcfcfc",
};

const ServiceModal = ({ open, handleClose, service_type }) => {
    const gtag = useGtag();
    const { profileData} = useSelector((state) => state.profile)
    const [isDisabled, setIsDisabled] = useState(false)

    const fields = {
        name: "",
        email: "",
        phone: "",
        message: "",
    };
    const [params, setParams] = useState(fields);
    const [errors, setErrors] = useState(fields);

    const makeServiceRequest = () => {

        let data = { ...params, "service": service_type }

        axiosInstance.post('/service_request', data)
            .then((response) => {
                gtag('event', 'conversion', {
                    send_to: 'AW-11437476569/IhseCPL9h4gZENmV6M0q',
                });
                setErrors({})
                setFlag('')
            }).catch((error) => {
                showToastMessage('Error Occurred while sending data for service request', "error")
            })
    }

    const isLoggedIn = isUserLoggedIn();


    const onCloseClick = () => {

        setParams({
            name: "",
            email: "",
            phone: "",
            message: "",
        })

        setErrors(fields)
        handleClose()
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setParams({ ...params, [name]: value });
        setIsDisabled(false)
    };

    const width = typeof window !== 'undefined' ? window?.innerWidth : 1440;
    const buttonHeight = width <= 768 ? "47px" : "56px";
    const [pFlag, setFlag] = useState("init");

    const SubmitFeedback = () => {
        const validation = new Validator(params, {
            name: "required|max:100",
            email: "required|email",
            phone: "required|max:15",
            message: "required|max:200",
        });

        let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;


        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setErrors(fieldErrors);

            const err = Object.keys(errors);
            if (err.length) {

                const input = document.querySelector(
                    `input[name=${err[0]}]`
                );

                input.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "start",
                });
            }
            showToastMessage("Please Select All Required Fields!", "error");
            if (!regex.test(`${params.phone}`)) {
                setErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
                return
            }
            return false;
        }
        makeServiceRequest()


    };


    const dispatch = useDispatch()

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchProfile())
        }
    }, [isLoggedIn])

    useEffect(() => {
        setParams({
            ...params,
            name: profileData?.name,
            phone: profileData?.phone,
            email: profileData?.email,
            message: " "
        })
    }, [profileData])

    return (
        <div className="">
            <Modal
                open={open}
                onClose={onCloseClick}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box
                    sx={style}
                    style={{
                        textAlign: "center",
                        borderRadius: "24px",
                        outline: "none",
                    }}
                >
                    <div className="flex flex-col items-center p-5 lg:w-[514px] md:w-[384px] w-[350px]">
                        {pFlag === "init" ? (
                            <>
                                <p className="mt-8 text-blueShadow font-jostSemibold lg:text-[26px] md:text-2xl text-base text-center">
                                    {service_type}
                                </p>

                                <div className="lg:mt-[31px] md:mt-[35px] mt-[27px] flex flex-col gap-2">
                                    <Input
                                        rows={1}
                                        width="w-full"
                                        disabled={false}
                                        readOnly={false}
                                        handleChange={handleChange}
                                        value={params?.name}
                                        error={!!errors?.name}
                                        helperText={errors?.name}
                                        label="Your Name*"
                                        name="name"
                                    />
                                    <Input
                                        rows={1}
                                        width="w-full"
                                        disabled={false}
                                        readOnly={false}
                                        handleChange={handleChange}
                                        value={params?.email}
                                        error={!!errors?.email}
                                        helperText={errors?.email}
                                        label="Email ID*"
                                        name="email"
                                    />
                                    
                                    <PhoneInput rows={1}
                                        width="w-full"
                                        disabled={false}
                                        readOnly={false}
                                        handleChange={handleChange}
                                        value={params?.phone}
                                        error={errors?.phone}
                                        helperText={errors?.phone}
                                        label="Contact No*"
                                        name="phone" />

                                    <TextArea
                                        rows={5}
                                        width="lg:w-[454px] md:w-[345px] w-[308px]"
                                        handleChange={handleChange}
                                        readOnly={false}
                                        value={params?.message}
                                        error={!!errors?.message}
                                        helperText={errors?.message}
                                        placeholder="Write a message*"
                                        name="message"
                                    />
                                </div>

                                <div className="flex lg:flex-row flex-col justify-center items-center gap-[21px] mt-[20px]"></div>

                                <div className="flex flex-col-reverse justify-center items-center gap-[17px] mt-[16px]  ">
                                    <div className="lg:w-[200px] md:w-[175px] w-[140px] flex justify-center items-center">
                                        <p
                                            onClick={onCloseClick}
                                            className="text-blueShadow lg:w-[454px] md:w-[345px] w-[308px] h-[47px] w-max-fit cursor-pointer underline font-satoshiBold"
                                        >
                                            Cancel
                                        </p>
                                    </div>
                                    <CustomButton
                                        onClick={() => {
                                            SubmitFeedback()
                                            setIsDisabled(true)
                                        }}
                                        disabled={isDisabled}
                                        width="lg:w-[250px] md:w-[175px] w-[140px] "
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        size="large"
                                        height={buttonHeight}
                                    >
                                        Submit your details
                                    </CustomButton>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className=" font-satoshiBold flex flex-col justify-center items-center lg:text-base md:text-base text-sm lg:w-[543px] ">
                                    <p className="text-[#33B0AA]">
                                        Our executive will connect with you soon!
                                    </p>
                                    <p className="text-blueShadow">
                                        Thanks for reaching out!
                                    </p>
                                    <div className="lg:mt-[26px] md:mt-[19px] mt-[16px]">
                                        <CustomButton
                                            onClick={() => {
                                                onCloseClick()
                                                setFlag("init");
                                            }}
                                            width="lg:w-[250px] md:w-[180px] w-[128px]"
                                            borderRadius="0.5rem"
                                            variant="secondary-contained"
                                            size="large"
                                            height={buttonHeight}
                                        >
                                            Okay
                                        </CustomButton>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default ServiceModal;
