import { useEffect } from "react";
import BreadCrumb from "./common/BreadCrumb";
import React from 'react'
import updateMeta from '../helpers/seo-meta'
const content = [

    {
        id: 1,
        title: 'Use of the website',
        desc: 'This website is intended for personal, non-commercial use only. You may not use any content on this website for any commercial purpose without our prior written consent. We reserve the right to refuse service, terminate accounts, and remove or edit content at our sole discretion.'
    },
    {
        id: 2,
        title: 'Content and Intellectual Property',
        desc: 'All content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Zain Alshifa Home Healthcare Center LLC or its affiliates and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, display, or create derivative works of any content on this website without our prior written consent.'
    },
    {
        id: 3,
        title: 'Limitation of Liability',
        desc: 'Zain Alshifa Home Healthcare Center LLC shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of this website. We make no warranty, express or implied, regarding the accuracy or completeness of any information on this website and disclaim any liability for errors or omissions in such information.'
    },
    {
        id: 4,
        title: 'Links to third-party websites',
        desc: 'This website may contain links to third-party websites that are not owned or controlled by Zain Alshifa Home Healthcare Center LLC. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that Zain Alshifa Home Healthcare Center LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, goods, or services available on or through any third-party websites.'
    },
    {
        id: 5,
        title: 'Payment Terms and Conditions',
        desc: 'All payments made through this website shall be processed in accordance with the laws of the United Arab Emirates. You agree to pay all fees and charges associated with your use of this website and any services or products offered through this website. We reserve the right to modify or update our fees and charges at any time without prior notice. All payments shall be made in UAE Dirhams (AED) unless otherwise specified.'
    },
    {
        id: 6,
        title: 'Governing Law',
        desc: 'These terms and conditions shall be governed by and construed in accordance with the laws of the United Arab Emirates. Any dispute arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Dubai.'
    },
    {
        id: 7,
        title: 'Amendments',
        desc: 'We reserve the right to update or modify these terms and conditions at any time without prior notice. Your continued use of this website after any such changes constitutes your acceptance of the revised terms and conditions. Please check this page periodically for updates.'
    },


]

const Terms = () => {

    useEffect(() => {
        document.title = "Terms and Conditions | Zain Care - Delivering High Quality Healthcare";
    }, []);

    return (
        <div className="">
            <div className="terms_and_cookie_bg">
                <div className="pt-[160px] lg:w-[1440px] m-auto">
                    <div className="px-10">
                        <BreadCrumb
                            links={[
                                { path: "Home", url: "/" },
                                { path: "Terms & Conditions", url: "/" },
                            ]}
                        />
                    </div>
                    <p className=" text-center text-blueShadow font-jostRegular font-[600] lg:text-[36px] md:text-[26px] text-[24px]">
                        Terms & Conditions
                    </p>
                </div>
            </div>

            {/* terms & conditions */}
            <div className="">
                <div className="relative text-[#3C567E] lg:bottom-[220px] md:bottom-[120px] bottom-[100px] left-0 right-0 bg-white shadow-xl rounded-lg ml-auto mr-auto lg:w-[1127px] md:w-[704px] w-[334px]  p-10 leading-2">

                    <p className="font-satoshiRegular lg:text-xl md:text-[18px] text-[14px] my-[10px] text-justify">
                        Welcome to <span className="text-blueShadow font-satoshiBold ">Zain Alshifa Home Healthcare Center</span> LLC's website, <a href="#" className="hover:underline">www.zaincare.com.</a> By accessing and using this website, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms and conditions, please do not use this website.
                    </p>

                    {content.map(item => (
                        <div className="my-5">
                            <p className=" font-bold lg:text-xl md:text-xl text-base my-[10px] ">
                                {item.id}. {item?.title}
                            </p>
                            <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] text-justify">
                                <span className="font-satoshiBold"></span>
                                {item?.desc}
                            </p>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
};

export default Terms;
