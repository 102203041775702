import React, { useEffect } from "react";
import { useRef } from 'react'
import Footer from './Footer'
import PartnerBanner from './Partner/PartnerBanner'
import Header from "./Header";
import updateMeta from "../helpers/seo-meta";

const Partner = () => {

  const formRef = useRef()

  // scroll into view
  const getInTouch = () => {
    formRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    })
  }

  useEffect(() => {
    document.title = 'Partner with us | Zain Care - Delivering High Quality Healthcare'
    updateMeta('description', `Partner with Zain Care, Dubai's leader in smart, affordable home healthcare. Join our mission to transform healthcare with tech and ethics.`);
  }, []);

  return (
    <div className='bg-WhiteLilac '>
      <PartnerBanner getInTouch={getInTouch} />
      <Footer formRef={formRef} bepartner />
    </div>
  )
}

export default Partner
