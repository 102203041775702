import React from 'react'
import CustomButton from '../common/Button'

const PartnerBanner = ({ getInTouch }) => {
  return (
    <div className='relative  max-w-[375px] md:max-w-[768px] lg:max-w-[1440px] m-auto pl-0 md:pl-12 lg:pl-28 mt-36 md:mt-60 lg:mt-[22rem] '>
      <img
        src='/images/images/Partner/mobile_banner_partner.svg'
        className='sm:hidden block absolute -right-10 -top-[28rem]'
        alt='mobile_banner_partner'
      />

      <div className='flex flex-col p-4 sm:p-0 sm:mt-0 mt-[30rem]'>
        <p className=' leading-tight  lg:text-[65px] md:text-[36px] text-[30px] text-blueShadow font-jostSemibold'>
          Join us as Business<br /> Partner!
        </p>

        <p className='font-satoshiRegular  lg:w-[587px] md:w-[296px] w-[98%] lg:text-[20px] md:text-[16px] text-[14px] text-secondaryLight lg:leading-[26px] md:leading-[18px] leading-[20px] mt-4 mb-6'>
          Zain Care is revolutionizing healthcare in Dubai with optimized resources, technology, and ethics for high-quality, affordable care. Join us in our mission to bring value to the community by partnering with like-minded individuals and organizations. Let's work together to prioritize ethics, leverage technology, and deliver frugal, yet high-quality care. Contact us today to explore partnership opportunities and make a meaningful difference in healthcare.
        </p>

        <CustomButton
          width='md:w-44 lg:w-56 w-full'
          height='56px'
          borderRadius='0.5rem'
          variant='secondary-contained'
          size='large'
          onClick={getInTouch}
        >
          Get in touch
        </CustomButton>
      </div>
      <img
        className='hidden sm:block absolute md:-top-80 lg:-top-[32rem] -right-72'
        src='/images/images/Partner/banner_partner.svg'
        alt=''
      />
    </div>
  )
}

export default PartnerBanner
