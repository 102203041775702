import Form from './Form'
import Validator from 'validatorjs'
import axiosInstance from '../../../helpers/axios'
import React, { useEffect, useState } from 'react'
import { validateNumber } from '../../../helpers/helpers'

import { useDispatch, useSelector } from 'react-redux'
import { fetchProfile } from '../../../features/userProfile/profileSlice'
import Header from '../../Header'
import updateMeta from '../../../helpers/seo-meta'
const { fields, rules } = require('./fields')
import useGtag from '../../../helpers/gtag';

function Support() {
    const gtag = useGtag();
    useEffect(() => {
        document.title = 'Customer Support | Zain Care - Delivering High Quality Healthcare'
    }, []);

    const [params, setParams] = useState(fields)
    const [errors, setErrors] = useState(fields)

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target
            updateParams([{ name, value }])
        }
        if (e.url) {
            updateParams([{ name: e?.name, value: e?.url }])
        }

        setErrors({})
    }

    const updateParams = (records) => {
        const newParams = JSON.parse(JSON.stringify(params))
        Object.keys(records).forEach((key) => (newParams[records[key].name] = records[key].value))
        setParams(newParams)
    }

    const validate = (parameters, rule) => {
        const validator = new Validator(parameters, rule)

        if (validator.fails()) {
            const fieldErrors = {}

            /* eslint-disable */
            for (const key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0]
            }
            /* eslint-enable */

            setErrors(fieldErrors)
            return false
        }
        setErrors({})
        return true
    }

    const handleSubmit = () => {

        if (params.phone && !validateNumber(params.phone)) {
            setErrors({ ...errors, phone: 'Invalid Phone Number' })
            return
        }
        if (!validate(params, rules)) {
            return
        }

        if (!validate(params, rules)) {
            const err = Object.keys(errors)
            if (err?.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            //   showToastMessage('Please Select All Required Fields!', 'error')
            return
        }
        postData()
    }

    const [ack, setAck] = useState('')

    const postData = () => {
        axiosInstance
            .post('/support-ticket', params)
            .then((response) => {
                gtag('event', 'conversion', {
                    send_to: 'AW-11437476569/IhseCPL9h4gZENmV6M0q',
                });
                let thisData = response.data.data
                setAck(thisData.message)
                setParams({
                    name: '',
                    phone: ' ',
                    email: '',
                    message: '',
                })
            })
            .catch((error) => {
            })

        setParams({ name: '', email: '', message: '', phone: '' })

        setTimeout(() => {
            setAck('')
        }, 3000)
    }


    const dispatch = useDispatch()

    const { profileData, isLoading, isCreateSuccess } = useSelector((state) => state.profile)

    useEffect(() => {
        dispatch(fetchProfile())
    }, [])

    useEffect(() => {
        setParams({
            ...params,
            name: profileData?.name,
            phone: profileData?.phone,
            email: profileData?.email,
        })
    }, [profileData])

    return (
        <React.Fragment>
            <div className='text-white lg:p-20 p-10 relative lg:top-[550px] md:top-[550px] top-[600px] lg:mt-[-500px] md:mt-[-430px] mt-[-550px] left-0 lg:w-[1440px] m-auto pt-[50px]'>
               
                <div className='lg:leading-[52px] leading-[35px] font-jostRegular font-[600]'>
                    <p className='lg:text-[36px] md:text-[24px] text-[24px]'>
                        Customer Support &
                    </p>
                    <p className='lg:text-[36px] md:text-[24px] text-[24px]'>Compliance</p>
                </div>
                <br />
                <p className='lg:w-[657px] md:[557px] sm:[329px] text-justify'>
                    At Zain Care, we are guided by our core values of integrity, compassion, and
                    professionalism. We provide exceptional customer support and comply with the
                    highest standards of ethical conduct in healthcare. Our team is committed to
                    providing safe, effective, and compassionate care. We prioritize transparency
                    and open communication, and our 24/7 customer support team is always
                    available to assist. At Zain Care, we believe that exceptional support and
                    compliance are essential, and we are committed to providing the best care to our
                    clients and their families.

                </p>
            </div>
            <div className='support_bg'></div>

            <div className='lg:w-[1440px] m-auto flex lg:flex-row md:flex-row flex-col-reverse lg:justify-around justify-center items-center'>
                <div className='lg:w-[412px]  lg:mr-[200px] text-white  lg:mt-0 mt-[-120px] mb-[120px]'>
                    <div className='text-blueShadow'>
                        <div className='flex flex-row items-start'>
                            <img className='m-5 relative bottom-[12px]' src='/images/icons/support_icon.svg' alt='Support Icon' />
                            <div className='font-satoshiRegular'>
                                {' '}
                                <p className='lg:text-xl text-base font-bold'>For Support </p>
                                <p className='lg:text-base text-[14px] lg:leading-[22px] leading-[19px] lg:w-[240px] w-[264px] '>
                                    Need Help? Get in touch with us.
                                </p>
                                <br />
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <div className='relative lg:top-9 top-7'>
                                <img
                                    className='mx-5 my-3 relative bottom-[12px]'
                                    src='/images/icons/call_icon.svg'
                                    alt='Call At'
                                />
                                <img
                                    className='mx-5 my-3 relative bottom-[12px]'
                                    src='/images/icons/messageIcon.svg'
                                    alt='Message Icon'
                                />
                            </div>

                            <div>
                                <p className='lg:text-xl text-[20px] font-bold'>Call us:</p>
                                <p className='lg:text-base text-[14px] lg:leading-[27px] leading-[19px] mt-2'>
                                    +971 45762589, +971 528866165
                                </p>
                                <p className='lg:text-base text-[14px] lg:leading-[27px] leading-[19px] mt-2'>
                                    +971 528866165
                                </p>

                            </div>
                        </div>
                        <br />

                        <div className='flex flex-row'>
                            <img
                                className='m-5 relative bottom-[12px]'
                                src='/images/icons/support_icon.svg'
                                alt='Support Icon'
                            />

                            <div>
                                <p className='lg:text-xl text-[20px] font-bold'>Email us:</p>
                                <p className='lg:text-base text-[14px] lg:leading-[27px] leading-[19px] hover:underline cursor-pointer'>
                                    <a href="mailto:info@zaincare.com">info@zaincare.com</a>
                                </p>
                            </div>
                        </div>
                        <br />
                    </div>
                    <br />

                </div>

                <br />
                <br />

                <Form
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    params={params}
                    errors={errors}
                    ack={ack}
                />
            </div>
        </React.Fragment>
    )
}

export default Support
