import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { makeStyles } from "@mui/styles";

import CustomButton from "../../common/Button";
import Validator from "validatorjs";


import moment from "moment";
import AccordionTitle from "./components/AccordionTitle";
import { useDispatch, useSelector } from "react-redux";
import CustomStepper from "../../common/CustomStepper";
import Confirmation from "./stepper_pages/Confirmation";
import SampleDateTime from "./stepper_pages/SampleDateTime";
import Address from "./stepper_pages/Address";
import Patients from "./stepper_pages/Patients";
import UserVerification from "./stepper_pages/UserVerification";
import { Link } from "react-router-dom";
import axiosInstance from "../../../helpers/axios";
import { toast } from "react-toastify";
import { SelectInput } from "../../common/Select";
import SelectNationality from '../../common/SelectNationality'
import {
    genderOptions,
    showToastMessage,
    validateNumber,
} from "../../../helpers/helpers";

import Backdrop from "@mui/material/Backdrop";
import { Box, Modal } from "@mui/material";
import { Input } from "../../common/Input";


import CommonDatepicker from "../../common/DatePicker";
import { editProfile } from "../../../features/userProfile/profileSlice";
import { updateCart } from "../../../features/cart/cartSlice";
import {
    fetchMembers,
} from "../../../features/userProfile/familySlice";
import { PhoneInput } from "../../common/PhoneInput";
import OtpInput from "react-otp-input";
import Header from "../../Header";
import updateMeta from "../../../helpers/seo-meta";
import useGtag from '../../../helpers/gtag';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fcfcfc",
};

export const useStyles = makeStyles(() => ({
    root: {
        "&:before": {
            display: "none",
            backgroundColor: "#fcfcfc",
        },
        backgroundColor: "#fcfcfc !important",
        borderRadius: "8px !important",
    },
    details: {
        backgroundColor: "#fcfcfc",
        borderRadius: "8px !important",
    },

    content: {
        justifyContent: "center",
    },

    summary: {
        color: "#3C567E",
        backgroundColor: "#fcfcfc !important",
        borderRadius: "8px !important",
    },
    icon: {
        pointerEvents: "auto",
    },
}));

const steps = [
    {
        title: "User Verification",
        id: 1,
    },
    {
        title: "Patient",
        id: 2,
    },
    {
        title: "Address",
        id: 3,
    },
    {
        title: "Date & Time",
        id: 4,
    },
    {
        title: "Confirmation",
        id: 5,
    },
];

const initialValue = {
    name: "",
    email: "",
    dob: null,
    gender: "",
    nationality: ''
};

const initialPatient = {
    name: "",
    email: "",
    dob: null,
    phone: "",
    gender: "",
    age: 0,
    relation: "",
};

function OrderCheckout() {
    const gtag = useGtag();
    // patient modal params
    const [patientParams, setPatientParams] = useState(initialPatient);
    // patient field errors
    const [patientErrors, setPatientErrors] = useState(initialPatient);

    const [patientModal, setPatientModal] = useState(false);

    // open patient modal
    const patientOpen = () => setPatientModal(true);

    // close patient modal
    const patientClose = () => {
        setPatientModal(false);
        // ZC - 263 clear inputs when modal is closed or after success
        setPatientParams({
            name: "",
            email: "",
            dob: "",
            phone: "",
            gender: "",
            age: 0,
            relation: "",
        });
        setPatientErrors({});
    };

    const handlePatientChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            if (name === "age") {
                const re = /^[0-9\b]+$/;
                if (value && !re.test(value)) {
                    return;
                }
            }
            setPatientParams({ ...patientParams, [name]: value });
        }
        setPatientErrors({});
    };

    const handlePatientSubmit = () => {
        if (patientParams.phone && !validateNumber(`${patientParams.phone}`)) {
            setPatientErrors({ ...formErrors, phone: "Invalid Phone Number" });
            return;
        }

        let payload = {
            name: patientParams.name,
            dob: patientParams.dob,
            gender: patientParams.gender,
            phone: `${patientParams.phone}`,
            relation: patientParams.relation,
            email: patientParams.email,
        };

        const rules = {
            name: "required|string|max:50",
            dob: "required",
            phone: "required|max:15",
            gender: "required",
            email: "required|email|max:225",
            relation: "required|string|max:50",
        };

        const validation = new Validator(payload, rules);

        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            const err = Object.keys(fieldErrors);
            if (err.length) {
                const input = document.querySelector(
                    `input[name=${err[0]}]`
                );
                if (input) {
                    input.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "start",
                    });
                }
            }
            setPatientErrors(fieldErrors);
            let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;
            if (!regex.test(`${patientParams.phone}`)) {
                setPatientErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "Invalid Phone Number",
                }));
                return;
            }
            return false;
        }

        setPatientErrors({});
        patientClose();
        axiosInstance
            .post('/cart/add_new_patient', {
                list: payload,
            })
            .then((response) => {
                dispatch(updateCart(response.data))
                setActiveStep(2)
            })
        return true;
    };


    const onPatientDate = (date) => {
        let date2 = moment(date).format("YYYY-MM-DD HH:mm:ss");
        if (date2 !== "Invalid date") {
            setPatientParams({
                ...patientParams,
                dob: date2,
                age: moment().diff(date2, "years"),
            });
        }
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(1);
    const cart = useSelector((state) => state.cart);
    const { isCreateSuccess } = useSelector((state) => state.family);

    const [params, setParams] = useState(initialValue);
    const [formErrors, setFormErrors] = useState(initialValue);
    const [isBookingLoading, setIsBookingLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const captureNationality = (event, newValue) => {
        setParams({ ...params, nationality: newValue });
        setFormErrors({})
    }


    useEffect(() => {
        if (isCreateSuccess) {
            dispatch(fetchMembers());
        }
    }, [isCreateSuccess]);

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            if (name === "phone" || name === "age") {
                const re = /^[0-9\b]+$/;
                if (value && !re.test(value)) {
                    return;
                }
            }
            setParams({ ...params, [name]: value });
        }
        setFormErrors({});
    };

    const onDateChange = (newValue) => {
        let date2 = moment(new Date(newValue)).format("YYYY-MM-DD");
        setParams({ ...params, dob: newValue });
    };

    const classes = useStyles();

    useEffect(() => {
        setActiveStep(cart.step_index);
        if (!cart.is_basic_done) {
            setOpen(true);
        }
    }, [cart]);

    const bookPackageRequest = (obj) => {
        if (!cart.mode_of_payment) {
            showToastMessage("Please Select Mode of Payment!", "error");
            return;
        }
        setIsBookingLoading(true);
        axiosInstance
            .post("book-package", {
                mode_of_payment: Number(cart.mode_of_payment),
            })
            .then((response) => {
               
                if (response.data) {
                    setIsBookingLoading(false);
                    const { url = null, mode, id } = response.data.data;
                    gtag('event', 'conversion', {
                        'send_to': 'AW-11437476569/VwvgCOz9h4gZENmV6M0q',
                        'value': cart.total.total,
                        'currency': 'AED',
                        'transaction_id': id,
                    });
                    if (url && mode === 1) {
                        window.location = url;
                    } else if (id && mode === 2) {
                        setParam({ ...param, order_id: id });
                        setIsOpenOtpModal(true)
                    }
                }
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsBookingLoading(false);
            });
    };

    const removePatient = (e, id) => {
        e.preventDefault();
        axiosInstance
            .put("cart/remove-patient/" + id, {})
            .then((response) => {
                dispatch(updateCart(response.data));
                showToastMessage("Patient Removed Successfully!", "success");
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsBookingLoading(false);
            });
    };

    const removePatientTest = (e, id, subIndex) => {
        e.preventDefault();
        axiosInstance
            .put("cart/remove-patient-test/" + id, {
                test_index: subIndex,
            })
            .then((response) => {
                dispatch(updateCart(response.data));
                showToastMessage(
                    "Package/Test Removed Successfully!",
                    "success"
                );
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsBookingLoading(false);
            });
    };

    const removeTest = (e, id) => {
        e.preventDefault();
        axiosInstance
            .delete("cart/delete-item/" + id)
            .then((response) => {
                dispatch(updateCart(response.data));
                showToastMessage(
                    "Package/Test Removed Successfully!",
                    "success"
                );
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };
    const handleOTPSubmit = (e) => {
        e.preventDefault();
        let payload = {
            otp: param.otp,
        };
        const rules = {
            otp: "required|numeric|digits:5",
        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setErrors(fieldErrors);
            return false;
        }
        setLoading(true)
        axiosInstance
            .put("book-package/verify-otp/" + param.order_id, payload)
            .then((response) => {
                showToastMessage("ORDER CONFIRMED SUCCESSFULLY", "success");
                navigate("/payment_process/" + param.order_id);
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                const { errors, message } = err.response.data;
                const erroMsg = errors[Object.keys(errors)[0]] || message;
                toast.error(erroMsg, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    // window resize

    const [width, setWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1000);
    const handleResize = () => {
        setWidth(window.innerWidth);
        {
            width <= 768 && setContent("stepper");
        }
    };
    useEffect(() => {
        document.addEventListener("resize", handleResize);
        document.removeEventListener("resize", handleResize);
    }, [width]);

    const [open, setOpen] = useState(false);

    const [isOpenOtpModal, setIsOpenOtpModal] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOtpModalClose = () => setIsOpenOtpModal(false);
    const [param, setParam] = useState({
        otp: "",
        order_id: "",
    });
    const [errors, setErrors] = useState({
        otp: "",
        order_id: "",
    });

    const handleOtp = (otp) => {
        setParam({ ...param, otp: otp });
    };

    const thisWidth = typeof window !== 'undefined' ? window.innerWidth : 1000;
    const buttonHeight = thisWidth <= 768 ? "47px" : "56px";

    const [content, setContent] = useState("stepper");

    const handleUpdate = (e) => {
        e.preventDefault();
        let payload = {
            name: params.name,
            email: params.email,
            dob: moment(params.dob).format('YYYY-MM-DD'),
            gender: params.gender,
            nationality: params.nationality
        };
        const rules = {
            name: "required|string|max:50",
            email: "required|email|max:50",
            dob: "required",
            gender: "required",
            nationality: "required"
        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            const err = Object.keys(fieldErrors);
            if (err.length) {
                const input = document.querySelector(
                    `input[name=${err[0]}]`
                );
                if (input) {
                    input.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "start",
                    });
                }
            }
            setFormErrors(fieldErrors);
            return false;
        }

        const formData = new FormData();

        Object.entries(payload).forEach(([key, value]) => {
            if (!["img_file"].includes(key)) {
                formData.append(`${key}`, `${value}`);
            }
        });

        dispatch(editProfile(formData));
        setFormErrors({});
        setOpen(false);
        return true;
    };

    useEffect(() => {
        document.title = 'Zain care | High-Quality, Affordable Healthcare at your Home';
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);

    


    return (
        <>
            <Header />
            {" "}
            {!cart.items?.length ? (
                <div className="pt-[150px] h-screen flex flex-col justify-center items-center">
                    <img src='/images/info/emptyCart.svg' alt='Empty Cart' />
                    <div className="mt-5">
                        <Link to="/">
                            <a className="text-gray-500 font-mono text-xl bg-gray-200 p-3 rounded-md hover:shadow-md">
                                Go back{" "}
                            </a>
                        </Link>
                    </div>
                </div>
            ) : (
                <div className="orders_bg">
                    <div className="flex lg:flex-row md:flex-col flex-col lg:w-[1440px] m-auto">
                        {content === "stepper" ? (
                            <div className="bg-white mt-[120px] mb-2 lg:hidden md:flex flex justify-center items-center gap-[5px] p-[4px] m-auto rounded-lg">
                                <p
                                    className={`bg-[#33B0AA] text-white w-[165px] cursor-pointer  p-3 flex justify-center items-center rounded-xl`}
                                >
                                    Booking Details
                                </p>
                                <p
                                    onClick={() => {
                                        setContent("summary");
                                        // handleClick();
                                    }}
                                    className={`text-[#909EB4] w-[165px] cursor-pointer  p-3 flex justify-center items-center rounded-xl`}
                                >
                                    Booking Summary
                                </p>
                            </div>
                        ) : (
                            <div className="bg-white mt-[120px] mb-2 lg:hidden md:flex flex justify-center items-center gap-[5px] p-[4px] m-auto rounded-lg">
                                <p
                                    onClick={() => {
                                        setContent("stepper");
                                        // handleClick();
                                    }}
                                    className={` text-[#909EB4] w-[165px] cursor-pointer  p-3 flex justify-center items-center rounded-xl`}
                                >
                                    Booking Details
                                </p>
                                <p
                                    className={`bg-[#33B0AA] text-white w-[165px] cursor-pointer  p-3 flex justify-center items-center rounded-xl`}
                                >
                                    Booking Summary
                                </p>
                            </div>
                        )}

                        {/* For Desktop */}

                        {/* Stepper form */}
                        <div
                            className={`lg:block hidden bg-white h-[100vh] lg:w-[50%] lg:pt-[140px] pt-[14px] mt-[5px] overflow-y-auto`}
                        >
                            <div className=" lg:w-[568px] m-auto mt-5">
                                <CustomStepper
                                    activeStep={activeStep}
                                    steps={
                                        width <= 768
                                            ? ["", "", "", "", ""]
                                            : steps
                                    }
                                />

                                <div className="flex flex-col justify-center items-center mt-5 ">
                                    {activeStep === 1 && (
                                        <UserVerification
                                            setActiveStep={setActiveStep}
                                        />
                                    )}
                                    {activeStep === 2 && (
                                        <Patients
                                            setActiveStep={setActiveStep}
                                        />
                                    )}
                                    {activeStep === 3 && (
                                        <Address
                                            setActiveStep={setActiveStep}
                                        />
                                    )}

                                    {activeStep === 4 && (
                                        <SampleDateTime
                                            setActiveStep={setActiveStep}
                                        />
                                    )}
                                    {activeStep === 5 && (
                                        <Confirmation
                                            handleChange={handleChange}
                                            bookPackageRequest={
                                                bookPackageRequest
                                            }
                                            isBookingLoading={isBookingLoading}
                                            setActiveStep={setActiveStep}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Order Details  */}
                        <div
                            className={`lg:block hidden lg:w-[50%] h-[100vh] lg:pt-[140px] pt-[14px] overflow-y-auto bg-[#EBEEF2]`}
                        >
                            <div className="lg:w-[568px] md:w-[704px] w-[344px] m-auto ">
                                <>
                                    <div className={`my-[16px]`}>
                                        <Accordion
                                            defaultExpanded
                                            className={classes.root}
                                            elevation={0}
                                            sx={{ padding: "10px" }}
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <img
                                                        className="rotate-180 "
                                                        src='/images/icons/arrowCircle.svg'
                                                        alt='Arrow'
                                                    />
                                                }
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                className={classes.summary}
                                                classes={{
                                                    content: classes.content,
                                                }}
                                            >
                                                <AccordionTitle title="Package Information" />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="p-3 bg-[#F4F7FA] rounded-lg">
                                                    {cart.patient_list
                                                        .length ? (
                                                        <div className="bg-[#ffffff] p-3 rounded-lg ">
                                                            <>
                                                                {cart.patient_list.map(
                                                                    (
                                                                        x,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <>
                                                                                <div className="flex justify-between items-center ">
                                                                                    <div className="">
                                                                                        <p className="text-blueShadow lg:text-[18px] text-[16px] font-satoshiBold ">
                                                                                            {
                                                                                                x?.name
                                                                                            }{" "}
                                                                                            |{" "}
                                                                                            <span className="text-[14px] font-satoshiMedium">
                                                                                                {
                                                                                                    x.relation
                                                                                                }
                                                                                            </span>
                                                                                        </p>
                                                                                        <p className="text-secondaryLight text-[12px] font-satoshiMedium">
                                                                                            {
                                                                                                x?.gender
                                                                                            }

                                                                                            ,{" "}
                                                                                            {
                                                                                                x.age
                                                                                            }{" "}
                                                                                            years
                                                                                        </p>
                                                                                    </div>
                                                                                    <p
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            removePatient(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        className="text-blueShadow font-satoshiBold text-[14px] border-b-2 border-blueShadow  w-max-fit cursor-pointer relative bottom-[10px]"
                                                                                    >
                                                                                        Remove
                                                                                    </p>
                                                                                </div>
                                                                                <div className="border my-3" />
                                                                                {x?.items?.map(
                                                                                    (
                                                                                        y,
                                                                                        sub_index
                                                                                    ) => (
                                                                                        <div className="flex flex-row justify-between items-center bg-[#F4F7FA] p-[16px] rounded-lg my-2">
                                                                                            <div>
                                                                                                <p className="text-blueShadow lg:text-[14px] text-[12px] font-satoshiMedium">
                                                                                                    <span className="text-[#363636]">
                                                                                                        {sub_index +
                                                                                                            1}

                                                                                                        .
                                                                                                    </span>{" "}
                                                                                                    {
                                                                                                        y?.name
                                                                                                    }
                                                                                                </p>
                                                                                                <p className="text-Verdigris text-[14px] font-satoshiBold">
                                                                                                    <span className="line-through text-secondaryLight text-[12px] font-satoshiMedium">
                                                                                                        {
                                                                                                            cart.currency
                                                                                                        }{" "}
                                                                                                        {
                                                                                                            y.mark_up_price
                                                                                                        }
                                                                                                    </span>{" "}
                                                                                                    {
                                                                                                        cart.currency
                                                                                                    }{" "}
                                                                                                    {
                                                                                                        y.price
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                            <img
                                                                                                onClick={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    removePatientTest(
                                                                                                        e,
                                                                                                        index,
                                                                                                        sub_index
                                                                                                    )
                                                                                                }
                                                                                                className="cursor-pointer relative bottom-[10px]"
                                                                                                src='/images/icons/removeIcon.svg'
                                                                                                alt='Remove Patient'
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}

                                                                <div className="flex justify-end ">
                                                                    <Link to="/packages">
                                                                        <p className="text-blueShadow font-satoshiBold border-b-2 border-blueShadow  w-max-fit text-[14px] cursor-pointer mt-[8px]">
                                                                            +Add
                                                                            more
                                                                            test
                                                                            or
                                                                            Package
                                                                        </p>
                                                                    </Link>
                                                                </div>
                                                            </>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {!cart.patient_list
                                                        .length &&
                                                        cart.items.length ? (
                                                        <div className="bg-[#ffffff] p-3 rounded-lg ">
                                                            <>
                                                                {cart.items.map(
                                                                    (
                                                                        y,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <>
                                                                                <div className="flex flex-row justify-between items-center bg-[#F4F7FA] p-[16px] rounded-lg my-2">
                                                                                    <div>
                                                                                        <p className="text-blueShadow lg:text-[14px] text-[12px] font-satoshiMedium">
                                                                                            <span className="text-[#363636]">
                                                                                                {index +
                                                                                                    1}

                                                                                                .
                                                                                            </span>{" "}
                                                                                            {
                                                                                                y?.name
                                                                                            }
                                                                                        </p>
                                                                                        <p className="text-Verdigris text-[14px] font-satoshiBold">
                                                                                            <span className="line-through text-secondaryLight text-[12px] font-satoshiMedium">
                                                                                                {
                                                                                                    cart.currency
                                                                                                }{" "}
                                                                                                {
                                                                                                    y.mark_up_price
                                                                                                }
                                                                                            </span>{" "}
                                                                                            {
                                                                                                cart.currency
                                                                                            }{" "}
                                                                                            {
                                                                                                y.price
                                                                                            }
                                                                                        </p>
                                                                                    </div>
                                                                                    <img
                                                                                        onClick={(
                                                                                            e
                                                                                        ) =>
                                                                                            removeTest(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        className="cursor-pointer relative bottom-[10px]"
                                                                                        src='/images/icons/removeIcon.svg'
                                                                                        alt='Remove Test'
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    }
                                                                )}

                                                                <div className="flex justify-end ">
                                                                    <Link to="/packages">
                                                                        <p className="text-blueShadow font-satoshiBold border-b-2 border-blueShadow  w-max-fit text-[14px] cursor-pointer mt-[8px]">
                                                                            +Add
                                                                            more
                                                                            test
                                                                            or
                                                                            Package
                                                                        </p>
                                                                    </Link>
                                                                </div>
                                                            </>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>

                                                {/* New Change */}
                                                <div className="flex justify-center items-center">
                                                    <button
                                                        onClick={patientOpen}
                                                        className="font-satoshiBold text-base text-center my-2 text-Verdigris underline cursor-pointer"
                                                    >
                                                        +Add New Patient
                                                    </button>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>

                                    {(Object.values(cart?.address).length ||
                                        cart.sample_collection_date_time) && (
                                            <div className={`my-[16px] `}>
                                                <Accordion
                                                    defaultExpanded
                                                    className={classes.root}
                                                    elevation={0}
                                                    sx={{
                                                        padding: "10px",
                                                        marginTop: "8px",
                                                    }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <img
                                                                className="rotate-180 "
                                                                src='/images/icons/arrowCircle.svg'
                                                                alt='arrow-circle'
                                                            />
                                                        }
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className={classes.summary}
                                                        classes={{
                                                            content:
                                                                classes.content,
                                                        }}
                                                    >
                                                        <AccordionTitle title="Sample Collection Information" />
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="bg-[#F4F7FA] p-3 rounded-md mb-5">
                                                            <div>
                                                                <div className="flex flex-row gap-2">
                                                                    {" "}
                                                                    <img
                                                                        src='/images/icons/loc_pin.svg'
                                                                        alt='Location'
                                                                    />{" "}
                                                                    <p className="text-blueShadow font-satoshiBold text-[14px]">
                                                                        {" "}
                                                                        Location
                                                                    </p>
                                                                </div>
                                                                <div className="border" />

                                                                <div className="p-3">
                                                                    <p className="text-blueShadow font-satoshiBold lg:text-[18px] text-[16px]">
                                                                        {
                                                                            cart
                                                                                ?.address
                                                                                ?.location_type
                                                                        }
                                                                    </p>
                                                                    <p className="text-secondaryLight font-satoshiMedium text-[14px]">
                                                                        {
                                                                            cart
                                                                                ?.address
                                                                                ?.house_no
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                            cart
                                                                                ?.address
                                                                                ?.building_name
                                                                        }
                                                                        ,{" "}
                                                                        {
                                                                            cart
                                                                                ?.address
                                                                                ?.locality
                                                                        }
                                                                        ,
                                                                        {
                                                                            cart
                                                                                ?.address
                                                                                ?.city
                                                                        }
                                                                        .
                                                                    </p>
                                                                    <p className="text-secondaryLight text-[13px] font-medium break-words">
                                                                        Address
                                                                        Instructions
                                                                        :{" "}
                                                                        {cart
                                                                            ?.address
                                                                            ?.address_instructions ||
                                                                            "NA"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {cart.sample_collection_date_time ? (
                                                            <div className="bg-[#F4F7FA] p-3 rounded-md mb-5">
                                                                <div>
                                                                    <div className="flex flex-row gap-2">
                                                                        {" "}
                                                                        <img
                                                                            src='/images/icons/sample_icon.svg'
                                                                            alt='Sample'
                                                                        />{" "}
                                                                        <p className="text-blueShadow font-satoshiBold text-[14px]">
                                                                            {" "}
                                                                            Sample
                                                                            Collection
                                                                            time
                                                                        </p>
                                                                    </div>
                                                                    <div className="border" />

                                                                    <div className="p-3">
                                                                        <p className="text-blueShadow font-satoshiBold lg:text-[18px] md:text-[16px] text-[16px] flex items-center">
                                                                            {moment(
                                                                                cart.sample_collection_date_time
                                                                            ).format(
                                                                                "DD-MM-YYYY |"
                                                                            )}{" "}
                                                                            <span className="text-secondaryLight pl-2  text-[14px] font-satoshiMedium">
                                                                                {
                                                                                    cart.samp_col_time_slot
                                                                                }
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        )}

                                    <div
                                        className={`bg-white rounded-lg my-[16px]`}
                                    >
                                        <div className="border-b p-5">
                                            <AccordionTitle title="Billing Information" />
                                        </div>
                                        <div className="p-5">
                                            <div className="p-5 bg-[#F4F7FA] rounded-md">
                                                <div className="flex justify-between items-center text-secondaryLight text-[16px] font-satoshiMedium">
                                                    <p>Sub Total</p>{" "}
                                                    <p className="font-satoshiBold">
                                                        AED{" "}{cart?.total?.subtotal}
                                                    </p>
                                                </div>
                                                <div className="flex pt-2 justify-between items-center text-secondaryLight text-[16px] font-satoshiMedium">
                                                    <p>
                                                        {" "}
                                                        Sample Collection
                                                        Charges
                                                    </p>{" "}
                                                    <p className="font-satoshiBold">
                                                        AED{" "}
                                                        {
                                                            cart?.total
                                                                ?.sample_coll_charge
                                                        }
                                                    </p>
                                                </div>

                                                {parseInt(
                                                    cart.total.promotion || 0
                                                ) !== 0 && (
                                                        <div className="flex justify-between items-center text-secondaryLight text-[16px] font-satoshiMedium mt-2">
                                                            <p>
                                                                Congrats! You got
                                                                Discount code of{" "}
                                                                &nbsp;
                                                                <span className="bg-Verdigris p-1 rounded text-white font-satoshiMedium">
                                                                    {
                                                                        cart.coupon
                                                                            .type
                                                                            .value
                                                                    }
                                                                    {cart.coupon.type.type.toLowerCase() ===
                                                                        "percentage"
                                                                        ? " % "
                                                                        : " "}
                                                                    OFF
                                                                </span>{" "}
                                                            </p>{" "}
                                                            <p className="font-satoshiBold">
                                                                {"- "}
                                                                AED{" "}
                                                                {
                                                                    cart?.total
                                                                        ?.promotion
                                                                }
                                                            </p>
                                                        </div>
                                                    )}

                                                <div className="border my-2" />
                                                <div className="flex justify-between items-center text-blueShadow">
                                                    <p className="text-[16px] font-satoshiMedium">
                                                        Total Price
                                                    </p>{" "}
                                                    <p className="text-[20px] font-satoshiBold">
                                                        {" "}
                                                        AED{" "}
                                                        {cart?.total?.total}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                {cart?.step_index == 5 && (
                                    <>
                                        {!isBookingLoading && (
                                            <>
                                                <div className="paymentBtnShadow">
                                                    <CustomButton
                                                        borderRadius="0.5rem"
                                                        variant="secondary-contained"
                                                        width="w-full mt-[16px] "
                                                        onClick={
                                                            bookPackageRequest
                                                        }
                                                    >
                                                        {cart.mode_of_payment ===
                                                            "1" ? (
                                                            <>
                                                                Pay{" "}
                                                                {cart?.currency}{" "}
                                                                {
                                                                    cart?.total
                                                                        ?.total
                                                                }
                                                            </>
                                                        ) : (
                                                            <>{"Proceed"}</>
                                                        )}
                                                    </CustomButton>
                                                </div>

                                                <br />
                                            </>
                                        )}

                                        {isBookingLoading && (
                                            <>
                                                <div className="paymentBtnShadow">
                                                    <CustomButton
                                                        borderRadius="0.5rem"
                                                        variant="secondary-contained"
                                                        width="w-full mt-[16px] "
                                                    >
                                                        Loading...
                                                    </CustomButton>
                                                </div>

                                                <br />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        {/* For Tab & Mobile */}

                        {content === "stepper" && (
                            <div
                                className={`lg:hidden block bg-white lg:h-[100vh] lg:w-[50%] lg:pt-[140px] pt-[14px] mt-[5px]`}
                            >
                                <div className=" lg:w-[568px] m-auto mt-5">
                                    <CustomStepper
                                        activeStep={activeStep}
                                        steps={["", "", "", "", ""]}
                                    />

                                    <div className="flex flex-col justify-center items-center mt-5 ">
                                        {activeStep === 1 && (
                                            <UserVerification
                                                setActiveStep={setActiveStep}
                                            />
                                        )}
                                        {activeStep === 2 && (
                                            <Patients
                                                setActiveStep={setActiveStep}
                                            />
                                        )}
                                        {activeStep === 3 && (
                                            <Address
                                                setActiveStep={setActiveStep}
                                            />
                                        )}

                                        {activeStep === 4 && (
                                            <SampleDateTime
                                                setActiveStep={setActiveStep}
                                            />
                                        )}
                                        {activeStep === 5 && (
                                            <Confirmation
                                                handleChange={handleChange}
                                                bookPackageRequest={
                                                    bookPackageRequest
                                                }
                                                isBookingLoading={
                                                    isBookingLoading
                                                }
                                                setActiveStep={setActiveStep}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {content === "summary" && (
                            <div
                                className={`lg:hidden block lg:w-[50%] h-[100vh] lg:pt-[140px] pt-[14px] overflow-y-auto bg-[#EBEEF2]`}
                            >
                                <div className="lg:w-[568px] md:w-[704px] w-[344px] m-auto ">
                                    <>
                                        <div className={`my-[16px]`}>
                                            <Accordion
                                                defaultExpanded
                                                className={classes.root}
                                                elevation={0}
                                                sx={{ padding: "10px" }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <img
                                                            className="rotate-180 "
                                                            src='/images/icons/arrowCircle.svg'
                                                            alt='arrow-circle'
                                                        />
                                                    }
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className={classes.summary}
                                                    classes={{
                                                        content:
                                                            classes.content,
                                                    }}
                                                >
                                                    <AccordionTitle title="Package Information" />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="p-3 bg-[#F4F7FA] rounded-lg">
                                                        {cart.patient_list
                                                            .length ? (
                                                            <div className="bg-[#ffffff] p-3 rounded-lg ">
                                                                <>
                                                                    {cart.patient_list.map(
                                                                        (
                                                                            x,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="flex justify-between items-center ">
                                                                                        <div className="">
                                                                                            <p className="text-blueShadow lg:text-[18px] text-[16px] font-satoshiBold ">
                                                                                                {
                                                                                                    x?.name
                                                                                                }{" "}
                                                                                                |{" "}
                                                                                                <span className="text-[14px] font-satoshiMedium">
                                                                                                    {
                                                                                                        x.relation
                                                                                                    }
                                                                                                </span>
                                                                                            </p>
                                                                                            <p className="text-secondaryLight text-[12px] font-satoshiMedium">
                                                                                                {
                                                                                                    x?.gender
                                                                                                }

                                                                                                ,{" "}
                                                                                                {
                                                                                                    x.age
                                                                                                }{" "}
                                                                                                years
                                                                                            </p>
                                                                                        </div>
                                                                                        <p
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                removePatient(
                                                                                                    e,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            className="text-blueShadow font-satoshiBold text-[14px] border-b-2 border-blueShadow  w-max-fit cursor-pointer relative bottom-[10px]"
                                                                                        >
                                                                                            Remove
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="border my-3" />
                                                                                    {x?.items?.map(
                                                                                        (
                                                                                            y,
                                                                                            sub_index
                                                                                        ) => (
                                                                                            <div className="flex flex-row justify-between items-center bg-[#F4F7FA] p-[16px] rounded-lg my-2">
                                                                                                <div>
                                                                                                    <p className="text-blueShadow lg:text-[14px] text-[12px] font-satoshiMedium">
                                                                                                        <span className="text-[#363636]">
                                                                                                            {sub_index +
                                                                                                                1}

                                                                                                            .
                                                                                                        </span>{" "}
                                                                                                        {
                                                                                                            y?.name
                                                                                                        }
                                                                                                    </p>
                                                                                                    <p className="text-Verdigris text-[14px] font-satoshiBold">
                                                                                                        <span className="line-through text-secondaryLight text-[12px] font-satoshiMedium">
                                                                                                            {
                                                                                                                cart.currency
                                                                                                            }{" "}
                                                                                                            {
                                                                                                                y.mark_up_price
                                                                                                            }
                                                                                                        </span>{" "}
                                                                                                        {
                                                                                                            cart.currency
                                                                                                        }{" "}
                                                                                                        {
                                                                                                            y.price
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                                <img
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        removePatientTest(
                                                                                                            e,
                                                                                                            index,
                                                                                                            sub_index
                                                                                                        )
                                                                                                    }
                                                                                                    className="cursor-pointer relative bottom-[10px]"
                                                                                                    src='/images/icons/removeIcon.svg'
                                                                                                    alt="Remove Patient Test"
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        }
                                                                    )}

                                                                    <div className="flex justify-end ">
                                                                        <Link to="/packages">
                                                                            <p className="text-blueShadow font-satoshiBold border-b-2 border-blueShadow  w-max-fit text-[14px] cursor-pointer mt-[8px]">
                                                                                +Add
                                                                                more
                                                                                test
                                                                                or
                                                                                Package
                                                                            </p>
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {!cart.patient_list
                                                            .length &&
                                                            cart.items.length ? (
                                                            <div className="bg-[#ffffff] p-3 rounded-lg ">
                                                                <>
                                                                    {cart.items.map(
                                                                        (
                                                                            y,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <>
                                                                                    <div className="flex flex-row justify-between items-center bg-[#F4F7FA] p-[16px] rounded-lg my-2 ">
                                                                                        <div>
                                                                                            <p className="text-blueShadow lg:text-[14px] text-[12px] font-satoshiMedium">
                                                                                                <span className="text-[#363636]">
                                                                                                    {index +
                                                                                                        1}

                                                                                                    .
                                                                                                </span>{" "}
                                                                                                {
                                                                                                    y?.name
                                                                                                }
                                                                                            </p>
                                                                                            <p className="text-Verdigris text-[14px] font-satoshiBold">
                                                                                                <span className="line-through text-secondaryLight text-[12px] font-satoshiMedium">
                                                                                                    {
                                                                                                        cart.currency
                                                                                                    }{" "}
                                                                                                    {
                                                                                                        y.mark_up_price
                                                                                                    }
                                                                                                </span>{" "}
                                                                                                {
                                                                                                    cart.currency
                                                                                                }{" "}
                                                                                                {
                                                                                                    y.price
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                        <img
                                                                                            onClick={(
                                                                                                e
                                                                                            ) =>
                                                                                                removeTest(
                                                                                                    e,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            className="cursor-pointer relative bottom-[10px]"
                                                                                            src='/images/icons/removeIcon.svg'
                                                                                            alt='Remove Test'
                                                                                        />
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }
                                                                    )}

                                                                    <div className="flex justify-end ">
                                                                        <Link to="/packages">
                                                                            <p className="text-blueShadow font-satoshiBold border-b-2 border-blueShadow  w-max-fit text-[14px] cursor-pointer mt-[8px]">
                                                                                +Add
                                                                                more
                                                                                test
                                                                                or
                                                                                Package
                                                                            </p>
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                    <div className="flex justify-center items-center">
                                                        <button
                                                            onClick={
                                                                patientOpen
                                                            }
                                                            className="font-satoshiBold text-base text-center my-2 text-Verdigris underline cursor-pointer"
                                                        >
                                                            +Add New Patient
                                                        </button>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>

                                        {(Object.values(cart?.address).length ||
                                            cart.sample_collection_date_time) && (
                                                <div className={`my-[16px] `}>
                                                    <Accordion
                                                        defaultExpanded
                                                        className={classes.root}
                                                        elevation={0}
                                                        sx={{
                                                            padding: "10px",
                                                            marginTop: "8px",
                                                        }}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={
                                                                <img
                                                                    className="rotate-180 "
                                                                    src='/images/icons/arrowCircle.svg'
                                                                    alt='arrow-circle'
                                                                />
                                                            }
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            className={
                                                                classes.summary
                                                            }
                                                            classes={{
                                                                content:
                                                                    classes.content,
                                                            }}
                                                        >
                                                            <AccordionTitle title="Sample Collection Information" />
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="bg-[#F4F7FA] p-3 rounded-md mb-5">
                                                                <div>
                                                                    <div className="flex flex-row gap-2">
                                                                        {" "}
                                                                        <img
                                                                            src='/images/icons/loc_pin.svg'
                                                                            alt="Location"
                                                                        />{" "}
                                                                        <p className="text-blueShadow font-satoshiBold text-[14px]">
                                                                            {" "}
                                                                            Location
                                                                        </p>
                                                                    </div>
                                                                    <div className="border" />

                                                                    <div className="p-3">
                                                                        <p className="text-blueShadow font-satoshiBold lg:text-[18px] text-[16px]">
                                                                            {
                                                                                cart
                                                                                    ?.address
                                                                                    ?.location_type
                                                                            }
                                                                        </p>
                                                                        <p className="text-secondaryLight font-satoshiMedium text-[14px]">
                                                                            {
                                                                                cart
                                                                                    ?.address
                                                                                    ?.house_no
                                                                            }
                                                                            ,{" "}
                                                                            {
                                                                                cart
                                                                                    ?.address
                                                                                    ?.building_name
                                                                            }
                                                                            ,{" "}
                                                                            {
                                                                                cart
                                                                                    ?.address
                                                                                    ?.locality
                                                                            }
                                                                            ,
                                                                            {
                                                                                cart
                                                                                    ?.address
                                                                                    ?.city
                                                                            }
                                                                            .
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {cart.sample_collection_date_time ? (
                                                                <div className="bg-[#F4F7FA] p-3 rounded-md mb-5">
                                                                    <div>
                                                                        <div className="flex flex-row gap-2">
                                                                            {" "}
                                                                            <img
                                                                                src='/images/icons/sample_icon.svg'
                                                                                alt="sample"
                                                                            />{" "}
                                                                            <p className="text-blueShadow font-satoshiBold text-[14px]">
                                                                                {" "}
                                                                                Sample
                                                                                Collection
                                                                                time
                                                                            </p>
                                                                        </div>
                                                                        <div className="border" />

                                                                        <div className="p-3">
                                                                            <p className="text-blueShadow font-satoshiBold lg:text-[18px] md:text-[16px] text-[16px] flex items-center">
                                                                                {moment(
                                                                                    cart.sample_collection_date_time
                                                                                ).format(
                                                                                    "DD-MM-YYYY |"
                                                                                )}{" "}
                                                                                <span className="text-secondaryLight pl-2  text-[14px] font-satoshiMedium">
                                                                                    {
                                                                                        cart.samp_col_time_slot
                                                                                    }
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            )}

                                        <div
                                            className={`bg-white rounded-lg my-[16px]`}
                                        >
                                            <div className="border-b p-5">
                                                <AccordionTitle title="Billing Information" />
                                            </div>
                                            <div className="p-5">
                                                <div className="p-5 bg-[#F4F7FA] rounded-md">
                                                    <div className="flex justify-between items-center text-secondaryLight text-[16px] font-satoshiMedium">
                                                        <p>Sub Total</p>{" "}
                                                        <p className="font-satoshiBold">
                                                            AED{" "}
                                                            {
                                                                cart?.total
                                                                    ?.subtotal
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="flex pt-2 justify-between items-center text-secondaryLight text-[16px] font-satoshiMedium">
                                                        <p>
                                                            {" "}
                                                            Sample Collection
                                                            Charges
                                                        </p>{" "}
                                                        <p className="font-satoshiBold">
                                                            AED{" "}
                                                            {
                                                                cart?.total
                                                                    ?.sample_coll_charge
                                                            }
                                                        </p>
                                                    </div>

                                                    {parseInt(
                                                        cart.total.promotion ||
                                                        0
                                                    ) !== 0 && (
                                                            <div className="flex justify-between items-center text-secondaryLight text-[16px] font-satoshiMedium mt-2">
                                                                <p>
                                                                    Congrats! You
                                                                    got Discount
                                                                    code of &nbsp;
                                                                    <span className="bg-Verdigris p-1 rounded text-white font-satoshiMedium">
                                                                        {
                                                                            cart
                                                                                .coupon
                                                                                .type
                                                                                .value
                                                                        }
                                                                        {cart.coupon.type.type.toLowerCase() ===
                                                                            "percentage"
                                                                            ? " % "
                                                                            : " "}{" "}
                                                                        &nbsp; OFF
                                                                    </span>{" "}
                                                                </p>{" "}
                                                                <p className="font-satoshiBold">
                                                                    {""}
                                                                    {
                                                                        cart?.currency_symbol
                                                                    }{" "}
                                                                    {
                                                                        cart?.total
                                                                            ?.promotion
                                                                    }
                                                                </p>
                                                            </div>
                                                        )}

                                                    <div className="border my-2" />
                                                    <div className="flex justify-between items-center text-blueShadow">
                                                        <p className="text-[16px] font-satoshiMedium">
                                                            Total Price
                                                        </p>{" "}
                                                        <p className="text-[20px] font-satoshiBold">
                                                            {" "}
                                                            AED{" "}
                                                            {cart?.total?.total}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    {cart?.step_index == 5 && (
                                        <>
                                            {!isBookingLoading && (
                                                <>
                                                    <div className="paymentBtnShadow">
                                                        <CustomButton
                                                            borderRadius="0.5rem"
                                                            variant="secondary-contained"
                                                            width="w-full mt-[16px] "
                                                            onClick={
                                                                bookPackageRequest
                                                            }
                                                        >
                                                            {cart.mode_of_payment ===
                                                                "1" ? (
                                                                <>
                                                                    Pay{" "}
                                                                    AED{" "}
                                                                    {
                                                                        cart
                                                                            ?.total
                                                                            ?.total
                                                                    }
                                                                </>
                                                            ) : (
                                                                <>{"Proceed"}</>
                                                            )}
                                                        </CustomButton>
                                                    </div>

                                                    <br />
                                                </>
                                            )}

                                            {isBookingLoading && (
                                                <>
                                                    <div className="paymentBtnShadow">
                                                        <CustomButton
                                                            borderRadius="0.5rem"
                                                            variant="secondary-contained"
                                                            width="w-full mt-[16px] "
                                                        >
                                                            Loading...
                                                        </CustomButton>
                                                    </div>

                                                    <br />
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {/* Basic Details Modal */}
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        disableEnforceFocus
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Box
                            sx={style}
                            style={{
                                textAlign: "center",
                                borderRadius: "24px",
                                outline: "none",
                            }}
                        >
                            <div className="flex flex-col items-center lg:py-10 py-5 lg:w-[514px] md:w-[450px] w-[343px]">
                                <>
                                    <p className="text-blueShadow font-satoshiBold lg:text-[26px] md:text-2xl text-base text-center">
                                        Enter Your Details
                                    </p>

                                    <div className="lg:mt-[37px] mt-[22px] flex flex-col gap-[24px] w-full px-8">
                                        <Input
                                            rows={1}
                                            width="w-full"
                                            disabled={false}
                                            readOnly={false}
                                            handleChange={handleChange}
                                            value={params?.name}
                                            error={formErrors?.name}
                                            helperText={formErrors?.name}
                                            label="Your Name*"
                                            name="name"
                                        />
                                        <Input
                                            rows={1}
                                            width="w-full"
                                            disabled={false}
                                            readOnly={false}
                                            handleChange={handleChange}
                                            value={params?.email}
                                            error={formErrors?.email}
                                            helperText={formErrors?.email}
                                            label="Email ID*"
                                            name="email"
                                        />
                                        <SelectInput
                                            readonly={false}
                                            width="w-full"
                                            handleChange={handleChange}
                                            options={genderOptions}
                                            label="Gender*"
                                            name="gender"
                                            value={params?.gender}
                                            error={formErrors?.gender}
                                            helperText={formErrors?.gender}
                                        />
                                        <CommonDatepicker
                                            label="Date of Birth*"
                                            value={params?.dob}
                                            error={formErrors?.dob}
                                            helperText={formErrors?.dob}
                                            onChange={onDateChange}
                                        />

                                        <div className='w-full'>
                                            <SelectNationality
                                                error={formErrors?.nationality}
                                                helperText={formErrors?.nationality}
                                                value={params?.nationality}
                                                captureNationality={captureNationality} />
                                        </div>
                                    </div>

                                    <div className="flex lg:flex-row flex-col justify-center items-center gap-[21px] mt-[20px]"></div>
                                    <CustomButton
                                        onClick={handleUpdate}
                                        width="w-[250px] "
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        size="large"
                                        height={buttonHeight}
                                    >
                                        Submit Details
                                    </CustomButton>
                                </>
                            </div>
                        </Box>
                    </Modal>

                    {/* +Add New Patient Modal */}
                    <Modal
                        open={patientModal}
                        onClose={patientClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            sx={{
                                ...style,
                                width: {
                                    lg: "543px",
                                    sm: "450px",
                                    xs: "343px",
                                },
                            }}
                            style={{
                                textAlign: "center",
                                borderRadius: "16px",
                                outline: "none",
                            }}
                        >
                            <p className="font-jostRegular font-bold text-[#363636] text-[26px] lg:pt-[32px] pt-[23px]">
                                {" "}
                                Add New Member
                            </p>
                            <br />
                            <div className="h-[400px] overflow-auto">
                                <div className=" flex flex-col justify-center items-center mt-2 ">
                                    <Input
                                        rows={1}
                                        width=" lg:w-[479px] md:w-[418px] w-[311px]"
                                        disabled={false}
                                        readOnly={false}
                                        handleChange={handlePatientChange}
                                        value={patientParams?.name}
                                        error={patientErrors?.name}
                                        helperText={patientErrors?.name}
                                        label="Name"
                                        name="name"
                                    />
                                    <PhoneInput
                                        rows={1}
                                        width="lg:w-[479px] md:w-[418px] w-[311px] mt-5"
                                        disabled={false}
                                        readOnly={false}
                                        handleChange={handlePatientChange}
                                        value={patientParams?.phone}
                                        error={patientErrors?.phone}
                                        helperText={patientErrors?.phone}
                                        label="Contact No*"
                                        name="phone"
                                    />

                                    <Input
                                        rows={1}
                                        width="lg:w-[479px] md:w-[418px] w-[311px] mt-5"
                                        disabled={false}
                                        readOnly={false}
                                        handleChange={handlePatientChange}
                                        value={patientParams?.email}
                                        error={patientErrors?.email}
                                        helperText={patientErrors?.email}
                                        label="Email"
                                        name="email"
                                    />
                                    <div className='flex flex-row items-center mt-2 justify-center gap-2 w-[88%]'>
                                        <SelectInput
                                            width='w-full text-left mt-1'
                                            handleChange={handlePatientChange}
                                            options={genderOptions}
                                            label="Gender"
                                            name="gender"
                                            value={patientParams?.gender}
                                            error={patientErrors?.gender}
                                            helperText={patientErrors?.gender}
                                        />
                                        <div className=" w-full">
                                            <CommonDatepicker
                                                label="Date of Birth"
                                                value={patientParams?.dob}
                                                onChange={onPatientDate}
                                                error={patientErrors?.dob}
                                                helperText={patientErrors?.dob}
                                            />
                                        </div>
                                    </div>


                                    <Input
                                        rows={1}
                                        width=" lg:w-[479px] md:w-[418px] w-[311px] mt-5 mb-5"
                                        disabled={false}
                                        readOnly={false}
                                        handleChange={handlePatientChange}
                                        value={patientParams?.relation}
                                        error={patientErrors?.relation}
                                        helperText={patientErrors?.relation}
                                        label="Relation"
                                        name="relation"
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col justify-center items-center lg:mb-[47px] md:mb-[29px] ">
                                <CustomButton
                                    borderRadius="0.5rem"
                                    variant="secondary-contained"
                                    width="w-[250px] h-[56px]"
                                    onClick={handlePatientSubmit}
                                >
                                    Submit
                                </CustomButton>

                                <p
                                    // onClick={patientClose}
                                    onClick={() => {
                                        setPatientParams({
                                            name: "",
                                            email: "",
                                            dob: "",
                                            phone: "",
                                            gender: "",
                                            age: 0,
                                            relation: "",
                                        });
                                        setPatientErrors({});
                                        patientClose();
                                    }}
                                    className="underline text-blueShadow font-bold text-[16px] text-center cursor-pointer lg:mt-[27px] mt-[27px]"
                                >
                                    Cancel
                                </p>
                            </div>
                        </Box>
                    </Modal>

                    <Modal
                        open={isOpenOtpModal}
                        onClose={handleOtpModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            className="md:w-[420px] w-[320px] p-6"
                            sx={style}
                            style={{
                                textAlign: "center",
                                borderRadius: "16px",
                                outline: "none",
                                // padding: '10px',
                            }}
                        >
                            <div className="flex justify-end ">
                                <span
                                    className="cursor-pointer"
                                    onClick={handleOtpModalClose}
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                            stroke="#3C567E"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M9.17004 14.8299L14.83 9.16992"
                                            stroke="#3C567E"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M14.83 14.8299L9.17004 9.16992"
                                            stroke="#3C567E"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </div>
                            <div className="text-left">
                                <p className="text-Verdigris mt-1 md:text-3xl text-2xl font-jostSemibold">
                                    Verify Order Confirm OTP
                                </p>
                                <p className="text-BlueJay mt-2 text-sm text-center md:text-left">
                                    In Order to Comfirm Your Order Please Verify
                                    OTP, Please Enter the One time password (OTP)
                                    sent to: <br className="hidden md:block" />{" "}
                                    <strong>{` ${cart?.user?.phone}`}</strong>
                                </p>
                            </div>
                            <div className="mt-4 text-center">
                                <div className="flex md:flex-row flex-col justify-center items-center gap-2">
                                    <OtpInput
                                        value={param.otp}
                                        onChange={handleOtp}
                                        numInputs={5}
                                        renderSeparator={<span>&nbsp;&nbsp;</span>}
                                        renderInput={(props) => <input {...props} />}
                                        inputStyle={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid #33B0AA",
                                            color: "black",
                                        }}
                                        errorStyle={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "6px",
                                            border: "1px solid red",
                                            color: "black",
                                        }}
                                        isInputNum
                                        hasErrored={errors?.otp}
                                    />
                                </div>
                                {errors?.otp && (
                                    <p className="text-left text-xs text-[#EF4949] mt-1">
                                        OTP field is required.
                                    </p>
                                )}

                                <div className="mt-3">
                                    <CustomButton
                                        disabled={isLoading}
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        width="w-full"
                                        onClick={handleOTPSubmit}
                                    >
                                        Submit
                                    </CustomButton>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </div>
            )}
        </>
    );
}

export default OrderCheckout;
