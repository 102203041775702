import React from 'react'
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomButton from "./../common/Button";
import axiosInstance from '../../helpers/axios'
import { updateCart } from "../../features/cart/cartSlice";

const RightArrow = () => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 3.4641C33.7128 1.32051 38.2872 1.32051 42 3.4641L61.1769 14.5359C64.8897 16.6795 67.1769 20.641 67.1769 24.9282V47.0718C67.1769 51.359 64.8897 55.3205 61.1769 57.4641L42 68.5359C38.2872 70.6795 33.7128 70.6795 30 68.5359L10.8231 57.4641C7.11027 55.3205 4.82309 51.359 4.82309 47.0718V24.9282C4.82309 20.641 7.11027 16.6795 10.8231 14.5359L30 3.4641Z" fill="#33B0AA" />
            <path d="M33.57 29.9297L27.5 35.9997L33.57 42.0697" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44.5 36H27.67" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const PopularPackageCard = ({ data, handleSubmit }) => {
    return (
        <div className='flex lg:flex-row flex-col lg:w-[1033px] md:w-[75%] w-[90%] m-auto items-start mt-10 bg-white rounded-3xl shadow-lg my-5 border lg:border-none border-[#d9d9d9] lg:h-[90%] md:h-fit h-fit p-5 gap-5'>

            <div className='lg:w-9/12 w-full lg:h-full md:h-[30vh] h-full'>
                <img src={data.image ? data.image : '/images/package.webp'}
                    alt="random_image"
                    className='rounded-3xl w-full h-full object-cover' />
            </div>


            <div className='flex flex-col lg:w-full md:w-full m-auto h-full'>

                <div className='p-5 border border-[#d9d9d9] rounded-lg mb-2'>
                    <p className='font-satoshiBold text-blueShadow text-lg'>{data.name}</p>
                    <p className='text-sm  lg:text-base  lg:text-ellipsis font-medium text-CadetGrey'>{data.description.slice(0,150)}</p>

                    <Link
                        to={`/packages/${data?.slug}`}>
                        <button className='font-satoshiBold text-blueShadow text-base'>Know more</button>
                    </Link>
                </div>

                <div
                    className={`${data.test_package_groups
                        .length > 10
                        ? "lg:h-[270px]"
                        : "lg:h-max-fit"
                        } md:h-[180px] h-[230px] p-2 sm:pr-2 lg:pr-2 lg:grid-cols-3 md:grid-cols-3 grid grid-cols-2 gap-2 sm:gap-x-6 sm:gap-y-6 lg:overflow-hidden overflow-auto`}
                >
                    {data.test_package_groups.map(
                        (group) => {
                            return (
                                <div className="px-6 rounded-xl bg-white border border-[#d9d9d9] flex justify-center items-center h-[70px]">
                                    <p className="text-xs text-left sm:text-center sm:text-sm font-satoshiMedium text-BlueJay ">
                                        {group?.name}
                                    </p>
                                </div>
                            );
                        }
                    )}
                </div>

                <div className="lg:my-2 flex sm:gap-0 gap-y-4  flex-row items-center justify-between px-[9px] ">

                    <div className=" flex flex-col justify-center sm:justify-start   text-DarkGrey  items-center">
                        <p className="line-through  text-sm sm:text-base font-normal sm:font-medium">
                            AED {data?.markup_price}
                        </p>
                        <p className="lg:text-xl text-lg lg:font-bold font-medium font-satoshiBold text-cyanHeading">
                            AED {data?.price}
                        </p>
                    </div>

                    <div className="flex  gap-8  lg:gap-12  justify-center sm:justify-end  items-center lg:my-0 my-2">

                        <CustomButton
                            width="w-32 lg:w-48"
                            borderRadius="0.5rem"
                            variant="secondary-contained"
                            size="large"
                            height="56px"
                            onClick={(e) =>
                                handleSubmit(e, data)}
                        >
                            Book Now
                        </CustomButton>
                    </div>
                </div>

            </div>

        </div>
    )
}


const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
        <li
            className={active ? "mx-2" : "opacity-60 mx-2"}
            onClick={() => onClick()}
        >
            <div className={`h-[10px] w-[70px] rounded ${active ? 'bg-blueShadow' : 'bg-[#D5E4E9]'} cursor-pointer`}>
            </div>
        </li>
    );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="lg:block md:block hidden">
            <button
                className='absolute lg:left-10 left-5 top-[45%]'
                onClick={() => previous()}>
                <RightArrow />
            </button>
            <button
                className='absolute  lg:right-10 right-5 top-[45%]  rotate-180'
                onClick={() => next()}>
                <RightArrow />
            </button>
        </div>

    );
};


const MultiPackagesSlider = ({ content, handleAddToCartOpen }) => {
    const dispatch = useDispatch();

    function handleSubmit(e, item) {
        e.preventDefault();

        axiosInstance
            .post("/cart", {
                test_id: item.id,
            })
            .then((response) => {
                dispatch(updateCart(response.data));
                handleAddToCartOpen();
            })
            .catch((error) => {
                const fieldErrors = {};
            });
    }

    return (
        <div className='m-auto relative'>
            <Carousel
                arrows={false}
                draggable={false}
                infinite
                className=""
                itemClass=""
                dotListClass=""
                additionalTransfrom={0}
                customDot={<CustomDot />}
                centerMode={false}
                renderButtonGroupOutside={true}
                customButtonGroup={true ? <ButtonGroup /> : <></>}
                containerClass="container-with-dots"
                focusOnSelect={false}
                keyBoardControl={false}
                minimumTouchDrag={80}
                pauseOnHover={false}
                renderArrowsWhenDisabled={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 1,
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 1,
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                shouldResetAutoplay={false}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable={true}
            >
                {
                    content.map(item => (
                        <PopularPackageCard data={item} handleSubmit={handleSubmit} />
                    ))
                }
            </Carousel>
        </div>
    )
}

export default MultiPackagesSlider