import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomButton from "../../common/Button";
import Accordion from "@mui/material/Accordion";
import BreadCrumb from "../../common/BreadCrumb";
import axiosInstance from "../../../helpers/axios";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { updateCart } from "../../../features/cart/cartSlice";
import { TitleCase } from "../../../helpers/helpers";
import GetACall from "./GetACall";
import updateMeta from "../../../helpers/seo-meta";
import useGtag from '../../../helpers/gtag';

const addToCartStyles = {
    position: "absolute" ,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 4,
};

const SEO_META_DATA = [
    {
        name:"NIPT Noninvasive Prenatal Test",
        slug:"nipt-noninvasive-prenatal-test",
        description:"NIPT screens maternal blood for fetal DNA aneuploidy, offering genome-wide detection of Trisomy 13, 18, and 21 - a comprehensive prenatal test",
        keywords:"home health care, home health, care homes, healthcare at home, home caring, nipt test"
    },
    {
        name:"Food & Inhalant Allergy Test",
        slug:"food-and-inhalant-allergy-test",
        description:"Food & Inhalant Allergy Test is an IgE based allergy panel test including common Food and Inhalant agents related to allergies.",
        keywords:"food and inhalant allergy test, food allergy test, food sensitivity test, food intolerance test, at home food sensitivity test"
    },
    {
        name:"Comprehensive Wellness Checkup",
        slug:"comprehensive-wellness-checkup-test",
        description:"Comprehensive Wellness Checkup consists of timely screening can help you avoid illnesses, you can keep a track of your health.",
        keywords:"health checkup packages, health screening package, complete body checkup, health check up, health packages"
    },
    {
        name:"Senior Men Test",
        slug:"senior-men-health-test",
        description:"The elderly health checkup package is specially designed for elderly/senior men who are more prone to heart, liver, kidney, and other functional issues including prostate cancer screening.",
        keywords:"health checkup in dubai, senior test, senior men, men health test, health checkup packages dubai"
    },
    {
        name:"Women Hormone Profile",
        slug:"women-hormone-profile",
        description:"Women's hormonal balance affects menstrual cycles, fertility, mood, and overall health.",
        keywords:"female hormone profile test, women hormone test, female hormone"
    },
    {
        name:"Post Covid Test",
        slug:"post-covid-test",
        description:"This recommendation is offered to all COVID-19 survivors who may not have any symptoms and are seeking reassurance on their health and well-being",
        keywords:"post covid test, post covid check up, pcr test after having covid, post covid blood tests, post covid antibody test"
    },
    {
        name:"Hairfall Advance Test",
        slug:"hair-loss-advance-test",
        description:"Understand the cause of your hairfall along with other parameters which may be contributing to hairfall.",
        keywords:"home test, home test dubai, hairfall advance test, hairfall advance"
    },
    {
        name:"Hairfall Test Package",
        slug:"hairfall-test-package",
        description:"Discover the cause of hairfall with this blood test, analyzing crucial body functions for healthy hair growth and maintenance.",
        keywords:"blood test, diagnostic test, hairfall, hairfall package"
    },
    {
        name:"Arthritis 299 Test",
        slug:"arthritis-299-test",
        description:"Arthritis Screening Checkup is ideal for those with joint pain, family history of joint issues. Early detection for better management.",
        keywords:"blood test, diagnostic test, arthritis 299 test, arthritis 299 diagnosis"
    },
    {
        name:"Advance Diabetes Checkup",
        slug:"advance-diabetes-checkup",
        description:"Monitor and manage your diabetes, detect complications early, and reduce the risk of fatal outcomes.",
        keywords:"advance diabetes test, hba1c test, hba1c, glucose test"
    },
    {
        name:"Diabetes Package",
        slug:"diabetes-package",
        description:"Assess risk of developing diabetes, monitor condition, track progress, detect complications early, reduce risk of fatal outcomes.",
        keywords:"diabetes test, hba1c test, hba1c, glucose test"
    },
    {
        name:"Antenatal Package",
        slug:"antenatal-package",
        description:"Antenatal Package includes all the routine test needed during the pregnancy.",
        keywords:"antenatal package, antenatal test package, antenatal diagnosis, antenatal test"
    },
    {
        name:"Anemia Profile Advance",
        slug:"anemia-profile-advance",
        description:"Feeling tired, weak, or dizzy? It might be anemia. Our package detects anemia types and underlying causes for effective diagnosis.",
        keywords:"anemia profile advance checkup, anemia test, anemia profile test, hemoglobin blood test"
    },
    {
        name:"Wellness Health Checkup",
        slug:"wellness-health-checkup",
        description:"Essential Wellness Screening: Ideal for all ages, establish health baseline or monitor ongoing wellness with our comprehensive lab test.",
        keywords:"wellness checkup, health checkup, full body checkup, full body checkup near me, health checkup packages, preventive health checkup"
    }
]

const useStyles = makeStyles(() => ({
    hideBorder: {
        "&.MuiExpansionPanel-root:before": {
            display: "none",
        },
    },

    root: {
        "&:before": {
            display: "none",
            backgroundColor: "#fcfcfc",
        },

        backgroundColor: "#fcfcfc !important",
        borderRadius: "12px !important",
    },
    details: {
        margin: "10px",
        backgroundColor: "#fcfcfc",
        height: "90px",
        borderRadius: "12px !important",
    },

    summary: {
        height: "70px",
        color: "#3C567E",
        backgroundColor: "#fcfcfc !important",
        borderRadius: "12px !important",
    },
    icon: {
        pointerEvents: "auto",
    },
}));

const View = ({  }) => {
    let { slug } = useParams();
    const gtag = useGtag();
    useEffect(() => {
        document.title = 'Zain Care | Delivering High Quality Healthcare at your doorsteps in UAE';
        let meta = SEO_META_DATA.find(x=>x.slug === slug)
                if(meta){
                    document.title = `${meta.name} | Zain Care - Delivering High Quality Healthcare`
                    updateMeta('description', meta.description);
                    updateMeta('keywords', meta.keywords);    
               
                }else {
                    updateMeta('description', `Experience smart, affordable home healthcare solutions that are compassionate and accessible with ZainCare.`);
                    updateMeta('keywords', `blood test at home dubai, blood test at home, blood test home collection, blood sample collection from home, lab test home collection`);    
               
                }
        }, []);

    const [info, setInfo] = useState({} );

    const getInformation = () => {
        axiosInstance
            .get(`/packages/${slug}`)
            .then((response) => {
                console.log(response)
                let thisData = response.data.data;
                setInfo(thisData);
                let meta = SEO_META_DATA.find(x=>x.slug === slug)
                if(meta){
                    document.title = `${meta.name} | Zain Care - Delivering High Quality Healthcare`
                    updateMeta('description', meta.description);
                    updateMeta('keywords', meta.keywords);    
               
                }else {
                document.title = `${thisData.name} | Zain Care - Delivering High Quality Healthcare`
                updateMeta('description', thisData.description);

                }
                setTestParam(thisData.test_packages);
            })
            .catch((error) => {
            });
    };

    useEffect(() => {
        getInformation();
    }, [slug]);

    const [test_params, setTestParam] = useState([] );


    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);

    const handleAccordion =
        (panel) =>
            (event, isExpanded) => {
                setExpanded(isExpanded ? panel : false);
            };

    const [addToCart, setAddtoCart] = useState(false);
    const handleAddToCartOpen = () => setAddtoCart(true);
    const handleAddToCartClose = () => setAddtoCart(false);

    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    function handleSubmit(e, item) {
        e.preventDefault();
        setLoading(true);
        gtag('event', 'conversion', {
            'send_to': 'AW-11437476569/9wD7CO_9h4gZENmV6M0q',
            'value': 1.0,
            'currency': 'AED',
        });
        axiosInstance
            .post("/cart", {
                test_id: item.id,
            })
            .then((response) => {
                dispatch(updateCart(response.data));
                setLoading(false);
                handleAddToCartOpen();
            })
            .catch((error) => {
                const fieldErrors = {};
                setLoading(false);
            });
    }

    const cart = useSelector((state) => state.cart);

    return (
        <div className="">
            <div className="packages_bg">
                <div className="lg:pt-[150px] md:pt-[150px] pt-[100px] lg:mb-1 md:mb-1 lg:w-[1440px] m-auto">
                    <div className="px-10">
                        <BreadCrumb
                            links={[
                                { path: "Home", url: "/" },
                                {
                                    path: "Test & Packages",
                                    url: "/packages",
                                },
                                { path: info.name ?? "", url: "#" },
                            ]}
                        />
                    </div>

                    <div className="flex lg:flex-row md:flex-row lg:px-[100px] flex-col lg:justify-between lg:w-[1290px] m-auto md:justify-center md:items-center lg:items-center lg:gap-[146px] md:gap-10 gap-[26px] p-5 lg:mt-[10px] ">
                        <div>
                            <p className="text-cyanHeading font-jostSemibold lg:text-[36px] text-[24px] ">
                                {TitleCase(info.name)}
                            </p>
                            {/* Brief Description */}
                            <div className="lg:w-[649px] md:w-[441px] w-[316px] lg:h-max-fit md:h-max-fit h-[50px] text-blueShadow lg:text-[20px] text-[14px] mt-[12px]">
                                <p>

                                    {info?.description?.substring(0, 110) ?? ""} {info?.description?.length > 110 && '...'}
                                </p>
                            </div>
                            {/* No of parameters */}
                            <p className="text-blueShadow font-satoshiBold lg:text-[24px] md:text-[16px] text-[14px]  mt-[14px]">
                                {" "}
                                {info.param_cnt ?? ""} Parameters Included
                            </p>
                        </div>

                        <div>
                            <div className=" flex lg:flex-col md:flex-col flex-row lg:gap-5 sm:gap-0 lg:justify-end lg:items-end md:justify-end md:items-end justify-between">
                                <div className={`bg-white rounded-full lg:h-[53px] md:h-[47px] h-[30px] lg:w-[218px] md:w-[175px] w-[125px] flex justify-center items-center ${info.price_difference === 0 ? "hidden" : ""}`}>
                                    {/* Offer percentage */}
                                    <p className="text-Verdigris font-satoshiBold lg:text-[24px] md:text-base text-[12px]">
                                        Up to {info?.price_difference} Off
                                    </p>
                                </div>

                                <span className="flex gap-4 text-blueShadow font-satoshiBold items-center w-full justify-center">
                                    {/* Price */}
                                    <p className={`line-through lg:text-[24px] md:text-[20px] text-[14px] ${info.price_difference === 0 ? "hidden" : ""}`}>
                                        AED {info.markup_price ?? ""}
                                    </p>{" "}
                                    <p className="lg:text-[36px] md:text-[24px] text-[22px]">
                                        AED {info.price ?? ""}
                                    </p>
                                </span>
                                <div className="lg:pt-0 md:pt-0 pt-[15px] lg:block md:block hidden">
                                    {/* <Link to="/orders/checkout"> */}
                                    <CustomButton
                                        onClick={(e) =>
                                            handleSubmit(e, info)
                                        }
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        width="lg:w-[239px] md:w-[206px] w-[337px] lg:h-[56px] h-[47px] w-full mt-2"
                                    >
                                        {cart?.count > 0 ? 'Add To Cart' : 'Book Now'}

                                    </CustomButton>
                                    {/* </Link> */}
                                </div>
                            </div>
                            <div className="lg:pt-0 md:pt-0 pt-[15px] lg:hidden md:hidden block">
                                {/* <Link to="/orders/checkout"> */}
                                <CustomButton
                                    onClick={(e) => handleSubmit(e, info)}
                                    borderRadius="0.5rem"
                                    variant="secondary-contained"
                                    width="lg:w-[239px] md:w-[206px] w-[337px] lg:h-[56px] h-[47px] w-full mt-2"
                                >
                                    {cart?.count > 0 ? 'Add To Cart' : 'Book Now'}

                                </CustomButton>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="flex flex-col justify-center items-center m-auto">
                    {/* Key Features */}
                    <div className="relative bottom-[69px] bg-white rounded-lg keyFeatures border border-Verdigris px-5 text-center lg:w-[876px] md:w-[693px] w-[337px]">
                        <div className="border-b-2 border-blueShadow lg:p-[19px] md:p-[24px] p-5">

                            <p className="text-blueShadow font-satoshiMedium lg:text-[20px] md:text-base text-xs">
                                {
                                    info.is_free_samp_coll ? 'Free Sample Collection' : ''
                                }
                                {
                                    info.is_acc_covid && info.is_free_samp_coll || info.is_free_samp_coll && info.is_s_r_avail ? ' | ' : ''
                                }
                                {
                                    info.is_acc_covid ? 'Accused Covid Safety' : ''
                                }

                                {
                                    info.is_acc_covid && info.is_s_r_avail ? ' | ' : ''
                                }

                                {
                                    info.is_s_r_avail ? 'Smart Report Available' : ''
                                }

                            </p>

                        </div>
                        <div className="lg:p-[19px] md:p-[24px] p-5">
                            <p className="text-blueShadow font-satoshiBold lg:text-[20px] md:text-base text-xs text-center">
                                Report Time {info.report_time ?? ""} &#x2022; Recommended for{" "}
                                {info.test_rec ?? ""} &#x2022; Age Range{" "}
                                {info.age_start} to   {info.age_end}
                            </p>
                            {/* Special info */}
                            {
                                info.additional_info ? <p className="text-blueShadow mt-2 font-satoshiBold lg:text-[20px] md:text-base text-xs text-center">&#x2022; {info.additional_info}</p> : <></>
                            }
                        </div>
                    </div>
                    {/* Description */}
                    <div className="lg:w-[1081px] md:w-[668px] w-[343px]">
                        <p className="text-blueShadow font-satoshiBold lg:text-[24px] md:text-[20px] text-base">
                            Description
                        </p>
                        <p className="text-secondaryLight lg:text-[20px] text-[14px] leading-relaxed mt-[7px]">
                            {info?.description ?? ""}
                        </p>
                    </div>
                    {/* Process for Sample Collection */}
                    <div className="lg:w-[1081px] md:w-[668px] w-[343px] lg:mt-[36px] mt-[28px]">
                        <p className="text-blueShadow font-satoshiBold  lg:text-[24px] md:text-[20px] text-base">
                            Process for Sample Collection
                        </p>
                        <ol className="list-decimal pl-5">
                            <li className="text-secondaryLight lg:text-[20px] text-[14px] leading-relaxed mt-[7px]">
                                Book your test easily with our online system or via call/WhatsApp. Provide your details, choose a suitable date/time, and our friendly staff will assist you for a stress-free booking
                            </li>
                            <li className="text-secondaryLight lg:text-[20px] text-[14px] leading-relaxed mt-[7px]">
                                Our technician with a home visit kit will come to you, verify the OTP, follow our checklist, and collect the sample. It takes only 5-10 minutes.
                            </li>
                            <li className="text-secondaryLight lg:text-[20px] text-[14px] leading-relaxed mt-[7px]">
                                We handle samples with precision, transport them to affiliated labs, and process with strict protocols to ensure reliability. Smart reports are delivered within the specified time via email and online portal.
                            </li>
                        </ol>
                    </div>

                    <div className="flex justify-center items-center mt-[50px] w-full mx-5">
                        {/* <Link to="/orders/checkout"> */}
                        <CustomButton
                            onClick={(e) => handleSubmit(e, info)}
                            borderRadius="0.5rem"
                            variant="secondary-contained"
                            width="lg:w-[239px] md:w-[206px] lg:h-[56px] h-[47px] w-full mt-2 mx-5"
                        >
                            {cart?.count > 0 ? 'Add To Cart' : 'Book Now'}
                        </CustomButton>
                        {/* </Link> */}
                    </div>

                    {info?.type === 'TEST_GROUP' || info?.type === 'TEST' ? (
                        <></>
                    ) : (
                        <>
                            <div className="lg:w-[1081px] md:w-[668px] w-[343px] lg:mt-[90px] md:mt-[60px] mt-[55px] ">
                                <p className="text-blueShadow font-satoshiBold lg:text-[24px] md:text-[24px] text-base lg:mb-[57px] md:mb-[52px] mb-[30px]">
                                    Test Parameters ({info.param_cnt ?? ""})
                                </p>
                                {/* Accordion Test Parameters Cards */}

                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-[40px] md:gap-[40px] gap-[19px] m-auto justify-start items-start lg:px-10">
                                    {test_params.map(
                                        (param, index) => (
                                            <div className="static">
                                                <>
                                                    {param.sub_tests.length >
                                                        1 ? (
                                                        <div className="testParameter rounded-xl">
                                                            <Accordion
                                                                expanded={
                                                                    expanded ===
                                                                    `panel${index +
                                                                    1
                                                                    }`
                                                                }
                                                                onChange={handleAccordion(
                                                                    `panel${index +
                                                                    1
                                                                    }`
                                                                )}
                                                                className={
                                                                    classes.root
                                                                }
                                                                elevation={0}
                                                                sx={{
                                                                    border: "1px solid rgba(51, 176, 170, 0.65)",
                                                                }}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        <img
                                                                            className="lg:w-[32px] md:w-[36px] w-[23px] lg:h-[32px] md:h-[36px] h-[23px] "
                                                                            src='/images/icons/paramArrow.svg'
                                                                            alt='Arrow Icon'
                                                                        />
                                                                    }
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                    className={
                                                                        classes.summary
                                                                    }
                                                                >
                                                                    <p className="text-blueShadow capitalize relative right-[15px] text-start font-satoshiMedium  text-base pl-[15px]">
                                                                        {
                                                                            TitleCase(param.name)
                                                                        }
                                                                        &nbsp;(
                                                                        {
                                                                            param.param_cnt
                                                                        }
                                                                        )
                                                                    </p>
                                                                </AccordionSummary>

                                                                <AccordionDetails
                                                                    className={
                                                                        classes.details
                                                                    }
                                                                >
                                                                    <div
                                                                        style={{
                                                                            borderTop:
                                                                                "1px solid rgba(51, 176, 170, 0.65)",
                                                                        }}
                                                                        className="mx-4"
                                                                    />

                                                                    <ul className=" leading-relaxed h-[95px] overflow-auto pt-4 pb-6">
                                                                        {param.sub_tests.map(
                                                                            (
                                                                                item
                                                                            ) => (
                                                                                <li className="text-blueShadow text-sm cursor-pointer hover:underline px-3 relative bottom-[10px]">
                                                                                    {
                                                                                        TitleCase(item.name)
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        )}
                                                                    </ul>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                border: "1px solid rgba(51, 176, 170, 0.65)",
                                                            }}
                                                            className="testParameter lg:h-[72px]  md:h-[72px] h-[72px]  flex justify-start items-center bg-white rounded-xl text-blueShadow capitalize text-start font-satoshiMedium text-base lg:py-4 p-4"
                                                        >
                                                            {param?.name} &#40;
                                                            {param?.param_cnt}
                                                            &#41;
                                                        </div>
                                                    )}
                                                </>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>


                        </>
                    )}

                   
                    <GetACall title="Discuss with our Executive" />


                    <br />

                </div>
            </div>

            {/* Add to cart or Proceed to payment modal */}
            <Modal
                open={addToCart}
                onClose={handleAddToCartClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={addToCartStyles}
                    style={{
                        textAlign: "center",
                        borderRadius: "16px",
                        outline: "none",
                        // padding: "10px",
                    }}
                >
                    <div className="flex justify-end ">
                        <img
                            onClick={handleAddToCartClose}
                            className=" cursor-pointer"
                            src='/images/icons/closeIcon.svg'
                            alt='Clear Cart'
                        />
                    </div>

                    <p className="font-jostRegular font-bold text-[#3C567E] lg:text-[26px] text-[20px] lg:w-full w-[294px]">
                        {" "}
                        Want to add More test/ Package to your{" "}
                        <span className="lg:block">shopping cart?</span>
                    </p>
                    <br />

                    <div className="flex lg:flex-row flex-col justify-center items-center gap-5">
                        <CustomButton
                            borderRadius="0.5rem"
                            variant="secondary-outlined"
                            width="lg:w-[252px] w-[276px]  "
                            onClick={handleAddToCartClose}
                        >
                            Add More Test/ Package
                        </CustomButton>
                        <Link to="/orders/checkout">
                            <CustomButton
                                borderRadius="0.5rem"
                                variant="secondary-contained"
                                width="lg:w-[252px] w-[276px]  "
                            >
                                Proceed to Checkout
                            </CustomButton>
                        </Link>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default View;
