import Validator from 'validatorjs'
import { Input } from '../../common/Input'
import { useParams } from 'react-router-dom'
import CustomButton from '../../common/Button'
import Telephone from '../../common/Telephone'
import BreadCrumb from '../../common/BreadCrumb'
import FileUpload from '../../common/FileUpload'
import updateMeta from '../../../helpers/seo-meta'
import axiosInstance from '../../../helpers/axios'
import React, { useEffect, useState } from 'react'
import { showToastMessage } from '../../../helpers/helpers'
import { SelectInputCustom } from '../../common/CustomSelectInput'



const { fields, rules } = require('./fields')
const parse = require('html-react-parser')

function ViewJob() {

    const [flag, setFlag] = useState(false)

    const [params, setParams] = useState(fields)
    const [errors, setErrors] = useState(fields)

    const [files, setFiles] = useState({})

    const [resume, setResume] = useState({})

    let { id } = useParams()

    const [jobData, setJobData] = useState({
        id: 0,
        title: '',
        type: '',
        experience: '',
        preferred_location: '',
        description: '',
        is_active: true,
    })

    const getJobById = () => {
        // setParams({ ...params, job_id: id })

        axiosInstance
            .get(`/careers/${id}`)
            .then((response) => {
                let thisData = response.data.data
                setJobData(thisData)
            })
            .catch((error) => {
            })
    }

    useEffect(() => {
        getJobById()
    }, [])

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target

            if (name === 'pincode' || name === 'phone' || name === 'alternate_phone') {
                const re = /^[0-9\b]+$/
                if (value && !re.test(value)) {
                    return
                }
            }
            updateParams([{ name, value }])
        } else {
            updateParams([{ name: e?.name, value: e?.url }])
        }

        if (e?.file) {
            setResume(e.file)
            setFiles({ ...files, [e.name]: e.file })
        }


        setErrors({})
    }

    const updateParams = (records) => {
        setAck('')
        const newParams = JSON.parse(JSON.stringify(params))

        Object.keys(records).forEach((key) => (newParams[records[key].name] = records[key].value))
        setParams(newParams)
    }

    const validate = (parameters, rule) => {
        const validator = new Validator(parameters, rule)

        if (validator.fails()) {
            const fieldErrors = {}

            /* eslint-disable */
            for (const key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0]
            }
            /* eslint-enable */

            setErrors(fieldErrors)

            return false
        }
        setErrors({})
        return true
    }

    const handleSubmit = () => {
        // let regex = /^(?:\971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;

        if (!validate(params, rules)) {
            const err = Object.keys(errors)
            if (err?.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            //   showToastMessage('Please Select All Required Fields!', 'error')
            // if (!regex.test(`${params.phone}`)) {
            //     setErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
            //     return
            // }
            return
        }
        applyForJob()
    }

    const removeImage = (name) => {
        // setResume('')
        setFiles({ ...files, [name]: '' })
        updateParams([{ name: name, value: '' }])
    }

    const [ack, setAck] = useState('')

    const applyForJob = async () => {
        const formdata = new FormData()

        for (let key in params) {
            formdata.append(key, params[key])
        }
        formdata.append('resume', resume)
        formdata.append('job_id', id)


        axiosInstance
            .post(`/careers`, formdata)
            .then((response) => {
                let thisData = response.data.data.data
                setAck(thisData?.message)
                setTimeout(() => {
                    setAck('')
                }, 3000)
                setParams(fields),
                    // to remove file on submit
                    setFlag(true)
                showToastMessage("Successfully applied for job", "succes")
                setFlag(false)
            })
            .catch((error) => {

                let { errors } = error.response.data

                showToastMessage(errors.mesage, "error")
            })

    }

    const onPhoneNumberChange = (isValid, value, selectedCountryData) => {
        setParams({ ...params, phone: value, nationality: selectedCountryData.name })
        if (!isValid) {
            setErrors({ ...errors, phone: 'Enter valid phone number' })
        }
        else {
            setErrors({ ...errors, phone: '' })
        }
    }

    useEffect(() => {
        document.title = 'View Job Information'
        updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
        updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
    }, []);

    return (
        <div className='lg:mt-[150px] md:mt-[120px] mt-[80px] mb-10'>
            <div className='px-10 py-2'>
                <div className='w-[1440px] m-auto px-10'>
                    <BreadCrumb
                        links={[
                            { path: 'Home', url: '/' },
                            { path: 'Careers', url: '/careers' },
                            { path: jobData.title ?? '', url: '/' },
                        ]}
                    />
                </div>
            </div>

            <div className='pt-5'>
                <div className='mr-auto ml-auto lg:px-[26px] md:px-[18px] px-[21px] gradientGreen shadow-xl rounded-t-xl lg:w-[1083px] md:w-[704px] w-[339px] lg:h-[162px] md:h-[117px] h-[117px] flex flex-col'>
                    <div className='mt-auto mb-auto text-white'>
                        <p className='font-jostRegular font-[600] lg:text-[36px] md:text-[26.0916px] text-[24px]'>
                            {jobData.title ?? ''}
                        </p>
                        <div className='flex gap-[40px] font-medium lg:text-[20px] md:text-[14px] text-[10px]'>
                            <p className='flex justify-center items-center gap-2 capitalize'>
                                <span>
                                    <img src='/images/icons/pin_white.svg' alt="Location" />
                                </span>{' '}
                                {jobData.preferred_location ?? ''}
                            </p>
                            <p className='flex justify-center items-center gap-2 capitalize'>
                                <span>
                                    <img src='/images/icons/time_white.svg' alt='Job Type' />
                                </span>{' '}
                                {jobData.type ?? ''}
                            </p>
                            <p className='flex justify-center items-center gap-2 capitalize'>
                                <span>
                                    <img src='/images/icons/experience.svg' alt='experience' />
                                </span>{' '}
                                {jobData.experience ?? ''}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='mr-auto ml-auto lg:py-[40px] lg:px-[67px] md:py-[30px] md:px-[40px] py-[14px] px-[20px] bg-white rounded-b-xl shadow-xl lg:w-[1083px] md:w-[704px] w-[339px] flex flex-col'>
                    {/* Job description */}
                    <p className='text-blueShadow font-satoshiBold lg:text-[24px] md:text-[17px] text-[16px]'>
                        Job description
                    </p>
                    <div>
                        <div
                            // dangerouslySetInnerHTML={{ __html: jobData.description }}
                            className='lg:text-[16px] md:text-[12px] text-[14px] font-satoshiRegular text-secondaryLight lg:w-[1083px] md:w-[704px] w-[339px]'
                        >
                            {parse(`<div className="lg:w-[900px] md:w-[620px] w-[290px] break-words">` + jobData.description + `</div>`)}
                        </div>
                    </div>

                    <p className='text-blueShadow font-jostSemibold  lg:text-[36px] md:text-[26px] text-[24px]'>
                        Submit your Application
                    </p>
                    <p className='font-satoshiRegular text-secondaryLight lg:text-[16px] md:text-[12px] text-[16px] '>
                        Fill the following information
                    </p>
                    {/* Separator */}
                    <div className='border-b-[0.5px] m-5 border-[##EBEEF2]' />

                    <div className='grid lg:grid-flow-col grid-flow-row grid-2 p-5 lg:justify-between justify-center'>
                        <p className='text-blueShadow font-satoshiBold lg:text-[24px] md:text-[17px] text-[16px] lg:mb-0 mb-2'>
                            Personal Information
                        </p>
                        {/* Inputs */}
                        <div>
                            <Input
                                rows={1}
                                width='lg:w-[576px] md:w-[373px] w-[299px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.name}
                                error={errors?.name}
                                helperText={errors?.name}
                                label='Name'
                                name='name'
                            />
                            <br />
                            <Input
                                rows={1}
                                width='lg:w-[576px] md:w-[373px] w-[299px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.email}
                                error={errors?.email}
                                helperText={errors?.email}
                                label='Email ID'
                                name='email'
                            />
                            {/* <br />

                            <Input
                                rows={1}
                                width='lg:w-[576px] md:w-[373px] w-[299px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.phone}
                                error={errors?.phone}
                                helperText={errors?.phone}
                                label='Phone Number'
                                name='phone'
                            /> */}
                            <br />

                            <Telephone
                                onPhoneNumberChange={onPhoneNumberChange}
                                params={params}
                                errors={errors}
                            />

                            <p className='text-xs text-red-500 font-satoshiMedium relative left-[15px] top-[5px] mb-[-20px]'>{errors?.phone}</p>

                            <br />

                            <SelectInputCustom
                                // readonly={isview}
                                width='lg:w-[576px] md:w-[373px] w-[299px]'
                                handleChange={handleChange}
                                options={["Male", "Female", "Others"]}
                                label='Gender'
                                name='gender'
                                value={params?.gender}
                                error={errors?.gender}
                                helperText={errors?.gender}
                            />

                            <Input
                                rows={1}
                                width='lg:w-[576px] md:w-[373px] w-[299px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.location}
                                error={errors?.location}
                                helperText={errors?.location}
                                label='Location'
                                name='location'
                            />
                            <br />

                            <Input
                                rows={1}
                                width='lg:w-[576px] md:w-[373px] w-[299px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.experience}
                                error={errors?.experience}
                                helperText={errors?.experience}
                                label='Exp in Years'
                                name='experience'
                            />
                        </div>
                    </div>
                    <div className='border-b-[0.5px] m-5 border-[##EBEEF2]' />

                    <div className='grid lg:grid-flow-col grid-flow-row grid-2 p-5 lg:justify-between justify-center'>
                        <p className='text-blueShadow font-satoshiBold lg:text-[24px] md:text-[17px] text-[16px] lg:mb-0 mb-2'>
                            Upload Resume/CV
                        </p>


                        <div className='flex gap-5 items-center lg:w-[576px] md:w-[373px] w-[299px]'>
                            <FileUpload
                                flag={flag}
                                imageUrl={params.resume}
                                removeImage={() => {
                                    removeImage('resume')
                                    setResume('')
                                }}
                                styleType='md'
                                setImage={handleChange}
                                acceptMimeTypes={['application/pdf']}
                                title='Upload or Drag and Drop image'
                                label='File Format: .pdf'
                                id='pdf'
                                maxSize={5}
                                filename='resume'
                                error={errors?.resume}
                            />
                        </div>
                    </div>

                    <div className='border-b-[0.5px] m-5 border-[##EBEEF2]' />
                    {/* Submit Button */}
                    <div className='flex lg:justify-end md:justify-end justify-center px-5'>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            width='lg:w-[173px] md:w-[131px] w-[150px] lg:h-[56px] md:[49px] h-[47px]'
                            onClick={handleSubmit}
                        >
                            Apply now
                        </CustomButton>
                    </div>

                    <p className='text-center text-base'>{ack}</p>
                </div>
            </div>
        </div>
    )
}

export default ViewJob
