import React from 'react';
import 'react-intl-tel-input/dist/main.css';
import IntlTelInput from 'react-intl-tel-input';

const Telephone = ({ params, onPhoneNumberChange, errors }) => {

    let defaultStyles = 'border border-[#E7E8ED] text-[#3C567E]'
    let errorStyles = 'border border-[#dc2626] '

    return (
        <div className='w-full'>
            <IntlTelInput
                containerClassName='intl-tel-input w-full'
                inputClassName={`p-[14px] outline-Verdigris w-full rounded-md ${errors.phone ? errorStyles : defaultStyles} text-[16px] font-arial`}
                fieldId='telephone'
                fieldName='phone'
                nationalMode={false}
                // value={params.phone}
                formatOnInit={true}
                autoComplete='true'
                preferredCountries={['ae', 'in', 'us', 'ca']}
                onPhoneNumberChange={onPhoneNumberChange}
                onPhoneNumberBlur={onPhoneNumberChange}
                useMobileFullscreenDropdown={true}
            />
        </div>
    )
}


export default Telephone