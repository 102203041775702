import * as React from 'react'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import { makeStyles } from '@mui/styles'
import Stepper from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'
import StepLabel from '@mui/material/StepLabel'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'


const useStyles = makeStyles(() => ({
    root: {
        '& .Mui-active .MuiStepIcon-root': {
            color: '#33B0AA',
            width: '24px',
            height: '24px',
        },
        '& .Mui-completed .MuiStepIcon-root': {
            color: '#3C567E',
            width: '24px',
            height: '24px',
        },

        '& .Mui-disabled .MuiStepIcon-root': {
            color: '#EBEEF2',
            width: '24px',
            height: '24px',
        },
        '& .MuiStepLabel-label.Mui-active': {
            color: '#33B0AA',
            fontWeight: '600',
            fontSize: '14px',
        },
        '& .MuiStepLabel-label.Mui-disabled': {
            color: '#909EB4',
            fontWeight: '600',
            fontSize: '14px',
        },
        '& .MuiStepLabel-label.Mui-completed': {
            color: '#3C567E',
            fontWeight: '600',
            fontSize: '14px',
        },

        '& .MuiStepIcon-text': {
            fill: '#6A6A78 !important',
        },

        '& .Mui-active .MuiStepIcon-text': {
            fill: '#000 !important',
        },
    },
}))

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(90deg, #3C567E 5.11%, #3C567E 43.17%, #33B0AA 100%);',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(90deg, #3C567E 5.11%, #3C567E 43.17%, #3C567E 100%);',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        // width: '7rem',
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}))

const QontoStepIconRoot = styled('div')(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#EBEEF2',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#33B0AA',
        }),
        '& .QontoStepIcon-completedIcon': {
            // zIndex: 1,
            // color: '#3C567E',
            // fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            zIndex: 1,
            width: 24,
            height: 24,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }),
)

function QontoStepIcon(props) {
    const { active, completed, className } = props

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <img
                    className='QontoStepIcon-completedIcon'
                    src='/images/icons/completedStepper.svg'
                    alt='QontoStepIcon'
                />
            ) : (
                <div className='QontoStepIcon-circle flex justify-center items-center'>
                    <img src='/images/shapes/activeStepper.svg' alt="Qonto" />
                </div>
            )}
        </QontoStepIconRoot>
    )
}

export default function CustomStepper(props) {
    const { activeStep } = props
    const classes = useStyles()
    const stepRef = React.useRef()

    return (
        <Box sx={{ width: '100%', justifyItems: 'center', alignItems: 'center' }}>
            <Stepper activeStep={activeStep - 1} alternativeLabel connector={<QontoConnector />}>
                {props.steps.map((label) => {
                    const stepProps = {}
                    const labelProps= {}

                    return (
                        <Step key={label.title} {...stepProps}>
                            <StepLabel
                                ref={stepRef}
                                // {...labelProps}
                                StepIconComponent={QontoStepIcon}
                                className={classes.root}
                            >
                                <p className='text-[12px] font-satoshiRegular font-bold'>
                                    {label.title}
                                </p>
                            </StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
        </Box>
    )
}
