import './pagination.css';
import React, { useState } from 'react';
import { uuid } from '../../../helpers/helpers';
import { usePagination, DOTS } from './usePagination';

export const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const [customPageNo, setcustomPageNo] = useState('');

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter'
      && Number(customPageNo) > 0
      && paginationRange
      && Number(customPageNo) <= paginationRange[paginationRange.length - 1]
    ) {
      onPageChange(Number(customPageNo));
    }
  };

  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value && Number(value) === 0) return;
    const re = /^[0-9\b]+$/;
    if (
      (value && !re.test(value))
      || (paginationRange && Number(value) > paginationRange[paginationRange.length - 1])
    ) {
      return;
    }
    setcustomPageNo(value);
  };

  return (
    <div className="flex space-x-4  justify-center">
      <ul className={`pagination-container ${className}`}>
        <li
          key={uuid()}
          className={`arrow-icon ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={onPrevious}
        >
          <div className="nav-arrow flex items-center justify-center">
            <svg className='arrow-svg' width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.79834 1L1.29834 5.25L5.79834 9.5" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </li>
        {paginationRange?.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li key={uuid()} className="pagination-item dots">
                &#8230;
              </li>
            );
          }

          return (
            <li
              key={uuid()}
              className={`pagination-item ${pageNumber === currentPage ? 'selected' : ''}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          key={uuid()}
          className={`arrow-icon  ${currentPage === lastPage ? 'disabled' : ''}`}
          onClick={onNext}
        >
          <div className="nav-arrow flex items-center justify-center">
            <svg className='arrow-svg' width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.79834 10L5.29834 5.75L0.79834 1.5" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
