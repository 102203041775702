import React from 'react'
const CopyrightText = () => {
  return (
    <div className=' flex lg:flex-row md:flex-row flex-col justify-center gap-2 '>
      <p className='text-base font-medium text-center text-white opacity-45'>
        {' '}
        © Zaincare {new Date().getFullYear()}. All Rights Reserved
      </p>
      <p className='lg:block md:block hidden text-base font-medium text-center text-white opacity-45'>
        {' '}
        |{' '}
      </p>
      <p className='text-base font-medium text-center text-white opacity-45'>
        Made with <img className='inline-block' src='/images/icons/heart.svg' alt='hearticon' /> from SCUBE
      </p>
    </div>
  )
}

export default CopyrightText
