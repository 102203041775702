import { useEffect } from 'react';

function useGtag() {
  useEffect(() => {
    // Check if window is defined (not in SSR)
    if (typeof window !== 'undefined') {
      // Access the gtag function from window.dataLayer
      window.gtag = (...args) => window.dataLayer.push(...args);

      return () => {
        // Cleanup if needed
        // delete window.gtag;
      };
    }
  }, []);

  return typeof window !== 'undefined' ? window.gtag : () => {};
}

export default useGtag;
