import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from '../../helpers/helpers'

const initialState = {
  bookings: [],
  isLoading: false,
  isCreatePending: false,
  isCreateSuccess: false,
  apiSuccess: false,
  metaData: {},

};

export const booking = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    formReset: () => { },
    resetLoading: (state) => {
      state.isLoading = true;
    },
    CreatePending: (state) => {
      state.isLoading = true;
      state.isCreatePending = true
  },
    creationSuccessful: (state, action) => {
      state.isLoading = false;
      state.isCreatePending = false
      state.isCreateSuccess = true;
      showToastMessage(action.payload.message, 'success')
    },

    resetApiSuccess: (state) => {
      state.isCreateSuccess = false
    },

    creationFailure: (state, action) => {
      state.isLoading = false;
      state.apiSuccess = false;
      state.isCreatePending = false
      showToastMessage(action.payload, 'error');
    },

    SuccessfulList: (state, action) => {
      state.bookings = action?.payload?.data;
      state.metaData = action?.payload?.meta
      state.isLoading = false;
    },

    UserListPending: (state) => {
      state.isLoading = true;
    },

  },
});

export const {
  creationSuccessful,
  creationFailure,
  formReset,
  resetLoading,
  SuccessfulList,
  UserListPending,
  CreatePending,
  resetApiSuccess,
} = booking.actions;
export default booking.reducer;

export const fetchBookings = (search_key, start_date, end_date,page) =>
  apiCallBegan({
    url: `/customer-bookings?search_key=${search_key}&start_date=${start_date}&end_date=${end_date}&page=${page}`,
    method: 'GET',
    onStart: UserListPending.type,
    onSuccess: SuccessfulList.type,
    onError: creationFailure.type,
  });

export const rescheduleTiming = (id, data) =>
    apiCallBegan({
        url: `/customer-bookings/reschedule/${id}`,
        method: 'PUT',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })

export const updateAddress = (id, data) =>
    apiCallBegan({
        url: `/customer-bookings/change-address/${id}`,
        method: 'PUT',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })

  export const getSupport = (data) =>
    apiCallBegan({
        url: `/support-ticket`,
        method: 'POST',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })
