import React from 'react'

function Note() {
    return (
        <React.Fragment>
            {/* <div className='bg-[#F4F7FA] text-secondaryLight text-[12px] lg:w-[532px] md:w-[524px] w-[312px] p-5 rounded-lg'>
                <p className='font-satoshiBold text-[14px] text-blueShadow'>Note</p>
                <p className='text-12px font-satoshiRegular'>
                    1. lorem ipsum dolor sit amet consectetur. Ut sed turpis enim dictumst placerat
                    consectetur quisque.{' '}
                </p>
                <p className='text-12px font-satoshiRegular'>
                    2. lorem ipsum dolor sit amet consectetur. Ut sed turpis enim dictumst placerat
                    consectetur quisque.{' '}
                </p>
            </div> */}

            {/* assistance banner */}
            <div className='bg-[#EBEEF2] text-secondaryLight text-[12px] lg:w-[532px] md:w-[524px] w-[312px] h-[49px] rounded-lg my-2 flex justify-center items-center'>
                <p className='font-satoshiMedium text-blueShadow text-sm'>For Any Assistance please contact <span className='font-satoshiBold text-blueShadow text-sm'>+971 52 886 6165</span> </p>
            </div>

        </React.Fragment>
    )
}

export default Note
