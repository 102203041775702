import React from 'react'
import { Input } from '../../common/Input'
import CustomButton from '../../common/Button'
import { PhoneInput } from '../../common/PhoneInput'
import TextArea from '../../common/TextArea'

function Form({ handleChange, params, errors, handleSubmit, ack }) {
    return (
        <div className='relative lg:w-[531px] w-[339px] lg:bottom-[206px] bottom-[80px] lg:right-[35px] bg-white shadow-2xl grid-flow-row rounded-lg m-5'>
            <div className=' p-10 '>
                <p className='text-[#2D4464] lg:text-[20px] text-[12px] font-satoshiRegular lg:leading-[27px] leading-[16px]'>
                    Send us your message and let us get back <br /> to you !
                </p>
                <br />
                <Input
                    rows={1}
                    width='w-full'
                    disabled={false}
                    readOnly={false}
                    handleChange={handleChange}
                    value={params?.name}
                    error={errors?.name}
                    helperText={errors?.name}
                    label='Name*'
                    name='name'
                />
                <br />

                <PhoneInput  rows={1}
                    width="w-full"
                    disabled={false}
                    readOnly={false}
                    handleChange={handleChange}
                    value={params?.phone}
                    error={errors?.phone}
                    helperText={errors?.phone}
                    label="Contact No*"
                    name="phone" />
                <br />

                <Input
                    rows={1}
                    width='w-full'
                    disabled={false}
                    readOnly={false}
                    handleChange={handleChange}
                    value={params?.email}
                    error={errors?.email}
                    helperText={errors?.email}
                    label='Email*'
                    name='email'
                />
                <br />

                <TextArea
                    rows={5}
                    readOnly={false}
                    handleChange={handleChange}
                    value={params?.message}
                    error={errors?.message}
                    helperText={errors?.message}
                    placeholder="Write a message*"
                    name="message"
                />
                <br />
                <CustomButton
                    borderRadius='0.5rem'
                    variant='secondary-contained'
                    size='large'
                    onClick={handleSubmit}
                >
                    Submit
                </CustomButton>

                <p className='text-center text-sm py-2'>{ack}</p>
            </div>
        </div>
    )
}

export default Form
