import React, { useEffect, useState } from "react";
import CustomButton from "../../../common/Button";
import { Input } from "../../../common/Input";
import Title from "../components/Title";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddressRadioSelect from "../components/AddressRadioSelect";
import Next from "../components/Next";
import Previous from "../components/Previous";
import InfoLabel from "../components/InfoLabel";
import { useDispatch, useSelector } from "react-redux";
import {
    addAddress,
    fetchAllAddress,
} from "../../../../features/userProfile/addressSlice";
import Validator from "validatorjs";
import { toast } from "react-toastify";
import axiosInstance from "../../../../helpers/axios";
import { updateCart } from "../../../../features/cart/cartSlice";
import { SelectInput } from "../../../common/Select";
import { cityList } from "../../../../helpers/helpers";
import TextArea from "../../../common/TextArea";

const style = {
    position: "absolute" ,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 2,
};

const initiaDeflValue = {
    location_type: "",
    building_name: "",
    house_no: "",
    locality: "",
    city: "",
    address_instructions: ""
};


const initialValue = {
    location_type: "",
    building_name: "",
    house_no: "",
    locality: "",
    city: "Dubai",
    address_instructions: ""
};

const locationTypes = [
    {
        id: 'HOME',
        value: 'HOME',
    },
    {
        id: 'OFFICE',
        value: 'OFFICE',
    },
    {
        id: 'OTHER',
        value: 'OTHER',
    },
]

function Address({ isGuest, setActiveStep }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const { addressList, isCreateSuccess } = useSelector(
        (state) => state.address
    );
    const cart = useSelector((state) => state.cart);
    const [params, setParams] = useState(initialValue);
    const [formErrors, setFormErrors] = useState(initiaDeflValue) ;
    const [list, setList] = useState([]);

    useEffect(() => {
        if(cart.authenticated){
        dispatch(fetchAllAddress());
        }
    }, []);

    useEffect(() => {
        dispatch(fetchAllAddress());
    }, [isCreateSuccess]);

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;
            setParams({ ...params, [name]: value });
        }
        setFormErrors({});
    };

    useEffect(() => {
        let address = JSON.parse(JSON.stringify(addressList));
        address = address.map((x) => {
            x["checked"] = false;
            return x;
        });
        
        if (!cart.is_guest_user && cart.address) {
            let index = address.findIndex((x)=>x.id === cart.address.id)
            if(index!=-1){
                address[index]['checked'] = true;
            }
        } 
        setList(address);
    }, [addressList]);

    const handleSubmit = () => {
        let payload = {
            location_type: params.location_type,
            building_name: params.building_name,
            house_no: params.house_no,
            locality: params.locality,
            city: params.city,
            address_instructions: params.address_instructions
        };
        const rules = {
            location_type: "required|string|max:50",
            building_name: "required|string|max:50",
            house_no: "required|string|max:50",
            locality: "required|string|max:50",
            city: "required|string",
            address_instructions: 'max:500'
        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setFormErrors(fieldErrors);
            return false;
        }

        dispatch(addAddress(payload));
        setFormErrors({});
        handleClose();
        return true;
    };

    const onSelect = (e, item) => {
        e.preventDefault();
        let addressList = [...list];
        addressList = addressList.map((x) => {
            x["checked"] = false;
            return x;
        });

        let index = addressList.findIndex((x) => x.id === item.id);
        if (index != -1) {
            addressList[index]["checked"] = true;
        }
        setList(addressList);
    };

    const onAddressAdd = (e) => {
        e.preventDefault();
        let selected = list.some((x) => x.checked);
        if (!selected) {
            toast.error("Please Select atleast one Address");
            return;
        }

        axiosInstance
            .post("/cart/update_address_or_sample_date", {
                type: "address",
                data: list.find((x) => x.checked),
            })
            .then((response) => {
                dispatch(updateCart(response.data));
                setActiveStep(3);
            });
    };
    const handleGusetUserAddress = (e) => {
        e.preventDefault();
        let payload = {
            location_type: params.location_type,
            building_name: params.building_name,
            house_no: params.house_no,
            locality: params.locality,
            city: params.city,
            address_instructions: params.address_instructions
        };
        const rules = {
            location_type: "required|string|max:50",
            building_name: "required|string|max:50",
            house_no: "required|string|max:50",
            locality: "required|string|max:50",
            city: "required|string",
            address_instructions: 'max:500'
        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setFormErrors(fieldErrors);
            return false;
        }

        axiosInstance
            .post("/cart/update_address_or_sample_date", {
                type: "address",
                data: payload,
            })
            .then((response) => {
                dispatch(updateCart(response.data));
                setActiveStep(3);
            });
    };

    useEffect(()=>{
        if(cart.is_guest_user && cart.address){
            setParams({ ...params, ...cart.address })
        }
    },[])

    return (
        <div>
            <p className="text-xs font-satoshiBold text-Verdigris relative md:left-[330px] left-[150px] bottom-[20px] lg:hidden md:block block">
                Address
            </p>
            <Title title="Add Address" />
            {cart.is_guest_user ? (
                <>
                    <div className="border-t-0 border border-[#F4F7FA] lg:w-[568px] md:w-[704px] w-[344px] flex flex-col justify-center items-center rounded-b-lg">
                        <div className="h-[320px] overflow-y-scroll w-full grid grid-flow-row justify-center items-center mt-[12px]">
                            <InfoLabel title="Enter the Sample Collection Address" />
                            <SelectInput
                                readonly={false}
                                width=" lg:w-[400px] md:w-[400px] w-[312px] mt-[16px]"
                                handleChange={handleChange}
                                options={locationTypes}
                                value={params?.location_type}
                                error={formErrors?.location_type}
                                helperText={formErrors?.location_type}
                                label="Location Type*"
                                name="location_type"
                            />

                            <Input
                                rows={1}
                                width=" lg:w-[400px] md:w-[400px] w-[312px] mt-[16px]"
                                disabled={false}
                                handleChange={handleChange}
                                value={params?.house_no}
                                error={formErrors?.house_no}
                                helperText={formErrors?.house_no}
                                label="Door Number*"
                                name="house_no"
                            />
                            <Input
                                rows={1}
                                width=" lg:w-[400px] md:w-[400px] w-[312px] mt-[16px]"
                                disabled={false}
                                handleChange={handleChange}
                                value={params?.building_name}
                                error={formErrors?.building_name}
                                helperText={formErrors?.building_name}
                                label="Building/Area Name*"
                                name="building_name"
                            />
                            <Input
                                rows={1}
                                width=" lg:w-[400px] md:w-[400px] w-[312px] mt-[16px]"
                                disabled={false}
                                handleChange={handleChange}
                                value={params?.locality}
                                error={formErrors?.locality}
                                helperText={formErrors?.locality}
                                label="Locality*"
                                name="locality"
                            />
                            <SelectInput
                                readonly={false}
                                width=" lg:w-[400px] md:w-[400px] w-[312px] mt-[16px]"
                                handleChange={handleChange}
                                options={cityList}
                                value={params?.city}
                                error={formErrors?.city}
                                helperText={formErrors?.city}
                                label="City*"
                                name="city"
                            />
                             <TextArea
                                className={"text-[#3C567E]"}
                                rows={5}
                                handleChange={handleChange}
                                name="address_instructions"
                                value={params?.address_instructions}
                                placeholder="Address Instructions "
                                label="Additional Instructions"
                                error={formErrors?.address_instructions?.length}
                                helperText={formErrors?.address_instructions}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row justify-center items-center gap-5 my-1">
                        <Previous
                            onClick={() => {
                                setActiveStep(2);
                            }}
                        />

                        <Next onClick={handleGusetUserAddress} />
                    </div>
                </>
            ) : (
                <>
                    <div className="border-t-0 border border-[#F4F7FA] lg:w-[568px] md:w-[704px] w-[344px] flex flex-col justify-center items-center p-5 rounded-b-lg">
                        <div className="h-[250px] overflow-y-scroll">
                            <div className="grid grid-col gap-[16px] justify-center items-center w-[444px] mx-[52px]">
                                {list.map((item) => (
                                    <AddressRadioSelect
                                        onSelect={onSelect}
                                        data={item}
                                    />
                                ))}
                                {list && !list.length && (
                                    <div>No Address Found.</div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-row justify-center items-center gap-[8px] mt-2">
                            <p className="text-blueShadow text-[14px] font-satoshiRegular">
                                Not in the list ?
                            </p>

                            <p
                                onClick={handleOpen}
                                className="text-Verdigris font-bold text-[16px] underline cursor-pointer"
                            >
                                +Add New Address
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center gap-5 my-1">
                        <Previous
                            onClick={() => {
                                setActiveStep(2);
                            }}
                        />

                        <Next onClick={onAddressAdd} />
                    </div>
                </>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={style}
                    style={{
                        textAlign: "center",
                        borderRadius: "16px",
                        outline: "none",
                    }}
                >
                    <p className="font-jostRegular font-bold text-[#363636] text-[26px]">
                        {" "}
                        Add New Address
                    </p>
                    <br />
                    <div className="h-[349px] md:w-[500px] w-[350px]  overflow-y-scroll">
                        <div className=" flex flex-col justify-center items-center mt-2 px-4">
                            <div className="flex gap-2 w-full">
                                <SelectInput
                                    width="w-full text-left"
                                    options={[
                                        { name: "HOME", value: "HOME" },
                                        {
                                            name: "OFFICE",
                                            value: "OFFICE",
                                        },
                                        {
                                            name: "OTHERS",
                                            value: "OTHERS",
                                        },
                                    ]}
                                    handleChange={handleChange}
                                    value={params?.location_type}
                                    error={formErrors?.location_type}
                                    helperText={formErrors?.location_type}
                                    label="Location Type*"
                                    name="location_type"
                                />

                                <Input
                                    rows={1}
                                    width="w-full"
                                    disabled={false}
                                    handleChange={handleChange}
                                    value={params?.house_no}
                                    error={formErrors?.house_no}
                                    helperText={formErrors?.house_no}
                                    label="House No/ Flat Number*"
                                    name="house_no"
                                />
                            </div>
                            <div className="flex gap-2 my-2 w-full">
                                <Input
                                    rows={1}
                                    width="w-full"
                                    disabled={false}
                                    readOnly={false}
                                    handleChange={handleChange}
                                    value={params?.building_name}
                                    error={formErrors?.building_name}
                                    helperText={formErrors?.building_name}
                                    label="Building Name*"
                                    name="building_name"
                                />
                                <Input
                                    rows={1}
                                    width="w-full"
                                    disabled={false}
                                    handleChange={handleChange}
                                    value={params?.locality}
                                    error={formErrors?.locality}
                                    helperText={formErrors?.locality}
                                    label="Locality*"
                                    name="locality"
                                />
                            </div>

                            <SelectInput
                                readonly={false}
                                width="w-full text-left"
                                handleChange={handleChange}
                                options={cityList}
                                value={params?.city}
                                label="City*"
                                name="city"
                            />
                            <div className="w-full mb-2">
                            <TextArea
                                className={"text-[#3C567E]"}
                                rows={5}
                                handleChange={handleChange}
                                name="address_instructions"
                                value={params?.address_instructions}
                                placeholder="Address Instructions "
                                label="Additional Instructions"
                                error={formErrors?.address_instructions?.length}
                                helperText={formErrors?.address_instructions}
                            />
                            </div>
                            
                        </div>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                        <CustomButton
                            borderRadius="0.5rem"
                            variant="secondary-contained"
                            width="w-[250px] h-[56px]"
                            onClick={handleSubmit}
                        >
                            Submit
                        </CustomButton>

                        <p
                            onClick={handleClose}
                            className="underline text-blueShadow font-bold text-[16px] text-center cursor-pointer"
                        >
                            Cancel
                        </p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default Address;
