import React from 'react'

function InfoLabel({ title, params, flag }) {
    return (
        <p className='text-blueShadow font-satoshiMedium text-[14px] text-center lg:w-[374px] '>
            {title}{' '}
            {flag ? (
                <span className='font-satoshiBold'>
                    {params?.phone}
                </span>
            ) : (
                <></>
            )}
        </p>
    )
}

export default InfoLabel
