const fields = {
    name: "",
    email: "",
    phone: "",
    file: "",
    message: "",
};

const rules = {
    name: "required|max:100",
    phone: "required|numeric",
    email: "required|email",
    file: "required",
    message: "max:200",
};

module.exports = { fields, rules };
