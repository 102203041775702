import React from 'react'
import Cares from './Cares';
import "react-multi-carousel/lib/styles.css";

const ServicesCarousel = ({handleContactFormOpen, setServiceType }) => {

    return (
        <Cares handleContactFormOpen={handleContactFormOpen} setServiceType={setServiceType} />
    )
}

export default ServicesCarousel