import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Validator from 'validatorjs'
import Note from '../components/Note'
import OtpInput from 'react-otp-input'
import Title from '../components/Title'
import { Input } from '../../../common/Input'
import InfoLabel from '../components/InfoLabel'
import CustomButton from '../../../common/Button'
import axiosInstance from '../../../../helpers/axios'
import { useDispatch, useSelector } from 'react-redux'
import { PhoneInput } from '../../../common/PhoneInput'
import { updateCart } from '../../../../features/cart/cartSlice'
import { login, verifyOtp } from '../../../../features/auth/authSlice'

const initialval = {
    phone: '',
    code: '+971',
}

function UserVerification({
    setActiveStep,
}) {
    const dispatch = useDispatch()
    const [params, setParams] = useState(initialval)
    const [errors, setErrors] = useState(initialval) 
    const { show_verify_otp, login_successful, isLoading } = useSelector((state) => state.auth)
    const [show_otp_form, setOtpForm] = useState(false)
    const [otp, setOTP] = useState('')
    const cart = useSelector((state) => state.cart);
    const [timer, setTimer] = useState(60);

    const resetTimer = function () {
        // if (timer) {
        setTimer(60);
        // }
    };

    useEffect(() => {
        const timerInterval =
            timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
        return () => clearInterval(timerInterval);
    }, [timer, show_verify_otp])

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target
            setParams({ ...params, [name]: value })
        }
        setErrors({})
    }

    useEffect(() => {
        if (show_verify_otp) {
            setOtpForm(true)
            resetTimer()
        }
        else {
            setOtpForm(false)
        }
    }, [show_verify_otp])

    useEffect(() => {
        if (login_successful) {
            axiosInstance.get('/cart').then((response) => {
                dispatch(updateCart(response.data))
            })
        }
    }, [login_successful])


    const handleSubmit = () => {
        let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;

        let payload = {
            phone: `${params.phone}`,
            otp: otp,
        }
        const rules = {
            otp: 'required|numeric|digits:5',
        }
        const validation = new Validator(payload, rules)
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            const err = Object.keys(fieldErrors)
            if (err.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            setErrors(fieldErrors)
            if (!regex.test(`${params.phone}`)) {
                setErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
                return
            }
            return false
        }

        dispatch(verifyOtp(payload))
        setErrors({})
        setParams(initialval)
        return true
    }

    const sendOtp = () => {
        let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;

        let payload = {
            phone: `${params.phone}`,
        }
        const rules = {
            phone: 'required|max:15',
        }
        const validation = new Validator(payload, rules)
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            const err = Object.keys(fieldErrors)
            if (err.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            setErrors(fieldErrors)
            return false
        }
        if (!regex.test(`${params.phone}`)) {
            setErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
            return
        }

        dispatch(login(payload))
        setErrors({})
        return true
    }

    const handleOtp = (otp) => {
        setOTP(otp)
    }
    const bookAsGuest = () => {
        axiosInstance
            .post("/cart/book_as_guest", {})
            .then((response) => {
                dispatch(updateCart(response.data));
            });
    };
    const reEnterOTP = () => {
        setOtpForm(false);
        setOTP('')
    }

    return (
        <div>
            <p className="text-xs font-satoshiBold text-Verdigris relative bottom-[20px] lg:hidden md:block block">
                User Verification
            </p>
            <Title title="User Verification" />
            <div className="border-t-0 border border-[#F4F7FA] lg:w-[568px] md:w-[704px] w-[344px] flex flex-col justify-center items-center p-5 rounded-b-lg">
                <InfoLabel
                    params={params}
                    flag={show_otp_form}
                    title={
                        show_otp_form
                            ? "Please Enter the One time password (OTP) sent to:"
                            : "Login with your Mobile Number"
                    }
                />

                {/* Input Phone Number */}
                {show_otp_form ? (
                    <div className="text-center">
                        <div className="mt-[31px]">
                            <OtpInput
                                value={otp}
                                onChange={handleOtp}
                                numInputs={5}
                                separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                inputStyle={{
                                    width: "56px",
                                    height: "56px",
                                    borderRadius: "6px",
                                    border: "1px solid #33B0AA",
                                    color: "black",
                                    outline: "none",
                                }}
                                errorStyle={{
                                    width: "56px",
                                    height: "56px",
                                    borderRadius: "6px",
                                    border: "1px solid red",
                                    color: "black",
                                }}
                                isInputNum
                            />
                        </div>

                        {
                            timer === 0 ?
                                <div className='flex flex-col justify-center items-center mt-5'>
                                    <CustomButton
                                        disabled={isLoading}
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        width="lg:w-[169px] md:w-[160px] w-[160px]"
                                        onClick={() => {
                                            sendOtp()
                                            resetTimer()
                                        }}
                                    >
                                        Resend Otp
                                    </CustomButton>
                                </div> : <p className=" text-secondaryLight text-[16px] font-satoshiMedium mt-[24px]">
                                    {timer} seconds
                                </p>
                        }



                        <p className="text-blueShadow text-[16px] font-satoshiMedium mt-[8px] mb-[5px]">
                            or
                        </p>
                        <span
                            onClick={reEnterOTP}
                        >
                            <p className="text-Verdigris font-satoshiBold underline text-[16px] cursor-pointer">
                                Re-enter mobile Number
                            </p>
                        </span>

                        <div className="mt-3">
                            <CustomButton
                                borderRadius="0.5rem"
                                variant="secondary-contained"
                                width="w-full"
                                onClick={handleSubmit}
                            >
                                Submit
                            </CustomButton>
                        </div>
                    </div>
                ) : (
                    <>
                        {/* <div className="grid grid-flow-col lg:gap-5 gap-2 mt-5">
                            <Input
                                rows={1}
                                width=" lg:w-[79px] w-[69px]"
                                disabled={false}
                                readOnly={true}
                                handleChange={handleChange}
                                value={params?.code}
                                name="code"
                                variant="alternate"
                            />
                            <Input
                                rows={1}
                                width=" lg:w-[344px] md:w-[324px] w-[242px]"
                                disabled={false}
                                handleChange={handleChange}
                                value={params?.phone}
                                error={errors?.phone}
                                helperText={errors?.phone}
                                label="Mobile Number"
                                name="phone"
                                variant="alternate"
                            />
                        </div> */}
                        <div className='mt-5 flex justify-center items-center'>
                            <PhoneInput rows={1}
                                width="lg:w-[344px] md:w-[324px] w-[242px]"
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.phone}
                                error={errors?.phone}
                                helperText={errors?.phone}
                                label="Contact No*"
                                name="phone" />
                        </div>

                        <div className="mt-5">
                            <CustomButton
                                disabled={isLoading}
                                borderRadius="0.5rem"
                                variant="secondary-contained"
                                width="lg:w-[169px] md:w-[160px] w-[160px]"
                                onClick={sendOtp}
                            >
                                Submit
                            </CustomButton>
                        </div>
                        {!cart.authenticated && (
                            <>
                                <p className="text-blueShadow text-[16px] font-satoshiMedium mt-[8px] mb-[5px]">
                                    or
                                </p>
                                <span onClick={bookAsGuest}>
                                    <p className="text-Verdigris font-satoshiBold underline text-[16px] cursor-pointer">
                                        Book test as a Guest user.
                                    </p>
                                </span>
                            </>
                        )}
                    </>
                )}

                <br />
                <Note />
            </div>
        </div>
    );
}

export default UserVerification
