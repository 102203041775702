import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React, { useEffect, useState } from 'react'
import CustomButton from '../../common/Button'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}


const CommonPopup = ({
    open,
    handleClose,
    title,
    title2,
    submit,
    btnText,
    underlineText
}) => {



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                className='md:w-[450px] w-[320px] p-4 md:px-6 md:py-0'
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className='flex flex-col justify-center items-center gap-5 p-5'>
                    <p className='text-lg font-satoshiBold text-BlueJay mt-4'>
                        {title}
                        <span className='text-Verdigris'>{title2}</span>
                    </p>
                    <div className={`mb-2 ${underlineText ? 'flex flex-row-reverse justify-end ml-auto lg:ml-0 items-center lg:flex-col lg:justify-center gap-10 md:gap-20 lg:gap-4' : ''}`}>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            size='large'
                            width='md:w-[180px] w-[150px]'
                            onClick={submit}
                        >
                            {btnText}
                        </CustomButton>
                        <p
                            className='w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiBold'
                            onClick={handleClose}
                        >
                            {underlineText}
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default CommonPopup