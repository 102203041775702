import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { Input } from "../../../common/Input";
import TextArea from "../../../common/TextArea";
import CustomButton from "../../../common/Button";
import { SelectInput } from "../../../common/Select";
import { cityList } from "../../../../helpers/helpers";

import React from 'react'

const style = {
    position: "absolute" ,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
};

const locationTypes = [
    {
        id: "HOME",
        value: "HOME",
    },
    {
        id: "OFFICE",
        value: "OFFICE",
    },
    {
        id: "OTHER",
        value: "OTHER",
    },
];

const AddMember = ({
    open,
    handleClose,
    params,
    formErrors,
    handleChange,
    handleSubmit,
}) => {
    const { isLoading } = useSelector((state) => state.address);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className="md:w-[500px] w-[350px] px-4 py-2 md:px-6 md:py-0"
                sx={style}
                style={{
                    textAlign: "center",
                    borderRadius: "16px",
                    outline: "none",
                }}
            >
                <div className="flex justify-end mt-4">
                    <span className="cursor-pointer" onClick={handleClose}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                stroke="#3C567E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M9.17004 14.8299L14.83 9.16992"
                                stroke="#3C567E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M14.83 14.8299L9.17004 9.16992"
                                stroke="#3C567E"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>
                </div>
                <div className="w-full flex flex-col gap-3 max-h-[700px] overflow-auto mb-6">
                    <p className="font-satoshiBold text-BlueJay text-2xl mb-4">
                        {params.location_type !== '' ? 'Edit Address' : ' Add New Address'}
                    </p>
                    <div className="flex gap-2">
                        <SelectInput
                            readonly={false}
                            width="w-full text-left"
                            handleChange={handleChange}
                            options={locationTypes}
                            value={params?.location_type}
                            error={formErrors?.location_type}
                            helperText={formErrors?.location_type}
                            label="Location Type"
                            name="location_type"
                        />
                        <Input
                            rows={1}
                            width="w-full"
                            disabled={false}
                            readOnly={false}
                            handleChange={handleChange}
                            value={params?.house_no}
                            error={formErrors?.house_no}
                            helperText={formErrors?.house_no}
                            label="House No/ Flat Number"
                            name="house_no"
                        />
                    </div>
                    <div className="flex gap-2">
                        <Input
                            rows={1}
                            width="w-full"
                            disabled={false}
                            readOnly={false}
                            handleChange={handleChange}
                            value={params?.building_name}
                            error={formErrors?.building_name}
                            helperText={formErrors?.building_name}
                            label="Building Name"
                            name="building_name"
                        />
                        <Input
                            rows={1}
                            width="w-full"
                            disabled={false}
                            readOnly={false}
                            handleChange={handleChange}
                            value={params?.locality}
                            error={formErrors?.locality}
                            helperText={formErrors?.locality}
                            label="Locality"
                            name="locality"
                        />
                    </div>

                    <SelectInput
                        readonly={false}
                        width="w-full text-left"
                        handleChange={handleChange}
                        options={cityList}
                        value={params?.city}
                        label="City"
                        name="city"
                    />
                    <TextArea
                        className={'text-[#3C567E]'}
                        rows={5}
                        handleChange={handleChange}
                        name="address_instructions"
                        value={params?.address_instructions}
                        placeholder="Address Instructions "
                        label="Additional Instructions "
                        error={formErrors?.address_instructions?.length}
                        helperText={formErrors?.address_instructions}
                    />
                    <div className="mt-2 flex flex-row-reverse justify-start lg:flex-col lg:justify-center items-center gap-20 lg:gap-4">
                        <CustomButton
                            borderRadius="0.5rem"
                            variant="secondary-contained"
                            size="large"
                            width="md:w-[180px] lg:w-[200px] w-[150px]"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {params.location_type !== '' ? 'Update' : ' Submit'}

                        </CustomButton>
                        <p
                            className="w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiMedium"
                            onClick={handleClose}
                        >
                            Cancel
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};
export default AddMember;
