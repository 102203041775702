import * as _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
const units = ["bytes", "Kb", "Mb", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

/* eslint-disable */
export const uuid = () => {
  let uuid = "";
  let i;
  for (i = 0; i < 32; i += 1) {
    switch (i) {
      case 8:
      case 20:
        uuid += "-";
        uuid += (Math.random() * 16 || 0).toString(16);
        break;
      case 12:
        uuid += "-";
        uuid += "4";
        break;
      case 16:
        uuid += "-";
        uuid += (Math.random() * 4 || 8).toString(16);
        break;
      default:
        uuid += (Math.random() * 16 || 0).toString(16);
    }
  }
  return uuid;
};

export const showToastMessage = (message, type) => {
  if (type === "error") {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

export const genderOptions = [
  {
    value: "Male",
    name: "Male",
  },
  {
    value: "Female",
    name: "Female",
  },];


export const dateRange = (type) => {
  let range = [];

  switch (type) {
    case "0":
      range = [
        moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      ];
      break;
    case "-1":
      range = [
        moment()
          .subtract(1, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      ];
      break;
    case "-7":
      range = [
        moment()
          .subtract(6, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      ];
      break;
    case "-30":
      range = [
        moment()
          .subtract(29, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      ];
      break;
    case "TM":
      range = [
        moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
      ];
      break;
    case "LM":
      range = [
        moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
        moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"),
      ];
      break;
    default:
      range = [];
  }
  return range;
};

export const defaultFiltersDropDown = [
  {
    id: "ALL",
    name: "All",
  },
  {
    id: "0",
    name: "Today",
  },
  {
    id: "-1",
    name: "Yesterday",
  },
  {
    id: "-7",
    name: "Last 7 Days",
  },
  {
    id: "-30",
    name: "Last 30 Days",
  },
  {
    id: "TM",
    name: "This Month",
  },
  {
    id: "LM",
    name: "Last Month",
  },
  {
    id: "custom",
    name: "Custom Date",
  },
];

export const calculateAge = (dd) => {
  let dob = moment(dd).format("YYYY-MM-DD");
  let dob_year = dob?.split("-")[0];
  let d = new Date();
  let curr_year = d.getFullYear();
  let age = curr_year - dob_year;
  return age;
};

export const cityList = [
  {
    id: "Dubai",
    value: "Dubai",
  },
  {
    id: "Sharjha",
    value: "Sharjha",
  },
  {
    id: "Ajman",
    value: "Ajman",
  },
];

export const bytesToKb = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const TitleCase = (str) => {
  if (!str) return;
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getUserName = (user) => {
  if (user.name) {
    return user.name;
  }
  if (
    user.phone &&
    !user.phone.startsWith("971") &&
    !user.phone.startsWith("+971")
  ) {
    return `971${user.phone}`;
  }

  return user.phone;
};

export const validateNumber = (phone) => {
  if (!phone) return true;
  const phoneNumber = !String(phone).startsWith("+") ? `+${phone}` : phone;
  const regex = /^(\+971|0)?5[024568]\d{7}$/;
  return regex.test(phoneNumber);
};

export const slots = [
  "06:00 AM - 06:30 AM",
  "06:30 AM - 07:00 AM",
  "07:00 AM - 07:30 AM",
  "07:30 AM - 08:00 AM",
  "08:00 AM - 08:30 AM",
  "08:30 AM - 09:00 AM",
  "09:00 AM - 09:30 AM",
  "09:30 AM - 10:00 AM",
  "10:00 AM - 10:30 AM",
  "10:30 AM - 11:00 AM",
  "11:00 AM - 12:00 PM",
  "12:00 PM - 12:30 PM",
  "12:30 PM - 01:00 PM",
  "01:00 PM - 01:30 PM",
  "01:30 PM - 02:00 PM",
  "02:00 PM - 02:30 PM",
  "02:30 PM - 03:00 PM",
  "03:00 PM - 03:30 PM",
  "03:30 PM - 04:00 PM",
  "04:00 PM - 04:30 PM",
  "04:30 PM - 05:00 PM",
  "05:00 PM - 05:30 PM",
  "05:30 PM - 06:00 PM",
  "06:00 PM - 06:30 PM",
  "06:30 PM - 07:00 PM",
  "07:00 PM - 07:30 PM",
  "07:30 PM - 08:00 PM",
  "08:00 PM - 08:30 PM",
  "08:30 PM - 09:00 PM",
  "09:00 PM - 09:30 PM",
  "09:30 PM - 10:00 PM",
  "10:00 PM - 10:30 PM",
  "10:30 PM - 11:00 PM",
  "11:00 PM - 11:30 PM",
  "11:30 PM - 12:00 AM",
  "12:00 AM - 12:30 AM",
  "12:30 AM - 01:00 AM",
  "01:00 AM - 01:30 AM",
  "01:30 AM - 02:00 AM",
  "02:00 AM - 02:30 AM",
  "02:30 AM - 03:00 AM",
  "03:00 AM - 03:30 AM",
  "03:30 AM - 04:00 AM",
  "04:00 AM - 04:30 AM",
  "04:30 AM - 05:00 AM",
  "05:00 AM - 05:30 AM",
  "05:30 AM - 06:00 AM",
];

export const nationalityList = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepalese",
  "New Zealander",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian or Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];
