const fields = {
    name: '',
    email: '',
    phone: '',
    gender: '',
    location: '',
    experience: '',
    resume: '',
    nationality:''
}

const rules = {
    name: 'required|max:100',
    phone: 'required|numeric',
    email: 'required|email',
    gender: 'required|max:500',
    location: 'required|max:500',
    experience: 'required|numeric',
    resume: 'required',
}

module.exports = { fields, rules }
