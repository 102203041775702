import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { store } from "./store";
import { Provider } from "react-redux";

import Faq from "./components/Faq";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import Terms from "./components/Terms";
import Contact from "./components/Pages/contact/Contact";
import Support from "./components/Pages/support/Support";
import Careers from "./components/Pages/careers/Careers";
import ViewJob from "./components/Pages/careers/ViewJob";
import About from "./components/Pages/About";
import PageNotFound from "./components/PageNotFound";
import Partner from "./components/Partner";
import ApplyFilter from "./components/ApplyFilter";
import Packages from "./components/Pages/packages/Packages";
import View from "./components/Pages/packages/View";
import Home from "./components/Home";
import Whatsapp from "./components/Whatsapp";
import FloatingCart from "./components/FloatingCart";
import PaymentProcess from "./components/PaymentProcess";
import { ErrorBoundary } from "./components/ErrorBoundry";

import MyProfile from "./components/Pages/MyProfile/MyProfile";
import Profile from "./components/Pages/MyProfile/Profile/Profile";
import OrderCheckout from "./components/Pages/orders/OrderCheckout";
import MyFamily from "./components/Pages/MyProfile/MyFamily/MyFamily";
import MyReports from "./components/Pages/MyProfile/MyReports/MyReports";
import MyBookings from "./components/Pages/MyProfile/MyBookings/MyBookings";
import BookingDetails from "./components/Pages/MyProfile/MyBookings/BookingDetails";
import ManageAddress from "./components/Pages/MyProfile/ManageAddress/ManageAddress";
import LifeSavingsCarousel from "./LifeSavingsCarousel";
// import Temp from "./components/Pages/Temp";

function App(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (typeof window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <div className="overflow-hidden">
          <Whatsapp />
          <FloatingCart />
          <Routes>
            <Route
              path="/"
              exact
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />

            <Route
              path="/about-us"
              exact
              element={
                <>
                  <Header />
                  <About />
                  <Footer />
                </>
              }
            />

            <Route
              path="/user-profile"
              element={
                <>
                  <Header />
                  <MyProfile />
                  <Footer />
                </>
              }
            />
            <Route
              path="/user-profile/my-profile"
              element={
                <>
                  <Profile />
                </>
              }
            />
            <Route
              path="/user-profile/my-family-friends"
              element={
                <>
                  <MyFamily />
                </>
              }
            />
            <Route
              path="/user-profile/bookings"
              element={
                <>
                  <MyBookings />
                </>
              }
            />
            <Route
              path="/user-profile/bookings/booking-details/:id"
              element={
                <>
                  <BookingDetails />
                  <Footer />
                </>
              }
            />
            <Route
              path="/user-profile/reports"
              element={
                <>
                  <MyReports />
                </>
              }
            />
            <Route
              path="/user-profile/manage-address"
              element={
                <>
                  <ManageAddress />
                </>
              }
            />

            <Route
              path="/orders/checkout"
              element={
                <>
                  <OrderCheckout />
                </>
              }
            />

            <Route
              path="/faq"
              exact
              element={
                <>
                  <Header />
                  <Faq />
                  <Footer />
                </>
              }
            />

            <Route
              path="/privacy-policy"
              exact
              element={
                <>
                  <Header />
                  <PrivacyPolicy />
                  <Footer />
                </>
              }
            />

            <Route
              path="/search_filter"
              exact
              element={
                <>
                  <ApplyFilter />
                </>
              }
            />

            <Route
              path="/cookie-policy"
              exact
              element={
                <>
                  <Header />
                  <CookiePolicy />
                  <Footer />
                </>
              }
            />

            <Route
              path="/terms-conditions"
              exact
              element={
                <>
                  <Header />
                  <Terms />
                  <Footer />
                </>
              }
            />

            <Route
              path="/contact-us"
              exact
              element={
                <>
                  <Header />
                  <Contact />
                </>
              }
            />

            <Route
              path="/support"
              exact
              element={
                <>
                  <Header />
                  <Support />
                </>
              }
            />

            <Route
              path="/careers"
              exact
              element={
                <>
                  <Header />
                  <Careers />
                  <Footer />
                </>
              }
            />

            <Route
              path="/careers/job-details/:id"
              exact
              element={
                <>
                  <Header />
                  <ViewJob />
                  <Footer />
                </>
              }
            />

            <Route
              path="/partner-us"
              exact
              element={
                <>
                  <Header />
                  <Partner />
                </>
              }
            />

            <Route
              path="/packages"
              exact
              element={
                <>
                  <Header />
                  <Packages title="All Packages" />
                  <Footer />
                </>
              }
            />

            <Route
              path="/packages/:slug"
              exact
              element={
                <>
                  <Header />
                  <View />
                  <Footer />
                </>
              }
            />
            <Route
              path="/payment_process/:id"
              element={
                <>
                  <Header />
                  <PaymentProcess />
                  <Footer />
                </>
              }
            />

            <Route
              path="/tests/:slug"
              exact
              element={
                <>
                  <View isTest title="View Test Information" />
                  <Footer />
                </>
              }
            />

            <Route
              path="*"
              exact
              element={
                <>
                  <PageNotFound />
                </>
              }
            />
          </Routes>
        </div>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
