import JobCard from "./JobCard";
import { useEffect } from "react";
import CustomButton from "../../common/Button";
import { uuid } from "../../../helpers/helpers";
import React, { useRef, useState } from "react";
import axiosInstance from "../../../helpers/axios";
import updateMeta from "../../../helpers/seo-meta";
import CircularProgress from "@mui/material/CircularProgress";

function Careers() {

    useEffect(() => {
        document.title = "Careers | Zain Care - Delivering High Quality Healthcare";
        updateMeta('description', `Explore careers at ZainCare! Join our mission to provide smart, affordable home healthcare solutions. `);
    }, []);
    

    const [isLoading, setIsLoading] = useState(true);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(6);

    const [lastPage, setLastPage] = useState(0);

    const learnMore = useRef()

    // scroll into view
    const handleLearnMore = () => {
        learnMore.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'start',
        })
    }

    const getAllJobs = () => {
        axiosInstance
            .get(`/careers?page=${page}&limit=${limit}`)
            .then((response) => {
                let thisData = response.data.data.data;
                let meta = response.data.data.meta;
                setLastPage(meta.last_page);
                setJobs(thisData);
                setIsLoading(false);
            })
            .catch((error) => {
            });
    };

    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        getAllJobs();
    }, []);

    useEffect(() => {
        getAllJobs();
    }, [page, limit]);

    const getNext = () => {
        if (limit === 10) {
            setPage((page) => page + 1);
            return;
        }
        setLimit((limit) => limit + 2);
    };

    const width = typeof window !== 'undefined' ? window.innerWidth : 1000;
    const buttonHeight = width <= 768 ? "47px" : "56px";

    const [email, setEmail] = useState('')
    const [isValidEmail, setIsValid] = useState('')

    const jobAlert = () => {
        axiosInstance.post("/job-alert-request", { email: email }).then((response) => {
            const thisData = response.data
            setAck('Request Submitted Successfully!')
            setTimeout(() => {
                setAck('')

            }, 5000)
        }).catch((error) => {
        })
    }

    const handleSubscribe = () => {
        // const validator = new Validator({email:''}, {email:'required|email'})
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            setIsValid('valid')
            jobAlert()
            setEmail('')
        }
        else {
            setIsValid('invalid')
            return;
        }
    }

    const [ack, setAck] = useState('')

    return (
        <div className="lg:w-[1440px] m-auto relative top-[100px] mb-[100px]">
            {/* Heading && Animation */}
            <div className="flex lg:flex-row md:flex-row flex-col lg:justify-between md:justify-between lg:px-5 justify-center items-center relative lg:left-[30px] ">
                <div className="lg:w-[663px] w-full flex flex-col p-10 ">
                    <p className=" lg:text-[64px] md:text-[36px] text-[30px] text-blueShadow font-jostSemibold lg:leading-[73px] md:leading-[42px] leading-[32px]">
                        Find the{" "}
                        <span className="text-Verdigris block">
                            Perfect jobs
                        </span>{" "}
                        that you Deserve
                    </p>
                    <br />
                    {/* for phone */}
                    <img
                    
                        src='/images/images/lab_tech.svg'
                        alt='labe Tech'
                        className="lg:hidden md:hidden w-[366px] h-[365px] flex "
                    />
                    <br />
                    <p className="lg:mt-[-22px] md:mt-[15px] mt-[-56px] text-secondaryLight font-satoshiRegular lg:leading-[26px] md:leading-[16px] leading-[20px] lg:text-[20px] md:text-[14px] text-[16px] lg:w-[487px] md:w-[321px] text-start ">
                        At ZainCare, we are passionate about providing high-quality healthcare. We understand that the key to achieving this goal is by hiring talented and dedicated professionals who share our values
                        and work culture.
                    </p>
                    <br />
                    <div className="flex flex-row gap-5 ">
                        <CustomButton
                            width="w-full sm:w-44 "
                            borderRadius="0.5rem"
                            variant="secondary-contained"
                            size="large"
                            height={buttonHeight}
                            onClick={handleLearnMore}
                        >
                            Learn More
                        </CustomButton>
                    </div>
                </div>
                {/* for desktop & tab */}
                <img
                    src='/images/images/lab_tech.svg'
                    alt="Lab tech"
                    className="lg:w-[800px] lg:h-[800px] w-[400px] h-[400px] hidden md:flex lg:flex relative lg:right-[0px] lg:top-[35px]"
                />
            </div>

            {/* Job Cards */}
            <div ref={learnMore} className="flex flex-col justify-center items-center ">

                <p className="font-jostSemibold text-blueShadow lg:text-[36px] text-[24px]">
                    {/* Recent Job Openings */}
                    Job Openings

                </p>
                <br />

                {isLoading ? (
                    <p className="text-center text-3xl text-CadetGrey font-satoshiMedium flex flex-row gap-2 items-center">
                        <CircularProgress sx={{ color: "#909EB4" }} />
                        <span>Loading...</span>
                    </p>
                ) : (
                    <>
                        {jobs.length > 0? (
                            <>
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-[30px] gap-5">
                                    {jobs.map((job) => (
                                        <JobCard key={uuid()} job={job} />
                                    ))}
                                </div>
                                <br />
                                {/* View more */}
                                {page === lastPage ? (
                                    <></>
                                ) : (
                                    <p
                                        onClick={getNext}
                                        className="text-Verdigris font-satoshiBold lg:text-[24px] text-[16px] mt-[71px] underline cursor-pointer"
                                    >
                                        View More
                                    </p>
                                )}
                            </>
                        ) : (
                            <p className="text-center font-satoshiMedium text-lg">
                                No job openings currently
                            </p>
                        )}
                    </>
                )}
            </div>

            <div className="flex flex-col lg:text-start md:text-start text-center p-10">
                {/* Culture */}
                <div className="flex lg:flex-row md:flex-row flex-col justify-center items-center">
                    {/* for phone */}
                    <p className=" font-jostSemibold text-blueShadow lg:hidden md:hidden block text-center lg:text-[36px] text-[24px] lg:leading-[52px] leading-[35px] mb-[13px]">
                        Our Culture
                    </p>

                    <img
                        width={500} height={500}
                        src='/images/images/culture.png' alt='Our Culture' />

                    <div className="lg:w-[487px] lg:h-[289px] relative lg:bottom-[120px] lg:mb-[-120px] md:bottom-[120px]">
                        <p className=" font-jostSemibold text-blueShadow lg:block md:block hidden lg:text-[36px] text-[24px] lg:leading-[52px] leading-[35px] mb-[13px]">
                            Our Culture
                        </p>
                        <div className="relative lg:bottom-0 md:bottom-0 bottom-[80px] mb-[-80px]">
                            <div className="flex flex-row justify-start item-center font-bold text-blueShadow lg:text-[20px] md:text-[14px] text-[16px] lg:leading-[26px] md:leading-[16px] leading-[26px] ">
                                <div className="lg:border-l-[3px] border-[#33B0AA] lg:p-2 lg:h-[52px]" />
                                <p>
                                    We believe that our{" "}
                                    <span className="text-Verdigris">
                                        culture {" "}
                                    </span>
                                    is the backbone
                                    <span className="lg:block">
                                        of our success
                                    </span>{" "}

                                </p>
                            </div>
                            <p className="text-secondaryLight font-satoshiRegular lg:text-[22px] text-[14px] lg:mt-[14px] mt-[8px] ">
                                Our culture values respect, collaboration, and innovation. We provide a supportive environment for employee growth and are committed to creating a space where everyone can thrive and be motivated.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Values */}
                <div className="mt-[64px] flex lg:flex-row md:flex-row flex-col-reverse justify-center items-center relative lg:top-[-250px] lg:bottom-[120px] lg:mb-[-120px] md:bottom-[180px] md:mb-[-180px]">
                    <div className="lg:w-[487px] lg:h-[289px] relative lg:bottom-[120px] lg:mb-[-120px] md:bottom-[120px]">
                        <p className="font-jostSemibold text-blueShadow lg:block md:block hidden lg:text-[36px] text-[24px] lg:leading-[52px] leading-[35px] mb-[13px]">
                            Our Values
                        </p>
                        <div className="relative lg:bottom-0 md:bottom-0 bottom-[80px] mb-[-80px]">
                            <div className="flex flex-row justify-start item-center font-bold text-blueShadow lg:text-[20px] md:text-[14px] text-[16px] lg:leading-[26px] md:leading-[16px] leading-[26px] ">
                                <div className="lg:border-l-[3px] border-[#33B0AA] lg:p-2 lg:h-[52px]" />
                                <p>
                                    We believe that our{" "}
                                    <span className="text-Verdigris">
                                        values {" "}
                                    </span>
                                    are the guiding principles
                                    <span className="lg:block">
                                        of our company
                                    </span>{" "}

                                </p>
                            </div>
                            <p className="text-secondaryLight font-satoshiRegular lg:text-[22px] text-[14px] lg:mt-[14px] mt-[8px] ">
                                We value teamwork and believe in working collaboratively as a family. We also place a strong emphasis on ehtical behaviour and integrity and we strive to maintain the highest standards of professionalism at all times.
                            </p>
                        </div>
                    </div>
                    <img
                        width={500} height={500}
                        src='/images/images/values.png' alt='Our Values' />

                    {/* for phone */}
                    <p className="font-jostSemibold text-blueShadow lg:hidden md:hidden block text-center lg:text-[36px] text-[24px] lg:leading-[52px] leading-[35px] mb-[13px]">
                        Our Values
                    </p>
                </div>
            </div>

            {/* <CultureAndValues /> */}

            <p className="text-center text-blueShadow font-jostSemibold lg:text-[36px] text-[24px] lg:mt-[-250px]">
                Get new job alerts by email
            </p>
            <div className="flex flex-row justify-center items-center  p-10">
                <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                    className={`placeholder:text-[16px] outline-none ${isValidEmail === 'invalid' ? 'border-[#FF0000] border' : ''} placeholder:font-medium placeholder:text-secondaryLight placeholder:font-satoshiRegular bg-[#EBEEF2] lg:w-[37%] p-3 rounded-lg`}
                    placeholder="Enter your Email ID"
                />
                <CustomButton
                    onClick={handleSubscribe}
                    borderRadius="0.5rem"
                    width="lg:w-[156px] md:w-[156px] w-[117px] relative right-[10px]"
                    variant="contained"
                    size="large"
                >
                    Submit
                </CustomButton>
            </div>
            {
                isValidEmail === 'invalid' && (
                    <p className="text-[#FF0000] text-xs font-satoshiMedium text-center relative bottom-[35px] lg:right-[270px] md:right-[120px] right-[100px]">Enter valid email</p>
                )
            }

            {ack && (<p className="text-base text-blueShadow text-center relative bottom-[35px]">{ack}</p>)}
            <div className="lg:pb-[55px]" />
        </div>
    );
}

export default Careers;
