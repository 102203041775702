import React from 'react'
import Box from '@mui/material/Box'
import OtpInput from 'react-otp-input'
import Modal from '@mui/material/Modal'
import CustomButton from '../../common/Button'
import { PhoneInput } from '../../common/PhoneInput'
import { useDispatch, useSelector } from 'react-redux'
import { initialReset } from '../../../features/auth/authSlice'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}

const Login = ({
    loginOpen,
    handleLoginClose,
    handleOtp,
    otp,
    handleChange,
    sendOtp,
    errors,
    loginflag,
    toPrivacyPolicy,
    params,
    handleSubmit,
    counter,
    reEnterNumber
}) => {
    const { isLoading } = useSelector((state) => state.auth);

    const dispatch = useDispatch()

    return (
        <Modal
            open={loginOpen}
            onClose={() => {
                dispatch(initialReset({}))
                handleLoginClose()
            }}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                className='md:w-[420px] w-[320px] p-6'
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className='flex justify-end '>
                    <span
                        className='cursor-pointer'
                        onClick={() => {
                            dispatch(initialReset({}))
                            handleLoginClose()
                        }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.17004 14.8299L14.83 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.83 14.8299L9.17004 9.16992" stroke="#3C567E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </span>
                </div>
                {loginflag ? (
                    <>
                        <div className='text-left'>
                            <p className='text-Verdigris mt-1 md:text-3xl text-2xl font-jostSemibold'>Verify OTP</p>
                            <p className='text-BlueJay mt-2 text-sm text-center md:text-left'>
                                Please Enter the One time password (OTP) sent to: <br className='hidden md:block' /> <strong>{` ${params?.phone}`}</strong>
                            </p>
                        </div>
                        <div className='mt-4 text-center'>
                            <div className='flex md:flex-row flex-col justify-center items-center gap-2'>
                                <OtpInput
                                    value={otp}
                                    onChange={handleOtp}
                                    numInputs={5}
                                    // separator={<span>&nbsp;&nbsp;</span>}
                                    inputStyle={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '6px',
                                        border: '1px solid #33B0AA',
                                        color: 'black',
                                    }}
                                    errorStyle={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '6px',
                                        border: '1px solid red',
                                        color: 'black',
                                    }}
                                    renderInput={(props) => <input {...props} />}
                                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                                    isInputNum
                                    hasErrored={errors?.otp}
                                />
                                <p className='mr-auto text-secondaryLight text-sm font-bold'>
                                    <div className='flex gap-1'>
                                        <span>{counter}</span> <span>seconds</span>
                                    </div>
                                </p>
                            </div>
                            {
                                errors?.otp &&
                                <p className='text-left text-xs text-[#EF4949] mt-1'>OTP field is required.</p>
                            }
                            <span
                                onClick={reEnterNumber}
                            >
                                <p className='w-fit mt-3 text-left text-BlueJay font-bold border-b-[2px] border-BlueJay text-sm cursor-pointer'>
                                    Re-enter mobile Number
                                </p>
                            </span>
                            <div className='mt-3'>
                                <CustomButton
                                    borderRadius='0.5rem'
                                    variant='secondary-contained'
                                    width='w-full'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </CustomButton>
                            </div>

                            <p className='text-left text-BlueJay text-xs mt-3'>
                                By proceeding, you agree to our
                                <span
                                    className='cursor-pointer border-b-[1px] border-BlueJay ml-1'
                                    onClick={toPrivacyPolicy}
                                >
                                    Privacy policy.
                                </span>
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='text-left mt-2'>
                            <p className='text-BlueJay md:text-xl text-lg font-jostSemibold'>Hello User,</p>
                            <p className='text-Verdigris md:text-3xl text-2xl font-jostSemibold'>Welcome to Zaincare</p>
                            <p className='text-BlueJay mt-2 text-sm font-satoshiRegular'>Login with your Mobile Number</p>
                        </div>

                        {/* <div className='flex flex-row md:gap-4 gap-2 mt-5'>
                            <Input
                                rows={1}
                                width='md:w-[90px] w-[120px]'
                                disabled={false}
                                readOnly={true}
                                handleChange={handleChange}
                                value={"+971"}
                                label='code'
                                name='code'
                            />
                            <Input
                                rows={1}
                                width=' w-[344px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.phone}
                                error={errors?.phone}
                                helperText={errors?.phone}
                                label='Mobile Number'
                                name='phone'
                            />
                        </div> */}
                        <div className='mt-5' />
                        <PhoneInput rows={1}
                            width="w-full"
                            disabled={false}
                            readOnly={false}
                            handleChange={handleChange}
                            value={params?.phone}
                            error={errors?.phone}
                            helperText={errors?.phone}
                            label="Mobile Number*"
                            name="phone" />



                        <div className='mt-5'>
                            <CustomButton
                                disabled={isLoading}
                                borderRadius='0.5rem'
                                variant='secondary-contained'
                                width='w-full'
                                onClick={sendOtp}
                            >
                                Login
                            </CustomButton>
                        </div>

                        <p className='text-left text-BlueJay text-sm mt-3 font-satoshiRegular'>
                            By proceeding, you agree to our
                            <span
                                className='cursor-pointer border-b-[1px] border-BlueJay ml-1'
                                onClick={toPrivacyPolicy}
                            >
                                Privacy policy.
                            </span>
                        </p>
                    </>
                )}
            </Box>
        </Modal>
    )
}
export default Login