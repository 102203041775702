import React from 'react'

const Next = ({ onClick }) => {
    return (
        <div className='flex justify-center items-center'>
            <button
                onClick={onClick}
                className='bg-Verdigris text-white text-[14px] font-satoshiBold py-[13px] px-[32px] rounded-lg '
            >
                Next
            </button>
        </div>
    )
}

export default Next
