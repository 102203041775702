// Slide.js
import React from "react";

const Slide = ({ index, currentSlide, img, title, info }) => {
  const isCurrentSlide = currentSlide === index;

  return (
    <div
      className={`${
        isCurrentSlide ? "scale-100" : "scale-75"
      } carousel-slide relative w-full flex-none justify-center transition-all duration-1000 lg:w-[55%]`}
    >
      <div className="relative flex  w-full items-center justify-center md:h-auto ">
        <div className="relative">
          <p
            className={`relative   ${
              currentSlide === index
                ? "left-[36vw] transition-all duration-[1500ms] ease-in-out lg:left-[18vw]"
                : "left-[10vw] transition-all  delay-[3000ms] duration-75"
            } -top-16 font-jostBold text-[4rem] text-[#D5E4E9] md:-top-28 md:text-[5rem] lg:text-[8rem] `}
          >
            {index + 1}
          </p>
        </div>

        <div className="relative">
          <img
            src="/images/shapes/hexagonLight.svg"
            alt="hexa"
            className={`${
              currentSlide === index
                ? "left-[10vw] transition-all duration-[1500ms] ease-in-out"
                : "left-[0vw]  transition-all delay-[3000ms] duration-1000"
            } relative bottom-0 top-10 z-10 md:top-16`}
          />
        </div>

        <div className="relative">
          <img
            src={img}
            alt="hexa"
            className={`${
              currentSlide === index
                ? "-translate-y-28 transition-all duration-[1500ms] "
                : "translate-y-28 transition-all delay-[3000ms] duration-1000"
            } relative top-28  z-40`}
          />
        </div>

        <div className="relative">
          <img
            src="/images/shapes/hexagonLight.svg"
            alt="hexa"
            // className="relative -top-14 right-[10vw] z-10 "
            className={`${
              currentSlide === index
                ? "right-[10vw] transition-all duration-[1500ms] ease-in-out"
                : "right-[0vw]  transition-all delay-[3000ms] duration-1000"
            } relative -top-10 bottom-0 z-10 md:-top-14`}
          />
        </div>
      </div>

      <div
        className={`${
          currentSlide === index
            ? "   transition-all duration-[1500ms] ease-in-out"
            : " transition-all delay-[3000ms] duration-1000 ease-in-out "
        } relative  left-1/2 -translate-y-10  z-40 h-auto w-10/12  -translate-x-1/2 transform rounded-2xl  bg-white p-4 shadow-2xl`}
      >
        <div className="w-full">
          <p className="mt-[10px] text-center font-satoshiBlack text-blueShadow lg:text-2xl">
            {title ?? "Sunt elit et qui ea."}
          </p>
          <p className="mb-[16px] text-center font-satoshiMedium text-sm text-[#909EB4] md:text-base  lg:text-base ">
            {info ??
              "Quis cillum labore ipsum est eu. Sit velit ad laboris nisi minim esse dolor do est."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Slide;
