import moment from 'moment'
import Validator from 'validatorjs'
import Box from '@mui/material/Box'
import Next from '../components/Next'
import { toast } from 'react-toastify'
import Modal from '@mui/material/Modal'
import Title from '../components/Title'
import { Input } from '../../../common/Input'
import Previous from '../components/Previous'
import InfoLabel from '../components/InfoLabel'
import CustomButton from '../../../common/Button'
import React, { useEffect, useState } from 'react'
import { SelectInput } from '../../../common/Select'
import MemberSelect from '../components/MemberSelect'
import axiosInstance from '../../../../helpers/axios'
import { useDispatch, useSelector } from 'react-redux'
import { PhoneInput } from '../../../common/PhoneInput'
import CommonDatepicker from '../../../common/DatePicker'
import { updateCart } from '../../../../features/cart/cartSlice'
import SelectNationality from '../../../common/SelectNationality'
import { genderOptions, validateNumber } from '../../../../helpers/helpers'
import { addMembers, fetchMembers } from '../../../../features/userProfile/familySlice'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}

const initialValue = {
    name: '',
    email: '',
    dob: null,
    phone: '',
    gender: '',
    age: 0,
    relation: '',
    nationality: ''
}

function Patients({ setActiveStep }) {
    const dispatch = useDispatch()
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)

    const handleClose = () => {
        setOpen(false)
        // ZC - 263 clear inputs when modal is closed or after success
        setParams({
            name: '',
            email: '',
            dob: '',
            phone: '',
            gender: '',
            age: 0,
            relation: '',
            nationality: ''
        })
        setFormErrors({})
    }
    const { familyFriends } = useSelector((state) => state.family)
    const { isCreateSuccess } = useSelector((state) => state.profile)

    const cart = useSelector((state) => state.cart);

    const [params, setParams] = useState(initialValue)
    const [formErrors, setFormErrors] = useState(initialValue)
    const [list, setList] = useState([])


    const onDateChange = (date) => {
        let date2 = moment(date).format('YYYY-MM-DD HH:mm:ss')
        if (date2 !== 'Invalid date') {
            setParams({ ...params, dob: date, age: moment().diff(date2, 'years') })
        }
    }

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target
            if (name === 'age') {
                const re = /^[0-9\b]+$/
                if (value && !re.test(value)) {
                    return
                }
            }
            setParams({ ...params, [name]: value })
        }
        setFormErrors({})
    }



    const handleSubmit = () => {

        if (params.phone && !validateNumber(`${params.phone}`)) {
            setFormErrors({ ...formErrors, phone: 'Invalid Phone Number' })
            return
        }

        let payload = {
            name: params.name,
            dob: moment(params.dob).format('YYYY-MM-DD'),
            gender: params.gender,
            phone: `${params.phone}`,
            relation: params.relation,
            email: params.email,
            nationality: params.nationality
        }

        const rules = {
            name: 'required|string|max:50',
            dob: 'required',
            phone: 'required|max:15',
            gender: 'required',
            email: 'required|email|max:225',
            relation: 'required|string|max:50',
            nationality: 'required',
        }

        const validation = new Validator(payload, rules)

        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            const err = Object.keys(fieldErrors)
            if (err.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            setFormErrors(fieldErrors)
            let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;
            if (!regex.test(`${params.phone}`)) {
                setFormErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
                return
            }
            return false
        }


        dispatch(addMembers(payload))
        dispatch(fetchMembers())
        setFormErrors({})
        handleClose()
        return true
    }

    useEffect(() => {
        if (cart.authenticated) {
            dispatch(fetchMembers())
        }
    }, [])

    useEffect(() => {
        if (isCreateSuccess) {
            dispatch(fetchMembers())
        }
    }, [isCreateSuccess])

    useEffect(() => {
        let patientList = JSON.parse(JSON.stringify(familyFriends));
        for (let patient of patientList) {
            if (!cart.is_guest_user && cart.patient_list.length) {
                patient["checked"] = cart.patient_list.some(
                    (x) =>
                        x.name === patient.name && x.phone === patient.phone
                )
            } else {
                patient["checked"] = false;
            }
        }
        setList(patientList);
    }, [familyFriends]);

    const onSelect = (checked, item) => {
        let patientList = [...list]
        let index = patientList.findIndex((x) => x.id === item.id)
        if (index != -1) {
            patientList[index]['checked'] = checked
        }
        setList(patientList)
    }

    const addPatients = (e) => {
        e.preventDefault()
        let selected = list.some((x) => x.checked)
        if (!selected) {
            toast.error('Please Select atleast one patient')
            return
        }

        axiosInstance
            .post('/cart/add_patient', {
                list: list.filter((x) => x.checked),
            })
            .then((response) => {
                dispatch(updateCart(response.data))
                setActiveStep(2)
            })
    }

    const addGuestPatient = (e) => {
        e.preventDefault()
        let payload = {
            name: params.name,
            dob: params.dob,
            gender: params.gender,
            phone: `${params.phone}`,
            age: params.dob ? moment().diff(params.dob, 'years') : '',
            relation: 'self',
            email: params.email,
            nationality: params.nationality
        }
        const rules = {
            name: 'required|string|max:50',
            dob: 'required',
            phone: 'required',
            gender: 'required',
            relation: 'required|string|max:50',
            email: 'required|email',
            nationality: 'required'
        }

        const validation = new Validator(payload, rules)
        if (validation.fails()) {
            const fieldErrors = {}
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0]
            })
            const err = Object.keys(fieldErrors)
            if (err.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            setFormErrors(fieldErrors)
            let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[01256])[0-9]{7}$/;
            if (!regex.test(`${params.phone}`)) {
                setFormErrors(({ ...formErrors, phone: 'Invalid Phone Number' }));
                return
            }
            return false
        }


        axiosInstance
            .post('/cart/add_patient', {
                list: [payload],
            })
            .then((response) => {
                dispatch(updateCart(response.data))
                setActiveStep(2)
            })
    }

    useEffect(() => {
        if (cart.is_guest_user && cart.patient_list.length) {
            let patient = { ...cart.patient_list[0], dob: Date.parse(cart.patient_list[0].dob) }

            patient.phone = patient.phone.replace('+971', '')
            setParams({ ...params, ...patient })
        }
    }, [])

    const captureNationality = (event, newValue) => {
        setParams({ ...params, nationality: newValue });
        setFormErrors({})
    }

    return (
        <div>
            <p className='text-xs font-satoshiBold text-Verdigris relative md:left-[180px] left-[80px] bottom-[20px] lg:hidden md:block block'>
                Patient
            </p>
            <Title title='Add Patient' />
            {cart.is_guest_user ? (
                <>
                    <div className='border-t-0 border border-[#F4F7FA] lg:w-[568px] md:w-[704px] w-[344px] flex flex-col justify-center items-center  rounded-b-lg'>
                        <div className='w-full grid grid-flow-row justify-center items-center mt-[12px]'>
                            <InfoLabel title='Enter the Patient Information' />
                            <Input
                                rows={1}
                                width=' lg:w-[400px] md:w-[400px] w-[311px] mt-[16px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.name}
                                error={formErrors?.name}
                                helperText={formErrors?.name}
                                label='Name*'
                                name='name'
                            />
                            {/* <div className='grid grid-flow-col lg:gap-[16px] md:gap-[16px] gap-[12px] mt-[16px]'>
                                <Input
                                    rows={1}
                                    width=' lg:w-[87px] md:w-[59px] w-[69px]'
                                    disabled={false}
                                    readOnly={true}
                                    value='+971'
                                    name='code'
                                />
                                <Input
                                    rows={1}
                                    width=' lg:w-[297px] md:w-[326px] w-[230px]'
                                    disabled={false}
                                    handleChange={handleChange}
                                    value={params?.phone}
                                    error={formErrors?.phone}
                                    helperText={formErrors?.phone}
                                    label='Phone Number*'
                                    name='phone'
                                />
                            </div> */}
                            <div className='mt-[16px]' />
                            <PhoneInput rows={1}
                                width="w-full"
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.phone}
                                error={formErrors?.phone}
                                helperText={formErrors?.phone}
                                label="Contact No*"
                                name="phone" />

                            <Input
                                rows={1}
                                width=' lg:w-[400px] md:w-[400px] w-[311px] mt-[16px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.email}
                                error={formErrors?.email}
                                helperText={formErrors?.email}
                                label='Email*'
                                name='email'
                            />

                            <SelectInput
                                width='lg:w-[400px] md:w-[400px] w-[311px] mt-[16px]'
                                handleChange={handleChange}
                                options={genderOptions}
                                label='Gender*'
                                name='gender'
                                value={params?.gender}
                                error={formErrors?.gender}
                                helperText={formErrors?.gender}
                            />
                            <div className='mt-[16px]'>
                                <CommonDatepicker
                                    label='Date of Birth*'
                                    value={params?.dob}
                                    onChange={onDateChange}
                                    error={formErrors?.dob}
                                    helperText={formErrors?.dob}
                                />
                            </div>

                            <div className='lg:w-[400px] md:w-[400px] w-[311px] mt-[16px]'>
                                <SelectNationality
                                    error={formErrors?.nationality}
                                    helperText={formErrors?.nationality}
                                    value={params?.nationality}
                                    captureNationality={captureNationality} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row justify-center items-center gap-5 my-4'>
                        <Next onClick={addGuestPatient} />
                    </div>
                </>
            ) : (
                <>
                    <div className='border-t-0 border border-[#F4F7FA] lg:w-[568px] md:w-[704px] w-[344px] flex flex-col justify-center items-center p-5 rounded-b-lg'>
                        <div className='lg:h-[220px] h-[190px] overflow-y-scroll'>
                            <div className='grid grid-col gap-[16px] justify-center items-center w-[444px] mx-[52px]'>
                                {list.map((item) => (
                                    <MemberSelect onSelect={onSelect} data={item} />
                                ))}
                                {familyFriends && !familyFriends.length && (
                                    <div>No Member Found.</div>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row justify-center items-center gap-[8px] mt-[50px]'>
                            <p className='text-blueShadow text-[14px]'>Not in the list ?</p>

                            <p
                                onClick={handleOpen}
                                className='font-satoshiBold text-base text-center my-2 text-Verdigris underline cursor-pointer'
                            >
                                +Add New Patient
                            </p>
                        </div>
                    </div>
                    <div className='flex justify-center items-center mt-1'>
                        <Next onClick={addPatients} />
                    </div>
                </>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box
                    sx={{ ...style, width: { lg: '543px', sm: '450px', xs: '343px' } }}
                    style={{
                        textAlign: 'center',
                        borderRadius: '16px',
                        outline: 'none',
                    }}
                >
                    <p className='font-jostRegular font-bold text-[#363636] text-[26px] lg:pt-[32px] pt-[23px]'>
                        {' '}
                        Add New Member
                    </p>
                    <br />

                    <div className='h-[400px] overflow-auto'>
                        <div className=' flex flex-col justify-center items-center mt-2 w-full'>
                            <Input
                                rows={1}
                                width=' lg:w-[479px] md:w-[418px] w-[311px]'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.name}
                                error={formErrors?.name}
                                helperText={formErrors?.name}
                                label='Name*'
                                name='name'
                            />
                            {/* <div className='grid grid-flow-col lg:gap-[16px] md:gap-[16px] gap-2 mt-[16px]'>
                                <Input
                                    rows={1}
                                    width='lg:w-[79px] md:w-[69px] w-[69px]'
                                    disabled={false}
                                    readOnly={true}
                                    value='+971'
                                    name='code'
                                />
                                <Input
                                    rows={1}
                                    width=' lg:w-[384px] md:w-[339px] w-[232px]'
                                    disabled={false}
                                    readOnly={false}
                                    handleChange={handleChange}
                                    value={params?.phone}
                                    error={formErrors?.phone}
                                    helperText={formErrors?.phone}
                                    label='Phone Number*'
                                    name='phone'
                                />
                            </div> */}

                            <PhoneInput rows={1}
                                width="lg:w-[479px] md:w-[418px] w-[311px] mt-5"
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.phone}
                                error={formErrors?.phone}
                                helperText={formErrors?.phone}
                                label="Contact No*"
                                name="phone" />

                            <Input
                                rows={1}
                                width='lg:w-[479px] md:w-[418px] w-[311px] mt-5'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.email}
                                error={formErrors?.email}
                                helperText={formErrors?.email}
                                label='Email*'
                                name='email'
                            />

                            <SelectInput
                                width=' lg:w-[479px] md:w-[418px] w-[311px] mt-5'
                                handleChange={handleChange}
                                options={genderOptions}
                                label='Gender*'
                                name='gender'
                                value={params?.gender}
                                error={formErrors?.gender}
                                helperText={formErrors?.gender}
                            />
                            <div className='lg:w-[479px] md:w-[418px] w-[311px] mt-5'>
                                <CommonDatepicker
                                    label='Date of Birth*'
                                    value={params?.dob}
                                    onChange={onDateChange}
                                    error={formErrors?.dob}
                                    helperText={formErrors?.dob}
                                />
                            </div>

                            <Input
                                rows={1}
                                width=' lg:w-[479px] md:w-[418px] w-[311px] mt-5'
                                disabled={false}
                                readOnly={false}
                                handleChange={handleChange}
                                value={params?.relation}
                                error={formErrors?.relation}
                                helperText={formErrors?.relation}
                                label='Relation*'
                                name='relation'
                            />

                            <div className='lg:w-[479px] md:w-[418px] w-[311px] mt-5 mb-5'>
                                <SelectNationality
                                    error={formErrors?.nationality}
                                    helperText={formErrors?.nationality}
                                    value={params?.nationality}
                                    captureNationality={captureNationality} />
                            </div>
                        </div>
                    </div>



                    <div className='flex flex-col justify-center items-center lg:mb-[47px] md:mb-[29px] '>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            width='w-[250px] h-[56px]'
                            onClick={handleSubmit}
                        >
                            Submit
                        </CustomButton>

                        <p
                            onClick={handleClose}
                            className='underline text-blueShadow font-bold text-[16px] text-center cursor-pointer lg:mt-[27px] mt-[27px]'
                        >
                            Cancel
                        </p>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default Patients
