import React, { useState } from "react";
import { Link } from "react-router-dom";

const FooterAccordion = () => {
  const [expand, setExpand] = useState(-1);

  const toggleAccordion = (index) => {
    setExpand(expand === index ? -1 : index);
  };

  const [menus, setMenus] = useState([
    {
      name: "Our Services",
      subList: [{ name: "Tests & Packages", link: "/packages" }],
    },
    {
      name: "About us",
      subList: [
        { name: "Partner with us", link: "/partner-us" },
        {
          name: "Careers",
          link: "/careers",
        },
        {
          name: "Terms and conditions",
          link: "/terms-conditions",
        },
        {
          name: "Privacy policy",
          link: "/privacy-policy",
        },
        {
          name: "Cookie policy",
          link: "/cookie-policy",
        },
      ],
    },
    {
      name: "Help",
      subList: [
        { name: "Customer Support", link: "/support" },
        {
          name: "Faq's",
          link: "/faq",
        },
        {
          name: "Contact us",
          link: "/contact-us",
        },
        // {
        //   name: 'Legal Information',
        //   link: '#',
        // },
      ],
    },
  ]);

  return (
    <div className="flex flex-col bg-transparent  ">
      {menus.map((item, index) => (
        <div className="overflow-hidden">
          <div
            className={`${
              expand === index
                ? "transition-all duration-0   "
                : "transition-all duration-100 "
            }   cursor-pointer   ${
              index === menus.length - 1 ? "border-none" : ""
            } `}
          >
            <div
              className={`flex rounded-xl ${
                expand === index
                  ? "bg-[#425b83b3] px-4 transition-all duration-500 "
                  : ""
              } items-center justify-between py-4 `}
              onClick={() => toggleAccordion(index)}
            >
              <div className="summary-content">{item?.name}</div>
              <div
                style={{
                  transform: `rotate(${expand === index ? 180 : 0}deg)`,
                  transition: "transform 0.3s ease-in-out",
                }}
              >
                <svg
                  width="12"
                  height="7"
                  viewBox="0 0 12 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 0.996094L5.66667 5.66276L10.3333 0.996094"
                    stroke="#FFFFFF"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div
            className={`bg-transparent  ${
              expand === index
                ? "max-h-screen   transition-all duration-1000 ease-in-out "
                : "max-h-0 transition-all duration-100 ease-in-out"
            } `}
          >
            <div className="flex flex-col gap-2">
              {item?.subList?.map((item, index) => (
                <Link
                  key={index + 1}
                  to={item?.link}
                  className="details-content border-b pb-1"
                >
                  {item?.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FooterAccordion;
