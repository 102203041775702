import React from 'react'
import moment from 'moment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

export const TimeandDatePicker = ({
    handleChange,
    value,
    error,
    label = 'Date time',
}) => {
    const popperSx = {
        '& .MuiPickersDay-dayWithMargin': {
            color: 'black',
            background: 'none',
            borderRadius: '10px !important',
            '&:hover': {
                background: '#F5FBFD',
            },
        },
        '& .MuiPickersDay-today': {
            // background: "#0C8EC7 !important",
            // color: "#000",
            border: 'none !important',
        },
        '& .MuiPickersDay-dayOutsideMonth': {
            color: '#6A6A78 !important',
            '&:hover': {
                background: 'none !important',
            },
        },

        '& .css-pgdzhj-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected':
            {
                // backgroundColor: "#0C8EC7 !important",
                color: '#000',
                borderRadius: '10px !important',
            },
        '& .MuiTypography-root': {
            color: '#6A6A78',
            // borderTop: "1px solid #404050",
        },
        '& .css-1n2mv2k': {
            // borderTop: "1px solid #404050 ",
            // borderBottom: "1px solid #404050 ",
        },
        '& .MuiTypography-caption': {
            height: '30px !important',
        },

        '& .css-l0iinn': {
            color: '#000',
            '& .MuiSvgIcon-root': {
                color: '#000',
            },
        },
        '& .PrivatePickersYear-yearButton': {
            color: '#000',
        },
        '& .css-m1gykc.Mui-selected': {
            backgroundColor: '#0C8EC7 !important',
            color: '#000',
        },

        '& .MuiPickersArrowSwitcher-root': {
            '& .MuiSvgIcon-root': {
                color: 'white',
                // border: "1px solid white",
                borderRadius: '11px',
            },

            '& button .MuiDateRangePickerDay-dayOutsideRangeInterval': {
                backgroundColor: 'red !important',
            },

            '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
                backgroundColor: 'red !important',
            },
        },
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
                spacing={3}
                sx={{
                    '& .MuiInputBase-input': {
                        color: '#5B6082',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#404050',
                        borderRadius: '8px',
                    },
                    backgroundColor: 'transparent',
                    textarea: { color: '#000' },
                    label: { color: '#6A6A78' },
                    '& .MuiFormLabel-root.Mui-hovered': {
                        color: '#3C567E',
                    },
                    '& .MuiFormLabel-root.Mui-focused': {
                        color: '#3C567E',
                    },
                    '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                            borderColor: '#FFFFFF',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        '& > fieldset': {
                            borderColor: '#404050',
                        },
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                            borderColor: '#3C567E',
                        },
                    },
                }}
            >
                <DateTimePicker
                    label={label}
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => (
                        <TextField {...params} sx={{ width: '100%' }} error={error} />
                    )}
                    minDate={moment()}
                    components={
                        {
                            // OpenPickerIcon: MoreTimeIcon,
                            // LeftArrowIcon: ArrowBackIcon,
                            // RightArrowIcon: ArrowForwardIcon,
                            // SwitchViewIcon: ChangeCircleIcon
                        }
                    }
                    onError={() => error}
                    PopperProps={{ sx: popperSx }}
                    OpenPickerButtonProps={{ style: { color: '#5B6082' } }}
                />
            </Stack>
        </LocalizationProvider>
    )
}
