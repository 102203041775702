import * as React from 'react';
import { makeStyles } from "@mui/styles";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { nationalityList } from '../../helpers/helpers'


const useStyles = makeStyles({
    error: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
            borderRadius: '8px',
        },
    },
    select: {
        '& ul': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '& li': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    icon: {
        fill: 'white',
    },
    root: {
        '& .MuiOutlinedInput-input': {
            color: '#3C567E',
        },
        '& .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7E8ED',
            borderRadius: '8px',
        },
        '&:hover .MuiOutlinedInput-input': {
            color: '#3C567E',
        },
        '&:hover .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3C567E',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#33B0AA',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#33B0AA',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#33B0AA',
        },
    },
})

export default function SelectNationality({ error, helperText, width, value, captureNationality }) {
    const classes = useStyles()

    return (
        <div className={`${width}`}>
            <Autocomplete
                className={!error ? classes.root : classes.error}
                value={value}
                onChange={captureNationality}
                id="nationality_select"
                options={nationalityList}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        error={error}
                        label="Nationality*"
                        helperText={error ? helperText : ''} />}
            />
        </div>
    );
}