import React from "react";
import Card from "./Card";
import Validator from "validatorjs";
import { uuid } from "../../../helpers/helpers";
import { Input } from "../../common/Input";
import TextArea from "../../common/TextArea";
import CustomButton from "../../common/Button";
import axiosInstance from "../../../helpers/axios";
import BreadCrumb from "../../common/BreadCrumb";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Backdrop, Box, Modal } from "@mui/material";
import FileUpload from "../../common/upload/FileUpload";
import { CustomSelectInput } from "../../common/CustomSelect";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomInput } from "../../common/CustomInput";
import { PhoneInput } from "../../common/PhoneInput";
import GetACall from "./GetACall";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../../features/userProfile/profileSlice";
import updateMeta from "../../../helpers/seo-meta";
import useGtag from '../../../helpers/gtag';

const { fields, rules } = require("./fields");

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 656,
    bgcolor: "background.paper",
    p: 4,
};


const Packages = ({ title }) => {

    const width = typeof window !== 'undefined' ? window.innerWidth : 1000
    const buttonHeight = width <= 768 ? "47px" : "56px";
    const [files, setFiles] = useState({});
    const [file, setUploadFile] = useState({});
    const [pFlag, setFlag] = useState("init");
    const [uploadParams, setUploadParams] = useState(fields)
    const [uploadErrors, setUploadErrors] = useState(fields)
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const gtag = useGtag();

    const removeImage = (name) => {
        setFiles({ ...files, [name]: "" });
    };

    const uploadPrescription = () => {
        const formData = new FormData();

        for (let key in uploadParams) {
            formData.append(key, uploadParams[key]);
        }
        formData.append("image", file);

        axiosInstance
            .post(`/prescription-upload-request`, formData)
            .then((response) => {
                gtag('event', 'conversion', {
                    send_to: 'AW-11437476569/IhseCPL9h4gZENmV6M0q',
                });
                setFlag("success");
                setUploadParams({
                    name: "",
                    email: "",
                    phone: " ",
                    file: "",
                    message: "",
                })
                setUploadParams({})
            })
            .catch((error) => {
            });
    };


    const handlePrescriptionSubmit = () => {

        let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[0125])[0-9]{7}$/;

        if (!uploadValidate(uploadParams, rules)) {
            const err = Object.keys(uploadErrors);
            if (err?.length) {
                const input = document.querySelector(
                    `input[name=${err[0]}]`
                );
                if (input) {
                    input.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "start",
                    });
                }
            }

            if (!regex.test(`${uploadParams.phone}`)) {
                setUploadErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
                return
            }
            return;

        }

        setFlag("success");
        uploadPrescription();
    };

    const handleUploadModalOpen = () => {
        setUploadModalOpen(true)
    }
    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
        setUploadParams({});
        setUploadErrors({});
    };

    const initialStates = {
        search_key: "",
        age: "",
        gender: "",
        symptoms: [],
        sort_by: "",
        test_or_package: 'Packages',
        page: 1
    };

    const [open, setOpen] = useState(false);
    const [params, setParams] = useState(initialStates);
    const [isLoading, setIsLoading] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => {
        document.title = 'Test & Packages | Zain Care - Delivering High Quality Healthcare'
        updateMeta('description', `Looking for routine check-ups or specialized ones, we have a package that's right for you. Book your test today and take charge of your health.`);
        updateMeta('keywords', `blood test, lab tests, blood test packages, full body checkup, medical check up, medical checkup, health check up, medical lab`);
        gtag('event', 'conversion', {
            send_to: 'AW-11437476569/CV6GCPX9h4gZENmV6M0q',
        });
    }, []);

    const [packages, setPackages] = useState([]);

    const [meta, setMeta] = useState({
        last_page: '',
        current_page: ''
    });


    const getNext = () => {
        setIsLoading(true);
        const query = { ...params };
        query.page += 1;
        getPackagesList(query)
        setParams(query)
    };

    const getPackagesList = (query, reset = false) => {
        setIsLoading(true)
        let test_type = '';
        if (query.test_or_package) {
            test_type = query.test_or_package == 'Tests' ? 3 : 2
        }
        axiosInstance
            .get(
                `/packages?limit=6&type=${test_type}&age=${query.age}&gender=${query.gender}&sort=${query.sort_by}&search_key=${query.search_key}&page=${query.page}`
            )
            .then((response) => {
                let data = response.data.data.data;
                let meta = response.data.data.meta;
                let pkg = [...packages]
                if (reset) pkg = []
                const updated = [...pkg, ...data]
                setMeta(meta)
                setPackages(updated);
                setIsLoading(false);
            })
            .catch((error) => {
               setIsLoading(false)
            });
    };


    // run when switched to tests or packages page
    useEffect(() => {
        setIsLoading(true);
        getPackagesList(params);
    }, []);



    const updateUploadParams = (records) => {

        const newParams = JSON.parse(JSON.stringify(uploadParams));
        Object.keys(records).forEach(
            (key) => (newParams[records[key].name] = records[key].value)
        );
        setUploadParams(newParams);
    };

    const handleUploadChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;

            if (
                name === "pincode" ||
                name === "phone" ||
                name === "alternate_phone"
            ) {
                const re = /^[0-9\b]+$/;
                if (value && !re.test(value)) {
                    return;
                }
            }
            updateUploadParams([{ name, value }]);
        } else {
            updateUploadParams([{ name: e?.name, value: e?.url }]);
        }

        if (e?.file) {
            setUploadFile(e.file);
            setFiles({ ...files, [e.name]: e.file });
        }

        setUploadErrors({});
    };

    const uploadValidate = (parameters, rule) => {
        const validator = new Validator(parameters, rule)

        if (validator.fails()) {
            const fieldErrors = {};

            /* eslint-disable */
            for (const key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0];
            }
            /* eslint-enable */

            setUploadErrors(fieldErrors);

            return false;
        }



        setUploadErrors({});
        return true;
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            getPackagesList(params, true)
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (
            name === "age"
        ) {
            const re = /^[0-9\b]+$/;
            if (value && !re.test(value)) {
                return;
            }

            if (Number(value) < 0 || Number(value) > 100) {
                return
            }
        }
        setParams({
            ...params, [name]: value
        });
        if (name != 'search_key') {
            getPackagesList({
                ...params, [name]: value, page: 1
            }, true)
        }

    };
    const handleApplyFilter = () => {
        setIsLoading(true);
        getPackagesList(params, true)

    };



    const resetFilter = () => {
        setParams(initialStates);
        getPackagesList(initialStates, true)
    };

    // dropdown states

    const [genderDropdown, setGenderDropdown] = useState([]);
    const [sortByOptions, setSortByOptions] = useState([]);


    // get dropdown values from api
    const getAllDropdown = () => {
        axiosInstance
            .get("/tests/prerequisite")
            .then((response) => {
                let thisData = response.data.data;
                setGenderDropdown(thisData.GENDER);
                setSortByOptions(thisData.SORT);
            })
            .catch((error) => {
            });
    };

    useEffect(() => {
        getAllDropdown();
    }, []);


    const updateParams = (records) => {
        const newParams = JSON.parse(JSON.stringify(uploadParams))
        Object.keys(records).forEach((key) => (newParams[records[key].name] = records[key].value))
        setUploadParams(newParams)
    }

    const handlePhone = (e) => {
        if (e.target) {
            let { name, value } = e.target
            updateParams([{ name, value }])
        }
        if (e.url) {
            updateParams([{ name: e?.name, value: e?.url }])
        }

        setUploadErrors({})
    }

    const dispatch = useDispatch()

    const { profileData } = useSelector((state) => state.profile)

    useEffect(() => {
        dispatch(fetchProfile())
    }, [])

    useEffect(() => {
        setUploadParams({
            ...uploadParams,
            name: profileData?.name,
            phone: profileData?.phone,
            email: profileData?.email,
        })
    }, [profileData])

    return (
        <div>
            <div>
                <div className="lg:pt-[150px] md:pt-[130px] pt-[120px] lg:w-[1440px] m-auto">
                    {/* BreadCrumb */}
                    <div className="px-10">
                        <BreadCrumb
                            links={[
                                { path: "Home", url: "/" },
                                {
                                    path: "Test & Packages",
                                    url: "#",
                                },
                            ]}
                        />
                    </div>

                    {/*Title  */}
                    <p className=" lg:text-center md:text-center text-start px-7 text-blueShadow font-jostSemibold lg:text-[36px] md:text-[23px] text-[24px] lg:mt-[40px] md:mt-[28px]">
                        Let's find the right Test/Package for you.
                    </p>
                    {/* Sub Title */}
                    <p className="lg:text-center lg:m-auto md:text-center text-blueShadow opacity-50 font-satoshiRegular lg:text-[20px] md:text-[14px] text-[14px] lg:mt-[8px] md:mt-[14px] mt-[11px] px-7 lg:w-[963px] lg:leading-[26px] md:leading-[16px] leading-[18px]">
                        Whether you&#39;re looking for routine check-ups or
                        specialized ones, we have a package that&#39;s right for
                        you. Book your test/package today and take charge of
                        your health and well-being.
                    </p>

                    {/* Search Bar */}

                    <div className="lg:px-0 md:px-0 px-7">
                        <div className="flex justify-between items-center lg:w-[780px] md:w-[600px] w-full m-auto bg-[#EBEEF2] rounded-lg lg:mt-[45px] md:mt-[34px] mt-[32px] ">
                            <input
                                type="text"
                                name="search_key"
                                value={params?.search_key}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                className="outline-none p-3 bg-[#EBEEF2] rounded-lg w-full placeholder:font-satoshiMedium lg:placeholder:text-lg md:placeholder:text-lg placeholder:text-sm font-satoshiMedium text-[#363636] text-lg"
                                placeholder={"Search Tests/Packages"}
                            />

                            <div className="flex bg-none rounded-lg gap-2">
                                {/* Search */}
                                <div
                                    onClick={handleApplyFilter}
                                    className="rounded-lg flex justify-center p-[18px] bg-[#33B0AA] w-16 cursor-pointer"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M21.9999 22.7514C21.8099 22.7514 21.6199 22.6814 21.4699 22.5314L19.4699 20.5314C19.1799 20.2414 19.1799 19.7614 19.4699 19.4714C19.7599 19.1814 20.2399 19.1814 20.5299 19.4714L22.5299 21.4714C22.8199 21.7614 22.8199 22.2414 22.5299 22.5314C22.3799 22.6814 22.1899 22.7514 21.9999 22.7514Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                {/* Reset */}
                                <div
                                    onClick={resetFilter}
                                    className="cursor-pointer bg-[#dfdfdf] p-[18px] rounded-lg "
                                >
                                    <div className="w-[24px] h-[24px]">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M19.91 15.51H15.38C15.1148 15.51 14.8604 15.6154 14.6729 15.8029C14.4854 15.9904 14.38 16.2448 14.38 16.51C14.38 16.7752 14.4854 17.0296 14.6729 17.2171C14.8604 17.4046 15.1148 17.51 15.38 17.51H17.78C16.6769 18.6627 15.2544 19.4593 13.6952 19.7974C12.1359 20.1355 10.5112 19.9996 9.02978 19.4072C7.54834 18.8149 6.27787 17.7931 5.38159 16.4732C4.48531 15.1532 4.00418 13.5955 4 12C4 11.7348 3.89464 11.4804 3.70711 11.2929C3.51957 11.1054 3.26522 11 3 11C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2.00529 13.9528 2.58222 15.8613 3.6596 17.49C4.73699 19.1187 6.26767 20.3964 8.06274 21.1652C9.85782 21.9341 11.8387 22.1605 13.761 21.8166C15.6833 21.4727 17.4628 20.5735 18.88 19.23V21C18.88 21.2652 18.9854 21.5196 19.1729 21.7071C19.3604 21.8946 19.6148 22 19.88 22C20.1452 22 20.3996 21.8946 20.5871 21.7071C20.7746 21.5196 20.88 21.2652 20.88 21V16.5C20.8775 16.2416 20.7752 15.9943 20.5943 15.8097C20.4135 15.6251 20.1683 15.5177 19.91 15.51ZM12 2C9.43639 2.00731 6.97349 2.99891 5.12 4.77V3C5.12 2.73478 5.01464 2.48043 4.82711 2.29289C4.63957 2.10536 4.38522 2 4.12 2C3.85478 2 3.60043 2.10536 3.41289 2.29289C3.22536 2.48043 3.12 2.73478 3.12 3V7.5C3.12 7.76522 3.22536 8.01957 3.41289 8.20711C3.60043 8.39464 3.85478 8.5 4.12 8.5H8.62C8.88522 8.5 9.13957 8.39464 9.32711 8.20711C9.51464 8.01957 9.62 7.76522 9.62 7.5C9.62 7.23478 9.51464 6.98043 9.32711 6.79289C9.13957 6.60536 8.88522 6.5 8.62 6.5H6.22C7.32247 5.34787 8.74409 4.5515 10.3024 4.21311C11.8607 3.87472 13.4846 4.00975 14.9656 4.60086C16.4466 5.19198 17.7172 6.21221 18.6142 7.5306C19.5113 8.849 19.9938 10.4054 20 12C20 12.2652 20.1054 12.5196 20.2929 12.7071C20.4804 12.8946 20.7348 13 21 13C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Filters */}
                    <div className="lg:w-[1190px] lg:m-auto mt-[17px]">
                        <div className="grid grid-flow-col lg:justify-center md:justify-center items-center lg:gap-[22px] gap-[16px] overflow-x-scroll lg:overflow-hidden md:overflow-hidden lg:mx-0 md:mx-5 mx-7 py-5">
                            <CustomSelectInput
                                width="lg:w-[291px] md:w-[220px] w-[240px]"
                                handleChange={handleInputChange}
                                options={["Tests", "Packages"]}
                                label="Select tests/packages"
                                name="test_or_package"
                                value={params?.test_or_package}
                            />


                            <div className="relative bottom-[1px]">
                                <CustomInput
                                    rows={1}
                                    width='lg:w-[100px] md:w-[88px] w-[88px]'
                                    disabled={false}
                                    readOnly={false}
                                    handleChange={handleInputChange}
                                    value={params?.age}
                                    label='Age'
                                    name='age'
                                />
                            </div>

                            {/* </div> */}

                            <CustomSelectInput
                                width="lg:w-[137px] md:w-[137px] w-[107px]"
                                handleChange={handleInputChange}
                                options={genderDropdown}
                                label="Gender"
                                name="gender"
                                value={params?.gender}
                            />
                            <CustomSelectInput
                                width="lg:w-[197px] md:w-[197px] w-[137px] "
                                handleChange={handleInputChange}
                                options={sortByOptions}
                                label="Sort by Price"
                                name="sort_by"
                                value={params?.sort_by}
                            />
                        </div>
                    </div>

                    <br />
                    <div className="flex br-red-400 relative lg:w-[60%] w-full m-auto gap-8">
                        <div className="hr-line w-full"></div>
                        <p className="absolute -top-4 text-lg font-bold text-DarkGrey left-[49.2%]">
                            or
                        </p>
                        <div className="hr-line w-full"></div>
                    </div>
                    <br />
                    <div className="flex flex-col items-center justify-center">
                        <p className="text-sm lg:text-xl text-center  font-bold sm:font-normal text-CadetGrey">
                            Upload your Prescription, We are here to help you!
                        </p>
                        <br />
                        <CustomButton
                            width="w-56"
                            borderRadius="0.5rem"
                            variant="secondary-contained"
                            size="large"
                            onClick={handleUploadModalOpen}
                        >
                            Upload Prescription
                        </CustomButton>
                    </div>
                    {/*  */}

                    <div className="text-start lg:pb-[26px] md:pb-[14px] pb-[16px] lg:px-[60px] md:px-[30px] px-[25px] mt-10">
                        <p className="text-blueShadow font-jostRegular font-[600] lg:text-[36px] md:text-[20px] text-[20px]">
                            {params.test_or_package
                                ? params.test_or_package
                                : "Tests & Packages"}
                        </p>
                    </div>

                    <div className="grid grid-flow-row justify-center items-center">
                        <>
                            <div className="flex justify-center items-center">
                                {packages.length == 0 && !isLoading ? (
                                    <p className="font-satoshiBold text-base flex justify-center items-center">
                                        No Data Found!
                                    </p>
                                ) : (
                                    <>
                                        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5 justify-center items-center">
                                            {packages.map(
                                                (packageInfo) => (
                                                    <Card
                                                        key={uuid()}
                                                        packageInfo={
                                                            packageInfo
                                                        }
                                                        handleOpen={handleOpen}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>

                            {meta &&
                                meta?.current_page !== meta?.last_page &&
                                !isLoading && (
                                    <button
                                        onClick={getNext}
                                        className="lg:mt-[83px] md:mt-[60px] mt-[41px] flex justify-center items-center w-fit-content m-auto text-Verdigris font-bold lg:text-[24px] md:text-[16px] text-[14px] underline cursor-pointer"
                                    >
                                        View More
                                    </button>
                                )}
                        </>
                    </div>
                    {isLoading && (
                        <p className="text-center text-3xl mt-8 text-CadetGrey font-satoshiMedium flex flex-row gap-2 items-center justify-center">
                            <CircularProgress sx={{ color: "#909EB4" }} />
                            <span>Loading...</span>
                        </p>
                    )}

                </div>
            </div>

            <GetACall />


            {/* Add to cart modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={style}
                    style={{
                        textAlign: "center",
                        borderRadius: "16px",
                        outline: "none",
                        // padding: '10px',
                    }}
                >
                    <div className="flex justify-end ">
                        <img
                            onClick={handleClose}
                            className=" cursor-pointer"
                            src='/images/icons/closeIcon.svg'
                            alt='Close'
                        />
                    </div>

                    <p className="font-jostRegular font-bold text-[#3C567E] lg:text-[26px] text-[20px] lg:w-full w-[294px]">
                        {" "}
                        Want to add More test/ Package to your{" "}
                        <span className="lg:block">shopping cart?</span>
                    </p>
                    <br />

                    <div className="flex lg:flex-row flex-col justify-center items-center gap-5">
                        <CustomButton
                            borderRadius="0.5rem"
                            variant="secondary-outlined"
                            width="lg:w-[252px] w-[276px]  "
                            onClick={handleClose}
                        >
                            Add More Test/ Package
                        </CustomButton>
                        <Link to="/orders/checkout">
                            <CustomButton
                                borderRadius="0.5rem"
                                variant="secondary-contained"
                                width="lg:w-[252px] w-[276px]  "
                            >
                                Proceed to Checkout
                            </CustomButton>
                        </Link>
                    </div>
                </Box>
            </Modal>

            {/* Upload prescription modal */}
            <Modal
                open={uploadModalOpen}
                onClose={handleUploadModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box
                    sx={style}
                    style={{
                        textAlign: "center",
                        borderRadius: "24px",
                        outline: "none",
                    }}
                >
                    <div className="flex flex-col items-center lg:py-[45px] md:py-[25px] py-5 lg:w-full md:w-[437px] w-[350px]">
                        {pFlag === "init" && (
                            <>
                                <p className="text-blueShadow font-satoshiBold lg:text-2xl md:text-2xl text-base text-center">
                                    Upload Prescription
                                </p>
                                {/* Inputs */}
                                <div className="flex lg:flex-row flex-col justify-center items-center lg:gap-[21px] gap-4 mt-[20px]">
                                    <div>
                                        <FileUpload
                                            imageUrl={uploadParams.file}
                                            removeImage={() => {
                                                removeImage("file");
                                                setUploadFile("");
                                            }}
                                            styleType="md"
                                            setImage={handleUploadChange}
                                            acceptMimeTypes={[
                                                "application/pdf",
                                                "image/jpeg",
                                            ]}
                                            title="Upload or Drag and Drop image"
                                            label="File Format: .pdf/ .docx"
                                            id="pdf"
                                            maxSize={3}
                                            filename="file"
                                            error={uploadErrors?.file}
                                        />
                                    </div>

                                    {/* input fields */}
                                    <div>
                                        <Input
                                            rows={1}
                                            width="lg:w-[417px] md:w-[382px] w-[308px] "
                                            disabled={false}
                                            readOnly={false}
                                            handleChange={handleUploadChange}
                                            value={uploadParams?.name}
                                            error={uploadErrors?.name}
                                            helperText={uploadErrors?.name}
                                            label="Name*"
                                            name="name"
                                        />
                                        <Input
                                            rows={1}
                                            width="lg:w-[417px] md:w-[382px] w-[308px] mt-[16px]"
                                            disabled={false}
                                            readOnly={false}
                                            handleChange={handleUploadChange}
                                            value={uploadParams?.email}
                                            error={uploadErrors?.email}
                                            helperText={uploadErrors?.email}
                                            label="Email Address"
                                            name="email"
                                        />
                                        <div className="mt-[16px]">
                                            <PhoneInput rows={1}
                                                width="w-full"
                                                disabled={false}
                                                readOnly={false}
                                                handleChange={handlePhone}
                                                value={uploadParams?.phone}
                                                error={uploadErrors?.phone}
                                                helperText={uploadErrors?.phone}
                                                label="Contact No*"
                                                name="phone" />
                                        </div>

                                        <div className="mt-[16px]">
                                            <TextArea
                                                rows={3}
                                                readOnly={false}
                                                handleChange={
                                                    handleUploadChange
                                                }
                                                value={uploadParams?.message}
                                                error={uploadErrors?.message}
                                                helperText={
                                                    uploadErrors?.message
                                                }
                                                placeholder="Write a message"
                                                name="message"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* CTA */}
                                <div className="flex flex-row  items-center gap-[17px] mt-[16px] w-[949px] lg:justify-end justify-center lg:relative right-[50px] ">
                                    <div className="lg:w-[200px] md:w-[175px] w-[140px] flex justify-center items-center">
                                        <p
                                            onClick={handleUploadModalClose}
                                            className="text-blueShadow w-max-fit border-b-2 border-blueShadow cursor-pointer"
                                        >
                                            Cancel
                                        </p>
                                    </div>
                                    <CustomButton
                                        onClick={handlePrescriptionSubmit}
                                        width="lg:w-[200px] md:w-[175px] w-[140px] "
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        size="large"
                                        height={buttonHeight}
                                    >
                                        Submit
                                    </CustomButton>
                                </div>
                            </>
                        )}

                        {pFlag === "success" && (
                            <div className=" font-satoshiBold flex flex-col justify-center items-center lg:text-base md:text-base text-sm lg:w-[543px] ">
                                <p className="text-[#33B0AA]">
                                    Your Prescription has been Submitted!
                                </p>
                                <p className="text-blueShadow">
                                    Our experts will call you back within 15
                                    minutes.
                                </p>
                                <div className="lg:mt-[26px] md:mt-[19px] mt-[16px]">
                                    <CustomButton
                                        onClick={() => {
                                            handleUploadModalClose();
                                            setFlag("init");
                                        }}
                                        width="lg:w-[250px] md:w-[180px] w-[128px]"
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        size="large"
                                        height={buttonHeight}
                                    >
                                        Okay
                                    </CustomButton>
                                </div>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default Packages;
