import React, { useEffect, useRef, useState } from "react";
import Slide from "./components/Slider";

const slidesData = [
  {
    title: "Book your tests",
    info: " Book your test easily with our online system or via call/WhatsApp. Provide your details, choose a suitable date/time, and our friendly staff will assist you for a stress-free booking",
    img: "/images/people/lady_1_v1.webp",
    number: "/images/numbers/one.svg",
  },
  {
    title: "Home Lab test Collection",
    info: " Our technician with a home visit kit will come to you, verify the OTP, follow our checklist, and collect the sample. It takes only 5-10 minutes",
    img: "/images/people/lady_2_v1.webp",
    number: "/images/numbers/two.svg",
  },

  {
    title: "Lab Reports delivery",
    info: "  We handle samples with precision, transport them to affiliated labs, and process with strict protocols to ensure reliability. Smart reports are delivered within the specified time via email and online portal.",
    img: "/images/people/lady_3_v1.webp",
    number: "/images/numbers/three.svg",
  },
];

const LifeSavingCarousel = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const carouselRef = useRef(null);

  const shiftSlide = (direction) => {
    const carousel = carouselRef.current;
    setCurrentSlide((prevSlide) =>
      direction === 1
        ? (prevSlide + 1) % slidesData.length
        : prevSlide === 0
        ? slidesData.length - 1
        : prevSlide - 1
    );

    let slideWidth = document.querySelector(".carousel-slide");
    let width = slideWidth.offsetWidth;

    carousel.style.transition = "transform 0.7s ease-in-out";
    carousel.style.transform = `translateX(-${width}px)`;

    setTimeout(() => {
      if (direction === 1) {
        carousel.appendChild(carousel.firstChild);
      } else {
        carousel.insertBefore(carousel.lastChild, carousel.firstChild);
      }
      carousel.style.transition = "none";
      carousel.style.transform = "translateX(0px)";
    }, 700);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      shiftSlide(1);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className="relative lg:h-screen  w-full overflow-hidden bg-white pt-24">
      <div className="flex flex-col items-center justify-center ">
        <p className="font-satoshiMedium text-base text-[#909EB4] lg:text-[22px]">
          How It Works
        </p>
        <p className="font-jostSemibold text-2xl text-[#3C567E] lg:text-4xl">
          Lifesaving Easy Steps
        </p>
      </div>
      <div
        id="carousel"
        ref={carouselRef}
        className="mt-20  flex items-center justify-center"
      >
        {slidesData?.map((slide, index) => (
          <Slide
            key={index}
            index={index}
            currentSlide={currentSlide}
            img={slide.img}
            title={slide.title}
            info={slide.info}
          />
        ))}
        {slidesData?.map((slide, index) => (
          <Slide
            key={index}
            index={index}
            currentSlide={currentSlide}
            img={slide.img}
            title={slide.title}
            info={slide.info}
          />
        ))}
        {slidesData?.map((slide, index) => (
          <Slide
            key={index}
            index={index}
            currentSlide={currentSlide}
            img={slide.img}
            title={slide.title}
            info={slide.info}
          />
        ))}
      </div>

      <div className=" -mt-4 flex w-full items-center justify-center gap-4">
        {[0, 1, 2].map((item, index) => (
          <div
            key={index}
            className={`h-[8px] w-[50px] md:h-[10px] md:w-[70px] ${
              currentSlide === index
                ? "bg-blueShadow transition-all duration-700"
                : "bg-[#D5E4E9] transition-all duration-700"
            }  rounded-md`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default LifeSavingCarousel;
