import { useScroll } from '@react-hooks-library/core'
import React, { useState, useRef, useEffect } from 'react'


const CoreValues = ({ coreValue, content }) => {

  const [isNext, setNextDisabled] = useState(false)
  const [isPrev, setPrevDisabled] = useState(true)

  const prevClick = () => {
    setNextDisabled(false)
    setPrevDisabled(true)

    const element = document?.getElementById("firstContentID");
    element?.scrollIntoView({
      behavior: "smooth",
      block: 'nearest',
      inline: 'start',
    });
  }
  const nextClick = () => {
    setNextDisabled(true)
    setPrevDisabled(false)

    const element = document?.getElementById("fourthContentID");
    element?.scrollIntoView({
      behavior: "smooth",
      block: 'nearest',
      inline: 'start',
    });
  }

  const box = useRef(null)
  const [scroll, setScroll] = useState({ x: 0, y: 0 }) 

  useScroll(box, ({ scrollX, scrollY }) =>
    setScroll({ x: scrollX, y: scrollY })
  )

  useEffect(() => {
    if (scroll.x >= 0.96) {
      setNextDisabled(true)
      setPrevDisabled(false)
    }
    if (scroll.x === 0) {
      setNextDisabled(false)
      setPrevDisabled(true)
    }

  }, [scroll.x])


  return (
    <div ref={coreValue} className='mt-20 sm:mt-32 relative lg:w-[1440px] m-auto'>
      <p className='text-2xl  lg:text-4xl font-semibold font-jostSemibold text-center text-BlueJay'>
        Our Core <span className='text-Verdigris'>Values</span>
      </p>

      {/* Prev Button */}
      <img className={`lg:block hidden cursor-pointer absolute left-6 top-[50%] ${isPrev ? 'opacity-20' : ''}`} onClick={!isPrev ? prevClick : () => { }} src='/images/icons/arrow.svg' alt="" />


      <div ref={box} className='flex overflow-auto lg:max-w-[1041px] w-[80%] m-auto py-10 sm:py-16 gap-x-5'>
        {content.map((item, index) => {
          return (
            <div className=' w-full '>
              <div id={item.id} className='flex w-full justify-center sm:justify-between'>
                <img className=' relative left-[10px] w-[110px] h-[110px]' src={item?.logo} alt='' />
                {
                  index != content.length - 1 ?
                    <img className='sm:block hidden ' src='/images/images/core_horizontal_line.svg' alt='Line' /> : <></>
                }
              </div>

              <div className='w-[320px] sm:w-[350px] lg:text-start text-center'>
                <div className='sm:m-0 m-auto  w-full sm:w-4/6'>
                  <p className='sm:text-left text-center text-xl lg:text-2xl my-2 text-BlueJay font-bold font-satoshiBold'>
                    {item?.title}
                  </p>
                  <div className='lg:w-[300px] w-[270px] m-auto'>
                    <p className='sm:text-left text-[1rem] text-CadetGrey font-medium '>
                      {item?.desc}

                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      {/* Next Button */}
      <img className={`lg:block hidden rotate-180 cursor-pointer absolute right-6 top-[50%] ${isNext ? 'opacity-20' : ''}`} onClick={!isNext ? nextClick : () => { }} src='/images/icons/arrow.svg' alt="Next" />

    </div>
  )
}

export default CoreValues
