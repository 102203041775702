import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../common/Button";
import axiosInstance from "../../../helpers/axios";
import { updateCart } from "../../../features/cart/cartSlice";
import { TitleCase } from "../../../helpers/helpers";
import useGtag from '../../../helpers/gtag';

function Card({ handleOpen, isTest, packageInfo }) {
    const gtag = useGtag();
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();

    function handleSubmit(e, item) {
        e.preventDefault();
        setLoading(true);
        gtag('event', 'conversion', {
            'send_to': 'AW-11437476569/9wD7CO_9h4gZENmV6M0q',
            'value': 1.0,
            'currency': 'AED',
        });

        axiosInstance
            .post("/cart", {
                test_id: item.id,
            })
            .then((response) => {
                dispatch(updateCart(response.data));
                setLoading(false);
                handleOpen();
            })
            .catch((error) => {
                const fieldErrors = {};
                setLoading(false);
            });
    }

    const cart = useSelector((state) => state.cart);

    return (
        <div className=" bg-[#F4F7FA] rounded-xl packageShadow lg:w-[429px] md:w-[344px] w-[330px] border border-[#CED6DF]">
            <div className="flex justify-between">
                <p className="p-5 text-blueShadow font-satoshiBold lg:text-[24px] text-[16px] leading-relaxed large-text h-[72px]">
                    {/* Healthy 2023 Full Body <span className='block'>Checkup</span> */}
                    {TitleCase(packageInfo.name)}
                </p>
                {/* Offer value */}
                <div
                    className={`relative right-[20px] ${packageInfo.price_difference === 0 ? "hidden" : ""
                        }`}
                >
                    <img src='/images/shapes/bookmark.svg' alt='Bookmark' />
                    <p
                        className={`relative text-center bottom-[55px] text-[#F4F7FA] font-satoshiBold lg:text-[14px] text-[12px]`}
                    >
                        {packageInfo?.price_difference}{" "}
                        <span className="block">Off</span>
                    </p>
                </div>
            </div>

            <div
                className={`relative lg:top-[40px] ${packageInfo.price_difference === 0
                    ? "lg:top-[40px] top-[35px] left-[25px] lg:mt-[-7px] mt-[-10px] "
                    : "lg:top-[40px] top-[37px] left-[25px] lg:mt-[-40px] mt-[-37px]"
                    } `}
            >
                <img
                    className="w-[145px] h-[38.97px]"
                    src='/images/shapes/hexagon.svg'
                    alt='Hexagon'
                />
                <p className="relative text-center uppercase lg:right-[142px] lg:bottom-[30px] md:right-[100px] right-[90px] md:bottom-[28px] bottom-[28px] text-white font-satoshiBold lg:text-[14px] text-[12px]">
                    {packageInfo.param_cnt ?? ""} Parameters
                </p>
            </div>

            <div className="bg-white rounded-2xl p-5 pt-5">
                <div className="h-[70px]">
                    <p className=" text-secondaryLight font-satoshiMedium lg:text-[16px] text-[12px] leading-[20px] pt-[9px]">
                        {packageInfo?.description.substring(0, 120)}{packageInfo.description.length > 120 && '...'}
                    </p>
                </div>

                <Link
                    to={
                        isTest
                            ? `/tests/${packageInfo?.slug}`
                            : `/packages/${packageInfo?.slug}`
                    }
                >
                    <p className="mt-1 cursor-pointer underline text-blueShadow font-satoshiBold lg:text-[16px] text-[12px]">
                        Know more
                    </p>
                </Link>

                <ul className="list_package_info text-[#909EB4] p-5 font-satoshiMedium capitalize lg:w-[429px] md:w-[344px] w-[330px]">
                    <li>&nbsp;Receive report in 24hrs</li>
                    <li>&nbsp;For {packageInfo.rec_age ?? ""} years</li>
                    <li>&nbsp;Recommended for {packageInfo.test_rec ?? ""}</li>
                </ul>

                <div className="flex justify-between items-center">
                    <div className="flex flex-col">
                        <p
                            className={`line-through ${packageInfo.markup_price === packageInfo.price
                                ? "hidden"
                                : ""
                                } text-secondaryLight font-satoshiBlack lg:text-[14px] md:text-[12px] text-[10px] `}
                        >
                            AED {packageInfo.markup_price ?? ""}
                        </p>
                        <p className="text-blueShadow font-satoshiBlack lg:text-[20px] md:text-[22px] text-[15px] ">
                            AED {packageInfo.price ?? ""}
                        </p>
                    </div>
                    <CustomButton
                        disabled={loading}
                        borderRadius="0.5rem"
                        variant="secondary-contained"
                        width="lg:w-[202px] md:w-[162px] w-[155px] h-[56px] "
                        onClick={(e) => handleSubmit(e, packageInfo)}
                    >
                        {cart?.count > 0 ? 'Add To Cart' : 'Book Now'}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
}

export default Card;
