import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}`;
}

export default function RangeSlider({ value, handleChange }) {

  return (
    <Box sx={{ width: '100%' }}>
      <Slider
        aria-label="Age Range"
        defaultValue={45}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
      />
    </Box>
  );
}
