import Form from './Form'
import Validator from 'validatorjs'
import updateMeta from '../../../helpers/seo-meta'
import axiosInstance from '../../../helpers/axios'
import { isUserLoggedIn } from '../../../helpers/auth'
import React, { useEffect, useState, useMemo } from 'react'
import { showToastMessage, validateNumber } from '../../../helpers/helpers'
import useGtag from '../../../helpers/gtag';

const { fields, rules } = require('./fields')

function Contact() {
    const gtag = useGtag();
    useEffect(() => {
        document.title = 'Contact Us | Zain Care - Delivering High Quality Healthcare'
        updateMeta('description', `Connect with experts dedicated to providing smart, affordable home healthcare solutions. Send us your queries, feedback, or discuss personalized care options tailored to your needs.`);
    }, []);

    const [params, setParams] = useState(fields)
    const [errors, setErrors] = useState(fields)

    const handleChange = (e) => {
        if (e.target) {
            let { name, value } = e.target
            updateParams([{ name, value }])
        }
        if (e.url) {
            updateParams([{ name: e?.name, value: e?.url }])
        }

        setErrors({})
    }

    const updateParams = (records) => {
        const newParams = JSON.parse(JSON.stringify(params))
        Object.keys(records).forEach((key) => (newParams[records[key].name] = records[key].value))
        setParams(newParams)
    }

    const validate = (parameters, rule) => {
        const validator = new Validator(parameters, rule)

        if (validator.fails()) {
            const fieldErrors = {}

            /* eslint-disable */
            for (const key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0]
            }
            /* eslint-enable */

            setErrors(fieldErrors)
            return false
        }
        setErrors({})
        return true
    }

    const handleSubmit = () => {

        if (params.phone && !validateNumber(params.phone)) {
            setErrors({ ...errors, phone: 'Invalid Phone Number' })
            return
        }
        if (!validate(params, rules)) {
            return
        }

        if (!validate(params, rules)) {
            const err = Object.keys(errors)
            if (err?.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }

            return
        }
        postData()
    }

    const [ack, setAck] = useState('')

    const postData = () => {
        axiosInstance
            .post('/contact-us', params)
            .then((response) => {
                gtag('event', 'conversion', {
                    send_to: 'AW-11437476569/IhseCPL9h4gZENmV6M0q',
                });
                let thisData = response.data.data
                setAck(thisData.message)
                setParams({
                    name: '',
                    phone: ' ',
                    email: '',
                    message: '',
                })
            })
            .catch((error) => {
                showToastMessage("Error Occured while submitting details", "error")
            })


        setTimeout(() => {
            setAck('')
        }, 3000)
    }

    const isLoggedIn = isUserLoggedIn();


    const getAutofillDetails = () => {
        axiosInstance.get('/customer').then((response) => {
            let thisData = response.data.data
            setParams({
                ...params,
                name: thisData.name,
                phone: thisData.phone,
                email: thisData.email
            })
        }).catch((error) => {
        })
    }

    useMemo(() => {
        if (isLoggedIn) {
            getAutofillDetails()
        }
    }, [isLoggedIn])

    return (
        <>
            <div className='map_bg static' />
            <div className='sticky top-[100vh] contact_us_footer_bg grid lg:grid-flow-col md:grid-flow-col grid-flow-row lg:justify-center justify-center items-center lg:h-[554px] md:h-[498px]'>

                <Form
                    params={params}
                    errors={errors}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    ack={ack}
                />

                <div className='lg:w-[412px] text-white lg:left-[100px] lg:mr-[200px] lg:mt-[-150px] md:mt-[-150px] mt-[-190px] mb-[120px]'>
                    {/* <div>
                        <div className='flex flex-row'>
                            <img
                                className='m-5 relative lg:bottom-[25px] md:bottom-[17px] bottom-[15px]'
                                src='/images/icons/address_pin.svg'
                                alt='address Icon'
                            />
                            <div>
                                {' '}
                                <p className='lg:text-[24px] text-[20px] font-bold'>Address:</p>
                                <p className='lg:text-[20px] text-[14px] lg:leading-[27px] leading-[19px] lg:w-[362px] w-[264px] '>
                                    Zain AlShifa Homecare Center, 303, Al Murjan Tower, Al Nahda 2,
                                    Dubai, UAE.
                                </p>
                            </div>
                        </div>
                    </div>
                    <br /> */}

                    <div>
                        <div className='flex lg:mb-2'>
                            <div className='mx-[30px]' />
                            <p className='lg:text-[24px] text-[20px] font-bold text'>Call us:</p>
                        </div>

                        <div className='flex flex-row items-center'>
                            <div>
                                <img
                                    className='mx-5 my-3'
                                    src='/images/icons/call_icon.svg'
                                    alt="call Us"
                                />
                                <img
                                    className='mx-5 my-3'
                                    src='/images/icons/messageIcon.svg'
                                    alt="message Us"
                                />
                            </div>

                            <div>

                                <p className='lg:text-[20px] text-[14px] lg:leading-[27px] leading-[19px]'>
                                    +971 45762589, +971 528866165
                                </p>
                                <p className='lg:text-[20px] text-[14px] lg:leading-[27px] leading-[19px] mt-3'>
                                    +971 528866165
                                </p>

                            </div>
                        </div>
                    </div>

                    <br />

                    <div className='flex flex-row'>
                        <img
                            className='m-5 relative bottom-[12px]'
                            src='/images/icons/mail_icon.svg'
                            alt='Email Us'
                        />

                        <div>
                            <p className='lg:text-[24px] text-[20px] font-bold'>Email us:</p>
                            <p className='lg:text-[20px] text-[14px] lg:leading-[27px] leading-[19px] hover:underline cursor-pointer'>
                                <a href="mailto:info@zaincare.com">info@zaincare.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
