import React from 'react'
import { Link } from 'react-router-dom'
import CustomButton from '../../common/Button'

function JobCard({ job }) {
    let id = job.id

    let x = job.description.replace(/<[^>]+>/g, '')
    let y = x.split(' ')
    const slicedArray = y.slice(0, 30);

    return (
        <div
            style={{ border: '1px solid rgba(51, 176, 170, 0.65)' }}
            className='bg-white p-5 rounded-[23px]  flex flex-col lg:w-[532px] md:w-[354px] w-[343px] lg:h-[240px] md:h-[180px] h-[205px] shadow-xl'
        >
            <p className='font-satoshiBold text-Verdigris lg:text-[20px] text-[16px]'>
                {job.title ?? '-'}
            </p>
            {/* parsing html from db to page*/}
            <p className='text-ellipsis h-[60px] overflow-hidden font-satoshiRegular text-secondaryLight lg:text-[16px] text-[14px] lg:leading-[18px] md:leading-[16px] leading-[18px] pt-[5px]'>
                {slicedArray.join(' ') ?? '-'}...
            </p>

            <p className='lg:mt-5 mt-2 flex flex-row gap-[30px] font-satoshiMedium text-secondaryLight lg:text-[16px] text-[12px] lg:leading-[18px] md:leading-[16px] leading-[18px]'>
                <span className='flex flex-row capitalize '>
                    <img src='/images/icons/location_icon.svg' alt='Location' /> &nbsp;
                    {job.preferred_location ?? '-'}
                </span>
                <span className='flex flex-row capitalize'>
                    <img src='/images/icons/work_type.svg' alt='Location Type' /> &nbsp;{job.type ?? '-'}
                </span>
            </p>
            <div className='flex justify-end items-end relative lg:top-[28px]  md:left-[5px] md:top-[-10px] top-[10px]'>
                <Link to={`/careers/job-details/${job.id}`}>
                    <CustomButton
                        borderRadius='0.5rem'
                        variant='secondary-contained'
                        width='lg:w-[173px] md:w-[131px] w-[150px] lg:h-[56px] md:[49px] h-[47px] '
                    >
                        Apply now
                    </CustomButton>
                </Link>
            </div>
        </div>
    )
}

export default JobCard
