import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from '../../helpers/helpers'

const initialState = {
  addressList: [],
  isLoading: false,
  isCreatePending: false,
  isCreateSuccess: false,
  apiSuccess: false,
};

export const address = createSlice({
  name: 'address',
  initialState,
  reducers: {
    formReset: () => { },
    resetLoading: (state) => {
      state.isLoading = true;
    },
    CreatePending: (state) => {
      state.isLoading = true;
      state.isCreatePending = true
  },
    creationSuccessful: (state, action) => {
      state.isLoading = false;
      state.isCreatePending = false
      state.isCreateSuccess = true;
      showToastMessage(action.payload.message, 'success')
    },

    resetApiSuccess: (state) => {
      state.isCreateSuccess = false
    },

    creationFailure: (state, action) => {
      state.isLoading = false;
      state.apiSuccess = false;
      state.isCreatePending = false
      showToastMessage(action.payload.errors, 'error');
    },

    SuccessfulList: (state, action) => {
      state.addressList = action?.payload;
      state.isLoading = false;
    },

    UserListPending: (state) => {
      state.isLoading = true;
    },

  },
});

export const {
  creationSuccessful,
  creationFailure,
  formReset,
  resetLoading,
  SuccessfulList,
  UserListPending,
  CreatePending,
  resetApiSuccess,
} = address.actions;
export default address.reducer;

export const fetchAllAddress = () =>
  apiCallBegan({
    url: `/customer-address`,
    method: 'GET',
    onStart: UserListPending.type,
    onSuccess: SuccessfulList.type,
    onError: creationFailure.type,
  });

export const editAddress = (id, data) =>
    apiCallBegan({
        url: `/customer-address/${id}`,
        method: 'PUT',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })

export const addAddress = (data) =>
    apiCallBegan({
        url: `/customer-address`,
        method: 'POST',
        data,
        onStart: CreatePending.type,
        onSuccess: creationSuccessful.type,
        onError: creationFailure.type,
    })
