import axiosInstance from "../helpers/axios";
import CustomButton from "./common/Button";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { updateCart } from "../features/cart/cartSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate, useParams } from "react-router-dom";

const PaymentProcess = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const [isLoading, setLoading] = useState(true);
    const [order, setOrder] = useState({});

    function fetchOrders() {
        axiosInstance
            .get(`/orders/${params.id}`)
            .then((response) => {
                const { data } = response;
                setOrder(data.data);
                setLoading(false);
                if(data.data.status === "PAID" || (data.data.mode_of_payment === 2 && data.data.order_status === "PLACED")){
                    updateCartData()
                }
            })
            .catch((error) => {
                navigate("/");
            });
    }

    const updateCartData = () =>{
        axiosInstance.get("/cart").then((response) => {
            dispatch(updateCart(response.data));
        });
    }
    useEffect(() => {
        document.title = "Payment Process | Zaincare";
        fetchOrders();
    }, []);

    return (
        <div>
            {/* payment failed */}
            {!isLoading &&
                order?.status === "UNPAID" &&
                order.mode_of_payment === 1 && (
                    <div className=" lg:w-[1440px] md:w-[768px] m-auto p-4 sm:p-10 mt-44 md:mt-80  lg:mt-60  lg:mb-60 md:mb-96 mb-44   ">
                        <div className="flex gap-y-6 flex-col items-center  w-10/12 sm:w-1/2 m-auto">
                            <img src='/images/images/payment/pay_failed.svg' alt="pay_failed" />

                            <div className="flex flex-col items-center gap-1 xl:gap-y-3">
                                <p className=" text-base md:text-[22px] lg:text-2xl font-jostSemibold text-BlueJay">
                                    Your Payment Has Been Failed
                                </p>
                                <Link to="/orders/checkout">
                                    <p className="underline text-xs md:text-lg lg:text-xl font-satoshiMedium text-Verdigris cursor-pointer ">
                                        You can retry the payment
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            {/* payment success */}
            {!isLoading &&
                (order?.status === "PAID" ||
                    (order.mode_of_payment === 2 &&
                        order.order_status === "PLACED")) && (
                    <div className="lg:w-[1440px] md:w-[768px] m-auto p-4 sm:p-10 mt-44 md:mt-60  lg:mt-48  lg:mb-60 md:mb-96 mb-44   ">
                        <div className="flex gap-y-6 flex-col items-center w-[99%]  xl:w-1/2 m-auto">
                            <img
                                className="w-3/5 xl:w-6/12"
                                src='/images/images/payment/pay_success.svg'
                                alt="pay_success"
                            />

                            <div className="gap-8 sm:gap-28 flex justify-between ">
                                <div className="flex gap-1 items-center">
                                    <p className=" text-[10px] md:text-sm lg:text-base text-BlueJay font-satoshiMedium ">
                                        Order ID:{" "}
                                    </p>
                                    <p className="text-Verdigris  font-satoshiBold   text-[10px] md:text-base lg:text-lg">
                                        {/* OD23445378783 */}
                                        {order.id}
                                    </p>
                                </div>

                                {order?.mode_of_payment === 1 && (
                                    <div className="flex gap-1 items-center">
                                        <p className="text-[10px] md:text-sm lg:text-base text-BlueJay font-satoshiMedium">
                                            Transaction Ref ID:{" "}
                                        </p>
                                        <p className="text-Verdigris font-satoshiBold   text-[10px] md:text-base lg:text-lg ">
                                            {order.payment_ref_id}
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="flex flex-col items-center gap-y-2   xl:gap-y-4">
                                {order?.mode_of_payment === 1 ? (
                                    <>
                                        <p className=" text-base md:text-[22px] lg:text-2xl font-jostSemibold text-BlueJay">
                                            Your Payment Is Successful
                                        </p>

                                        <p className="font-satoshiMedium text-BlueJay opacity-60 text-xs lg:text-xl md:text-lg  lg:mb-0 mb-2">
                                            An automated payment receipt will be
                                            sent to your e-mail id.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p className=" text-base md:text-[22px] lg:text-2xl font-jostSemibold text-BlueJay">
                                            Your Order Is Successful
                                        </p>

                                        <p className="font-satoshiMedium text-BlueJay opacity-60 text-xs lg:text-xl md:text-lg  lg:mb-0 mb-2">
                                            An automated Order invoice will be
                                            sent to your e-mail id.
                                        </p>
                                    </>
                                )}

                                <Link to="/">
                                    <CustomButton
                                        borderRadius="0.5rem"
                                        variant="secondary-contained"
                                        size="large"
                                        width="w-44"
                                    >
                                        Back to Home
                                    </CustomButton>
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

            {/* Loading fallback */}
            {isLoading ? (
                <div className=" lg:w-[1440px] md:w-[768px] flex flex-col justify-center items-center m-auto p-4 sm:p-10 mt-44 md:mt-80  lg:mt-60  lg:mb-60 md:mb-96 mb-44   ">
                    <p className="text-center text-3xl text-CadetGrey font-satoshiMedium flex flex-row gap-2 items-center">
                        <CircularProgress sx={{ color: "#909EB4" }} />
                        <span>Loading...</span>
                    </p>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default PaymentProcess;