import React from 'react'
import moment from 'moment'
import Validator from 'validatorjs'
import Next from '../components/Next'
import Note from '../components/Note'
import Title from '../components/Title'
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from 'react'
import Previous from '../components/Previous'
import InfoLabel from '../components/InfoLabel'
import { slots } from '../../../../helpers/helpers'
import axiosInstance from '../../../../helpers/axios'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField } from '@mui/material'
import CommonDatepicker from '../../../common/DatePicker'
import { updateCart } from '../../../../features/cart/cartSlice'


// styles for autocomplete select
const useStyles = makeStyles({
    error: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            borderRadius: "8px",
        },
    },
    select: {
        "& ul": {
            backgroundColor: "#fcfcfc",
        },
        "& li": {
            backgroundColor: "#fcfcfc",
        },
    },
    icon: {
        fill: "white",
    },
    root: {
        "& .MuiOutlinedInput-input": {
            color: "#3C567E",
        },
        "& .MuiInputLabel-root": {
            color: "#3C567E",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C567E",
            borderRadius: "8px",
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#3C567E",
        },
        "&:hover .MuiInputLabel-root": {
            color: "#3C567E",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C567E",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#33B0AA",
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#33B0AA",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
            borderColor: "#33B0AA",
        },
    },
});

function SampleDateTime({ setActiveStep }) {
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart);


    const [newParams, setNewParams] = useState({
        samp_col_time_slot: "",
        sample_date_time: null,
    })


    const [newFormErrors, setNewFormErrors] = useState({
        samp_col_time_slot: "",
        sample_date_time: "",
    })


    const onChangeHandler = (e, newValue) => {
        setNewParams({ ...newParams, samp_col_time_slot: newValue })
        setNewFormErrors({})
    }

    const onDateChange = (date) => {
        // let thisDate = moment(date).format('YYYY-MM-DD')
        let thisDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
        if (thisDate !== 'Invalid date') {
            setNewFormErrors({})
            setNewParams({ ...newParams, sample_date_time: date })
        }
        else {
            setNewParams({ ...newParams, sample_date_time: '' })
        }

        return

    }

    const validate = (parameters, rule) => {
        const validator = new Validator(parameters, rule)

        if (validator.fails()) {
            const fieldErrors = {}

            /* eslint-disable */
            for (const key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0]
            }
            /* eslint-enable */

            setNewFormErrors(fieldErrors)
            return false
        }
        setNewFormErrors({})
        return true
    }


    const onSubmit = (e) => {
        e.preventDefault()
        let thisDate = moment(newParams?.sample_date_time).format('YYYY-MM-DD HH:mm:ss')


        let payload = {
            sample_date_time: newParams.sample_date_time,
            samp_col_time_slot: newParams.samp_col_time_slot
        }
        const rules = {
            sample_date_time: 'required',
            samp_col_time_slot: 'required',
        }

        if (thisDate === 'Invalid date') {
            setNewFormErrors({ ...newFormErrors, sample_date_time: 'Sample collection date is not valid' })
            return
        }

        else if (!validate(payload, rules)) {
            return
        }

        else if (!validate(payload, rules)) {
            const err = Object.keys(newFormErrors)
            if (err?.length) {
                const input = document.querySelector(`input[name=${err[0]}]`)
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    })
                }
            }
            // if (thisDate === 'Invalid date') {
            //     setNewFormErrors({ ...newFormErrors, sample_date_time: 'Your date is not valid' })
            //     return
            // }

            return
        }


        axiosInstance
            .post('/cart/update_address_or_sample_date', {
                type: 'sample_date',
                sample_date_time: moment(newParams.sample_date_time).format('YYYY-MM-DD'),
                samp_col_time_slot: newParams.samp_col_time_slot,
            })
            .then((response) => {
                dispatch(updateCart(response.data))
                setActiveStep(4)
            })
    }

    useEffect(() => {
        if (cart.sample_collection_date_time) {
            setNewParams({
                ...newParams,
                // sample_date_time: cart.sample_collection_date_time,
                sample_date_time: Date.parse(cart?.sample_collection_date_time),
                samp_col_time_slot: cart.samp_col_time_slot
            })
        }
    }, [])

    const classes = useStyles()

    return (
        <div>
            <p className="text-xs font-satoshiBold text-Verdigris relative md:left-[480px] left-[225px] bottom-[20px] lg:hidden md:block block">
                SC Time
            </p>
            <Title title="Sample Collection Date & Time" />
            <div className="border-t-0 border border-[#F4F7FA] lg:w-[568px] md:w-[704px] w-[344px] flex flex-col justify-center items-center rounded-b-lg">
                <br />
                <InfoLabel title="Select the Appropriate Date and time for Sample collection" />
                <div className="lg:w-[400px] md:w-[324px] w-[311px] mt-[24px] mb-[55px]">

                    <div className="lg:w-[400px] md:w-[324px] w-[311px]">
                        <CommonDatepicker
                            isSample
                            label="Sample Collection Date*"
                            value={newParams?.sample_date_time}
                            onChange={onDateChange}
                            error={newFormErrors?.sample_date_time}
                            helperText={newFormErrors?.sample_date_time}
                        />


                    </div>
                    <div className='mt-4' />
                    <Autocomplete
                        className={newFormErrors?.samp_col_time_slot ? classes.error : classes.root}
                        sx={{ outlineColor: '#33B0AA' }}
                        // className="mt-4"
                        id="name"
                        options={slots}
                        getOptionLabel={(option) => option}
                        value={newParams?.samp_col_time_slot}
                        onChange={onChangeHandler}
                        renderInput={(params) => (
                            <TextField
                                value={newParams?.samp_col_time_slot}
                                error={newFormErrors?.samp_col_time_slot}
                                helperText={newFormErrors?.samp_col_time_slot && 'The sample collection time is required'}
                                {...params}
                                label="Sample Collection Time*"
                            />
                        )}
                    />
                </div>

                <Note />
                <br />
            </div>

            <div className="flex flex-row justify-center items-center gap-5 my-1">
                <Previous
                    onClick={() => {
                        setActiveStep(3);
                    }}
                />

                <Next onClick={onSubmit} />
            </div>
        </div>
    );
}

export default SampleDateTime
