import React from "react";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";

export const PhoneInput= ({
    success,
    rows,
    height,
    width,
    type,
    value,
    label,
    error,
    helperText,
    readOnly,
    disabled,
    handleChange,
    name,
    bgcolor,
    variant,
    isInFilter,
}) => {
    const onChange = (e) => {
        e.target.value = e.target.value
            .replace(/ /g, "")
            .replace("(", "")
            .replace(")", "");
        handleChange(e);
    };
    const defaultStyles = {
        "& .MuiInputBase-input": {
            color: "#3C567E;",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
        },
        backgroundColor: bgcolor || "transparent",
        borderRadius: "8px",
        textarea: { color: "black" },
        "& .MuiFormLabel-root.Mui-hovered": {
            color: "red",
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: "#33B0AA",
        },
        "& .MuiFormLabel-root.Mui-hover": {
            color: "red",
        },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                color: "red",
                borderColor: "#3C567E",
            },
        },
        "& .MuiOutlinedInput-root": {
            height: isInFilter && "47px",
            backgroundColor: "white",
            borderRadius: "8px",
            "& > fieldset": {
                borderColor: success ? "#3AC430" : "#E7E8ED",
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
                borderColor: "#33B0AA",
            },
        },
    };
    const alternateStyles = {
        "& .MuiInputBase-input": {
            color: "#3C567E;",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
        },
        backgroundColor: bgcolor || "transparent",
        borderRadius: "8px",
        textarea: { color: "black" },
        "& .MuiFormLabel-root.Mui-hovered": {
            color: "red",
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: "#33B0AA;",
        },
        "& .MuiFormLabel-root.Mui-hover": {
            color: "red",
        },
        "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
                color: "red",
                borderColor: "#33B0AA",
            },
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            borderRadius: "8px",
            "& > fieldset": {
                borderColor: success ? "#3AC430" : "#E7E8ED",
            },
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
                borderColor: "#33B0AA",
            },
        },
    };

    return (
        <div className={`${width} ${height}`}>
            <InputMask
                alwaysShowMask={true}
                mask="(+\971) 59 999 9999"
                value={value ? value : null}
                maskChar="x"
                onChange={onChange}
            >
                {(inputProps) => (
                    <TextField
                        {...inputProps}
                        autoComplete="off"
                        type={type}
                        rows={rows}
                        name={name}
                        fullWidth
                        inputProps={{ readOnly, autoComplete: "off" }}
                        error={error}
                        helperText={helperText}
                        sx={
                            variant === "outlined" && !disabled
                                ? defaultStyles
                                : variant === "alternate" && !disabled
                                ? alternateStyles
                                : defaultStyles
                        }
                        label={label}
                    />
                )}
            </InputMask>
        </div>
    );
};
