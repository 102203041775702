import { useEffect } from "react";
import BreadCrumb from "./common/BreadCrumb";
import { uuid } from "../helpers/helpers";
import React from "react";
import updateMeta from "../helpers/seo-meta";

const content = [
    {
        id: uuid(),
        title: 'Information we collect:',

        data: ' We may collect personal information such as your name, email address, phone number, and address when you visit our website or contact us through our online forms or email. We may also collect non-personal information such as your IP address, browser type, and device information.'
    },
    {
        id: uuid(),
        title: 'How we use your information:',

        data: ' We may use your personal information to contact you regarding your inquiries, provide you with information about our services, and process and manage your orders. We may also use your non-personal information for analytical purposes, to improve our website and services, and to prevent fraud and unauthorized access.'
    },
    {
        id: uuid(),
        title: 'Disclosure of your information:',
        data: ' We may disclose your personal information to our trusted third-party service providers who assist us in operating our website and providing our services. We may also disclose your information if required by law or if we believe that such disclosure is necessary to protect our rights or to comply with legal proceedings.'
    },
    {
        id: uuid(),
        title: 'Security of your information:',

        data: ' We take the security of your personal information seriously and have implemented appropriate technical and organizational measures to protect against unauthorized access, loss, or theft. However, please note that no method of transmission over the Internet or electronic storage is 100% secure and we cannot guarantee the absolute security of your information.'
    },
    {
        id: uuid(),
        title: 'Changes to our Privacy Policy:',

        data: ' We reserve the right to update or modify this Privacy Policy at any time without prior notice. We encourage you to periodically review this page for the latest information on our privacy practices.'
    }

]

const PrivacyPolicy = () => {

    useEffect(() => {
        document.title = 'Privacy Policy | Zain Care - Delivering High Quality Healthcare';
    }, []);

    return (
        <div className="">
            <div className="terms_and_cookie_bg">
                <div className="pt-[160px] lg:w-[1440px] m-auto">
                    <div className="px-10">
                        <BreadCrumb
                            links={[
                                { path: "Home", url: "/" },
                                { path: "Privacy Policy", url: "/" },
                            ]}
                        />
                    </div>
                    <p className=" text-center text-blueShadow font-jostRegular font-[600] lg:text-[36px] md:text-[26px] text-[24px]">
                        Privacy Policy
                    </p>
                </div>
            </div>

            {/* terms & conditions */}
            <div className="">
                <div className="relative text-[#3C567E] lg:bottom-[220px] md:bottom-[120px] bottom-[100px] left-0 right-0 bg-white shadow-xl rounded-lg ml-auto mr-auto lg:w-[1127px] md:w-[704px] w-[334px]  p-10 leading-2">

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] my-[10px]">
                        Privacy Policy for <span className="font-satoshiBold">Zaincare</span>
                    </p>

                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] my-5 text-justify">
                        At <span className="font-satoshiBold">Zaincare</span>, we are committed to protecting your privacy and maintaining the confidentiality of your personal information. This Privacy Policy outlines how we collect, use, and disclose your personal information when you visit our website <a href="http://www.zaincare.com" className="hover:underline">http://www.zaincare.com.</a>
                    </p>

                    {content.map((item) => (
                        <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] my-5 text-justify">
                            <span className="font-satoshiBold">{item?.title}</span> {item?.data}
                        </p>
                    ))}
                    <p className="font-satoshiRegular lg:text-[20px] md:text-[18px] text-[14px] my-5 text-justify">
                        <span className="font-satoshiBold">Contact us:</span> If you have any questions or concerns regarding our Privacy Policy or the way we handle your personal information, please contact us at  <a href="mailto:info@zaincare.com" className="hover:underline">info@zaincare.com.</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
