import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { uuid } from '../helpers/helpers'
import { Link } from 'react-router-dom'

import { Skeleton } from "@mui/material";
import RadioButton from './common/RadioButton'
import RangeSlider from './common/RangeSlider'
import axiosInstance from '../helpers/axios'
import { CustomSelectInput } from './common/CustomSelect'
import { CustomInput } from './common/CustomInput'
import updateMeta from '../helpers/seo-meta';

const ApplyFilter = () => {

  const [symArray, setSymArray] = useState([
    {
      id: 1,
      name: 'headache',
      status: false
    },
    {
      id: 2,
      name: 'vomit',
      status: false
    }
  ])
  const navigate = useNavigate()
  const [active, setActive] = useState("tests");

  const [packagesList, setSearchPackagesList] = useState([]);
  const [testsList, setTestsList] = useState([]);

  const [totalPackages, setTotalPackages] = useState(0);
  const [totalTests, setTotalTests] = useState(0);

  const [isLoading, setIsLoading] = useState(false)

  const [gotoFilter, setGotoFilter] = useState(false)

  // age range value
  const [value, setValue] = React.useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue );
  };

  const filterInitialStates = {
    search_key: "",
    age: "",
    gender: "",
  }

  const [applyFilter, setApplyFilter] = useState(false);

  const [filterParams, setFilterParams] = useState(filterInitialStates);

  const resetFilter = () => {
    setApplyFilter(false);
    setFilterParams({
      search_key: "",
      age: "",
      gender: "",
    });
    setValue('')

    symArray.map((item) => {
      item.status = false
    })

    const arr = [...symArray]
    setSymArray(arr)
  };

  const handleFilterApply = () => {
    setIsLoading(true);
    setApplyFilter((prevState) => !prevState);
  };

  const updateFilterParams = (records) => {

    const newParams = JSON.parse(JSON.stringify(filterParams));

    Object.keys(records).forEach(
      (key) => (newParams[records[key].name] = records[key].value)
    );

    setFilterParams(newParams);
  };

  const handleFilterChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      if (name === "price" || name === "markup_price") {
        const re = /^\d*\.?\d*$/;
        if ((value && !re.test(value)) || value === "0") {
          return;
        }
      }

      if (e.target.name === "name") {
        let res = /^[a-zA-Z0-9 ]*$/g;

        if (!res.test(e.target.value)) {
          return;
        }
      }

      if (name === "param_cnt") {
        const re = /^[0-9\b]+$/;
        if ((value && !re.test(value)) || value === "0") {
          return;
        }
      }

      if (name?.includes("is")) {
        updateFilterParams([
          { name: e.target.name, value: e.target.checked },
        ]);
      } else {
        updateFilterParams([{ name: name, value: value }]);
      }
    }

  };

  const searchTestsPackages = () => {
    let temp = [] 
    symArray.map((item) => {
      if (item.status === true) {
        temp.push(item.name)
      }
    })


    axiosInstance
      .get(
        `/search-packages?search_key=${filterParams.search_key.trim()}&age=&gender=${filterParams.gender}&symptoms=${temp}`
      )
      .then((response) => {
        setSearchPackagesList(response?.data?.packages);
        setTestsList(response?.data?.tests);
        setTotalTests(response?.data?.total_tests);
        setTotalPackages(response?.data?.total_packages);
        setIsLoading(false);
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    searchTestsPackages();
  }, [applyFilter]);

  useEffect(() => {
    let temp = { ...filterParams, age: value.toString() }
    setFilterParams(temp)
  }, [value])

  const [genderDropdown, setGenderDropdown] = useState([]);
  const [symptoms, setSymptoms] = useState([]);


  // get dropdown values from api
  const getAllDropdown = () => {
    axiosInstance
      .get("/tests/prerequisite")
      .then((response) => {
        let thisData = response.data.data;
        setGenderDropdown(thisData.GENDER);
        setSymptoms(thisData.SYMPTOMS);
        let objArray = [] 

        thisData.SYMPTOMS.map(function (item, index) {
          objArray.push({
            id: index,
            name: item,
            status: false
          })
        })
        setSymArray(objArray)
      })
      .catch((error) => {
      });
  };

  useEffect(() => {
    getAllDropdown()
  }, [])


  let genderItems = [] 

  genderDropdown.map(function (item) {
    genderItems.push({
      label: item,
      value: item
    })
  })

  useEffect(() => {
    document.title =  'Zain care | High-Quality, Affordable Healthcare at your Home';
    updateMeta('description', `Blood Tests with innovative SMART Lab reports within 24 hours; CAP-Accredited labs. Book online to schedule your test today with FREE Home collection`);
    updateMeta('keywords', `Blood Tests,Diabetic package, zaincare, book home test online`);
}, []);


  return (
    <div className='bg-white pb-5 h-screen'>
      <div className=''>
        {/* heading and navigate back to home */}
        <div className='border-b border-[#EBEEF2] p-4'>
          <img
            width={8}
            height={6}
            className='relative left-[10px] top-[20px]'
            src='/images/icons/backArrow.svg'
            alt='Back Arrow'
            onClick={() => { gotoFilter ? setGotoFilter(false) : navigate('/') }} />
          <p className={`text-lg ${gotoFilter ? 'font-satoshiMedium' : 'font-satoshiBold'} text-blueShadow text-center`}>{
            gotoFilter ? 'Filter' : 'Search for Tests and Packages'
          }</p>
        </div>

        {
          !gotoFilter && (
            <>


              <div className='p-5 flex flex-col'>

                <div className='flex justify-center items-center'>
                  <input
                    name="search_key"
                    value={filterParams?.search_key}
                    onChange={handleFilterChange}
                    className='w-full h-[47px] outline-none border border-blueShadow text-base bg-transparent p-3 font-satoshiMedium text-[#323232] rounded-lg'
                    placeholder='Search for Tests / Packages' />
                  <img onClick={handleFilterApply} className='relative right-[10px] mr-[-10px]'
                  src='/images/icons/blueSearchIcon.svg' alt='Search Icon' />
                </div>
                <br />


                <div onClick={() => setGotoFilter(true)} className='flex flex-row justify-center items-center gap-2 overflow-y-auto'>
                  <CustomSelectInput
                    readonly={true}
                    width="lg:w-[137px] md:w-[137px] w-[107px]"
                    // handleChange={handleChange}
                    options={genderDropdown}
                    label="Gender"
                    name="gender"
                    value={filterParams?.gender}
                  />
                  
                <div className='relative bottom-[1px]'>
                <CustomInput
                    rows={1}
                    width='lg:w-[100px] md:w-[88px] w-[88px]'
                    disabled={false}
                    readOnly={true}
                    value={value}
                    label='Age'
                    name='age'
                />
                </div>
                </div>

                {/* Search Results */}

                <div className="md:mt-[18px] mt-[26px] lg:hidden block">
                  {active === "tests" && (
                    <>
                      <div className="grid grid-cols-2 items-center">
                        <button className="bg-[#F4F7FA]">
                          <div className="flex flex-row gap-[10px] justify-center items-center py-3">
                            <p className="text-[#363636] font-satoshiBold text-[18px]">
                              Tests{" "}
                            </p>
                            <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                              {/* 134 */}
                              {totalTests}
                            </p>
                          </div>
                        </button>

                        <button
                          onClick={() => {
                            setActive("packages");
                          }}
                        >
                          <div className="flex flex-row gap-[10px] justify-center items-center py-3 opacity-60">
                            <p className="text-[#363636] font-satoshiBold text-[18px]">
                              Packages{" "}
                            </p>
                            <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                              {/* 56 */}
                              {totalPackages}
                            </p>
                          </div>
                        </button>
                      </div>
                      <br />

                      <div
                        className={`px-5 ${testsList.length >= 5
                          ? "h-[380px]"
                          : "h-max-fit"
                          } grid  gap-[12px] grid-flow-row ${testsList.length > 5 ? "overflow-y-scroll" : ""
                          }`}
                      >
                        {isLoading ? (
                          <div className="flex flex-col gap-2 justify-center items-center">
                            {[1, 1, 1, 1, 1].map((item) => (
                              <Skeleton
                                key={uuid()}
                                variant="rectangular"
                                width={310}
                                height={60}
                                sx={{ borderRadius: "14px" }}
                              />
                            ))}
                          </div>
                        ) : (
                          <>
                            {testsList.length == 0 ? (
                              <p className="font-satoshiBold text-base flex justify-center items-center">
                                No Tests Found!
                              </p>
                            ) : (
                              <>
                                {testsList.map((item) => (
                                  <Link
                                    key={uuid()}
                                    to={`/tests/${item.slug}`}
                                  >
                                    <div className="px-[29px] py-[24px] border cursor-pointer border-[#E9EEF5] rounded-xl hover:bg-[#EBEEF2]">
                                      <p className="text-xs font-satoshiMedium text-[#363636]">
                                        {item?.name}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {active === "packages" && (
                    <>
                      <div className="grid grid-cols-2 items-center">
                        <button
                          onClick={() => {
                            setActive("tests");
                          }}
                        >
                          <div className="flex flex-row gap-[10px] justify-center items-center py-3 opacity-60">
                            <p className="text-[#363636] font-satoshiBold text-[18px]">
                              Tests{" "}
                            </p>
                            <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                              {/* 134 */}
                              {totalTests}
                            </p>
                          </div>
                        </button>

                        <button className="bg-[#F4F7FA]">
                          <div className="flex flex-row gap-[10px] justify-center items-center py-3">
                            <p className="text-[#363636] font-satoshiBold text-[18px]">
                              Packages{" "}
                            </p>
                            <p className="flex font-satoshiMedium justify-center items-center  px-2 rounded-xl bg-[#EAEDF2] text-[#33B0AA]">
                              {/* 56 */}
                              {totalPackages}
                            </p>
                          </div>
                        </button>
                      </div>
                      <br />
                      <div
                        className={`px-5 ${packagesList.length >= 5
                          ? "h-[380px]"
                          : "h-max-fit"
                          } grid gap-[12px] grid-flow-row ${packagesList.length > 5
                            ? "overflow-y-scroll"
                            : ""
                          }`}
                      >
                        {isLoading ? (
                          <div className="flex flex-col gap-2 justify-center items-center">
                            {[1, 1, 1, 1, 1].map((item) => (
                              <Skeleton
                                key={uuid()}
                                variant="rectangular"
                                width={310}
                                height={60}
                                sx={{ borderRadius: "14px" }}
                              />
                            ))}
                          </div>
                        ) : (
                          <>
                            {packagesList.length == 0 ? (
                              <p className="font-satoshiBold text-base flex justify-center items-center">
                                No Packages Found!
                              </p>
                            ) : (
                              <>
                                {packagesList.map((item) => (
                                  <Link
                                    key={uuid()}
                                    to={`/packages/${item.slug}`}
                                  >
                                    <div className="px-[29px] py-[24px] cursor-pointer border border-[#E9EEF5] rounded-xl hover:bg-[#EBEEF2]">
                                      <p className="text-xs font-satoshiMedium text-[#363636]">
                                        {item?.name}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <p className='text-base font-satoshiBold underline cursor-pointer text-blueShadow text-center'
                onClick={() => navigate("/packages")}>Show all</p>
            </>
          )
        }

        {
          gotoFilter && (
            <>
              <div className=' px-10'>

                <p className='text-sm font-satoshiBold text-Verdigris my-2'>Age Group</p>
                {/* <input type='range'/> */}
                <RangeSlider
                  value={value}
                  handleChange={handleChange} />
                <p className='text-sm text-[#323232] font-satoshiBold my-2'>{value} years</p>
                {/* <br /> */}
                <p className='text-sm font-satoshiBold text-Verdigris my-2'>Gender</p>
                <div className='my-2 scale-[0.9] relative right-[15px]'>
                  <RadioButton
                    name="gender"
                    defaultValue={filterParams?.gender}
                    onChange={handleFilterChange}
                    items={genderItems}
                    row
                  />
                </div>
              </div>
              <hr />
              <br />
              <div className='flex flex-row justify-center gap-5 items-center '>
                <button
                  onClick={resetFilter}
                  className='text-blueShadow text-base underline w-[121px] h-[42px] font-satoshiBold'>Reset</button>
                <button
                  onClick={() => {
                    handleFilterApply()
                    setGotoFilter(false)
                  }}
                  className="h-[42px] w-[121px]  border-blueShadow rounded-lg bg-blueShadow text-white text-base font-satoshiMedium"
                >
                  <p className="flex flex-row gap-2 justify-center items-center">
                    Apply
                  </p>
                </button>
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default ApplyFilter