import React from "react";
import { uuid } from "../../../helpers/helpers";
import { usePagination, DOTS } from "./usePagination";

export const CustomPagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        currentItemsCount
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const lastPage =
        paginationRange && paginationRange[paginationRange.length - 1];

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="flex flex-1 justify-between sm:hidden">
            
                <a 
                onClick={onPrevious}
                 className={`${
                    currentPage === 1 ? "pointer-events-none" : "cursor-pointer"
                } relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50`}>
                    Previous
                </a>
                
                <a onClick={onNext}  className={`  ${
                              currentPage === lastPage
                                  ? "pointer-events-none"
                                  : "cursor-pointer"
                          } relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50`}>
                    Next
                </a>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-base text-BlueJay flex gap-1">
                        Showing
                        <span className="font-medium">{(currentPage != 1 ? ((currentPage - 1) * 10) : 1)}</span>
                        to
                        <span className="font-medium">{currentItemsCount + (currentPage != 1 ? ((currentPage - 1) * 10) : 0)}</span>
                        of
                        <span className="font-medium">{totalCount}</span>
                        results
                    </p>
                </div>
                <div>
                    <nav
                        className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                        aria-label="Pagination"
                    >
                        <a
                            onClick={onPrevious}
                            className={`${
                                currentPage === 1 ? "pointer-events-none" : "cursor-pointer"
                            } relative inline-flex items-center rounded-l-md px-2 py-2
                           text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0`}
                        >
                            <span className="sr-only">Previous</span>
                            <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>
                        {paginationRange?.map((pageNumber) => {
                            if (pageNumber === DOTS) {
                                return (
                                    <a
                                        key={uuid()}
                                        aria-current="page"
                                        className="relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-BlueJay focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        &#8230;
                                    </a>
                                );
                            }

                            return (
                                <a
                                    key={uuid()}
                                    aria-current="page"
                                    onClick={() => onPageChange(pageNumber)}
                                    className={`relative z-10 inline-flex items-center px-4 py-2 cursor-pointer
                                        text-sm font-semibold  focus:z-20 focus-visible:outline focus-visible:outline-2 
                                            focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                                                pageNumber === currentPage
                                                    ? "bg-BlueJay text-white"
                                                    : " text-BlueJay ring-1 ring-inset ring-gray-300"
                                            }`}
                                >
                                    {pageNumber}
                                </a>
                            );
                        })}

                        <a
                            onClick={onNext}
                            className={`relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0
                          ${
                              currentPage === lastPage
                                  ? "pointer-events-none"
                                  : "cursor-pointer"
                          }
                          `}
                        >
                            <span className="sr-only">Next</span>
                            <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default CustomPagination;
