import React, { useRef, useEffect } from 'react'
import CoreValues from '../AboutUs/CoreValues'
import OpeningBanner from '../AboutUs/OpeningBanner'
import { uuid } from '../../helpers/helpers'
import updateMeta from '../../helpers/seo-meta'

const content = [
  {
    id: 'firstContentID',
    title: 'Quality & Standards',
    desc: 'Our unwavering commitment to excellence demands that it be delivered with the utmost quality & standard, setting new benchmarks while having a positive impact on the lives that we serve',
    logo: '/images/core_values/quality.svg',
  },
  {
    id: uuid(),
    title: 'Affordability',
    desc: 'Healthcare should no longer be a luxury or a distant dream, but achievable realities that are affordable',
    logo: '/images/core_values/afford.svg',

  },
  {
    id: uuid(),
    title: 'Accessibility',
    desc: "Accessible at the comforts of one’s home",
    logo: '/images/core_values/access.svg',

  },
  {
    id: 'fourthContentID',
    title: 'Technology driven',
    desc: 'We strive to make healthcare as efficient and effective as possible, eliminating waste and errors, while remaining frugal and bridging the gaps inherent in conventional approaches by embracing advancing technologies',
    logo: '/images/core_values/tech.svg',
  },
  {
    id: uuid(),
    title: 'Compassion',
    desc: 'We strive to make healthcare as efficient and effective as possible, eliminating waste and errors, while remaining frugal and bridging the gaps inherent in conventional approaches by embracing advancing technologies',
    logo: '/images/core_values/tech.svg'
  },
  {
    id: uuid(),
    title: 'Integrity',
    desc: 'We hold ourselves to the highest standards of honesty, ethics, and professionalism. We are committed to transparency and accountability in all our actions',
    logo: '/images/core_values/integrity.svg'
  },

]

const About = () => {

  const coreValue = useRef()

  // scroll into view
  const handleExplore = () => {
    coreValue.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    })
  }

  useEffect(() => {
    document.title = 'About Us | Zain Care - Delivering High Quality Healthcare';
    updateMeta('description', `We strive to redefine healthcare and to promote overall wellness by optimization of your health by achieving limitless potential of human body.`);
    updateMeta('keywords', `home health care near me, home health care, home health, home health care services`);
  }, []);

  return (
    <div className='mb-32 md:mb-48 relative top-[120px]'>
      <OpeningBanner handleExplore={handleExplore} />

      {/* mission */}
      <div className=' bg-mission bg-cover p-8 sm:p-16  mt-20 sm:mt-40 '>
        <div className='  w-[80%] lg:w-1/2 m-auto flex flex-col gap-4'>
          <p className='text-white font-bold text-2xl lg:text-4xl font-jostRegular text-center'>
            Our Mission is to Help you live Better.
          </p>

          <p className='text-center text-white opacity-50  '>
            Our mission is to create a successful model in Dubai, a city of the future, which will set an example for the rest of the world to follow. We are driven by our passion for healthcare and our unwavering commitment to excellence, and we are excited to be part of this transformative journey.
          </p>
        </div>
      </div>
      {/* passed ref as prop */}
      <CoreValues content={content} coreValue={coreValue} />
    </div>
  )
}

export default About
