import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { bytesToKb } from "../../../helpers/helpers";
import React from 'react'

const FileUpload = ({
    id,
    label,
    acceptMimeTypes = ['image/jpeg','application/pdf'],
    setImage,
    title = "Drag and Drop PDF here",
    maxSize = 3, // in MB,
    imageUrl = "",
    styleType = "lg",
    filename,
    previewData = "",
    setPreviewMeta,
    removeImage,
    error,
}) => {
    const [isUploaded, setUpload] = useState(false);
    const [active, setActive] = useState(false);
    const [imageMeta, setImagePreview] = useState({
        name: "",
        url: "",
        type: "",
    } );

    const [thisFile, setFile] = useState({} );

    useEffect(() => {
        // show preview image
        if (imageUrl && imageUrl.startsWith("https")) {
            setUpload(true);
            const name = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
            const lastDot = name.lastIndexOf(".");
            const ext = name.substring(lastDot + 1);
            setImagePreview({
                name,
                url: imageUrl,
                type: ext === 'pdf' ? 'application/pdf' : 'image/png',
            });
        }

        if (previewData) {
            setImagePreview(previewData);
            setUpload(true);
        }
    }, [previewData, imageUrl]);

    const validation = (file) => {
        const maxSizeInBytes = Number(maxSize) * 1024 ** 2;
        if (file.size > maxSizeInBytes) {
            // show toast message;
            toast.error(`File Size Exceeds the ${maxSize}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        if (!acceptMimeTypes.includes(file.type)) {
            toast.error("Unsupported file selected!", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }

        return true;
    };

    const convertBase64 = (file) => {
        const isValid = validation(file); // validation for file type and size
        if (isValid) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const meta = {
                    name: file.name,
                    url: reader.result,
                    type: file.type,
                };
                setImagePreview(meta);
                // setPreviewMeta && setPreviewMeta(meta);
                if (setPreviewMeta) {
                    setPreviewMeta(meta);
                }
                setUpload(true);
                setActive(true);
                setImage({
                    url: reader.result || "",
                    name: filename,
                    file,
                    preview: meta,
                });
            };
        }
    };

    const chooseFile = (e) => {
        e.preventDefault();
        const ele = document.getElementById(id);
        if (ele) ele.click();
    };
    const onChange = (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        setFile(file);
        convertBase64(file);
    };

    const handleCancel = () => {
        setUpload(false);
        setActive(false);
        if (removeImage) {
            removeImage("");
        }
    };

    // drag and drop functionalities
    const onDrag = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragover") {
            setActive(true);
        }

        if (event.type === "dragleave") {
            setActive(false);
        }

        if (event.type === "drop") {
            const file = event.dataTransfer.files[0];
            convertBase64(file);
        }
    };
    return (
        <div
            className={`relative file-upload-box w-full h-fit ${
                error && "file-upload-error"
            } ${active ? "file-upload-active" : ""}`}
        >
            {!isUploaded ? (
                <>
                    <label
                        htmlFor={id}
                        className={`cursor-pointer ${
                            styleType === "md" ? "hidden" : "block"
                        }`}
                        onDragOver={(e) => onDrag(e)}
                        onDragLeave={(e) => {
                            onDrag(e);
                        }}
                        onDrop={(e) => {
                            onDrag(e);
                        }}
                    >
                        <p className="px-[50px] py-3 border-2 cursor-pointer hover:shadow-md border-[#909EB4] rounded-lg text-[#3C567E] font-satoshiBold text-base">
                            Add File
                        </p>
                    </label>
                    <div
                        className={`flex justify-start items-center gap-5  ${
                            styleType === "md" ? "pt-0" : "pt-6"
                        }`}
                    >
                        <div>
                            <input
                                type="file"
                                id={id}
                                onChange={onChange}
                                accept={acceptMimeTypes.toString()}
                                className="hidden"
                            />
                            {/* <div
                                className="upload-btn   flex cursor-pointer justify-center items-center rounded-lg"
                                id={id}
                                role="presentation"
                                onClick={(e) => chooseFile(e)}
                            >
                                <p className="lg:px-[50px] lg:py-3 md:px-[50px] md:py-3 py-4 px-5 border-2 cursor-pointer hover:shadow-md border-[#909EB4] rounded-lg text-[#3C567E] font-satoshiBold text-base">
                                    Add File
                                </p>
                            </div> */}
                            <div
                                onClick={(e) => chooseFile(e)}
                                className="bg-white cursor-pointer shadow-[0px_22px_70px_4px_rgba(0,0,0,0.1)] rounded-lg lg:w-[405px] md:w-[382px] w-[300px] lg:h-[327px] h-[110px]  flex flex-col justify-center items-center"
                            >
                                <p className="text-[#363636] text-base font-satoshiBold">
                                    Drop files to upload
                                </p>
                                <p className="text-[#363636] text-base font-satoshiBold">
                                    {" "}
                                    or{" "}
                                    <span className="w-max-fit border-b border-blueShadow text-blueShadow">
                                        browse files
                                    </span>
                                </p>
                                <p className="text-blueShadow opacity-70 text-[14px] relative top-[50px] lg:block hidden">
                                    Supported files Jpeg/Jpg/Pdf. File size upto
                                    3mb.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                   
                    <div className="lg:w-[405px] md:w-[382px] w-[308px] lg:h-[200px] ">
                        <div className="flex flex-row justify-between items-center p-[12px] rounded-lg shadow-[0px_22px_70px_4px_rgba(0,0,0,0.2)]">
                            <div className=" flex items-center gap-[26px]">
                                <div className="lg:w-[64px] w-[47px] lg:h-[64px] h-[47px] bg-Verdigris rounded-lg flex justify-center items-center text-white font-satoshiBold text-sm">
                                    {imageMeta.name.split('.').pop()?.toUpperCase()}
                                </div>
                                <div className="text-start">
                                    <p className="text-[#363636] text-base  font-satoshiMedium">
                                        {imageMeta.name.substring(0, 10)}
                                        ...
                                    </p>
                                    <p className="text-[#363636] text-sm ">
                                        {   bytesToKb(thisFile?.size )}
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={handleCancel}
                                type="button"
                                className="bg-none text-[#363636] font-satoshiBold text-base flex cursor-pointer justify-center items-center underline"
                            >
                                Remove
                            </button>
                        </div>

                        {/* <div className="flex flex-row justify-between items-center mt-[11px] p-[12px] rounded-lg shadow-[0px_22px_70px_4px_rgba(0,0,0,0.2)]">
                            <div className=" flex items-center gap-[26px]">
                                <div className="w-[64px] h-[64px] bg-Verdigris rounded-lg flex justify-center items-center text-white font-satoshiBold text-sm">
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.76562 10.6406V6.23438H0.359375V4.76562H4.76562V0.359375H6.23438V4.76562H10.6406V6.23438H6.23438V10.6406H4.76562Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <div className="text-start">
                                    <p className="text-[#363636] text-base  font-satoshiMedium">
                                        Add Another
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {/* <div
                        className={`flex justify-between items-center  ${
                            styleType === "md" ? "pt-0" : "pt-6"
                        }`}
                    >
                        <div className="flex justify-center items-center space-x-6">
                            <p className="text-SpaceCadet text-sm">
                                {imageMeta.name.length > 10 ? (
                                    <>
                                        {imageMeta.name.substring(0, 10)}
                                        ...
                                    </>
                                ) : (
                                    <>{imageMeta.name}</>
                                )}
                            </p>
                        </div>

                        <div>
                            <button
                                onClick={handleCancel}
                                type="button"
                                className="bg-none text-[#363636] font-satoshiBold text-base flex cursor-pointer justify-center items-center underline"
                            >
                                Remove
                            </button>
                        </div>
                    </div> */}
                </>
            )}

            {error && (
                <p className="absolute -bottom-6 ml-3 text-[#EF4949] text-xs">
                    {error}
                </p>
            )}
        </div>
    );
};

export default FileUpload;
