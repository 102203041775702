import React from 'react'

function AccordionTitle({ title }) {
    return (
        <p className='text-Verdigris font-satoshiBold lg:text-[18px] text-[16px] text-center'>
            {title}
        </p>
    )
}

export default AccordionTitle
