import { makeStyles } from '@mui/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import React from 'react'

export const useStyles = makeStyles({
    error: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
            borderRadius: '8px',
        },
    },
    select: {
        '& ul': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '& li': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    icon: {
        fill: 'white',
    },
    root: {
        '& .MuiOutlinedInput-input': {
            color: '#3C567E',
        },
        '& .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7E8ED',
            borderRadius: '8px',
        },
        '&:hover .MuiOutlinedInput-input': {
            color: '#3C567E',
        },
        '&:hover .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#3C567E',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#33B0AA',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#33B0AA',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#33B0AA',
        },
    },
})


export const SelectInput= ({
    handleChange,
    value,
    label,
    error,
    helperText,
    options,
    width,
    name,
    required,
    readonly,
    disabled,
}) => {
    const classes = useStyles()
    return (
        <div className={`${width} `}>
            <FormControl
                className={!error ? classes.root : classes.error}
                fullWidth
                error={error}
                disabled={disabled}
            >
                <InputLabel id='select-input-label'>{label}</InputLabel>
                <Select
                    labelId='select-input-label'
                    style={{
                        borderRadius: '8px',
                        width,
                        backgroundColor: 'white',
                    }}
                    IconComponent={(_props) => (
                        <ExpandMoreIcon sx={{ color: '#E7E8ED', marginRight: '20px' }} />
                    )}
                    MenuProps={{
                        // disableScrollLock: true,

                        sx: {
                            '&& .MuiMenuItem-root': {
                                backgroundColor: '#fcfcfc',
                                border: '1px solid #E7E8ED !important',
                                color: '#3C567E',
                                '&:hover': {
                                    backgroundColor: '#F5FBFD !important',
                                },
                            },
                            '&& .MuiMenu-list': {
                                padding: '0',
                            },

                            '&& .Mui-selected': {
                                color: '#33B0AA !important',
                                backgroundColor: '#F5FBFD',
                            },
                        },
                    }}
                    sx={{
                        color: '#3C567E',
                        '.MuiSvgIcon-root ': {
                            fill: '#3C567E !important',
                        },
                    }}
                    required={required}
                    value={value}
                    onChange={handleChange}
                    label={label}
                    name={name}
                    error={error}
                    fullWidth
                    readOnly={readonly}
                >
                    {options.length > 0 ? (
                        options?.map((item) => (
                            <MenuItem value={item.id ? item.id : item.name}>
                                {item?.name ||
                                    item?.address_1 ||
                                    item?.poc_name ||
                                    item?.account_name ||
                                    item?.company_name ||
                                    item?.id}
                                {item.start &&
                                    `-${item?.start} to
              ${item?.end}`}
                            </MenuItem>
                        ))
                    ) : (
                        <p className='text-SpaceCadet p-4 text-xl'>Options Not found !</p>
                    )}
                </Select>

                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        </div>
    )
}
