import React from 'react'

const Previous = ({ onClick }) => {
    return (
        <div className='flex justify-center items-center'>
            <button
                onClick={onClick}
                className='bg-[#EBEEF2] text-blueShadow text-[14px] font-satoshiBold py-[13px] px-[32px] rounded-lg '
            >
                Previous
            </button>
        </div>
    )
}

export default Previous
