import React from "react";
import { Link } from "react-router-dom";
import MultiPackagesSlider from "./PackagesSlider/MultiPackagesSlider";
const Packages = ({ packagesList, handleAddToCartOpen }) => {


    return (
        <>
            {
                packagesList.length > 1 &&
                (
                    <div className=" max-w-[1440px] m-auto ">
                        <div className="flex flex-col justify-center">
                            <div className=" m-auto">
                                <p className="text-base lg:text-[22px] text-center text-BlueJay opacity-50">
                                    Popular Packages
                                </p>
                                <p className=" font-jostSemibold text-center font-semibold sm:font-bold  text-2xl lg:text-4xl text-blueShadow">
                                    Valuable and Affordable
                                </p>
                            </div>
                        </div>

                        <MultiPackagesSlider
                            content={packagesList}
                            handleAddToCartOpen={handleAddToCartOpen} />

                        <div className="flex justify-center my-10">
                            <Link to="/packages">
                                <p className="text-blueShadow font-bold underline text-center cursor-pointer">
                                    View all Packages
                                </p>
                            </Link>
                        </div>

                    </div>
                )
            }
        </>
    );
};

export default Packages;
