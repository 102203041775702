import React from 'react'
import Radio from '@mui/material/Radio'

function AddressRadioSelect({ handleRadio, item }) {

    return (
        <label
            htmlFor='select'
            className={` cursor-pointer bg-[#F4F7FA] rounded-lg w-full py-2 px-4 focus-within:border focus-within:border-[#33B0AA]`}
        >
            <div className='text-left'>
                <div className='text-blueShadow flex flex-row items-center justify-between'>
                    <p className='font-satoshiBold text-[18px]'>{item?.location_type}</p>
                    <Radio
                        checked={item?.checked}
                        onChange={handleRadio}
                        // value={params?.address}
                        name='address'
                        inputProps={{ 'aria-label': 'A' }}
                    />
                </div>
                <p className='text-secondaryLight text-[16px] font-medium'>
                    {item?.house_no}, {item?.building_name}, {item?.locality}, {item?.city}
                </p>
            </div>
        </label>
    )
}

export default AddressRadioSelect
