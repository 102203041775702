import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CustomButton from '../../../common/Button'
import { TimeandDatePicker } from '../../../common/DateTimePicker'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
}


const Reschedule = ({
    open,
    handleClose,
    submit,
    rescheduleDateTime,
    formErrors,
    dateTime
}) => {



    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box
                className='md:w-[450px] w-[320px] p-4 md:p-6'
                sx={style}
                style={{
                    textAlign: 'center',
                    borderRadius: '16px',
                    outline: 'none',
                    // padding: '10px',
                }}
            >
                <div className='flex flex-col justify-center items-center gap-4'>
                    <p className='font-satoshiBold text-BlueJay text-2xl'>Reschedule</p>
                    <p className='text-BlueJay font-satoshiMedium'>Select the Appropriate Date and time for Sample collection</p>
                    <div className='w-full'>
                        <TimeandDatePicker
                            handleChange={rescheduleDateTime}
                            value={dateTime}
                            error={formErrors?.dateTime}
                        />
                    </div>
                    <div className='mt-2 flex flex-row-reverse justify-end ml-auto lg:ml-0 items-center lg:flex-col lg:justify-center gap-10 md:gap-20 lg:gap-4'>
                        <CustomButton
                            borderRadius='0.5rem'
                            variant='secondary-contained'
                            size='large'
                            width='md:w-[200px] w-[150px]'
                            onClick={submit}
                        >
                            Submit
                        </CustomButton>
                        <p
                            className='w-fit cursor-pointer text-BlueJay border-b-[2px] border-BlueJay font-satoshiMedium'
                            onClick={handleClose}
                        >
                            Cancel
                        </p>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
export default Reschedule