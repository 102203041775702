import React, { useEffect, useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "./common/Button";
import { useState } from "react";
import { Box, Modal } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Login from "./Pages/LoginModal/Login";
import Validator from "validatorjs";
import { loggedOut, login, verifyOtp } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../helpers/axios";
import { getLoggedInUser, isUserLoggedIn, logout } from "../helpers/auth";
import Badge from "@mui/material/Badge";
import useOutsideClick from "./common/ClickOutsideHook";
import { updateCart } from "../features/cart/cartSlice";
import { getUserName, showToastMessage, uuid } from "../helpers/helpers";
import LogoutPopup from "./Pages/LoginModal/LogoutPopup";

const menuList = [
    {
        name: "Home",
        link: "/",
    },
    {
        name: "Tests & Packages",
        link: "/packages",
    },
    {
        name: "About Us",
        link: "/about-us",
    },
    {
        name: "FAQ's",
        link: "/faq",
    },
    {
        name: "Contact Us",
        link: "/contact-us",
    },
];

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#3C567E",
    width: "250px",
    boxShadow: 24,
    p: 2,
};

const Header = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [loginflag, setloginflag] = useState(false);
    const [otp, setOTP] = useState("");
    const initial = {
        phone: "",
        otp: otp,
    };

    const [params, setParams] = useState(initial);
    const [errors, setErrors] = useState(initial);
    const [dropdown, setDropdown] = useState(false);
    const { isLoading, login_successful, show_verify_otp } = useSelector((state) => state.auth);
    const cart = useSelector((state) => state.cart);
    const isLoggedIn = isUserLoggedIn();
    const impactRef = useRef();
    const [logOutPopup, setLogOutPopup] = useState(false);
    useOutsideClick(impactRef, () => setDropdown(false));
    const [counter, setCounter] = useState(60);
    const navbarRef = useRef(null);
    ;

    useEffect(() => {
        if (login_successful) {
            setLoginOpen(false);
            handleClose();
        }
    }, [login_successful])

    useEffect(() => {
        if (show_verify_otp) {
            setloginflag(true);
            setCounter(60);
        }
    }, [show_verify_otp])

    useEffect(() => {
        let x = "";
        if (counter > 0) {
            x = setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        } else {
            setloginflag(false);
        }
        return () => {
            clearInterval(x);
        };
    }, [counter]);

    const user = useMemo(() => {
        const user = getLoggedInUser();
        return user;
    }, [isLoggedIn]);

    const handleLoginClose = () => setLoginOpen(false);

    const handleLogoutOpen = () => {
        setLogOutPopup(true);
        setDropdown(false);
    };

    const handleLogoutClose = () => setLogOutPopup(false);

    const handleOtp = (otp) => {
        setOTP(otp);
    };

    useOutsideClick(impactRef, () => setDropdown(false));

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setParams(initial);
        setOTP("");
        setloginflag(false);
    };

    const handleLoginOpen = () => {
        setLoginOpen(true);
        handleClose();
    };

    const handlePhone = (e) => {
        if (e.target) {
            let { name, value } = e.target
            updateParams([{ name, value }])
        }
        if (e.url) {
            updateParams([{ name: e?.name, value: e?.url }])
        }

        setErrors({})
    }

    const updateParams = (records) => {
        const newParams = JSON.parse(JSON.stringify(params))
        Object.keys(records).forEach((key) => (newParams[records[key].name] = records[key].value))
        setParams(newParams)
    }


    const sendOtp = () => {

        let payload = {
            phone: `${params.phone}`,
        };
        const rules = {
            phone: "required|max:15",
        };

        let regex = /^(?:\+971|0(0971)?)(?:[234679]|5[01256])[0-9]{7}$/;
        if (!regex.test(`${params.phone}`)) {
            setErrors(({ ...errors, phone: 'Invalid Phone Number' }));
            return
        }

        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setErrors(fieldErrors);
            return false;
        }
        if (!regex.test(`${params.phone}`)) {
            setErrors((prevErrors) => ({ ...prevErrors, phone: 'Invalid Phone Number' }))
            return
        }


        dispatch(login(payload));
        setErrors({});
        return true;
    };

    const handleSubmit = () => {

        let payload = {
            phone: `${params.phone}`,
            otp: otp,
        };
        const rules = {
            otp: "required|numeric|digits:5",
        };
        const validation = new Validator(payload, rules);
        if (validation.fails()) {
            const fieldErrors = {};
            Object.keys(validation.errors.errors).forEach((key) => {
                fieldErrors[key] = validation.errors.errors[key][0];
            });
            setErrors(fieldErrors);
            return false;
        }

        dispatch(verifyOtp(payload));
        setErrors({});
        return true;
    };

    const signOut = () => {
        logout();
        typeof window !== 'undefined' ? () => localStorage.removeItem("step") : () => { };
        showToastMessage("Successfully signed out", "success");
        handleLogoutClose();
        axiosInstance.get("/cart").then((response) => {
            dispatch(updateCart(response.data));
        });
        dispatch(loggedOut({}));
        navigate("/");
    };

    const reEnterNumber = () => {
        setloginflag(false);
        setCounter(60);
        setOTP("");
    };

    useEffect(() => {
        axiosInstance.get("/cart").then((response) => {
            dispatch(updateCart(response.data));
        });
    }, []);

    const goToPage = (option) => {
        setDropdown(false);
        typeof window !== 'undefined' ? () => localStorage.setItem("step", option) : () => { };
    };

    const toPrivacyPolicy = () => {
        handleLoginClose();
        navigate("/privacy-policy");
    };

    return (
        <div
            ref={navbarRef}
            className={`fixed z-50 max-w-[1440px] left-0 right-0 p-6 m-auto
            ${pathname.includes("my-profile") ||
                    pathname.includes("my-family-friends") ||
                    pathname.includes("bookings") ||
                    pathname.includes("reports") ||
                    pathname.includes("manage-address") ||
                    pathname.includes("feedback")
                    ? "hidden md:block"
                    : pathname === '/search_filter' ? 'hidden' : "block"
                }`}
        >
            <header className="lg:px-12 lg:py-0  md:px-4 md:py-4 py-4 px-4  bg-BlueJay text-primary   rounded-2xl shadow-lg shadow-blue-500/40  ">
                <div className="">
                    <div className="flex flex-row justify-between items-center py-0 lg:py-4  ">
                        <Link to="/" className="text-accent header-title">
                            <img
                                className="header-logo cursor-pointer"
                                src='/images/images/logo.svg'
                                alt='Zaincare Logo'
                            />
                        </Link>
                        <nav className="hidden  lg:flex">
                            <ul className="flex gap-8">
                                {React.Children.toArray(
                                    menuList.map((x, i) => (
                                        <li key={uuid()}>
                                            <Link
                                                to={x.link}
                                                smooth='true'
                                                className={` p-3 block  text-base font-medium font-jostRegular   ${pathname === x.link
                                                    ? "text-Verdigris "
                                                    : "text-white "
                                                    }`}
                                            >
                                                {x.name}
                                            </Link>
                                        </li>
                                    ))
                                )}
                            </ul>
                        </nav>

                        <div className="flex flex-row items-center justify-around gap-5">
                            <div className="hidden lg:flex gap-5 items-center">
                                {user && !isLoading ? (
                                    <div className="flex flex-col justify-start items-start relative">
                                        <p className="text-xs font-jostRegular text-white opacity-50">
                                            Welcome
                                        </p>
                                        <p
                                            onClick={() =>
                                                setDropdown(!dropdown)
                                            }
                                            className=" text-base font-medium font-jostRegular cursor-pointer text-white flex items-center gap-1"
                                        >
                                            <span> {getUserName(user)} </span>
                                            <span>
                                                <svg
                                                    width="14"
                                                    height="7"
                                                    viewBox="0 0 14 7"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.2802 0.966797L7.93355 5.31346C7.42021 5.8268 6.58022 5.8268 6.06688 5.31346L1.72021 0.966797"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeMiterlimit="10"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </p>
                                        {dropdown && (
                                            <ul
                                                ref={impactRef}
                                                className="absolute top-12 right-0 bg-white p-4 flex flex-col gap-2 text-center w-[140px] rounded-lg
                          text-base font-medium font-jostRegular text-BlueJay"
                                            >
                                                <Link
                                                    to="/user-profile"
                                                    onClick={() => goToPage(1)}
                                                >
                                                    <li>My Profile</li>
                                                </Link>
                                                <Link
                                                    to="/user-profile"
                                                    onClick={() => goToPage(4)}
                                                >
                                                    <li>My Bookings</li>
                                                </Link>
                                                <Link
                                                    to="/user-profile"
                                                    onClick={() => goToPage(5)}
                                                >
                                                    <li>My Reports</li>
                                                </Link>
                                                <li
                                                    className="cursor-pointer"
                                                    onClick={handleLogoutOpen}
                                                >
                                                    Sign Out
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                ) : (
                                    <CustomButton
                                        onClick={handleLoginOpen}
                                        borderRadius="0.5rem"
                                        width="w-[150px] h-[56px]"
                                        variant="contained"
                                        size="large"
                                    >
                                        Login/ Sign Up
                                    </CustomButton>
                                )}
                            </div>
                            <img
                                className="sm:flex md:flex lg:hidden xl:hidden cursor-pointer hover:bg-secondaryLight p-2 rounded"
                                onClick={handleOpen}
                                src='/images/icons/menuIcon.svg'
                                alt='Menu Icon'
                            />
                            <Badge
                                badgeContent={cart?.count}
                                sx={{
                                    "& .MuiBadge-badge": {
                                        color: "#33B0AA",
                                        backgroundColor: "white",
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <Link to="/orders/checkout">
                                    <img
                                        className="cursor-pointer"
                                        src='/images/icons/cart.svg'
                                        alt='Cart Icon'
                                    />
                                </Link>
                            </Badge>
                        </div>
                    </div>
                </div>
            </header>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box
                    sx={style}
                    style={{
                        textAlign: "center",
                        borderRadius: "24px",
                        outline: "none",
                        padding: "25px",
                    }}
                >
                    <div className="flex justify-end">
                        <img
                            onClick={handleClose}
                            className="cursor-pointer"
                            src='/images/icons/closeButton.svg'
                            alt='Close button'
                        />
                    </div>
                    <div className="my-4 flex  gap-y-2 flex-col justify-center items-center  text-sm font-bold">
                        {user && !isLoading ? (
                            <>
                                <div className="flex flex-col justify-center items-center gap-2 mb-4">
                                    <img src='/images/icons/profileIcon.svg' alt="User Profile" />
                                    <p className="text-sm font-medium font-jostRegular text-white">
                                        {getUserName(user)}
                                    </p>
                                    <Link
                                        to="/user-profile"
                                        onClick={handleClose}
                                    >
                                        <p className={`border-b  font-jostRegular ${pathname === '/user-profile' ? 'text-Verdigris border-Verdigris' : 'text-white border-white'}`}>
                                            My Account
                                        </p>
                                    </Link>
                                </div>
                                {React.Children.toArray(menuList.map((item) => (
                                    <>
                                        {/* <span>{item.name}</span> */}
                                        <Link
                                            className={`${pathname === item.link ? 'text-Verdigris' : 'text-white'}`}
                                            key={uuid()}
                                            to={item.link}
                                            onClick={handleClose}
                                            smooth='true'
                                        >
                                            {item.name}
                                        </Link>
                                        <br />
                                    </>
                                )))}
                            </>
                        ) : (
                            <>
                                {React.Children.toArray(menuList.map((item) => (
                                    <>
                                        <Link
                                            key={uuid()}
                                            className={`${pathname === item.link ? 'text-Verdigris' : 'text-white'}`}
                                            to={item.link}
                                            onClick={handleClose}
                                            smooth='true'
                                        >
                                            {item.name}
                                        </Link>
                                        <br />
                                    </>
                                )))}
                                <CustomButton
                                    onClick={handleLoginOpen}
                                    borderRadius="0.5rem"
                                    width="w-fit h-[48px] text-[14px]"
                                    variant="contained"
                                    size="large"
                                >
                                    Login/ Sign Up
                                </CustomButton>
                            </>
                        )}
                    </div>
                </Box>
            </Modal>
            {loginOpen && (
                <Login
                    loginOpen={loginOpen}
                    handleLoginClose={handleLoginClose}
                    handleOtp={handleOtp}
                    otp={otp}
                    handleChange={handlePhone}
                    sendOtp={sendOtp}
                    errors={errors}
                    loginflag={loginflag}
                    setloginflag={setloginflag}
                    params={params}
                    handleSubmit={handleSubmit}
                    counter={counter}
                    reEnterNumber={reEnterNumber}
                    toPrivacyPolicy={toPrivacyPolicy}
                />
            )}

            {logOutPopup && (
                <LogoutPopup
                    logOutPopup={logOutPopup}
                    handleLogoutClose={handleLogoutClose}
                    signOut={signOut}
                />
            )}
        </div>
    );
};

export default Header;
